import React from "react"
import ReportsContainer from "../core/ReportsContainer"
import { useTranslation } from "react-i18next"

import { parsedUser } from "../../common/GetCurrentUser"
import moment from "moment"

const MostSoldProduct = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields = [
    {
      name: "from",
      title: t("from"),
      type: "date",
      cssColClass: "col-4",
      value: moment().add(-1, "months").format("YYYY-MM-DD"),
    },
    {
      name: "until",
      title: t("until"),
      type: "date",
      cssColClass: "col-4",
      value: moment().format("YYYY-MM-DD"),
    },
  ]
  const headers = [
    {
      name: "name",
      title: t("Name"),
      type: "text",
    },
    {
      name: "count",
      title: t("Count"),
      type: "int",
      class: "text-center",
    },
    {
      name: "total",
      title: t("Total"),
      type: "money",
      class: "text-end",
    },
  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("mostSoldProducts")}
      filterFields={filterFields}
      headers={headers}
      endpoint={`products/mostSold?organizationId=${user.OrganizationId}`}
    />
  )
}

export default MostSoldProduct
