//import moment from "moment"
import React, { useEffect, useState } from "react"

//import { useHistory } from "react-router"
import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"
import { sortArray } from "../../../common/helpers"
//import { Routes } from "../../routes"
import AddEditBank from "./AddEditBank"
import emptyImg from "../../../assets/img/illustrations/no_content.svg"
import { FormatMoney /*sortArray*/ } from "../../../common/helpers"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

//Translation
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"

const BankList = props => {
  //const history = useHistory()

  const [state, setState] = useState({
    Banks: [],
    UI: {
      showAddEdit: false,
      Bankselected: null,
    },
  })

  const theUser = parsedUser()

  const { t } = useTranslation("global")

  useEffect(() => {
    request()
  }, [])

  const request = async () => {
    let requestAPI = await API.getAction(
      "FinancialEntities/List?OrganizationId=" + theUser.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      let copy = JSON.parse(JSON.stringify(state))
      copy.Banks = requestAPI.data.response
      copy.Banks = sortArray(copy.Banks, "name")
      setState(copy)
    }
  }

  const toggleAddEdit = async (obj, reload) => {
    setState({
      ...state,
      UI: {
        ...state.UI,

        showAddEdit: !state.UI.showAddEdit,
        Bankselected: obj,
      },
    })

    if (reload) {
      await request()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "FinancialEntities/Delete?id=" +
                obj.id +
                "&UserId=" +
                currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditBank
          toggle={toggleAddEdit}
          show={state.UI.showAddEdit}
          model={state.UI.Bankselected}
        />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("banks")}</Modal.Title>
          <button
            className="ms-2 btn btn-sm btn-primary"
            onClick={() => toggleAddEdit()}
          >
            <i className="fa fa-plus"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          {state.Banks.length === 0 ? (
            <div className="row">
              <div className="col-6 mx-auto text-center">
                <img
                  src={emptyImg}
                  className="img-fluid"
                  style={{ maxWidth: "50%" }}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="shadow-sm">
              {state.Banks.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th>{t("account_digits")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.Banks.map((item, index) => {
                      let parsedItem = JSON.parse(JSON.stringify(item))
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{FormatMoney(item.bankAccountDigits)}</td>
                          <td className=" text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => toggleAddEdit(parsedItem, false)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(parsedItem)}
                                type="button"
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BankList
