import React, { useEffect, useState, useContext } from "react"
import { Col, Row, Card, Form } from "react-bootstrap"

import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { confirmAlert } from "react-confirm-alert"
//translation
import { useTranslation } from "react-i18next"
import languages from "../../common/languages"
import ChangePassword from "./ChangePassword"
import EditProfile from "./EditProfile"

const Profile = () => {
  const user = parsedUser()
  const [model, setModel] = useState([])
  const [organizations, setOrganizations] = useState()
  const [t, i18n] = useTranslation("global")
  const [showChangePass, setShowChangePass] = useState(false)
  const [showEditProfile, setShowEditProfile] = useState(false)

  const requestRemote = async () => {
    let request = await API.getAction("accounts/details?id=" + user.ID)

    if (request.data.status === "ok") {
      setModel(request.data.response)
    }
  }

  const requestOrganizationsRemote = async () => {
    let request = await API.getAction(
      "organization/OrganizationsWhereTheUserBelong?AccountId=" + user.ID
    )

    if (request.data.status === "ok") {
      setOrganizations(request.data.response)
    }
  }

  const deleteConfirm = async (objOrganization, objUser) => {
    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + objOrganization.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "organization/Delete?organizationid=" +
                objOrganization.id +
                "&UserId=" +
                objUser
            )
            await requestOrganizationsRemote()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const leaveConfirm = async (objOrganization, objUser) => {
    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_leave") + objOrganization.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "organization/LeaveOrganization?organizationid=" +
                objOrganization.id +
                "&UserId=" +
                objUser
            )
            await requestOrganizationsRemote()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  useEffect(() => {
    requestRemote()
    requestOrganizationsRemote()
  }, [])

  const handlerSelectedLanguage = e => {
    localStorage.setItem("language", e.target.value)
    i18n.changeLanguage(e.target.value)
  }

  const handleChangePassword = async reload => {
    console.log(reload)
    setShowChangePass(!showChangePass)
    if (reload) {
      await requestRemote()
      await requestOrganizationsRemote()
    }
  }

  const handleEditProfile = async reload => {
    setShowEditProfile(!showEditProfile)
    if (reload) {
      await requestRemote()
      await requestOrganizationsRemote()
    }
  }

  return (
    <Row className="mx-0">
      {showChangePass ? (
        <ChangePassword toggle={handleChangePassword} show={showChangePass} />
      ) : null}

      {showEditProfile ? (
        <EditProfile
          toggle={handleEditProfile}
          show={showEditProfile}
          model={model}
        />
      ) : null}

      <Col xs={12} xl={4}>
        <Row>
          <Col xs={12}>
            <Card className="mb-2">
              <Card.Body>
                <h3>{model ? model.firstName + " " + model.lastName : ""}</h3>
                <b>{t("email")}: </b>
                {model && model.email}
                <div className="mt-4">
                  <div className="btn-group">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEditProfile(false)}
                    >
                      {t("edit")}
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleChangePassword(false)}
                    >
                      {t("changePassword")}
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title>{t("preferences")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <b>{t("languages")}</b>
                    <Form.Select
                      defaultValue={i18n.language}
                      onChange={handlerSelectedLanguage}
                    >
                      <option value="" disabled>
                        {t("selectLanguage")}...
                      </option>
                      {languages.map(language => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Select>
                    <hr />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12} xl={8}>
        <Card className="mb-2">
          <Card.Header>
            <Card.Title>{t("organizations")}</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("owner")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {organizations &&
                    organizations.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>
                            {item.creatorName}
                            <br />
                            <small className="text-muted">
                              {item.creatorEmail}
                            </small>
                          </td>
                          <td className="text-right">
                            {item.creatorId === model.id ? (
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  deleteConfirm(item, model.id)
                                }}
                              >
                                {t("delete")}
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  leaveConfirm(item, model.id)
                                }}
                              >
                                {t("leave")}
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Profile
