import React, { useEffect } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

//import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6

import moment from "moment"
//import API from "../../common/API"
//import { onlyHTMLTags } from "../../common/helpers"
//import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"
//import { Search, Label, Checkbox } from "semantic-ui-react"
import Settings from "../../common/Settings"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  from: yup.date().required(),
  until: yup.date().required(),
})
//Component
const ExportToExcel = props => {
  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    let currentUser = parsedUser()
    let path = `${Settings.BasePathForAPI}expense/ExportToExcel?CurrentUserId=${currentUser.ID}&ExcelPeriodStart=${obj.from}&ExcelPeriodEnd=${obj.until}`
    window.open(path)
  }
  const formik = useFormik({
    initialValues: {
      from: moment().format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    setTimeout(() => {
      var obj = document.getElementsByName("from")[0]
      if (obj) {
        obj.focus()
      }
    }, 500)
  }, [props.model])

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("export_excel")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING */}
          {Object.values(formik.errors).map((item, index) => {
            return item
          })}
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>{t("since")}</Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={
                  formik.values.from
                    ? moment(formik.values.from).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD")
                }
                onChange={formik.handleChange}
              />
              {formik.errors.from ? (
                <div className="invalid text-sm">{formik.errors.from}</div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>{t("until")}</Form.Label>
              <Form.Control
                type="date"
                name="until"
                value={
                  formik.values.until
                    ? moment(formik.values.until).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD")
                }
                onChange={formik.handleChange}
              />
              {formik.errors.until ? (
                <div className="invalid text-sm">{formik.errors.until}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button variant="primary" type="submit" className="btn-sm">
            {t("export")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ExportToExcel
