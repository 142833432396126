import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const PayrollDiscountsComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("discounts") ?? ""}
      getRecordsPath={`Employees/Discounts?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath="Employees/AddEditDiscount"
      deleteRecordPath="Employees/DiscountsDelete"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },
        {
          name: "percentage",
          title: t("Percentage"),
          htmlType: "bool",
        },
        {
          name: "value",
          title: t("value"),
        },
        {name: "visibleByTheCompanyOnly", title: t("visibleByTheCompanyOnly"), htmlType: "bool"},
        {name: "type", "title": t("type")}
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "percentage",
          htmlType: "bool",
          dbType: "bit",
          title: t("Is Percentage") ?? "",
          containerClass: "col-6",
          required: false,
        },
        {
          name: "visibleByTheCompanyOnly",
          htmlType: "bool",
          dbType: "bit",
          title: t("visibleByTheCompanyOnly") ?? "",
          containerClass: "col-6",
          required: false,
        },
        {
          name: "type",
          htmlType: "select",
          dbType: "int",
          title: t("type") ?? "",
          containerClass: "col-6",
          required: true,
          dataSourceValueField: "id",
          dataSourceTextField: "name",
          dataSourceLocal: [
            {id: 1, name: t("Percentage")},
            {id: 2, name: t("Fraction Of Salary")}, 
            {id: 0, name: t("Value")}, 
          ]
          
        },
        {
          name: "value",
          htmlType: "number",
          dbType: "decimal",
          title: t("value") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default PayrollDiscountsComponent
