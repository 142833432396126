import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import API from "../../common/API"
import moment from "moment"
//import { FormatMoney } from "../../common/helpers"

//Translation
import { useTranslation } from "react-i18next"

const TotalBilledMonthly = () => {
  const [state, setState] = useState()
  const [records, setRecords] = useState()
  const user = parsedUser()

  const { t } = useTranslation("global")

  const request = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("invoice/TotalBilledMonthly", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)
      // {
      //     labels,
      //     datasets: [
      //       {
      //         label: 'Dataset 1',
      //         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //       },

      //     ],
      //   };
      let labels = []

      result.forEach(item => {
        labels.push(moment(item.month).format("MMM YYYY"))
      })
      setState({
        labels,
        datasets: [
          {
            label: t("total_billed_monthly.total_billed"),
            data: result.map(x => x.totalBilled),
            backgroundColor: "#4081d0",
          },
          {
            label: t("total_billed_monthly.pending_payment"),
            data: result.map(x => x.totalPendingToBePaid),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: t("total_billed_monthly.total_paid"),
            data: result.map(x => x.totalPaid),
            backgroundColor: "#6dc77e",
          },
        ],
      })
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: t("total_billed_monthly.total_billed_month"),
      },
    },
  }
  useEffect(() => {
    request()
  }, [])

  if (!state) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <span className="display-6">
            {t("total_billed_monthly.total_billed_month")}
          </span>
        </div>
        <div className="card-body">
          <Bar options={options} data={state} />
          {/* <table className="table">
            <thead>
                <tr>
                    <th>{t("month")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("total")}</th>
                </tr>
            </thead>
<tbody>
    {records.map((item, index)=>{
        return (<tr key={index}>
            <td>{moment(item.month).format("MMM YYYY")}</td>
            <td>{item.amount}</td>
            <td className="text-right">{FormatMoney( item.totalValue)}</td>
        </tr>)
    })}
</tbody>
        </table> */}
        </div>
      </div>
    </>
  )
}

export default TotalBilledMonthly
