import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const WarehouseSectionComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("warehouseSections") ?? ""}
      getRecordsPath={`products/WarehouseSections?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`products/AddEditWarehouseSection?currentUserId=${user.ID}`}
      deleteRecordPath="products/DeleteWarehouse"
      fieldsList={[
        {
          name: "warehouseName",
          title: t("warehouse")}
          ,
        {
          name: "section",
          title: t("section"),
        },
        {
          name: "area",
          title: t("area"),
          htmlType: "text",
          cssClass: 'text-center'
        },
        
        {
          name: "note",
          title: t("note"),
          htmlType: "text",
          cssClass: 'text-center'
        },
        
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "warehouseId",
          htmlType: "select",
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSource: `products/Warehouses?OrganizationId=${user.OrganizationId}`,
          dbType: "string",
          title: t("warehouse")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
        {
          name: "section",
          htmlType: "text",
          dbType: "string",
          title: t("section") ?? "",
          containerClass: "col-md-6 col-12",
          required: true,
        },
        {
          name: "area",
          htmlType: "text",
          dbType: "string",
          title: t("area") ?? "",
          containerClass: "col-md-6 col-12",
          required: false,
        },

        {
          name: "note",
          htmlType: "textarea",
          dbType: "string",
          title: t("note") ?? "",
          containerClass: "col-12",
          required: false,
        },
       
      ]}
    />
  )
}

export default WarehouseSectionComponent
