import React, { useEffect } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import API from "../../common/API"
import { useState } from "react"

//Trasnlation
import { useTranslation } from "react-i18next"

//Component
const LoadingModal = props => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation("global")

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleSubmit = async () => {
    setIsLoading(!isLoading)
    let request = await API.getAction(props.URL)
    setIsLoading(!isLoading)
    if (request.status === 200) {
      props.toggle(true)
    }
  }

  return (
    <Modal show={props.show} onHide={props.toggle} size="sm">
      <Modal.Body>
        <Row>
          <Col xs={12} className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}...</span>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal
