import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import AddToDo from "./AddToDo"
import { Col, Row } from "react-bootstrap"
import moment from "moment"

import EditToDo from "./EditToDo"
import { ToDoListProps } from "./TodoListProps"
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const ToDoList = () => {


  const user = parsedUser()
  const { t } = useTranslation("global")
  const [records, setRecords] = useState([] as ToDoListProps[])

  const bindDataRemote = async () => {
    try {
      const query = `assignedToId=${user.ID}`
      const request = await API.getAction("todo/list", query)

      console.log(request)
      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const toggleCompleted = async (obj: ToDoListProps) => {
    obj.completed = obj.completed ? null : moment().format()
    await API.postAction("ToDo/AddEdit?currentUserId=" + user.ID, obj)
    await bindDataRemote()
  }

  const deleteConfirm = async (obj: ToDoListProps) => {
    // confirmAlert({
    //   title: t("confirm") ?? "",
    //   message: t("questions.sure_delete") + obj.name + "?",
    //   buttons: [
    //     {
    //       label: t("yes"),
    //       onClick: async () => {
    await API.getAction(
      "todo/delete?id=" + obj.id + "&UserId=" + user.ID
    )

    toast.success(t("record deleted"))
    await bindDataRemote()
    //       },
    //     },
    //     {
    //       label: t("no") ?? "",
    //       onClick: () => console.log(""),
    //     },
    //   ],
    // })
  }

  const [showAdd, setShowAdd] = useState(false)
  const toggleAdd = () => {
    setShowAdd(!showAdd)

    if (showAdd) {
      bindDataRemote()
    }
  }
  const [showEdit, setshowEdit] = useState(false)
  const [record, setRecord] = useState(null);
  const toggleEdit = (obj: any) => {
    setshowEdit(!showEdit)
    setRecord(obj);

    if (showEdit) {
      bindDataRemote()
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <>
      {showEdit && <EditToDo show={showEdit} toggle={toggleEdit} record={record} />}

      <div className="todos card shadow-sm">
        <div className="card-header py-1 px-2">
          <div className="card-title">
            <i className="fa fa-id-card " />
            &nbsp; {t("tasks")}
            <button className="btn btn-sm btn-primary  float-end" onClick={() => toggleAdd()}>
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div className="card-body bg-light p-0 py-2">
          {showAdd && <AddToDo toggle={toggleAdd} />}
          {records &&
            records.map((item, index) => {
              return (
                <div key={index} className="todoItem clickable rounded bg-white border mx-2 py-1 mb-2">
                  <Row className="w-100">
                    <Col className="col-auto">
                      <button className="btn btn-sm" onClick={() => toggleCompleted(item)}>
                        {item.completed ? <i className="fa-solid fa-square"></i> : <i className="fa-regular fa-square"></i>}
                      </button>
                    </Col>
                    <Col className="ps-0 text-truncate" onClick={() => toggleEdit(item)}>
                      {item.name}
                      {item.created && (
                        <small className="date text-muted ms-1 d-inline">
                          {moment(item.created).fromNow()}
                        </small>
                      )}
                      {item.note && (
                        <small className="text-muted ms-1" title={item.note}><br />{item.note}</small>
                      )}
                    </Col>
                    <Col className="col-auto btns text-end ">
                      <button
                        className="btn text-danger btn-sm"
                        type="button"
                        onClick={() => {
                          deleteConfirm(item)
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                      {/* <button className="btn btn-sm">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </button> */}
                    </Col>
                  </Row>
                </div>
              )
            })}
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default ToDoList
