//import moment from "moment"
import React, { useEffect, useState } from "react"

//import { useHistory } from "react-router"
import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"
import { FormatMoney /*sortArray*/ } from "../../../common/helpers"
//import { Routes } from "../../routes"
import AddEditCurrency from "./AddEditCurrency"
import emptyImg from "../../../assets/img/illustrations/no_content.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const CurrencyList = props => {
  //const history = useHistory()
  const [records, setRecords] = useState([])
  const [recordSelected, setRecordSelected] = useState(null)
  const [showAddEdit, setShowAddEdit] = useState(false)
  const theUser = parsedUser()
  const { t } = useTranslation("global")
  useEffect(() => {
    request()
  }, [])

  const request = async () => {
    let requestAPI = await API.getAction(
      "Currencies/List?OrganizationId=" + theUser.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setRecords(requestAPI.data.response)
    }
  }
  const toggleAddEdit = async (obj, reload) => {
    setRecordSelected(obj)
    setShowAddEdit(!showAddEdit)

    if (reload) {
      await request()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction(
              "Currencies/Delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      {showAddEdit ? (
        <AddEditCurrency
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={recordSelected}
        />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("currencies")}</Modal.Title>
          <button
            className="ms-2 btn btn-sm btn-primary"
            onClick={() => toggleAddEdit()}
          >
            <i className="fa fa-plus"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          {records.length === 0 ? (
            <div className="row">
              <div className="col-6 mx-auto text-center">
                <img
                  src={emptyImg}
                  className="img-fluid"
                  style={{ maxWidth: "50%" }}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="shadow-sm">
              {records.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nombre</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, index) => {
                      let parsedItem = item
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td className=" text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => toggleAddEdit(parsedItem, false)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(parsedItem)}
                                type="button"
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CurrencyList
