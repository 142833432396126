import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getAbbreviation } from "../../common/helpers"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import Files from "../files"
import { confirmAlert } from "react-confirm-alert"
import DiagnosticList from "./diagnostic/DiagnosticList"
import Settings from "../../common/Settings"
import toast from "react-hot-toast"

const MaintenanceDetails = props => {
  const [note, setNote] = useState("")
  const [statuses, setStatuses] = useState([])
  const user = parsedUser()
  const [notes, setNotes] = useState([])
  const [model, setModel] = useState(null) // props.record
  console.log(model)
  const { t } = useTranslation("global")

  useEffect(() => {
    setModel(props.record)
  }, [props])

  // NOTES MANAGEMENT
  const bindRemoteNotes = async () => {
    if (model === null) return
    const request = await API.getAction(
      "workshop/ListComments?workshopId=" + model.id
    )
    console.log(request)
    setNotes(request.data.response)
  }

  const bindDataStatus = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    let requestAPI = await API.getAction("workshop/ListStatus", Query)

    let s = requestAPI.data.response
    let final = s.map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.name,
      }
    })
    setStatuses(final)
  }

  const addNote = async () => {
    if (model === null) return
    try {
      let query = {
        id: null,
        description: note,
        assignedToId: user.ID,
        workshopRecordId: model.id,
      }
      const request = await API.postAction(
        `workshop/AddEditComment?currentUserId=${user.ID}`,
        query
      )
      console.log(request)
    } catch (e) {
      console.error(e)
    } finally {
      setNote("")
      bindRemoteNotes()
    }
  }
  const markCompleted = async () => {
    if (model === null) return
    const request = await API.getAction("workshop/markAsCompleted?id=" + model.id)
    console.log(request)

   await  bindDataDetails()
  }

  
  const bindDataDetails = async () => {
    if (model === null) return
    const request = await API.getAction("workshop/Details?id=" + model.id)
    console.log(request)
    setModel(request.data.response)
  }


  const changeStatus = async status => {
    if (model === null) return
    try {
      let query = {
        id: model.id,
        statusId: status.value,
      }
      const request = await API.postAction(
        `workshop/changeStatus?currentUserId=${user.ID}`,
        query
      )
      console.log(request)

      bindDataDetails()
    } catch (e) {
      console.error(e)
    }
  }
  const deleteConfirm = async () => {
    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + model.product.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction("workshop/Delete?id=" + model.id)
            props.toggle()
          },
        },
        {
          label: t("no"),
          onClick: () => {
            console.log("no")
          },
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  useEffect(() => {
    bindRemoteNotes()
    bindDataStatus()
  }, [model])
  // END NOTES MANAGEMENT

  if (
    (model == null && !model) ||
    !model.product ||
    model.product === undefined
  ) {
    return <></>
  }
  return (
    <Modal show={props.show} onHide={props.toggle} className="modalRight">
      <Modal.Header>
        <Row className="mx-0 w-100">
          <Col>
            <b>#{model.sequence}</b>
            <button
            type="button"
            onClick={() => markCompleted()}
            className=
            {"btn btn-sm d-inline ms-2 " + (model.completed?"btn-success":"btn-outline-success")} >
              <i className="fa fa-check me-2"></i>
              {t('mark complete')}
              </button>
            {/* <button
              className="ms-2 btn btn-sm d-inline "
              type="button"
              onClick={() => props.toggle()}
            >
              <i className="fa fa-edit"></i>
            </button> */}
          </Col>
          <Col xs={"auto"} className="ml-auto">
            <small className="text-muted me-2">
              {moment(model.created).fromNow()}
            </small>
            <button
              className="btn btn-sm d-inline  me-2"
              type="button"
              onClick={() => {
                
                navigator.clipboard.writeText(Settings.AppURL + "workshop/record/external/" + model.id);  
  toast.success(t("copied to clipboard"))
              }}
            >
              <i className="fa-solid fa-copy"></i>
            </button>
            <button
              className="btn btn-sm d-inline text-danger me-2"
              type="button"
              onClick={() => deleteConfirm()}
            >
              <i className="fa fa-trash"></i>
            </button>
          
            <button
              className="btn btn-sm d-inline "
              type="button"
              onClick={() => props.toggle()}
            >
              <i className="fa fa-times"></i>
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Container className="px-0">
          <Row>
            <Col>
              <table className="table">
                <tbody>
                  <tr>
                    <th>{t("product")}</th>
                    <td>{`${model.product.brand ?? ""}  ${
                      model.product.name
                    } (${model.product.barcode})`}</td>
                  </tr>
                  <tr>
                    <th>{t("status")}</th>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="outline-primary"
                          id="dropdown-basic"
                        >
                          <i
                            className="fa fa-circle me-2"
                            style={{ color: model.status.color }}
                          ></i>
                          {`${model.status.name}`}{" "}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {statuses.map((item, index) => {
                            console.log(item)
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() => changeStatus(item)}
                              >
                                {item.text}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                  <tr>
                    <th>{t("assignee")}</th>
                    <td>
                      <span className=" avatar me-2">
                        <i>
                          {getAbbreviation(
                            model.assigned.firstName +
                              " " +
                              model.assigned.lastName??""
                          )}
                        </i>
                      </span>
                      {`${model.assigned.firstName} ${model.assigned.lastName??""}`}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("customer")}</th>
                    <td>
                      <span className=" avatar me-2">
                        <i>{getAbbreviation(model.customer.firstName)}</i>
                      </span>
                      <span>{`${model.customer.firstName}`}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="my-2 px-0">
              <Tabs defaultActiveKey={"notes"} className="mx-0 px-0">
                <Tab eventKey={"notes"} title={t("notes")}>
                  <Card>
                    {/* <Card.Header className="p-2">{t("notes")}</Card.Header> */}
                    <Card.Body className="p-2">
                      <Row>
                        {notes.map((item, index) => {
                          return (
                            <Col key={index} xs={12}>
                              <small className="text-muted">
                                {moment(item.created).fromNow()}
                              </small>
                              <br />
                              <b>
                                {item.assignedTo.firstName}{" "}
                                {item.assignedTo.lastName}
                              </b>
                              <br />
                              {item.description}
                              {index + 1 !== notes.length && <hr />}
                            </Col>
                          )
                        })}
                        {notes.length === 0 && (
                          <Col xs={12} className="text-center">{t("no_notes")}</Col>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>

                <Tab eventKey={"diagnostic"} title={t("diagnostic")}>
                 <DiagnosticList parentId={model.id}/>
                </Tab>
                <Tab eventKey={"files"} title={t("files")}>
                  <Files WorkshopRecordId={model.id} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <Row className="w-100">
          <Col>
            <textarea
              placeholder={t("Add a note")}
              className="form-control"
              value={note}
              onChange={e => setNote(e.target.value)}
            ></textarea>
          </Col>
          <Col className="col-auto">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                console.log("Send Email")
                addNote()
              }}
              disabled={note.length === 0}
            >
              <i className="fa fa-paper-plane"></i>
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenanceDetails
