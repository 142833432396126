import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  firstName: yup.string().required(),
  //    organizationId: yup.string(),

  country: yup.string().required(),
})

//Component
const AddEditCustomer = props => {
  const { t } = useTranslation("global")

  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })

  const user = parsedUser()

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }
    if (obj.identifier === null) {
      obj.identifier = uuidv4()
    }

    let request = await API.postAction("customer/AddEdit", obj)

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model,
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    setTimeout(() => {
      var focus = document.getElementsByName("firstName")[0]
      if (focus) {
        document.getElementsByName("firstName")[0].focus()
      }
    }, 500)
  }, [])

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("customers")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>{t("first_name")}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName ? (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>
                {t("id")} / {t("rnc")}
              </Form.Label>
              <Form.Control
                type="text"
                name="identifier"
                value={formik.values.identifier ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.identifier ? (
                <div className="invalid text-sm">
                  {formik.errors.identifier}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6} className="mt-2">
              <Form.Label># {t("phone")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="phone"
                value={formik.values.phone ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>

            <Col xs={12} md={6} className="mt-2">
              <Form.Label># {t("phone")} 2</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="phone2"
                value={formik.values.phone2 ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.phone2 ? (
                <div className="invalid text-sm">{formik.errors.phone2}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                name="email"
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("address")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="address"
                value={formik.values.address ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.address ? (
                <div className="invalid text-sm">{formik.errors.address}</div>
              ) : null}
            </Col>
            <Col xs={6} className="mt-2">
              <Form.Label>{t("city")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="city"
                value={formik.values.city ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.city ? (
                <div className="invalid text-sm">{formik.errors.city}</div>
              ) : null}
            </Col>
            <Col xs={6} className="mt-2">
              <Form.Label>{t("country")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="country"
                value={formik.values.country ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.country ? (
                <div className="invalid text-sm">{formik.errors.country}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditCustomer
