import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import Settings from "../../common/Settings"
import "../../css/app_print.css"
import { useTranslation } from "react-i18next"

const Print = props => {
  const { t } = useTranslation("global")
  const [state, setState] = useState(null)
  const [subTotalGeneral, setSubtotalGeneral] = useState(0)
  const [TotalTaxes, setTotalTaxes] = useState(0)
  const request = async () => {
    let requestAPI = await API.getAction(
      "invoice/Details?Id=" + props.match.params.id
    )

    if (requestAPI.data.status === "ok") {
      setState(requestAPI.data.response)
    }
  }

  const summaryCalc = () => {
    let totalTaxes = 0,
      subTotalGeneral = 0
    state &&
      state.invoiceDetails
        .filter(x => x.deleted === null)
        .forEach((item, index) => {
          let calculateDisocunt = item.discount ?? 0
          let tax = item.tax * item.quantity //(item.tax / 100) * (item.price - calculateDisocunt);
          let subTotal = (item.price - calculateDisocunt) * item.quantity
          totalTaxes += tax
          subTotalGeneral += subTotal

          setTotalTaxes(totalTaxes)
          setSubtotalGeneral(subTotalGeneral)
        })
  }
  useEffect(() => {
    summaryCalc()
  }, [state])
  useEffect(() => {
    request()

    setTimeout(() => {
      if (window.location.href.includes("printExecute")) {
        window.print()
      }
    }, [5000])
  }, [])

  if (state === null) {
    return <></>
  }

  console.log(state)
  return (
    <>
      <section className="invoice bg-white">
        <input
          type="hidden"
          id="CurrencyExchangeRateSelected"
          name="CurrencyExchangeRateSelected"
          value="1"
        />

        <div className="row m-2">
          <div className="col-8">
            <h3 className="page-header text-left">
              {state.organization.logo ? (
                <img
                  src={`${Settings.BasePath}Files/${state.organization.logo}?h=100`}
                  title={state.organization.name}
                  alt={state.organization.name}
                  style={{ maxHeight: "100px" }}
                />
              ) : (
                state.organization.name
              )}
            </h3>
          </div>
          <div className="col-4 text-right">
            <b>No. {state.invoiceNumber}</b>

            {state.paidStatus === 2 ? (
              <span className="badge bg-success d-print-none ms-2">Pagada</span>
            ) : (
              <span className="badge bg-secondary d-print-none ms-2">
                Pendiente
              </span>
            )}
            <br />
            <b># {state.invoiceIdentifier}</b>
            {state.invoiceType.expirationDate ? (
              <>
                <br />
                <span className="ms-2">
                  {moment(state.invoiceType.expirationDate).format(
                    "MMM DD, YYYY"
                  )}
                </span>
              </>
            ) : null}
          </div>
        </div>

        <div className="row invoice-info m-2">
          <div className="col-6">
            <div className="col-12 ">
              <address>
                <strong>{state.organization?.name}</strong>
                <br />
                {state.organization.identifier}
                <br />
                {`${state.organization.address ?? ""}, ${
                  state.organization.city ?? ""
                }, ${state.organization.zipCode ?? ""}, ${
                  state.organization.country ?? ""
                }`.replace(", ,", ", ")}
                <br />

                {state.organization.phone ? (
                  <>
                    {`Teléfono: ${state.organization.phone}`}
                    <br />
                  </>
                ) : null}

                {state.organization.email
                  ? `Email: ${state.organization.email}`
                  : null}
              </address>
            </div>
            <div className="col-sm-12 col-md-12 CustomerInfo">
              <address>
                <strong>{`${state.customer.firstName} ${
                  state.customer.lastName ?? ""
                }`}</strong>
                <br />
                {state.customer.identifier}
                <br />
                {`${state.customer.address ?? ""}, ${
                  state.customer.city ?? ""
                }, ${state.customer.zipCode ?? ""}, ${
                  state.customer.country ?? ""
                }`.replace(", ,", ", ")}
                <br />
                {state.customer.phone ? (
                  <>
                    {`Teléfono: ${state.customer.phone}`}
                    <br />
                  </>
                ) : null}

                {state.customer.email ? `Email: ${state.customer.email}` : null}
              </address>
            </div>
          </div>

          <div className="col-6 text-right">
            <b>Fecha: </b>
            {moment(state.created).format("MMM DD, YYYY")}
            <br />

            <b>Hora: </b>

            {moment(state.created).format("HH:mm:ss ")}
            {state.invoiceType ? (
              <>
                <br />
                <b>Tipo: </b> {state.invoiceType.name}
              </>
            ) : null}
            {state.creditLimitDate ? (
              <>
                <br />
                <b>{t("credit_valid")}: </b>{" "}
                {moment(state.creditLimitDate).format("MMMM DD, YYYY")}
              </>
            ) : null}
            {state.invoiceType ? (
              <>
                <br />
                <b>Método de pago: </b>{" "}
                {state.paymentMethod === 0
                  ? "Efectivo"
                  : state.paymentMethod === 1
                  ? "Tarjeta"
                  : state.paymentMethod === 2
                  ? "Cheque"
                  : state.paymentMethod === 3
                  ? "Transferencia Bancaria"
                  : "Otros"}
              </>
            ) : null}
            {state.currency.name ? (
              <>
                <br />
                <b>Moneda: </b> {state.currency.name}
              </>
            ) : null}
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12 table-responsive">
            <table id="details" className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-md-6">Concepto</th>
                  <th className="col-md-1">Cantidad</th>
                  <th className="col-md-1">Precio Unitario</th>
                  <th className="col-md-1">Descuento</th>
                  <th className="col-md-1">Impuestos</th>
                  <th className="col-md-1">Sub-Total</th>
                </tr>
              </thead>
              <tbody>
                {state.invoiceDetails
                  .filter(x => x.deleted === null)
                  .map((item, index) => {
                    let calculateDisocunt =
                      item.discount && item.discount > 0 ? item.discount : 0
                    console.log("calcualted discount", calculateDisocunt)
                    let tax = item.tax * item.quantity // (item.tax / 100) * (item.price - calculateDisocunt);
                    let subTotal =
                      (item.price - calculateDisocunt) * item.quantity

                    return (
                      <tr key={index}>
                        <td className="text-wrap">{item.itemConcept}</td>
                        <td>{item.quantity}</td>
                        <td>{FormatMoney(item.price)}</td>
                        <td>{FormatMoney(calculateDisocunt)}</td>
                        <td>{FormatMoney(tax)}</td>
                        <td>{FormatMoney(subTotal)}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-6">
            {state.note ? (
              <p
                className="text-muted well well-sm no-shadow small"
                dangerouslySetInnerHTML={{
                  __html: state.note.replace(/\n/g, "<br />"),
                }}
              ></p>
            ) : null}
          </div>

          <div className="col-6">
            <p className="lead">Balance</p>

            <div className="table-responsive">
              <table className="table summary">
                <tbody>
                  <tr className="subtotal">
                    <th>Sub-Total:</th>
                    <td className="text-right">
                      {FormatMoney(subTotalGeneral)}
                    </td>
                  </tr>
                  <tr className="taxes">
                    <th>Impuestos:</th>
                    <td className="text-right">{FormatMoney(TotalTaxes)}</td>
                  </tr>

                  <tr className="total">
                    <th>Total:</th>
                    <td className="text-right">
                      {FormatMoney(subTotalGeneral + TotalTaxes)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="d-print-none ">
              <button
                className="btn btn-primary btn-block "
                type="button"
                onClick={() => {
                  window.print()
                }}
              >
                Imprimir
              </button>
            </p>
          </div>
          {state.invoiceType.disclaimer ? (
            <div className="col-12">
              <div
                className="text-muted well well-sm no-shadow small smallest"
                dangerouslySetInnerHTML={{
                  __html: state.invoiceType.disclaimer.replace(/\n/g, "<br />"),
                }}
              ></div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  )
}

export default Print
