import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminOrganizationsComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Organizations") ?? ""}
      icon={"fas fa-sitemap"}
      getRecordsPath={`organization/AdminOrganizations`}
      addEditRecordPath={`organization/AddEditAdminOrganizations?currentUserId=${user.ID}`}
      deleteRecordPath="organization/AdminOrganizationDelete"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },{
          name: "nextBillingDate",
          title: t("nextBilingDate"),
          htmlType: "date",
        },
        {
          name: "subscriptionName",
          title: t("subscription"),
        },
        {
          name: "modules",
          title: t("modules"),
        },
        {
          name: "creatorName",
          title: t("creator"),
        },
        {
          name: "creatorEmail",
          title: t("Email"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
    />
  )
}

export default AdminOrganizationsComponent
