import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"
import { Link, useHistory } from "react-router-dom"
import API from "../../common/API"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"
import ClearUser from "../../common/ClearUser"
import Sha256 from "../../common/sha256"
import toast, { Toaster } from "react-hot-toast"


import { useTranslation } from "react-i18next"
import languages from "../../common/languages"
import Logo from "../Common/Logo"

const Login = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [t, i18n] = useTranslation("global")

  const [state, setState] = useState({
    Email: "",
    Password: "",
    UI: {
      validated: false,
    },
  })

  useEffect(() => {
    ClearUser()
  }, [])
  const handleSubmit = async event => {
    event.preventDefault()
    const form = event.currentTarget
    let password = Sha256.hash(state.Password)
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      try {
        let query = {
          username: state.Email,
          password: password,
        }
        setIsLoading(true)
        let request = await API.postAction("accounts/RequestToken", query)
        setIsLoading(false)

        let response = request.data
        localStorage.setItem("LogedUser", response.token)

        history.push(Routes.SelectOrganization.path)
        //window.location = Routes.GetIn.clean + user.RefreshToken
      } catch (err) {
        toast.error(t("alerts.userpass_invalid"))
      } finally {
        setIsLoading(false)
      }
    }
  }
  const handlerSelectedLanguage = e => {
    localStorage.setItem("language", e.target.value)
    i18n.changeLanguage(e.target.value)
  }

  return (
    <>
      <Toaster />
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
         

            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col xs={12}>
              <div className="text-center">
              <Logo logoName="logo1" />
            </div>
              </Col>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  {/* <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">Sign in to our platform</h4>
                </div> */}
                  <Form
                    className="mt-4 shadow-sm"
                    noValidate
                    validated={state.UI.validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group id="email" className="mb-4 ">
                      <Form.Label>{t("email")} </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          type="email"
                          value={state.Email}
                          onChange={e => {
                            setState({
                              ...state,
                              Email: e.target.value,
                            })
                          }}
                        />
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        {t("alerts.email_invalid")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>{t("password")}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="password"
                            value={state.Password}
                            onChange={e => {
                              setState({
                                ...state,
                                Password: e.target.value,
                              })
                            }}
                          />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {t("alerts.field_required")}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4 text-right">
                        <Card.Link
                          className="small text-end"
                          as={Link}
                          to={Routes.ForgotPassword.path}
                        >
                          {t("recover_password")}
                        </Card.Link>
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm mx-4"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                      {t("login")}
                    </Button>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {t("questions.dont_have_account")}&nbsp;
                      <Card.Link
                        as={Link}
                        to={Routes.Register.path}
                        className="fw-bold"
                      >
                        {t("create_account")}
                      </Card.Link>
                    </span>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <FontAwesomeIcon
                      icon={faLanguage}
                      className="me-1 mt-1 fa-2x"
                    />
                    &nbsp;
                    <Form.Select
                      defaultValue={i18n.language}
                      onChange={handlerSelectedLanguage}
                      size="sm"
                      className="mt-1 me-1"
                      style={{ width: "10em" }}
                    >
                      <option value="" disabled>
                        {t("profile.preferences.select_language")}...
                      </option>
                      {languages.map(language => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Login
