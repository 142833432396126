import React, { useEffect, useState } from "react";
import API from "../common/API";
import { parsedUser } from "../common/GetCurrentUser";

import { useHistory } from "react-router";
import { Routes } from "../routes";



export const SubscriptionContext = React.createContext({});

const SubscriptionContextProvider = props => {
    const history = useHistory();
    const [subscription, setSubscription] = useState();
    const [currentRole, setCurrentRole]= useState(null);
    const [assignSubscription, setAssignSubscription] = useState(false);

    const user = parsedUser();

    const toggleAssignSubscription = () => {
        setAssignSubscription(!assignSubscription);
        
    }
    const bindDataRemote = async () => {
        if(!user){
            history.push(Routes.SignIn.path)
            return;
        }
        let Query = "OrganizationId=" + user.OrganizationId
        const request = await API.getAction("Subscription/ForOrganization", Query)

        console.log(request.data.status,request.data.response );
        setSubscription(request.data.status === 'error'? null:request.data.response);

        //Now get the current role the user is in 

        const request2 = await API.getAction("users/GetRoleForUser?id=" + user.ID)
        if(request2.data.status !== 'error'){
            setCurrentRole(request2.data.response);
        }
    }

    useEffect(() => {
        bindDataRemote();
    }, [])

    useEffect(()=>{
        if(subscription === null){
            toggleAssignSubscription ();
        }
    },[subscription])

    return (<SubscriptionContext.Provider value={{ subscription , currentRole}}>
        {props.children}
    </SubscriptionContext.Provider >
    )
}


export default SubscriptionContextProvider;