import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"
import AddEditService from "./AddEditService"
import emptyImg from "../../assets/img/illustrations/no_content.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import Pagination from "../core/Pagination"
import { FormatMoney } from "../../common/helpers"

import { v4 as uuidv4 } from "uuid"
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap"
import Settings from "../../common/Settings"

//Translation
import { useTranslation } from "react-i18next"
import ActionBar from "../core/ActionBar"
import FilterFields from "./FilterFields"
import Filter from "../core/Filter/Filter"

const ServiceList = props => {
  const history = useHistory()

  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Recordselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  const theUser = parsedUser()

  const { t } = useTranslation("global")

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields = FilterFields
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END
  useEffect(() => {
    if (theUser === null) {
      history.push(Routes.SignIn.path)
    }
    bindDataRemote()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const bindDataRemote = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      "&Search=" +
      (filterObj.search ?? "") +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage +
      "&OnlyPendingToPaid=" +
      state.OnlyPendingToPaid

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Service/List", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    setSelectedItem(obj)
    setShowAddEdit(!showAddEdit)
    if (reload) {
      await bindDataRemote()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "Service/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: t("no") ?? "",
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      <Container>
        {showAddEdit ? (
          <AddEditService
            toggle={toggleAddEdit}
            show={showAddEdit}
            model={selectedItem}
          />
        ) : null}

        {props.Full ? (
          <Row>
            <Col xs={12} className="mb-4">
              <ActionBar
                title={t("services")}
                icon={"fas fa-file-invoice"}
                actions={
                  <>
                    <button
                      className="btn btn-primary btn-sm me-2"
                      onClick={() => {
                        // TODO eventually update this modal

                        toggleAddEdit({
                          id: uuidv4(),
                          organizationId: "",
                          description: "",
                          price: 0,
                          maxDiscountPercentage: 0,
                          created: null,
                          deleted: null,
                          serviceTaxes: [],
                        })
                        //toggleAddEdit()
                      }}
                    >
                      <i className="fa fa-plus " />
                    </button>
                    <button
                      className={
                        "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                      }
                      onClick={() => toggleFilter()}
                    >
                      <i className="fa fa-filter" />
                    </button>
                    <DropdownButton
                      align="start"
                      size="sm"
                      id="mainMore"
                      className="ms-2 d-inline"
                      title={<i className="fa fa-ellipsis-v"></i>}
                    >
                      <Dropdown.Item
                        as="button"
                        onClick={() => {
                          window.open(
                            Settings.BasePathForAPI +
                              "service/exportToExcel?OrganizationId=" +
                              theUser.OrganizationId,
                            "_target"
                          )
                        }}
                      >
                        {t("export_excel")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </>
                }
              ></ActionBar>
            </Col>
            {showFilter ? (
              <Col xs={12}>
                <Filter
                  filterAction={toggleFilter}
                  fields={filterFields}
                  setFilterFields={getFilterValues}
                />
              </Col>
            ) : null}
          </Row>
        ) : null}

        {state.Records.length === 0 ? (
          <div className="row">
            <div className="col-6 mx-auto text-center">
              <img
                src={emptyImg}
                className="img-fluid"
                style={{ maxWidth: "50%" }}
                alt=""
              />
            </div>
          </div>
        ) : null}
        {state.Records.length > 0 ? (
          <div className="card shadow-sm">
            {!props.Full ? (
              <div className="card-header py-1">
                <div className="card-title">
                  <i className="fa fa-bell " />
                  &nbsp; {t("services")}
                </div>
              </div>
            ) : null}
            <div className="card-body p-0 ">
              <Row>
                <Col>
                  {state.Records.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th className="">{t("Service")}</th>
                            <th className="text-end  ">{t("price")}</th>
                            <th className="text-end "></th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.Records.map((item, index) => {
                            return (
                              <tr key={index} className="">
                                <td
                                  className=" clickable text-wrap  "
                                  onClick={() => toggleAddEdit(item)}
                                >
                                  {item.name}
                                </td>
                                <td className="text-end ">
                                  {item.currency?.name}{" "}
                                  {FormatMoney(item.price)}
                                </td>
                                <td className=" text-end">
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => toggleAddEdit(item, false)}
                                    >
                                      <i className="fa fa-edit" />
                                    </button>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => deleteConfirm(item)}
                                      type="button"
                                    >
                                      <i className="fa fa-trash text-danger" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div className="card-footer overflow-hidden">
              <Pagination
                totalPages={state.TotalPages}
                currentPage={state.currentPage}
                onPageChanged={onPageChanged}
              />
            </div>
          </div>
        ) : null}
      </Container>
    </>
  )
}

export default ServiceList
