import SettingSectionDTO from "./SettingSectionDTO";

const SettingSetions : SettingSectionDTO[]= [
	{
		name: "administration",
		parentId: "admin",
		requiredParent: false,
		sections: [
			{
				reference: "users",
				label: "users",
			},
			{
				reference: "roles",
				label: "roles",
			},
			
		]
	},
	{
		name: "hr",
		parentId: "hr",
		requiredParent: true,
		sections: [
			{
				reference: "banks",
				label: "banks",
				
			},
			{
				reference: "departments",
				label: "departments",
				
			},
			{
				reference: "discounts",
				label: "discounts",
				
			},
			{
				reference: "benefits",
				label: "benefits",
				
			},

			{
				reference: "positions",
				label: "positions",
				
			},
			{
				reference: "payrollCategories",
				label: "payrollCategories",
				
			},
			{
				reference: "extraHours",
				label: "extraHours",
				
			},
		]
	},
	{
		name: "finance",
		parentId: "finance",
		requiredParent: true,
		sections: [
			{
				reference: "banks",
				label: "banks",
				
			},
			{
				reference: "currencies",
				label: "currencies",
				
			},
			{
				reference: "taxes",
				label: "taxes",
				
			},

			{
				reference: "invoiceTypes",
				label: "invoiceTypes",
				
			},
		
			
			{
				reference: "expenseType",
				label: "expenseTypes",
				
			},
			
			
		]
	},

	{
		name: "products",
		parentId: "finance",
		requiredParent: true,
		sections: [
			
			{
				reference: "warehouse",
				label: "warehouse",	
			},

			{
				reference: "warehouseSection",
				label: "warehouseSection",	
			},
			{
				reference: "containers",
				label: "containers",	
			},
			{
				reference: "productCategories",
				label: "productCategories",	
			},
			
			
			
		]
	},
	{
		name: "workshop",
		parentId: "workshop",
		requiredParent: true,
		sections: [
			{
				reference: "workshopStatus",
				label: "workshopStatus",
				
			},
			{
				reference: "warehouse",
				label: "warehouse",	
			},

			{
				reference: "warehouseSection",
				label: "warehouseSection",	
			},
			{
				reference: "containers",
				label: "containers",	
			},
			{
				reference: "productCategories",
				label: "productCategories",	
			},
			
			
			
		]
	}
]

export default SettingSetions;