interface RouteDTO {
  path: string
  component: FC
  withSidebar: boolean
  exact?: boolean
}
import { FC } from "react"

import DashboardOverview from "../components/dashboard/DashboardOverview"

import { Routes } from "../routes"
import AssetsPage from "./AssetsPage"
import CalendarPage from "./CalendarPage"
import ChatPage from "./ChatPage"
import ExpensesPage from "./ExpensesPage"
import GetInPage from "./GetInPage"

import PosSalesPage from "./PosSalesPage"
import ProfilePage from "./ProfilePage"
import ProviderDetailsPage from "./ProviderDetailsPage"
import ProvidersPage from "./ProvidersPage"
import ReportsPage from "./ReportsPage"
import CustomersPage from "./CustomersPage"
import CustomerDetails from "../components/Customers/CustomerDetails"

import SettingsPage from "./SettingsPage"
import SubscriptionSelection from "../components/Subscriptions/SubscriptionSelection"
import WorkshopMaintenancePage from "./WorkshopMaintenancePage"
import PaymentHistoryDetailsPage from "./PaymentHistoryDetailsPage"
import WorkshopDiagnosticPrintPage from "./WorkshopDiagnosticPrintPage"

import AdminUsersPage from "./AdminUsersPage"
import AdminOrganizationPage from "./AdminOrganizationPage"
import WorkshopMaintenanceExternalPage from "./WorkshopMaintenanceExternalPage"
const routesWithComponent: RouteDTO[] = [
  {
    path: Routes.WorkshopMaintenance.path,
    component: WorkshopMaintenancePage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.workshopRecordExternal.path,
    component: WorkshopMaintenanceExternalPage,
    withSidebar: false,
    exact: true,
  },
  
  {
    path: Routes.WorkshopDiagnosticPrint.path,
    component: WorkshopDiagnosticPrintPage,
    withSidebar: false,
    exact: true,
  },
  {
    path: Routes.SelectSubscription.path,
    component: SubscriptionSelection,
    withSidebar: false,
    exact: true,
  },
  {
    path: Routes.getin.path,
    component: GetInPage,
    withSidebar: false,
    exact: true,
  },
  {
    path: Routes.PaymentHistoryDetails.path,
    component: PaymentHistoryDetailsPage,
    withSidebar: false,
    exact: true,
  },
  {
    path: Routes.Chat.path,
    component: ChatPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Profile.path,
    component: ProfilePage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Home.path,
    component: CalendarPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Calendar.path,
    component: CalendarPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.ExpensesList.path,
    component: ExpensesPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Providers.path,
    component: ProvidersPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.ProviderDetails.path,
    component: ProviderDetailsPage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.Assets.path,
    component: AssetsPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Home.path,
    component: CalendarPage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.BillingDashboard.path,
    component: DashboardOverview,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.Reports.path,
    component: ReportsPage,
    withSidebar: true,
    exact: true,
  },

  {
    path: Routes.Settings.path,
    component: SettingsPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.PosSales.path,
    component: PosSalesPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.Customers.path,
    component: CustomersPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.CustomerDetails.path,
    component: CustomerDetails,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.AdminUsers.path,
    component: AdminUsersPage,
    withSidebar: true,
    exact: true,
  },
  {
    path: Routes.AdminOrganizations.path,
    component: AdminOrganizationPage,
    withSidebar: true,
    exact: true,
  },
]

export default routesWithComponent
