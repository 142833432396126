import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"

import emptyImg from "../../assets/img/illustrations/no_contact.svg"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import AddEditContact from "./AddEditContact"

//Translation
import { useTranslation } from "react-i18next"

const ContactList = props => {
  //   const state = useState({
  //     Contacts: [],
  //     UI: {
  //       showAddEdit: false,
  //       SelectedItem: null,
  //     },
  //   })

  const { t } = useTranslation("global")

  const [state, setState] = useState({
    Contacts: [],
    UI: {
      showAddEdit: false,
      SelectedItem: null,
    },
  })

  useEffect(() => {
    //Load remote records
    request()
  }, [])

  const request = async () => {
    let query = ""

    if (props.CustomerId) {
      query += "CustomerID=" + props.CustomerId
    }
    if (props.EmployeeId) {
      query += (query.length > 0 ? "&" : "") + "EmployeeId=" + props.EmployeeId
    }
    if (props.OrganizationId) {
      query +=
        (query.length > 0 ? "&" : "") + "OrganizationId=" + props.OrganizationId
    }

    if (query.length === 0) {
      return
    }
    let requestAPI = await API.getAction("Contact/List?" + query)

    if (requestAPI.data.status === "ok") {
      console.log("post", requestAPI)
      let copy = JSON.parse(JSON.stringify(state))
      copy.Contacts = requestAPI.data.response

      setState(copy)
    }
  }
  const toggle = async (obj, reload) => {
    setState({ ...state, UI: { ...state.UI, SelectedItem: obj } })

    setState({
      ...state,
      UI: { ...state.UI, showAddEdit: !state.UI.showAddEdit },
    })
    if (reload !== undefined) {
      request()
    }
  }

  const deleteConfirm = async obj => {
    confirmAlert({
      title: t("confirm"),
      message: t("sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction("Contact/Delete?id=" + obj.id)
            await request()
          },
        },
        {
          label: t("no"),
          //onClick: () => alert("Click No"),
        },
      ],
    })
  }

  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditContact
          toggle={toggle}
          show={state.UI.showAddEdit}
          model={state.UI.SelectedItem}
        />
      ) : null}
      <div className="col-12">
        <div className="row">
          <div className="col-auto">
            <h5 className="display-5 d-inline">{t("contacts")}</h5>
          </div>
          <div className="col ml-auto text-right">
            <button
              className="btn btn-primary d-inline ml-auto btn-sm"
              onClick={() =>
                toggle({
                  id: "",
                  created: "",
                  name: "",
                  lastName: "",
                  position: "",
                  phone: "",
                  note: "",
                  employeeId: props.EmployeeId,
                  customerId: props.CustomerId,
                  employeePositionId: props.EmployeePositionId,
                })
              }
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        {state.Contacts.length > 0 ? (
          state.Contacts.map((item, index) => {
            let itemParsed = JSON.parse(JSON.stringify(item))
            console.log("the item", item, itemParsed)
            return (
              <div className="card shadow-sm mt-2" key={index}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      {item.name} {item.lastName}
                      <br />
                      <small className="text-muted">
                        {item.phone} {item.email ? " | " + item.email : null}
                      </small>
                    </div>
                    <div className="col text-right">
                      <div className="btn-group">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => toggle(itemParsed)}
                        >
                          <i className="fa fa-edit" />
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => deleteConfirm(itemParsed)}
                        >
                          <i className="fa fa-trash text-danger" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="col-12 text-center">
            <img
              src={emptyImg}
              className="img-fluid"
              style={{ maxWidth: "25%" }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ContactList
