import React from "react"
import ReportsContainer from "../core/ReportsContainer"
import { useTranslation } from "react-i18next"
import CRUDField from "../core/genericCRUD/CRUDField"
import { parsedUser } from "../../common/GetCurrentUser"


const ProfitAndLoss = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields: CRUDField[] = [
    {
      name: "from",
      title: t("from")??"",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-4",
    },
    {
      name: "until",
      title: t("until")??"",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-4",
    }
  ]
  const headers = [
   
    {
      name: "concept",
      title: t("concept"),
      type: "text",
    },
    {
      name: "amount",
      title: t("amount"),
      type: "money",
      class: "text-end"
    },

  ]
  const groupBy= "type";
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("profitAndLoss") ?? ""}
      filterFields={filterFields}
      headers={headers}
      endpoint={`organization/profitandloss?organizationId=${user.OrganizationId}`}
      groupBy={groupBy}
      summaryFields={[{
        name: "amount",
        title: t("amount") ?? "",
        type: "money",
      colspan:2,
      class:"text-end"
      }]}
    />
  )
}

export default ProfitAndLoss
