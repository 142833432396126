import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  // Dropdown,
  Modal,
  Row,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"

import { Search, Dropdown } from "semantic-ui-react"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
const MaintenanceAdd = props => {
  const [isLoading, setIsLoading]= useState(false);
  const [model, setModel] = useState({
    workshopStatusId: null,
    productId: null,
    assignedTo: null,
    customerId: null,
  })
  const user = parsedUser()

  const { t } = useTranslation("global")
  const [products, setProducts] = useState([])
  const [customers, setCustomers] = useState([])
  const resultRenderProducts = obj => {
    console.log(obj)
    return <div>{(obj.brand ?? "") + " " + (obj.name ?? "")}</div>
  }
  const resultRenderCustomer = obj => {
    console.log(obj)
    return <div>{obj.firstName ?? ""}</div>
  }

  const [search, setSearch] = useState("")
  const [searchCustomer, setSearchCustomer] = useState("")
  const requestProducts = async obj => {
    let Query =
      "OrganizationId=" + user.OrganizationId + "&q=" + obj + "&IsAnActive=true"
    let requestAPI = await API.getAction("products/search", Query)
    setProducts(requestAPI.data.response)
  }
  const [selectedProduct, setSelectedProduct] = useState(null)

  const requestCustomer = async obj => {
    let Query = "OrganizationId=" + user.OrganizationId + "&search=" + obj
    let requestAPI = await API.getAction("customer/list", Query)
    setCustomers(requestAPI.data.response)
  }
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [statuses, setStatuses] = useState([])
  const requestStatuses = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    let requestAPI = await API.getAction("workshop/ListStatus", Query)

    let s = requestAPI.data.response
    let final = s.map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.name,
      }
    })
    setStatuses(final)
  }

  const assetTimeoutRef = React.useRef()
  const assetHandleSearchChange = React.useCallback((e, data) => {
    clearTimeout(assetTimeoutRef.current)
    setSearch(data.value)
    assetTimeoutRef.current = setTimeout(async () => {
      console.log("data", data)
      if (data.value.length > 2) {
        await requestProducts(data.value)
      }
    }, 300)
  }, [])

  const customerTimeoutRef = React.useRef()
  const customerHandleSearchChange = React.useCallback((e, data) => {
    clearTimeout(customerTimeoutRef.current)
    setSearchCustomer(data.value)
    customerTimeoutRef.current = setTimeout(async () => {
      console.log("data", data)
      if (data.value.length > 2) {
        await requestCustomer(data.value)
      }
    }, 300)
  }, [])

  const [users, setUsers] = useState([])
  const requestUsers = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    let requestAPI = await API.getAction("users/AutoCompleteSearch", Query)
    console.log(requestAPI)
    let s = requestAPI.data.response
    let final = s.map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.firstName + " " + (item.lastName ?? ""),
      }
    })
    setUsers(final)
  }

  const handleSubmit = async () => {
    console.log(model)
    model.organizationId = user.OrganizationId
    setIsLoading(true)
    let request = await API.postAction(
      `workshop/Add?currentUserId=${user.ID}`,
      model
    )
    setIsLoading(false);
    console.log(request)
    props.toggle()
  }

  useEffect(() => {
    requestStatuses()
    requestUsers()
  }, [])
  useEffect(() => {
    if (props.model) setModel(props.model)
  }, [props.model])

  return (
    <Modal show={props.show} onHide={props.toggle} className="modalRight">
      <Modal.Header closeButton>
        <Row className="mx-0 w-100">
          <Col>{t("maintenance")}</Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <Search
                placeholder={t("asset") ?? ""}
                results={products}
                minCharacters={3}
                value={search}
                resultRenderer={resultRenderProducts}
                onSearchChange={assetHandleSearchChange}
                onResultSelect={(event, data) => {
                  console.log(data.result)
                  setSelectedProduct(data.result)
                  setModel({
                    ...model,
                    productId: data.result.id,
                  })
                }}
              ></Search>

              {/* SELECT PRODUCT */}
              {selectedProduct ? (
                <div className="border my-2 bg-white px-2 py-1">
                  <span>
                    {selectedProduct.brand} {selectedProduct.name}
                  </span>
                </div>
              ) : null}
            </Col>

            <Col xs={12}>
              <table className="table">
                <tbody>
                  <tr>
                    <th>{t("status")}</th>
                    <td>
                      <Dropdown
                        search
                        className="form-control"
                        options={statuses}
                        button
                        selection
                        value={model.workshopStatusId ?? ""}
                        onChange={(event, data) => {
                          console.log(data)
                          setModel({
                            ...model,
                            workshopStatusId: data.value,
                          })
                        }}
                        placeholder={t("select")}
                      ></Dropdown>
                    </td>
                  </tr>
                  <tr>
                    <th>{t("assignee")}</th>
                    <td>
                      <Dropdown
                        className="form-control"
                        search
                        options={users}
                        button
                        selection
                        value={model.assignedTo ?? ""}
                        onChange={(event, data) => {
                          console.log(data)
                          setModel({
                            ...model,
                            assignedTo: data.value,
                          })
                        }}
                        placeholder={t("select") ?? ""}
                      ></Dropdown>
                    </td>
                  </tr>
                  <tr>
                    <th>{t("customer")}</th>
                    <td>
                      <Search
                        results={customers}
                        minCharacters={3}
                        value={searchCustomer}
                        resultRenderer={resultRenderCustomer}
                        onSearchChange={customerHandleSearchChange}
                        onResultSelect={(event, data) => {
                          console.log(data.result)
                          setSelectedCustomer(data.result)
                          setModel({
                            ...model,
                            customerId: data.result.id,
                          })
                        }}
                      ></Search>

                      {/* SELECT CUSTOMER */}
                      {selectedCustomer ? (
                        <div className="border my-2 bg-white px-2 py-1">
                          <span>{selectedCustomer.firstName}</span>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button
        disabled={isLoading}
          className="btn btn-primary btn-sm"
          onClick={() => handleSubmit()}
        >
          {t("save")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MaintenanceAdd
