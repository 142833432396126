import React, { useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

interface Product {
  id: string
  name: string
  price: number
  icon: string
}

interface CartItem {
  productId: number
  quantity: number
}

interface Sale {
  cartItems: CartItem[]
  total: number
}

const PostSales: React.FC = () => {
  const { t } = useTranslation("global")
  const [search, setSearch] = useState("")
  const user = parsedUser()
  const [products, setProducts] = useState<Product[]>([
    {
      id: "1",
      name: "Product 1",
      price: 100,
      icon: "https://via.placeholder.com/150",
    },
    {
      id: "2",
      name: "Product 2",
      price: 150,
      icon: "https://via.placeholder.com/150",
    },
  ])

  const requestProducts = async () => {
    const query = `search=${search}&OrganizationId=${user.OrganizationId}`
    const request = await API.getAction("/serviceProduct/List", query)
    console.log("request", request.data)
    //setProducts(data)
  }

  return (
    <Row className="h-100" style={{ minHeight: "100vh" }}>
      <Col xs={12} md={8} className="bg-light border-right h-100">
        <Row className="mt-2">
          <Col xs={11}>
            <input type="text" className="form-control" />
          </Col>
          <Col xs={"auto"}>
            <button className="btn btn-primary">
              <i className="fa fa-plus"></i>
            </button>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12}>
            <Row>
              {products.map((product, index) => {
                return (
                  <Col key={index}>
                    <Card className="text-center clickable">
                      <Card.Body className="px-2 py-1">
                        <i className="fa-solid fa-tag fa-3x my-4"></i>
                        <p>{product.name}</p>
                      </Card.Body>
                      <Card.Footer className="px-2 py-1">
                        <p>{product.price}</p>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={4} className="bg-white">
        <Card className="mt-2 shaodw-sm">
          <Card.Header className="px-2 py-1">
            <Card.Title>{t("invoice")}</Card.Title>
            <Row>
              <Col>
                <label>{t("invoiceType")}</label>
                <select className="form-control form-control-sm"></select>
              </Col>
              <Col>
                <label>{t("customer")}</label>
                <select className="form-control form-control-sm"></select>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body></Card.Body>
          <Card.Footer className="px-2 py-1">
            <button className="btn btn-primary btn-sm btn-block">
              {t("print")}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  )
}

export default PostSales
