import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"

import { Routes } from "../../../routes"
import AddEditEmployee from "./AddEditEmployee"

import emptyImg from "../../../assets/img/illustrations/no_candidate.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import ActionBar from "../../core/ActionBar"
import Pagination from "../../core/Pagination"
import Filter from "../../core/Filter/Filter"
import { useTranslation } from "react-i18next"
import CRUDField from "../../core/genericCRUD/CRUDField"
import { Card } from "react-bootstrap"
import { FormatMoney } from "../../../common/helpers"
const EmployeeList = props => {
  const { t } = useTranslation("global")
  const history = useHistory()
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(null)

  const user = parsedUser()
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    quantityOfPages: 0,
    quantityOfRecords: 10,
    Search: "",
    totalRecords:0
  })

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields: CRUDField[] = [
    {
      name: "payrollCategoryId",
      title: "payrollCategory",
      dbType: "text",
      htmlType: "select",
      containerClass: "col-4",
      dataSourceValueField: "id",
      dataSourceTextField: "name",
      dataSource:
        "employees/payrollCategories?OrganizationId=" + user?.OrganizationId,
    },
    {
      name: "activeStatus",
      title: "status",
      dbType: "int",
      htmlType: "select",
      containerClass: "col-4",
      dataSourceValueField: "id",
      dataSourceTextField: "name",
      dataSourceLocal: [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
      ],
    },
    {
      name: "search",
      title: "search",
      htmlType: "text",
      dbType: "date",
      containerClass: "col-4",
    },
  ]
  const [filterObj, setFilterObj] = useState<any>({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END

  // useEffect(() => {
  //   bindDataRemote()
  // }, [])

  const bindDataRemote = async () => {
    try {
      console.log(pageInfo)
      
      let query =`OrganizationId=${user.OrganizationId}&Quantity=${(pageInfo.quantityOfRecords??"12")}&Page=${pageInfo.page}&payrollCategoryId=${(filterObj.payrollCategoryId ?? "")}&search=${ (filterObj.search ?? "")}&activeStatus=${(filterObj.activeStatus ?? "")}`
       
     
      
      let requestAPI = await API.getAction("Employees/List?" + query)

      if (requestAPI.data.status === "ok") {
        console.log(requestAPI.data)
        
        setRecords(requestAPI.data.response)
        setPageInfo({
          ...pageInfo,
          page: requestAPI.data.page,
          quantityOfPages: requestAPI.data.quantityOfPages,
          totalRecords: requestAPI.data.quantityOfRecords,
        })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const toggleAddEdit = async (obj?) => {
    setRecord(obj)
    setShowAddEdit(!showAddEdit)

    if (showAddEdit) {
      await bindDataRemote()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.firstName + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction(
              "Employees/Delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("Click No")
          },
        },
      ],
    })
  }

  const onPageChanged = async obj => {
    setPageInfo({
      ...pageInfo,
      page: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
useEffect(()=>{
  if(pageInfo){
    bindDataRemote();
  }
},[pageInfo.page])
  return (
    <>
      {showAddEdit ? (
        <AddEditEmployee
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={record}
        />
      ) : null}
      {props.Full ? (
        <div className="row mb-2 ">
          <div className="col ">
            <ActionBar
              title={t("employees")}
              icon={"fa fa-id-card"}
              actions={
                <>
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => toggleAddEdit()}
                  >
                    <i className="fa fa-plus " />
                  </button>
                  <button
                    className={
                      "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                    }
                    onClick={() => toggleFilter()}
                  >
                    <i className="fa fa-filter" />
                  </button>
                </>
              }
            ></ActionBar>
          </div>
        </div>
      ) : null}

      {/* FILTERS */}
      {showFilter && filterFields && filterFields.length > 0 && (
        <Filter
          filterAction={() => {
            bindDataRemote()
          }}
          fields={filterFields}
          setFilterFields={getFilterValues}
        />
      )}

      <div className="card shadow-sm">
        {/* RECORDS */}

        <div className="card-body p-0 ">
          {records.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped ">
                <thead>
                  <tr>
                    <td>{t("name")}</td>
                    {/* <th>{t("email")}</th> */}
                    <th>{t("department")}</th>
                    <th>{t("position")}</th>
                    <th>{t("status")}</th>

                    <th>{t("beginWorking")}</th>
                    <th>{t("payrollCategory")}</th>
                    <th>{t('baseSalary')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {records.map((item: any, index: number) => {
                  let parsedItem = item
                  return (
                    <tr
                      key={index}
                     
                    >
                      <td  className="clickable "
                      onClick={() =>
                        history.push(Routes.EmployeeDetails.clean + item.id)
                      }>
                        {item.firstName} {item.lastName}
                      </td>
                      {/* <td>
                        <span className="text-muted">{item.email}</span>
                      </td> */}
                      <td>
                        {item.employeeDepartment &&
                        item.employeeDepartment.name ? (
                          <small className="text-muted">
                            {item.employeeDepartment.name}
                          </small>
                        ) : null}
                      </td>
                      <td>
                        {item.employeePosition && item.employeePosition.name ? (
                          <small className="text-muted">
                            {item.employeePosition.name}
                          </small>
                        ) : null}
                      </td>
                      <td>
                        {" "}
                        {parsedItem.status ===
                        null ? null : parsedItem.status === 1 ? (
                          <span className="badge badge-sm bg-success text-white ">
                            {t('active')}
                          </span>
                        ) : parsedItem.status === 2 ? (
                          <span className="badge badge-sm bg-light text-dark">
                            {t('inactive')}
                          </span>
                        ) : null}
                      </td>
                      <td>
                        <small className="text-muted me-2">
                          {moment(item.beginWorkingDate).format("MMM DD, YYYY")}
                        </small>
                      </td>
                      <td>
                        {item.payrollCategory
                          ? item.payrollCategory.name
                          : "N/A"}
                      </td>
                      <td>
                        {FormatMoney(item.basePayroll)}
                          
                      </td>
                      <td className="text-end">
                        {props.noOptions ? null : (
                          <div className="btn-group">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => toggleAddEdit(parsedItem)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => deleteConfirm(parsedItem)}
                              type="button"
                            >
                              <i className="fa fa-trash text-danger" />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="col-12 text-center">
              <img
                src={emptyImg}
                className="img-fluid"
                style={{ maxWidth: "25%" }}
              />
            </div>
          )}
        </div>
        
      </div>
      <Card className="p-1 my-4">
        <Card.Body className="p-1">
        <Pagination
            totalPages={pageInfo.quantityOfPages}
            currentPage={pageInfo.page}
            onPageChanged={onPageChanged}
            totalRecords={pageInfo.totalRecords}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default EmployeeList
