import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import MaintenanceKanbanView from "./MaintenanceKanbanView"

import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import MaintenanceDetails from "./MaintenanceDetails"
import MaintenanceAdd from "./MaintenanceAdd"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"
import FilterFields from "./FilterFields"
import MaintenanceListView from "./MaintenanceListView"
import { confirmAlert } from "react-confirm-alert"
import SubscriptionStatusComponent from "../Subscriptions/SubscriptionStatusComponent"

const Maintenance = () => {
  const [viewMode, setViewMode] = useState("list")
  const [extendedView, setExtendedView] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const { t } = useTranslation("global")
  const [records, setRecords] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  const user = parsedUser()
  const toggleViewMode = obj => {
    setViewMode(obj)
  }

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields = FilterFields
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END

  const toggleAdd = obj => {
    setSelectedRecord(obj)
    setShowAdd(!showAdd)
    if (showAdd) {
      bindDataRemote()
    }
  }
  const toggleDetails = obj => {
    setSelectedRecord(obj)
    setShowDetails(!showDetails)
    if (showDetails) {
      bindDataRemote()
    }
  }

  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    if (filterObj.statusId) {
      Query += "&statusId=" + filterObj.statusId
    }
    if (filterObj.assignedToId) {
      Query += "&assignedToId=" + filterObj.assignedToId
    }

    if (filterObj.from) {
      Query += "&from=" + filterObj.from
    }
    if (filterObj.completed) {
      Query += "&completed=" + filterObj.completed
    } else {
      Query += "&completed=false"
    }

    if (filterObj.until) {
      Query += "&until=" + filterObj.until
    }
    if (filterObj.search) {
      Query += "&search=" + filterObj.search
    }
    setIsLoading(true)
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("workshop/list", Query)
    setIsLoading(false)
    if (requestAPI.data.status === "ok") {
      console.log(requestAPI.data)
      setRecords(requestAPI.data.response)
    }
  }
  const markCompleted = async model => {
    if (model === null) return
    const request = await API.getAction(
      "workshop/markAsCompleted?id=" + model.id
    )
    console.log(request)

    await bindDataRemote()
  }

  const deleteConfirm = async model => {
    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + model.product.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction("workshop/Delete?id=" + model.id)
            bindDataRemote()
          },
        },
        {
          label: t("no"),
          onClick: () => {
            console.log("no")
          },
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  useEffect(() => {
    console.log(viewMode)
  }, [viewMode])

  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <Container fluid>
      <SubscriptionStatusComponent workshop />
      {showDetails ? (
        <MaintenanceDetails
          show={showDetails}
          toggle={toggleDetails}
          record={selectedRecord}
        ></MaintenanceDetails>
      ) : null}
      {showAdd ? (
        <MaintenanceAdd
          show={showAdd}
          toggle={toggleAdd}
          model={selectedRecord}
        />
      ) : null}

      <Row>
        <Col xs={12}>
          <ActionBar
            title={t("maintenance")}
            icon={"fa fa-toolbox"}
            actions={
              <>
                <button
                  className="btn btn-sm me-2"
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    bindDataRemote()
                  }}
                >
                  <i className="fa fa-refresh"></i>
                </button>
                {viewMode === "kanban" ? (
                  <button
                    className={
                      "btn  btn-sm me-2 " + (extendedView ? "btn-primary" : "")
                    }
                    onClick={() => setExtendedView(!extendedView)}
                  >
                    <i className="fa-solid fa-up-right-and-down-left-from-center"></i>{" "}
                  </button>
                ) : null}
                <button
                  className="btn btn-primary btn-sm me-2"
                  onClick={() => toggleAdd()}
                >
                  <i className="fa fa-plus " />
                </button>

                <button
                  className={
                    "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                  }
                  onClick={() => toggleFilter()}
                >
                  <i className="fa fa-filter" />
                </button>
                <div className="btn-group ms-2">
                  <button
                    onClick={() => toggleViewMode("kanban")}
                    className={
                      "btn btn-sm " +
                      (viewMode === "kanban" ? "btn-secondary" : "")
                    }
                  >
                    {t("Cardview")}
                  </button>
                  <button
                    onClick={() => {
                      console.log("list")
                      toggleViewMode("list")
                    }}
                    className={
                      "btn btn-sm " +
                      (viewMode === "list" ? "btn-secondary" : "")
                    }
                  >
                    {t("ListView")}
                  </button>
                </div>
              </>
            }
          ></ActionBar>
        </Col>

        <Col xs={12} className="mb-4">
          {/* FILTERS */}
          {showFilter && filterFields && filterFields.length > 0 && (
            <Filter
              filterAction={() => {
                bindDataRemote()
              }}
              fields={filterFields}
              setFilterFields={getFilterValues}
            />
          )}
        </Col>

        <Col xs={12} className="mb-4">
          {viewMode === "kanban" && (
            <Card>
              <Card.Body className=" bg-white p-0">
                <MaintenanceKanbanView
                  extendedView={extendedView}
                  records={records}
                  markCompleted={markCompleted}
                  toggleDetails={toggleDetails}
                />
              </Card.Body>
            </Card>
          )}

          {viewMode === "list" && (
            <MaintenanceListView
              records={records}
              markCompleted={markCompleted}
              toggleDetails={toggleDetails}
              delete={deleteConfirm}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Maintenance
