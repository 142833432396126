import React, { useState, useEffect } from "react"
import SimpleBar from "simplebar-react"
import { useHistory, useLocation, Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Nav, Badge, Image, Button, Navbar, Col, Row } from "react-bootstrap"

import { parsedUser } from "../../../common/GetCurrentUser"
import { Routes } from "../../../routes"
import API from "../../../common/API"

import { useTranslation } from "react-i18next"
import {
  SidebarAdmin,
  SidebarFinance,
  SidebarPayroll,
  SidebarWorkshop,
} from "./SidebarElements.jsx"
import OrganizationUI from "./OrganizationUI"
import OmniBar from "./OmniBar"

const Sidebar = ({ sidebarToggle, handleSidebarShow, settings }) => {
  
  const location = useLocation()
  const { pathname } = location
  //let { sectionurl } = useParams()

  const [show, setShow] = useState(false)
  const [isCollapse, setIsCollapse] = useState()
  const showClass = show ? "show" : ""
  const [organizations, setOrganizations] = useState([])

  //const [showSidebar, setShowSidebar] = useState(false)

  const { t } = useTranslation("global")

  let user = parsedUser()
  const history = useHistory()

  const requestOrganizations = async () => {
    if (user === null) {
      return
    }
    let requestAPI = await API.getAction(
      "Organization/OrganizationsWhereTheUserBelong?AccountId=" + user.ID
    )
    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response)
    }
  }

  useEffect(() => {
    requestOrganizations()
  }, [])

  const onCollapse = () => {
    setShow(!show)
  }

  const NavItem = props => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : ""
    const navItemClassName = link === pathname ? "active" : ""
    const linkProps = external ? { href: link } : { as: Link, to: link || "" }

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link
          to="#"
          {...linkProps}
          as={Link}
          target={target}
          className={classNames}
        >
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <i className={icon}></i>{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={32}
                height={32}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    )
  }

  const Menu = () => {
    if (!settings) {
      return null
    }
    let option = null
    console.log(settings)
    if (settings.application === "admin") {
      option = SidebarAdmin()
    }
    if (settings.application === "finance") {
      option = SidebarFinance()
    } else if (settings.application === "hr") {
      option = SidebarPayroll()
    } else if (settings.application === "workshop") {
      option = SidebarWorkshop()
    } 
    // else if (settings.application === "pos") {
    //   option = SidebarPOS()
    // }

    if (!option) {
      return <></>
    }
    return (
      <Nav className="flex-column pt-2 pt-md-0 mainNavbar">
        <h4>{t(option.title)}</h4>
        <Nav.Link
          className="collapse-close d-md-none "
          onClick={onCollapse}
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Nav.Link>
        {option.items &&
          option.items.map((item, index) => {
            return (
              <NavItem
                tag={Link}
                key={index}
                title={t(item.title)}
                link={item.link}
                icon={item.icon}
                className="py-1"
              ></NavItem>
            )
          })}
        {OrganizationUI(user, organizations)}
      </Nav>
    )
  }
  const ContentSidebar = () => {
    return (
      <>
        <div className="container sidebar-inner p-0">
          <Row className="mx-0">
            <Col className="p-0 col-auto">
              <OmniBar settings={settings} />
            </Col>
            <Col>
              <Menu />
            </Col>
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none primaryNavBarWithLogo"
      >
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={() => {
            if (!sidebarToggle) {
              handleSidebarShow()
            }
            onCollapse()
          }}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Home.path}>
          <img
            src="/icons/32x32-icon.png"
            alt="pleelo"
            className="navbar-brand-light"
          />
        </Navbar.Brand>
      </Navbar>
      <button
        type="button"
        className={
          sidebarToggle
            ? "btn button-toggle text-white"
            : "btn button-toggle rotate bg-dark text-white ms-3 "
        }
        onClick={handleSidebarShow}
      >
        <FontAwesomeIcon
          className="fa-lg"
          icon={sidebarToggle ? faBars : faBars}
        />
      </button>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-inline-block bg-primary text-white`}
          style={
            sidebarToggle
              ? { transition: "width 1s 0.3s" }
              : { width: "0px", transition: "width 0.3s" }
          }
        >
          <ContentSidebar settings={settings}></ContentSidebar>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}

export default Sidebar
