import React from "react"
import ReportsContainer from "../../core/ReportsContainer"
import { useTranslation } from "react-i18next"
import CRUDField from "../../core/genericCRUD/CRUDField"
import { parsedUser } from "../../../common/GetCurrentUser"


const WorkshopTaskByStatusReport = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields: CRUDField[] = [

  ]
  const headers = [
    {
      name: "name",
      title: t("name"),
      type: "text",
    },
    {
      name: "count",
      title: t("count"),
      type: "number",
    },

  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("workshopTaskByStatus") ?? ""}
      filterFields={filterFields}
      headers={headers}
      endpoint={`workshop/SummaryByStatus?organizationId=${user.OrganizationId}`}
    />
  )
}

export default WorkshopTaskByStatusReport
