/* eslint-disable indent */
import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"
import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  name: yup.string().required("campo requrido"),
})

//Component
const AddEdit = props => {
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    setIsLoading(true)
    let request = await API.postAction("provider/addedit", obj)
    setIsLoading(false)
    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(request.data.response, true)
    }
  }

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          created: new Date(),
          id: "",
          organizationId: "",
          dgiiequivalentCode: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    //   loadDepartments();
  }, [])

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("provider")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("id")}</Form.Label>
              <Form.Control
                type="text"
                name="identifier"
                value={formik.values.identifier}
                onChange={formik.handleChange}
              />
              {formik.errors.identifier ? (
                <div className="invalid text-sm">
                  {formik.errors.identifier}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEdit
