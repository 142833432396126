import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"
import { PayrollTypes } from "../../../common/helpers"
import { useTranslation } from "react-i18next"
//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
  status: yup.string().required(),
  payrollCategoryId: yup.string().required(),
})
//Component
const AddEditPayroll = props => {
  const user = parsedUser()
  console.log("we get", props)
  const [state, setState] = useState({
    UI: {
      Validated: false,
      isLoading: false,
    },
  })
  const [categories, setCategories] = useState([])
  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    let request = await API.postAction("employees/AddEditPayroll", obj)

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(obj, true)
    }
  }
  const bindCatergories = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    let requestAPI = await API.getAction("employees/payrollCategories", Query)
    setCategories(requestAPI.data.response)
  }
  const formik = useFormik({
    initialValues: props.model
      ? props.model
      : {
          payrollTypeId: PayrollTypes.monthly,
          name: "",
          notes: "",
          from: moment().format("YYYY-MM-DD"),
          status: 1,
          created: new Date(),
          id: "",
          organizationId: "",
          payrollCategoryId: null,
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    bindCatergories()
  }, [])

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Nómina</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>Tipo de nómina</Form.Label>
              <select
                className="form-control"
                value={formik.values.payrollTypeId}
                name="payrollTypeId"
                onChange={formik.handleChange}
              >
                <option value="0">-</option>
                <option value={PayrollTypes.hourly}>Por Hora</option>
                <option value={PayrollTypes.weekly}>Semanal</option>
                <option value={PayrollTypes.biWeekly}>Quincenal</option>
                <option value={PayrollTypes.monthly}>Mensual</option>
              </select>
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("payrollCategory")}</Form.Label>
              <select
                className="form-control"
                name="payrollCategoryId"
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {categories.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>

              {formik.errors.payrollCategoryId ? (
                <div className="invalid text-sm">
                  {formik.errors.payrollCategoryId}
                </div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name ? formik.values.name : ""}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>

            <Col xs={12} md={4}>
              <Form.Label>Desde</Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={moment(formik.values.from).format("YYYY-MM-DD")}
                onChange={formik.handleChange}
              />
              {formik.errors.from ? (
                <div className="invalid text-sm">{formik.errorsfrom}</div>
              ) : null}
            </Col>
            <Col xs={12} md={4}>
              <Form.Label>Hasta</Form.Label>
              <Form.Control
                type="date"
                name="until"
                value={moment(formik.values.until).format("YYYY-MM-DD")}
                onChange={formik.handleChange}
              />
              {formik.errors.until ? (
                <div className="invalid text-sm">{formik.errors.until}</div>
              ) : null}
            </Col>

            <Col xs={12} md={4}>
              <Form.Label>Estatus</Form.Label>
              <select
                className="form-control"
                value={formik.values.status}
                onChange={formik.handleChange}
                name="status"
              >
                <option value="">-</option>
                <option value="1">En Progreso</option>
                <option value="2">Aprobado</option>
                <option value="3">Cancelado</option>
              </select>
            </Col>

            <Col xs={12}>
              <Form.Label>Nota</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
              {formik.errors.notes ? (
                <div className="invalid text-sm">{formik.errors.notes}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading.value}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditPayroll
