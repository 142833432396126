import React, { useEffect } from "react"
import { Routes } from "../../routes"
import ProductList from "./ProductList"

//Translation
import { useTranslation } from "react-i18next"
import { productTypes } from "../../common/helpers"

const Assets = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
   // document.title = t("assets")
  })
  return (
    <div className="row">

      <div className="col-12">
        <ProductList productType={productTypes.asset} />
      </div>
    </div>
  )
}
export default Assets
