import React from "react";

import { onlyHTMLTags } from '../../common/helpers';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.bubble.css'; // ES6
import { Formik, useFormik } from 'formik';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import { useEffect } from "react";
import { useRef } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";



const modelMail={
 toEmailAddress: '' ,
 sender : '',
 title: '',
 content: '',
 replyToEmail: '',
 replyToName : '',
 userId:'',
}

const SmallMailComposer = props => {
  

    const handleSubmit = async obj=>{
        console.log(obj);
        let user = parsedUser();
        debugger;
        obj.userId = user.ID;
       // obj.sender = user.firstName;
        obj.replyToEmail = user.Email;

        
        let request = await API.postAction("mail/SendMailInternal" , obj);
        props.toggle(null);

    }
    const mailContent = useRef();

  
    useEffect(() => {
        // setTimeout(() => {
        //     var obj = mailContent.current
        //     if (obj) {
        //         obj.focus();
        //     }
        // }, 500);
    }, []);

    const imageHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file'); 
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange =  async () => {
            var item = input.files[0];
            
            var formData = new FormData();

            formData.append('File', item);

            var fileName = item.name;
            let newItem = {
              
                FileName:fileName,
                Folder: null,
                OrganizationId : null
            };
            
         
                //Upload the actual file now

                let bodyData = new FormData();
                bodyData.append("File", item);
                let responseFile = await API.post(`/File/UploadImage`, bodyData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                console.log(responseFile)
                debugger;
            
           // const range = mailContent.current.getEditorSelection();
           // mailContent.current.getEditor().insertEmbed(range.index, 'image', reader);
            //const res = await this.uploadFiles(file, fileName, mailContent);  
        };
    }
    const toolbarQuill = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean'],
                [{ 'color': [] }]
            ],
            handlers: {
              //  image: imageHandler
            }
        },
    }

    const formik = useFormik({
        initialValues: props.model ? props.model : modelMail,
        // validationSchema,
          onSubmit:handleSubmit,
    });

    return (
        <Modal show={props.show} onHide={props.toggle}  size='md' className='modalRight'>
            <Modal.Header closeButton>
                Enviar correo
            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Row>
                        {/* <Col xs={12} >
                            <div className="input-group">
                                <div className="input-group-prepend me-2">
                                    <label>De</label>
                                </div>
                                <input type="text" className="form-control form-control-sm" />
                            </div>

                        </Col> */}
                        <Col xs={12}  className="mb-2">
                            <div className="input-group">
                                <div className="input-group-text">
                                    <label>Para</label>
                                </div>
                                <input type="text" 
                                name="toEmailAddress"
                                value={formik.values.toEmailAddress??""}
                                onChange={formik.handleChange}
                                className="form-control form-control-sm" />
                            </div>

                        </Col>
                        <Col xs={12} >
                            <div className="input-group">
                                <div className="input-group-text">
                                    <label>Asunto</label>
                                </div>
                                <input type="text"
                                name="title"
                                value={formik.values.title??""}
                                onChange={formik.handleChange}
                                className="form-control form-control-sm" />
                            </div>

                        </Col>
                        <Col xs={12} className="mt-2 textContainerQuill " >
                            
                            <ReactQuill value={formik.values.content??""} name="content"
                                modules={toolbarQuill}
                                id="content" 
                                //ref={mailContent}
                                theme='snow'   // Specify theme in configuration
                                onChange={e => {
                                    let onlyTags = onlyHTMLTags(e);
                                    formik.setFieldValue("content", onlyTags ? "" : e)
                                }}

                            />
                            {formik.errors.content ? <div className="invalid text-sm">{formik.errors.content}</div> : null}
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className="p-1">

                    <Button variant="primary" type="submit" className="btn-sm">
                        Enviar
                    </Button>
                </Modal.Footer>
            </Form>


        </Modal>
    )
}

export default SmallMailComposer;