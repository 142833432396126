import React, { useEffect, useState } from "react";


import { Col, Row, Container } from "react-bootstrap";

import API from "../../../common/API";
import moment from "moment";
import {
  FormatMoney,
  sortArray,
  TransactionType,
} from "../../../common/helpers";
import { divisionOfTheAmount, summaryPerTransactions } from "./payrollHelper";
import { useTranslation } from "react-i18next";

const PayrollPaymentDetails = (props) => {
  const [PayrollPayment, setPayrollPayment] = useState(null);

const { t } = useTranslation("global");
  useEffect(() => {
    request();
  }, []);

  const request = async () => {
    let requestAPI = await API.getAction(
      "Employees/PayrollPaymentDetails?Id=" + props.match.params.id
    );

    console.log(requestAPI);
    if (requestAPI.data.status === "ok") {

      setPayrollPayment(requestAPI.data.response);

    }
  };

  useEffect(() => {
    console.log("payment ", PayrollPayment)
  }, [PayrollPayment])


  if (!PayrollPayment) {
    return <></>;
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <h5 className="text-center mb-4">Payroll Payment Details</h5>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100">
                <div className="text-center  mb-4 mt-md-0">
                  <h4 className="mb-0">
                    {PayrollPayment.payroll.organization.name}
                  </h4>
                </div>
                <div className="row">
                  <div className="col-6">
                    <b>
                      {PayrollPayment.employee.firstName +
                        " " +
                        PayrollPayment.employee.lastName}
                    </b>{" "}
                    <br />
                    <small>
                      {
                        PayrollPayment.employee.employeePosition?.name

                      }
                    </small>
                    <br />
                    {PayrollPayment.employee.email}
                    <br />
                    {PayrollPayment.employee.identifier}
                    {PayrollPayment.employee.bankNavigation ? <>
                      <br />
                      {
                        PayrollPayment.employee.bankNavigation.name
                      }: {PayrollPayment.employee.bankAccount}
                    </> : null}
                  </div>
                  <div className="col-6 text-right">
                    <b>{PayrollPayment.payroll.name}</b>
                    <br />
                    {moment(PayrollPayment.payroll.from).format(
                      "MMMM DD,YYYY"
                    )}{" "}
                    {PayrollPayment.payroll.from
                      ? " - " +
                      moment(PayrollPayment.payroll.until).format(
                        "MMMM DD,YYYY"
                      )
                      : null}
                    <br />
                    {moment().format("MMM DD, YYYY hh:mm a")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <td>Concepto</td>

                          <td>Valor</td>

                          <td className="text-right">Sub-Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        {sortArray(
                          PayrollPayment.payrollEmployeeTransactions.filter(
                            (x) => !x.visibleByTheCompanyOnly
                          ),
                          "type"
                        ).map((item, index) => {
                          let base = summaryPerTransactions(
                            PayrollPayment,
                            TransactionType.baseAmount,
                            PayrollPayment.payroll.payrollTypeId
                          );
                          let globalbase = summaryPerTransactions(
                            PayrollPayment,
                            TransactionType.globalBaseAmount,
                            PayrollPayment.payroll.payrollTypeId
                          );
                          console.log(base);
                          let basePayrollTypeForUser = PayrollPayment.employee.payrollTypeId;
                          let basePayrollType = PayrollPayment.payroll.payrollTypeId;
                          let amount = divisionOfTheAmount(
                            basePayrollTypeForUser,
                            basePayrollType,
                            item,
                            globalbase
                          ).NewValue;

                          let transactionAmount =FormatMoney( amount);

                          let transactionSubTotal =FormatMoney(amount)
                          console.log("type for user", basePayrollTypeForUser, "type for payroll", basePayrollType,"base amount", base,"amount", amount,"item", item);
                          return (
                            <tr key={index}>
                              <td>{item.concept} </td>

                              <td>{transactionAmount}</td>
                              <td className={"text-right "}>
                                <span
                                  className={
                                    item.type === TransactionType.discounts
                                        ? "text-danger"
                                        : ""
                                  }
                                >
                                  {transactionSubTotal}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr className="text-normal">
                          <th colSpan={3} className="text-right ">
                            <b>
                              {FormatMoney(
                                summaryPerTransactions(
                                  PayrollPayment,
                                  0,
                                  PayrollPayment.payroll.payrollTypeId

                                )
                              )}
                            </b>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-primary d-print-none"
                      onClick={() => window.print()}
                    >
                      <i className="fa fa-print" />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default PayrollPaymentDetails;
