import React from "react"
import ReportsContainer from "../../core/ReportsContainer"
import { useTranslation } from "react-i18next"
import CRUDField from "../../core/genericCRUD/CRUDField"
import { parsedUser } from "../../../common/GetCurrentUser"


const SalaryReport = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields: CRUDField [] = [
   {
     name: "departmentId",
     title: t("department")??"",
     dbType: "text",
     htmlType: "select",
     dataSource: 'employees/departments?OrganizationId=' + user.OrganizationId,
     dataSourceValueField: "id",
     dataSourceTextField: "name",
     containerClass: "col-4",
     
   }
 
  ]
  const headers = [
    {
      name: "department",
      title: t("department"),
      type: "text",
    },
    {
      name: "firstName",
      title: t("firstName"),
      type: "text",
    },
    {
      name: "lastName",
      title: t("lastName"),
      type: "text",
      
    },
    {
      name: "basePayroll",
      title: t("basePayroll"),
      type: "money",
      
    },
  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("salaryReport") ?? ""}
      filterFields={filterFields}
      headers={headers}
      endpoint={`employees/SalaryReport?organizationId=${user.OrganizationId}`}
    />
  )
}

export default SalaryReport
