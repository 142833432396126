import React, { useEffect, useState } from "react"

import { Modal, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6
import { onlyHTMLTags } from "../../common/helpers"
import API from "../../common/API"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
  lastName: yup.string().required(),
  phone: yup.string().required(),
})
//Component
const AddEditContact = props => {
  //   const state = useState({
  //     UI: {
  //       isLoading: false,
  //     },
  //   })

  const { t } = useTranslation("global")

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  })

  useEffect(() => {
    console.log("props received on contact", props)
  }, [])

  const handleSubmit = async obj => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    // state.UI.isLoading.set(!state.UI.isLoading.value)
    setState({ ...state, UI: { isLoading: true } })
    let request = await API.postAction("Contact/AddEdit", obj)
    // state.UI.isLoading.set(!state.UI.isLoading.value)
    setState({ ...state, UI: { isLoading: false } })
    if (request.status === 200) {
      props.toggle(request.data, true)
    }
  }

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          lastName: "",
          phone: "",
          position: "",
          email: "",
          note: "",

          created: new Date(),
          id: "",
          OrganizationId: "",
          employeeId: "",
          customerId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("contact")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>{t("first_name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("last_name")}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("phone")}</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>
                {t("contact_position")}/{t("relation")}
              </Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formik.values.position}
                onChange={formik.handleChange}
              />
              {formik.errors.position ? (
                <div className="invalid text-sm">{formik.errors.position}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("notes")}</Form.Label>

              <ReactQuill
                value={formik.values.note}
                name="note"
                onChange={e => {
                  let onlyTags = onlyHTMLTags(e)
                  formik.setFieldValue("note", onlyTags ? "" : e)
                }}
              />
              {formik.errors.note ? (
                <div className="invalid text-sm">{formik.errors.note}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-sm" type="submit">
            {t("save")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditContact
