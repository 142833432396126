import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import 'react-quill/dist/quill.snow.css'; // ES6
import API from '../../../common/API';

import  { parsedUser } from '../../../common/GetCurrentUser';
import { useTranslation } from 'react-i18next';


//Component
const AssignBenefitTransaction = props => {
    const {t}= useTranslation("global");
    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
        Data: {}

    })

    const requestBenefitsAvailable = async () => {
        try {
            let currentUser = parsedUser();
            let query = "EmployeeID=" + props.employeeId + "&OrganizationId=" + currentUser.OrganizationId;
            let request = await API.getAction("Employees/BenefitsPerEmployee", query);
            if (request.status === 200) {
                setState({
                    ...state,
                    Data: request.data.response
                })

            }
        }
        catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        console.log("props received on post", props);
        requestBenefitsAvailable();
    }, [])

    const toggleSelect = async obj => {
       console.log(obj)
        let query =`payrollId=${props.payrollId}&benefitId=${obj.benefitID}&employeeId=${props.employeeId}`
                await API.getAction("employees/AssignBenefitTransaction?"+query);
       
       props.toggle();
    }

    return (
        <Modal show={props.show} onHide={() => props.toggle(null, true)} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>{t('benefits')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {state.Data.length > 0 && state.Data.map((item, index) => {
                    let itemParsed = item;
                    return (
                        <Row key={index} className='my-2 clickable'>
                           
                            <Col>{itemParsed.benefitName} {itemParsed.benefitPercentage ? itemParsed.benefitValue + "% " : itemParsed.benefitValue}</Col>
                            <Col className="col-auto">
                              <button className='btn btn-primary btn-sm' type="button"
                                onClick={() => toggleSelect(itemParsed)}> 
                                {t('assign')}
                              </button>
                            </Col>
                        </Row>
                    )
                })}

            </Modal.Body>
        </Modal>
    )
}

export default AssignBenefitTransaction;