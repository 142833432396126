import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"

import { Routes } from "../../routes"

import PayrollList from "../HR/Payroll/PayrollList"
import EmployeeList from "../HR/Employees/EmployeeList"

import Birthdates from "./Birthdates/Birthdates"
import { parsedUser } from "../../common/GetCurrentUser"

const DashboardOverview = ({ history }) => {
  const currentUser = parsedUser()

  useEffect(() => {
    if (!currentUser) {
      window.location = Routes.SignIn.path
    }
    //Content on load
    localStorage.setItem("selectedApp", "payroll")
  }, [])
  return (
    <Row>
      <Col xs={12} md={9} lg={9}>
        <PayrollList noOptions Quantity={5} />

        <EmployeeList noOptions Quantity={5} />
      </Col>
      <Col xs={12} md={3} lg={3}>
        <Birthdates />
      </Col>
    </Row>
  )
}

export default DashboardOverview
