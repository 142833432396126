import React, { useEffect } from "react"
import moment from "moment"
import { useState } from "react"
import { Search } from "semantic-ui-react"
import { Routes } from "../../routes"
import { v4 as uuidv4 } from "uuid"
import { Link, useHistory, useParams } from "react-router-dom"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"
import { useFormik } from "formik"
import * as yup from "yup"
import ExchangeRate from "../Common/ExchangeRate"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
//Translation
import { useTranslation } from "react-i18next"

const AddEditInvoice = () => {
  //Validation
  const validationSchema = yup.object({
    //   id: yup.string().required(),
    currencyId: yup.string().label("Moneda").required(),
    invoiceTypeId: yup.string().label("Tipo de factura").required(),
    paymentMethod: yup.string().label("Método de pago").required(),
    customerId: yup.string().label("Cliente").required(),
    invoiceDetails: yup
      .array()
      .label("Detalle")
      .min(1, "at least 1")
      .required(),
  })
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id, fromQuote } = useParams()
  const [state, setState] = useState({
    id: id,
    date: moment().format(),
    currencyId: null,
    organizationId: null,
    invoiceTypeId: null,
    customerId: null,
    customerName: null,
    customerPhone: null,
    customerEmail: null,
    invoiceNumber: null,
    paymentMethod: null,
    paymentMethodReference: null,
    note: "",
    currencyExchangeRate: 1,

    sequence: 0,
    credit: false,
    created: moment().format(),
    createdUserId: null,
    deleted: null,
    deletedUserId: null,
    invoiceDetails: [],
  })
  const [items, setItems] = useState([])
  const [queryCustomers, setQueryCustomers] = useState("")
  const [customers, setCustomers] = useState([])
  const [invoiceTypes, setInvoiceTypes] = useState([])
  const [summary, setSummary] = useState({
    subTotal: 0,
    taxes: 0,
    total: 0,
  })

  const requestCustomers = async obj => {
    let Query =
      "OrganizationId=" + user.OrganizationId + "&Search=" + obj + "&Quantity=5"

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customer/List", Query)

    if (requestAPI.data.status === "ok") {
      setCustomers(requestAPI.data.response)
    }
  }

  //Request INVOICE DETAILS
  const [showInvoiceSequence, setShowInvoiceSequence] = useState(false)
  const request = async () => {
    let requestAPI = await API.getAction("Invoice/Details?Id=" + id)

    if (requestAPI.data.status === "ok") {
      if (requestAPI.data.response) {
        //Let's parse
        setState(requestAPI.data.response)
        setShowInvoiceSequence(true)
      }
    } else {
      setShowInvoiceSequence(false)
    }
  }

  //Request QUOTE DETAILS
  const requestQuote = async () => {
    let requestAPI = await API.getAction("Quote/Details?Id=" + fromQuote)

    if (requestAPI.data.status === "ok") {
      if (requestAPI.data.response) {
        let quote = requestAPI.data.response
        let note = quote.note.replace(/<br\/>/g, "\n")

        let NEwInvoice = {
          id: id,
          date: moment().format(),
          currencyId: quote.currencyId,
          organizationId: quote.organizationId,
          invoiceTypeId: "",
          customerId: quote.customerId,
          customerName: quote.customerName,
          customerPhone: quote.customerPhone,
          customerEmail: quote.customerEmail,
          invoiceNumber: "",
          paymentMethod: "",
          paymentMethodReference: null,
          note: note,
          currencyExchangeRate: 1,

          sequence: 0,
          credit: false,
          created: moment().format(),
          createdUserId: null,
          deleted: null,
          deletedUserId: null,
          invoiceDetails: [],
        }

        quote.quoteDetails
          .filter(x => x.deleted === null)
          .forEach(item => {
            let calculateDiscount =
              item.discount && item.discount > 0
                ? item.price * (item.discount / 100)
                : 0

            let tax = (item.tax / 100) * (item.price - calculateDiscount)
            let newItem = {}
            newItem.itemType = item.itemType
            newItem.itemId = item.itemId
            newItem.itemConcept = item.itemConcept
            newItem.price = item.price * 1
            newItem.quantity = item.quantity * 1
            newItem.discount = calculateDiscount
            newItem.obj = item
            newItem.tax = tax
            newItem.deleted = item.deleted
            NEwInvoice.invoiceDetails.push(newItem)
          })

        // formik.setFieldValue("customerName", quote.customerName)
        //   formik.setValues(NEwInvoice)
        setState(NEwInvoice)
      }
    }
  }

  const requestInvoiceType = async () => {
    let requestAPI = await API.getAction(
      "InvoiceTypes/list?organizationId=" + user.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      if (requestAPI.data.response) {
        setInvoiceTypes(requestAPI.data.response)
      }
    }
  }

  const searchItems = async term => {
    let query = "search=" + term + "&organizationid=" + user.OrganizationId
    let request = await API.getAction("serviceProduct/list?" + query)
    if (request.data.status === "ok") {
      let grouped = [
        ...request.data.response.services,
        ...request.data.response.products,
      ]
      let parsed = []

      grouped.forEach((item, index) => {
        parsed.push(item)
      })
      setItems(parsed)
    }
  }

  const resultRenderCustomers = obj => {
    return <div>{`${obj.firstName}  ${obj.lastName ?? ""}`}</div>
  }
  const resultRendererItem = obj => {
    return <div>{obj.title}</div>
  }

  const user = parsedUser()

  const { t } = useTranslation("global")

  const [currencies, setCurrencies] = useState([])
  const requestCurrencies = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Currencies/List", Query)

    if (requestAPI.data.status === "ok") {
      setCurrencies(requestAPI.data.response)
    }
  }

  const addItem = () => {
    let obj = formik.values

    obj.invoiceDetails.push({
      id: uuidv4(),
      invoiceId: obj.id,
      itemType: 0,
      itemId: "",
      itemConcept: "",
      quantity: 1,
      price: 0,
      discount: 0,
      tax: 0,
      created: moment().format(),
      createdUserId: user.ID,
      deleted: null,
      obj: null,
    })

    formik.setValues(obj)
    // setState(obj)
  }
  const calcSummary = () => {
    let subTotal = 0
    let tax = 0
    state &&
      state.invoiceDetails &&
      state.invoiceDetails.forEach(item => {
        var recordsubTotal = (item.price - (item.discount ?? 0)) * item.quantity

        let calculatedTaxes = 0

        calculatedTaxes = item.tax * item.quantity

        subTotal += recordsubTotal // * exchangeRate
        tax += calculatedTaxes //* exchangeRate
        console.log(
          item.price,
          item.discount,
          item.quantity,
          recordsubTotal + tax
        )
      })
    console.log(subTotal)
    setSummary({
      subTotal: subTotal,
      taxes: tax,
      total: subTotal + tax,
    })
  }

  const handleSubmit = async obj => {
    console.log("values to submit", obj)
    //OBJECT TO BE SENT assemble
    let objToSent = {}
    objToSent.id = obj.id
    objToSent.created = obj.created

    if (obj.id.length === 0) {
      debugger
      objToSent.id = uuidv4()
      objToSent.created = moment().format("YYYY-MM-DD HH:mm:ss")
      objToSent.createdUserId = user.ID
    }
    objToSent.credit = obj.credit
    objToSent.creditLimitDate = obj.creditLimitDate
    objToSent.creditNoteId = obj.creditNoteId
    objToSent.currencyExchangeRate = obj.currencyExchangeRate
    objToSent.currencyId = obj.currencyId
    objToSent.customerAddress = obj.customerAddress
    objToSent.customerEmail = obj.customerEmail
    objToSent.customerId = obj.customerId
    objToSent.customerName = obj.customerName
    objToSent.customerPhone = obj.customerPhone
    objToSent.deleted = obj.deleted
    objToSent.disabled = obj.disabled
    objToSent.expirationDate = obj.expirationDate
    objToSent.organizationId = user.OrganizationId
    objToSent.invoiceIdentifier = obj.invoiceIdentifier
    objToSent.invoiceNumber = obj.invoiceNumber

    objToSent.invoiceTypeId = obj.invoiceTypeId
    objToSent.note = obj.note
    objToSent.paidStatus = obj.paidStatus
    objToSent.paymentMethod = obj.paymentMethod
    objToSent.paymentMethodReference = obj.paymentMethodReference

    //Details
    let details = []
    obj.invoiceDetails.forEach(item => {
      details.push({
        created: item.created,
        createdUserId: item.createdUserId,
        deleted: item.deleted,
        discount: item.discount,
        id: item.id,
        invoiceId: obj.id,
        itemConcept: item.itemConcept,
        itemId: item.itemId,
        itemType: item.itemType,
        price: item.price,
        quantity: item.quantity,
        tax: item.tax,
      })
    })
    objToSent.invoiceDetails = details
    setIsLoading(true)
    let request = await API.postAction("invoice/AddEdit", objToSent)
    setIsLoading(false)
    console.log("response from adding", request)
    if (request.status === 200) {
      //alert("We should navigate now");
      window.open(`${Routes.InvoicePrint.clean}${obj.id}?`, "_blank")
      history.push(Routes.InvoiceDashboard.path)
    }
  }
  const [showExport, setShowExport] = useState(false)
  const [InvoicePath, setInvoicePath] = useState("")
  const toggleExport = () => {
    setShowExport(!showExport)
  }

  const formik = useFormik({
    initialValues: state,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  })

  //EXCHANGE RATE
  const [showExchangeRate, setShowExchangeRate] = useState(false)
  const [exchangeRate, setExchangeRate] = useState(1)
  const toggleExchangeRate = amount => {
    setShowExchangeRate(!showExchangeRate)

    if (amount) {
      setExchangeRate(amount)
      let obj = state

      obj.invoiceDetails.forEach((item, index) => {
        obj.invoiceDetails[index].price = item.price * amount
        obj.invoiceDetails[index].discount = (item.discount ?? 0) * amount
        obj.invoiceDetails[index].tax = item.tax * amount
      })

      setState(obj)
    }
  }

  const deleteConfirm = async obj => {
    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.itemConcept + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            let s = formik.values
            s.invoiceDetails.filter(x => x.id == obj.id)[0].deleted =
              moment().format()
            formik.setValues(s)
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  //Initial request
  // EVENTS
  useEffect(() => {
    requestInvoiceType()
    requestCurrencies()

    if (fromQuote) {
      requestQuote()
    } else {
      request()
    }
  }, [])

  useEffect(() => {
    calcSummary()
  }, [items, exchangeRate])

  useEffect(() => {
    formik.setValues(state)
    calcSummary()
  }, [state])
  useEffect(() => {
    console.log("formik actualizado", formik.values)
  }, [formik])
  return (
    <>
      {showExport ? (
        <ExportModal
          path={InvoicePath}
          show={showExport}
          toggle={toggleExport}
        />
      ) : null}
      {showExchangeRate ? (
        <ExchangeRate show={showExchangeRate} toggle={toggleExchangeRate} />
      ) : null}
      <nav aria-label="breadcrumb" className="mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <i className="fa fa-home"></i>
            </a>
          </li>

          <li className="breadcrumb-item">
            <a href={Routes.InvoiceDashboard.path}>{t("invoices")}</a>
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col ">
          <h5 className="display-4">
            <Link className=" me-4 " to={Routes.InvoiceDashboard.path}>
              <i className="fas fa-chevron-left"></i>
            </Link>
            <i className="fas fa-file-invoice"></i>&nbsp;{t("invoices")}
          </h5>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mx-0">
          {/* GENERAL INFO */}
          <div className="col-xl-3 col-md-4 col-12 my-2">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <div className="row mx-0">
                  <div className="col-12 pe-0">
                    <label>{t("customers")}</label>
                  </div>
                  <div className="col-10 ">
                    <Search
                      id="customerName"
                      placeholder={t("customers")}
                      results={customers}
                      minCharacters={3}
                      title={
                        formik && formik.values.customerName
                          ? formik.values.customerName
                          : ""
                      }
                      value={
                        formik && formik.values.customerName
                          ? formik.values.customerName
                          : ""
                      }
                      resultRenderer={resultRenderCustomers}
                      onSearchChange={(event, data) => {
                        console.log("event", event, "data", data)
                        if (data.value.length > 2) {
                          requestCustomers(data.value)
                        }
                        formik.setFieldValue("customerName", data.value)
                      }}
                      onResultSelect={(event, data) => {
                        setQueryCustomers(data.result.firstName)
                        formik.setFieldValue("customerId", data.result.id)
                        formik.setFieldValue(
                          "customerName",
                          data.result.firstName
                        )
                        formik.setFieldValue("customerEmail", data.result.email)
                        formik.setFieldValue("customerPhone", data.result.phone)
                      }}
                    ></Search>
                    {formik.errors.customerId ? (
                      <div className="invalid text-sm">
                        {formik.errors.customerId}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-2 ">
                    <button
                      className="btn btn-secondary btn-sm mt-1"
                      onClick={() => {
                        //toggleAddEdit
                        //  toggleAddEditProvider()
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  <div className="col-12">
                    <label>{t("date")}</label>
                    <input
                      type="date"
                      className="form-control"
                      name="created"
                      value={
                        formik && formik.values.created
                          ? moment(formik.values.created).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue(
                          "created",
                          moment(e.target.value).format("YYYY-MM-DD")
                        )
                      }}
                    />
                  </div>
                  {showInvoiceSequence ? (
                    <div className="col-12">
                      <label>{t("sequence")}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="invoiceNumber"
                        value={formik && formik.values.invoiceNumber}
                        onChange={e => {
                          formik.setFieldValue("invoiceNumber", e.target.value)
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="col-12">
                    <label>{t("type_invoices")}</label>
                    <select
                      className="form-control"
                      name="invoiceTypeId"
                      value={
                        formik && formik.values.invoiceTypeId
                          ? formik.values.invoiceTypeId
                          : ""
                      }
                      onChange={e => {
                        let record = invoiceTypes.filter(
                          x => x.id === e.target.value
                        )[0]

                        formik.setFieldValue("invoiceTypeId", e.target.value)
                        if (!formik.values.note) {
                          formik.setFieldValue("note", record.defaultNote)
                        }
                      }}
                    >
                      <option value="">-</option>
                      {invoiceTypes &&
                        invoiceTypes.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          )
                        })}
                    </select>
                    {formik.errors.invoiceTypeId ? (
                      <div className="invalid text-sm">
                        {formik.errors.invoiceTypeId}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label>{t("payment_method")}</label>
                    <select
                      className="form-control"
                      name="paymentMethod"
                      value={
                        formik && formik.values.paymentMethod
                          ? formik.values.paymentMethod
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue("paymentMethod", e.target.value)
                      }}
                    >
                      <option value="">-</option>
                      <option value="0">{t("payment_method.cash")}</option>
                      <option value="1">
                        {t("payment_method.credit_card")}
                      </option>
                      <option value="2">{t("payment_method.check")}</option>
                      <option value="3">{t("Wire Transfer")}</option>
                      <option value="4">{t("PayPal")}</option>
                    </select>
                    {formik.errors.paymentMethod ? (
                      <div className="invalid text-sm">
                        {formik.errors.paymentMethod}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <label>{t("reference")}</label>
                    <input
                      className="form-control"
                      name="reference"
                      value={
                        formik && formik.values.reference
                          ? formik.values.reference
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue("reference", e.target.value)
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label>{t("currency")}</label>
                    <select
                      className="form-control"
                      value={
                        formik && formik.values.currencyId
                          ? formik.values.currencyId
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue("currencyId", e.target.value)
                      }}
                    >
                      <option value="">-</option>
                      {currencies &&
                        currencies.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          )
                        })}
                    </select>
                    {formik.errors.currencyId ? (
                      <div className="invalid text-sm">
                        {formik.errors.currencyId}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6">
                    <label>{t("credit")}</label>
                    <select
                      className="form-control Credit"
                      name="credit"
                      value={
                        formik && formik.values.credit
                          ? formik.values.credit
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue("credit", e.target.value)
                      }}
                    >
                      <option value="false">{t("no")}</option>
                      <option value="true">{t("yes")}</option>
                    </select>
                  </div>
                  {formik.values.credit ? (
                    <div className="col-12">
                      <label>{t("credit_time")}</label>
                      <input
                        type="date"
                        name="creditLimitDate"
                        className="form-control"
                        value={formik.values.creditLimitDate}
                        onChange={formik.handleChange}
                      ></input>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-footer p-2">
                <div className="row">
                  <div className="col-12">
                    <label>
                      {t("sub_total")}: {FormatMoney(summary.subTotal)}
                    </label>
                    <br />
                    <label>
                      {t("taxes")}: {FormatMoney(summary.taxes)}
                    </label>
                    <br />
                    <label>
                      <b>
                        {t("total")}: {FormatMoney(summary.total)}
                      </b>
                    </label>
                  </div>
                  <div className="col-12">
                    {formik.errors ? (
                      <ul>
                        {Object.values(formik.errors).map((item, index) => {
                          return (
                            <li key={index} className="text-danger">
                              {item}
                            </li>
                          )
                        })}
                      </ul>
                    ) : null}
                    <button
                      disabled={isLoading}
                      className="btn btn-primary btn-block my-4"
                      type="submit"
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL INFO */}
          <div className="col-xl-9 col-md-8 col-12 my-2">
            <div className="card shadow-sm">
              <div className="card-header p-2">
                <div className="row">
                  <div className="col text-right">
                    <button
                      className="btn btn-secondary btn-sm"
                      type="button"
                      onClick={() => addItem()}
                    >
                      {t("add_product_service")}
                    </button>
                    <button
                      className="btn btn-secondary btn-sm ms-2"
                      type="button"
                      onClick={() => {
                        toggleExchangeRate()
                      }}
                    >
                      {t("exchange_rate")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body p-2">
                <div className="w-100">
                  <table className="table">
                    <thead>
                      <tr>
                        <td>{t("concept")}</td>
                        <td>{t("quantity")}</td>
                        <td>{t("cost")}</td>
                        <td>{t("discount")}</td>
                        <td>{t("taxes")}</td>
                        <td>{t("sub_total")}</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {state &&
                        state.invoiceDetails
                          .filter(x => x.deleted === null)
                          .map((item, index) => {
                            let subTotal =
                              (item.price -
                                (item.discount === undefined
                                  ? 0
                                  : item.discount)) *
                              item.quantity

                            let taxes = item.tax * item.quantity

                            return (
                              <tr key={index}>
                                <td className="px-0">
                                  <Search
                                    placeholder={t("product_service")}
                                    className="form-control"
                                    results={items}
                                    minCharacters={1}
                                    value={item.itemConcept}
                                    resultRenderer={resultRendererItem}
                                    onSearchChange={(event, data) => {
                                      console.log("event", event, "data", data)

                                      searchItems(data.value)

                                      let details = formik.values.invoiceDetails
                                      details[index].itemConcept = data.value
                                      formik.setFieldValue(
                                        "invoiceDetails",
                                        details
                                      )
                                    }}
                                    onResultSelect={(event, data) => {
                                      console.log(
                                        "selected event",
                                        event,
                                        "data",
                                        data
                                      )
                                      // setSelectedProvider(data.result);
                                      let obj = formik.values
                                      item.itemType = data.result.type
                                      item.itemId = data.result.id
                                      item.itemConcept = data.result.title
                                      item.price = data.result.price
                                      item.quantity = data.result.quantity
                                      item.obj = data.result
                                      item.discount = data.result.discount

                                      let taxes = 0

                                      item.obj &&
                                        item.obj.taxes.forEach(subTax => {
                                          taxes += subTax.taxes.percentage
                                        })

                                      item.tax = (taxes / 100) * item.price

                                      obj.invoiceDetails[index] = item

                                      formik.setValues(obj)
                                      calcSummary()
                                    }}
                                  ></Search>
                                </td>
                                <td className="px-2">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={item.quantity}
                                    step={"any"}
                                    min={0}
                                    onChange={e => {
                                      let obj = formik.values
                                      let details = obj.invoiceDetails
                                      details[index].quantity = e.target.value
                                      obj.invoiceDetails = details
                                      formik.setValues(obj)
                                      calcSummary()
                                    }}
                                  />
                                </td>
                                <td className="px-2">
                                  <input
                                    type="number"
                                    step={"any"}
                                    className="form-control"
                                    value={item.price}
                                    readOnly
                                  />
                                </td>
                                <td className="px-2">
                                  <div className="input-group">
                                    <input
                                      id="discount"
                                      type="number"
                                      min={0}
                                      // max={100}
                                      step={"any"}
                                      // max={100}
                                      onChange={e => {
                                        let obj = formik.values
                                        let details = obj.invoiceDetails
                                        details[index].discount = (
                                          e.target.value * 1
                                        ).toFixed(2)
                                        obj.invoiceDetails = details
                                        // setTimeout(() => {
                                        formik.setValues(obj)
                                        // }, 2000)
                                        calcSummary()
                                      }}
                                      className="form-control"
                                      value={item.discount ?? 0}
                                    />
                                    <div className="input-group-text">%</div>
                                  </div>
                                </td>
                                <td className="px-2">
                                  <input
                                    type="number"
                                    step={"any"}
                                    className="form-control"
                                    value={taxes?.toFixed(2)}
                                    readOnly
                                  />
                                </td>
                                <td className="px-2">
                                  <input
                                    type="number"
                                    step={"any"}
                                    className="form-control"
                                    value={(subTotal + taxes)?.toFixed(2)}
                                    readOnly
                                  />
                                </td>
                                <td className="px-2">
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      deleteConfirm(item)
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card shadow-sm mt-4">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-12">
                    <label>{t("note")}</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      value={
                        formik && formik.values.note
                          ? formik.values.note.includes("<br/>")
                            ? formik.values.note.replace(/<br\/>/g, "\n")
                            : formik.values.note
                          : ""
                      }
                      onChange={e => {
                        formik.setFieldValue("note", e.target.value)
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AddEditInvoice
