//We are settings an initial state here for clarity, probably is not needed

const Settings = {
  App: "Pleelo",
  Name: "Pleelo",

  baseURl: "./",
  BasePathForAPI: "https://api.pleelo.com/api/",
  BasePath: "https://api.pleelo.com/",
  AppURL: "https://app.pleelo.com/",
  // BasePathForAPI: "https://localhost:44322/api/",
  // BasePath: "https://localhost:44322/",

  SocketPath: "https://signal.pleelo.com/communicationHub",
  SyncFusionKey: "Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW31XcHBQT2VVWEN/Xg=="//"ORg4AjUWIQA/Gnt2UVhhQlVDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5RdExjWn9adHBXQ2RY"
}

export default Settings


