export const Routes = {
  // GENERIC
  getin: {
    path: "/getin/:id",
    clean: "/getin/",
  },
  PaymentHistoryDetails: {
    path : "/PaymentHistoryDetails/:id",
    clean: "/PaymentHistoryDetails/",
  },
  AdminUsers: { path: "/admin/users" },
  AdminOrganizations: { path: "/admin/organizations" },
  SignIn: { path: "/signin" },
  ForgotPassword: { path: "/forgotPassword" },
  Register: { path: "/register" },
  Home: { path: "/" },
  PosSales: { path: "/pos/sales" },
  
  Reports: { path: "/reports/:id" },
  FinanceReports: { path: "/reports/finance" },
  HRReports: { path: "/reports/hr" },
  AdminReports: { path: "/reports/admin" },
  WorkshopReports: { path: "/reports/workshop" },

  workshopRecordExternal:{
    path: "/workshop/record/external/:id",
    clean: "/workshop/record/external/",
  },
  NotFound: { path: "/examples/NotFound" },
  ServerError: { path: "/examples/ServerError" },
WorkshopDiagnosticPrint:{
  path: "/workshop/diagnostic/print/:id",
  clean: "/workshop/diagnostic/print/",
},
  Chat: { path: "/Chat" },

  Files: { path: "/files" },

  // selectApp: { path: "/selectApp", clean: "/selectApp/" },
  Calendar: { path: "/calendar", clean: "/calendar/" },

  // EXPENSES

  ExpensesDashboard: { path: "/expenses" },

  ExpensesList: { path: "/expenses/ExpenseList" },
  ExpensesType: { path: "/expenses/type" },
  Providers: { path: "/Providers" },
  Profile: { path: "/Profile" },
  ProviderDetails: {
    path: "/Providers/Details/:id",
    clean: "/Providers/Details/",
  },

  // FINANCE

  BillingDashboard: { path: "/finance/dashboard" },
  Customers: { path: "/customers" },
  SelectSubscription: {
    path: "/selectSubscription",
    clean: "/selectSubscription/",
  },
  CustomerDetails: {
    path: "/customers/details/:id",
    clean: "/customers/details/",
  },
  CustomerStatements: {
    path: "/reports/customerStatements/print/:id",
    clean: "/reports/customerStatements/print/",
  },
  CustomerBalance: {
    path: "/customers/balance/:id",
    clean: "/customers/balance/",
  },
  PrintPayment: {
    path: "/invoice/payment/print/:id",
    clean: "/invoice/payment/print/",
  },
  Services: { path: "/services" },
  Products: { path: "/products" },
  QuoteDashboard: { path: "/quotes" },
  QuotePrint: { path: "/quotes/print/:id", clean: "/quotes/print/" },
  ReceivedPayments: { path: "/receivedPayments", clean: "/receivedPayments" },
  AddEditQuote: { path: "/quotes/AddEdit/:id", clean: "/quotes/AddEdit/" },
  AccountReceivables: {
    path: "/accountreceivables/",
    clean: "/accountReceivables/",
  },

  InvoiceDashboard: { path: "/Invoices" },
  InvoicePrint: { path: "/invoices/print/:id", clean: "/invoices/print/" },
  AddEditInvoice: {
    path: "/invoices/AddEdit/:id/:fromQuote?",
    clean: "/invoices/AddEdit/",
  },
  AddEditCredit: {
    path: "/credit/:creditNoteId/:invoiceId?",
    clean: "/credit/",
  },

  ActivityDetails: {
    path: "/Activity/details/:id",
    clean: "/Activity/Details/",
  },

  // PAYROLL
  DashboardPayroll: { path: "/dashboardPayroll" },
  Employees: { path: "/employees" },
  EmployeeDetails: {
    path: "/employees/detail/:id",
    clean: "/employees/Detail/",
  },

  Positions: { path: "/positions" },
  PositionDetails: {
    path: "/positions/detail/:id",
    clean: "/positions/Detail/",
  },
  PositionPublic: { path: "/p/:id", clean: "/p/" },

  Departments: { path: "/departments" },
  Benefits: { path: "/benefits" },
  Discounts: { path: "/discounts" },

  Payrolls: { path: "/payrolls" },
  PayrollDetails: {
    path: "/payrolls/detail/:id",
    clean: "/payrolls/Detail/",
  },
  PayrollPaymentDetails: {
    path: "/payrollPaymentDetails/:id",
    clean: "/payrollPaymentDetails/",
  },
  PayrollReport: {
    path: "/PayrollReport/:id",
    clean: "/PayrollReport/",
  },

  //WORKSHOP
  WorkshopMaintenance: { path: "/workshop/maintenance" },
  WorkshopStatus: { path: "/workshop/status" },

  EquipmentList: { path: "/equipment/list" },

  SelectOrganization: { path: "/SelectOrganization" },

  Assets: { path: "/assets" },

  Settings: { path: "/Settings/:id", clean: "/Settings/" },
}
