import React, { useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import Preloader from "../Preloader"
import API from "../../common/API"
import { Routes } from "../../routes"

const GetIn = () => {
  const { id } = useParams()
  const history = useHistory()

  const refreshToken = async () => {
    try {
      let request = await API.postAction("accounts/refreshtoken?ID=" + id)

      localStorage.setItem("LogedUser", request.data.token)

      setTimeout(() => {
        history.push(Routes.SelectOrganization.path)
      }, 2000)
    } catch (error) {
      console.error("Oh! thats a error? ", error)
      setTimeout(() => {
        history.push(Routes.SignIn.path)
      }, 5000)
    }
  }

  useEffect(() => {
    refreshToken()
  }, [])

  return (
    <>
      <Preloader show={true} />
    </>
  )
}

export default GetIn
