import React, { useState } from "react"
import { Col, Row, Form, Card, Button, Container, Alert } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import * as yup from "yup"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"
import API from "../../common/API"
import toast, { Toaster } from "react-hot-toast"
import { useFormik } from "formik"
import Sha256 from "../../common/sha256"

import logo from "../../assets/img/logoPleeloTexto.png"

//Translating
import { useTranslation } from "react-i18next"

const Register = () => {
  const [t] = useTranslation("global")

  const validationSchema = yup.object({
    firstName: yup.string().required("required"),
    lastName: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
  })
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  // const [state, setState] = useState({
  //     Email: "",
  //     Password: "",
  //     UI: {
  //         validated: false,
  //     },
  // })

  const handleSubmit = async obj => {
    console.log("values to submit", obj)
    // if (obj.id.length === 0) {
    //     //obj.id = uuidv4();
    //     obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    // }
    //state.UI.isLoading.set(!state.UI.isLoading.value);
    setIsLoading(true)
    obj.password = Sha256.hash(obj.password)
    obj.confirmPassword = null
    try {
      let request = await API.postAction("accounts/getin", obj)

      if (request.status === 200) {
        window.gtag("config", "AW-979970401")

        window.gtag("event", "conversion", {
          send_to: "AW-979970401/3V4DCNGC9ecYEOHSpNMD",
        })

        history.push(Routes.SignIn.path)
      }
    } catch (error) {
      if (error.response.data === "user_exist") {
        toast.error(t("alerts.is_registered"))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Toaster />
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <p className="text-center">
              <img
                src={logo}
                alt="pleelo"
                className="navbar-brand-light mb-4"
                style={{ maxWidth: "200px" }}
              />
            </p>
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{t("create_account")}</h3>
                  </div>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Form.Label>{t("first_name")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder={t("first_name")}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.firstName ? (
                          <div className="invalid text-sm">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={6}>
                        <Form.Label>{t("last_name")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder={t("last_name")}
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.lastName ? (
                          <div className="invalid text-sm">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </Col>

                      <Col xs={12}>
                        <Form.Label>{t("email")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder={t("ph_email")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.email ? (
                          <div className="invalid text-sm">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Form.Label>{t("password")}</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder={t("password")}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.password ? (
                          <div className="invalid text-sm">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Form.Label>{t("conf_password")}</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          placeholder={t("conf_password")}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.confirmPassword ? (
                          <div className="invalid text-sm">
                            {formik.errors.confirmPassword}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-100 mt-4"
                        >
                          {t("signin")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {t("questions.have_account")}&nbsp;
                      <Card.Link
                        as={Link}
                        to={Routes.SignIn.path}
                        className="fw-bold"
                      >
                        {t("login")}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Register
