import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import API from '../../../common/API';
import { parsedUser } from '../../../common/GetCurrentUser';
import AddBenefitsForEmployee from '../benefits/AddBenefitsForEmployee';

const BenefitsAssigned = props => {
    const [state, setState] = useState({
        UI:
        {
            showAssignBenefits: false
        }
    })

    const [employeeBenefitsAssigned, setEmployeeBenefitsAssigned] = useState([]);
    const toggleAssignBenefit = async (obj, reload) => {
        //state.UI.SelectedItem.set(obj);
        setState({
            ...state,
            UI: {
                ...state.UI,
                showAssignBenefits: !state.UI.showAssignBenefits
            }
        })

        if (reload !== undefined) {
            request();
        }
    };

    const request = async () => {
        let requestAPI = await API.getAction("Employees/BenefitAssigned?EmployeeId=" + props.employeeId);

        if (requestAPI.data.status === "ok") {
            setEmployeeBenefitsAssigned(requestAPI.data.response);
        }
    }

    const deleteConfirm = async (obj) => {

        let currentUser = parsedUser();

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.employeeBenefits.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Employees/DeleteBenefitForEmployee?id=" + obj.id + "&UserId=" + currentUser.ID);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });
    }

    useEffect(()=>{
        request();
    }, [])
    return (
        <>
            {state.UI.showAssignBenefits ?
                <AddBenefitsForEmployee toggle={toggleAssignBenefit} show={state.UI.showAssignBenefits} employeeId={props.employeeId} />
                : null}
            <div className="card shadow-sm mb-4">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <label className="display-5">Beneficios</label>
                        </div>
                        <div className="col text-right">
                            <button className="btn btn-sm btn-primary" onClick={toggleAssignBenefit}>
                                <i className="fa fa-plus" /> Asignar
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {employeeBenefitsAssigned && employeeBenefitsAssigned.filter(x => x.deleted === null) ?
                        employeeBenefitsAssigned
                            .filter(x => x.deleted === null)
                            .map((item, index) => {
                                var record = item;
                                return (<div className="row" key={index}>
                                    <div className="col-auto">
                                        {item.employeeBenefits.name}
                                    </div>
                                    <div className="col text-right">
                                        {item.employeeBenefits.value} {item.employeeBenefits.percentage ? "%" : ""}
                                        <button className='btn btn-sm ' type="button" 
                                        onClick={()=>deleteConfirm(record)}>
                                            <i className='fa fa-trash'></i>
                                        </button>
                                    </div>
                                    <div className='col-12'>
                                        <hr className='my-2 py-0'/>
                                        </div>
                                </div>)
                            }) : null
                    }

                </div>
            </div>
        </>)
}

export default BenefitsAssigned;