import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"
import { PayrollTypes } from "../../../common/helpers"
import { useTranslation } from "react-i18next"
//Validation
const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthdate: yup.date().required(),
  identifier: yup.string().required(),
  identifierType: yup.string().required(),
  employeePositionId: yup.string().required(),
})
//Component
const AddEditEmployee = props => {
  const { t } = useTranslation("global")
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
    Employee: { name: "" },
    Positions: [],
    Banks: [],
  })
  const [categories, setCategories] = useState([])
  const user = parsedUser()

  const handleSubmit = async (obj, event) => {
    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    let request = await API.postAction("employees/AddEdit", obj)

    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model
      ? props.model
      : {
          firstName: "",
          middleName: "",
          lastName: "",
          secondLastName: "",
          birthdate: null,
          created: new Date(),
          id: "",
          OrganizationId: "",
          payrollTypeId: PayrollTypes.monthly,
          employeeType: 0,
          payrollCategoryId: null,
        },
    validationSchema,
    onSubmit: handleSubmit,
  })
  const bindCatergories = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    let requestAPI = await API.getAction("employees/payrollCategories", Query)
    setCategories(requestAPI.data.response)
  }
  const loadPositions = async () => {
    let request = await API.getAction(
      "employees/PositionList?OrganizationId=" + user.OrganizationId
    )
    if (request.data.status === "ok") {
      let copy = state
      request.data.response.forEach(position => {
        copy.Positions.push(position)
      })
      setState({
        ...state,
        Positions: copy.Positions,
      })
    }
  }
  const loadBanks = async () => {
    let request = await API.getAction(
      "Banks/List?OrganizationId=" + user.OrganizationId
    )
    if (request.data.status === "ok") {
      let copy = state
      request.data.response.forEach(bank => {
        copy.Banks.push(bank)
      })
      setState({
        ...state,
        Banks: copy.Banks,
      })
    }
  }

  useEffect(() => {
    loadPositions()
    loadBanks()
    bindCatergories()
  }, [])

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Employee</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>Primer Nombre</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName ? (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Segundo Nombre</Form.Label>
              <Form.Control
                type="text"
                name="middleName"
                value={formik.values.middleName ? formik.values.middleName : ""}
                onChange={formik.handleChange}
              />
              {formik.errors.middleName ? (
                <div className="invalid text-sm">
                  {formik.errors.middleName}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                name="secondLastName"
                value={formik.values.secondLastName}
                onChange={formik.handleChange}
              />
              {formik.errors.secondLastName ? (
                <div className="invalid text-sm">
                  {formik.errors.secondLastName}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>Genero</Form.Label>
              <select
                className="form-control"
                value={formik.values.gender}
                onChange={formik.handleChange}
                name="gender"
              >
                <option value="">-</option>
                <option value="1">Masculino</option>
                <option value="2">Femenino</option>
              </select>
              {formik.errors.gender ? (
                <div className="invalid text-sm">{formik.errors.gender}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Fecha nacimiento</Form.Label>
              <Form.Control
                type="date"
                name="birthdate"
                value={
                  formik.values.birthdate
                    ? moment(formik.values.birthdate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={formik.handleChange}
              />
              {formik.errors.birthdate ? (
                <div className="invalid text-sm">{formik.errors.birthdate}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Tipo de identificación</Form.Label>
              <select
                className="form-control"
                value={formik.values.identifierType}
                onChange={formik.handleChange}
                name="identifierType"
              >
                <option value="">-</option>
                <option value="1">Cédula</option>
                <option value="2">Pasaporte</option>
              </select>

              {formik.errors.identifierType ? (
                <div className="invalid text-sm">
                  {formik.errors.identifierType}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Estatus</Form.Label>
              <select
                className="form-control"
                value={formik.values.status}
                onChange={formik.handleChange}
                name="status"
              >
                <option value="">-</option>
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </select>

              {formik.errors.status ? (
                <div className="invalid text-sm">{formik.errors.status}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>No. Identificación</Form.Label>

              <Form.Control
                type="text"
                name="identifier"
                value={formik.values.identifier}
                onChange={formik.handleChange}
              />
              {formik.errors.identifier ? (
                <div className="invalid text-sm">
                  {formik.errors.identifier}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={12}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Télefono</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                name="mobilePhone"
                value={formik.values.mobilePhone}
                onChange={formik.handleChange}
              />
              {formik.errors.mobilePhone ? (
                <div className="invalid text-sm">
                  {formik.errors.mobilePhone}
                </div>
              ) : null}
            </Col>

            <Col xs={12}>
              <h4 className="mt-4">Información Laboral</h4>
              <hr />
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("payrollCategory")}</Form.Label>
              <select
                className="form-control"
                name="payrollCategoryId"
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {categories.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>

              {formik.errors.payrollCategoryId ? (
                <div className="invalid text-sm">
                  {formik.errors.payrollCategoryId}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Fecha de Inicio</Form.Label>
              <Form.Control
                type="date"
                name="beginWorkingDate"
                value={
                  formik.values.beginWorkingDate
                    ? moment(formik.values.beginWorkingDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }
                onChange={formik.handleChange}
              />
              {formik.errors.beginWorkingDate ? (
                <div className="invalid text-sm">
                  {formik.errors.beginWorkingDate}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Posición</Form.Label>
              <select
                className="form-control"
                name="employeePositionId"
                value={formik.values.employeePositionId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {state.Positions &&
                  Array.isArray(state.Positions) &&
                  state.Positions.map(item => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
              {formik.errors.employeePositionId ? (
                <div className="invalid text-sm">
                  {formik.errors.employeePositionId}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Salario Base</Form.Label>
              <input
                type="number"
                min="0"
                className="form-control"
                name="basePayroll"
                value={formik.values.basePayroll}
                onChange={formik.handleChange}
              />
              {formik.errors.basePayroll ? (
                <div className="invalid text-sm">
                  {formik.errors.basePayroll}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Tipo de nómina</Form.Label>
              <select
                className="form-control"
                name="payrollTypeId"
                value={formik.values.payrollTypeId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                <option value={PayrollTypes.hourly}>Por Hora</option>
                <option value={PayrollTypes.weekly}>Semanal</option>
                <option value={PayrollTypes.biWeekly}>Quincenal</option>
                <option value={PayrollTypes.monthly}>mensual</option>
              </select>
              {formik.errors.payrollTypeId ? (
                <div className="invalid text-sm">
                  {formik.errors.payrollTypeId}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Banco</Form.Label>
              <select
                className="form-control"
                name="bank"
                value={formik.values.bank}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {state.Banks &&
                  Array.isArray(state.Banks) &&
                  state.Banks.map(item => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
              {formik.errors.bank ? (
                <div className="invalid text-sm">{formik.errors.bank}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>Cuenta Bancaria</Form.Label>
              <input
                type="text"
                min="0"
                className="form-control"
                name="bankAccount"
                value={formik.values.bankAccount}
                onChange={formik.handleChange}
              />
              {formik.errors.bankAccount ? (
                <div className="invalid text-sm">
                  {formik.errors.bankAccount}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditEmployee
