import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"
import { Search, Label, Checkbox } from "semantic-ui-react"
import AddEdit from "../Providers/AddEdit"

//Translation
import { useTranslation } from "react-i18next"
import { FormatMoney } from "../../common/helpers"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  date: yup.date().required("campo requerido"),
  amount: yup.number().required("campo requerido"),
  //    organizationId: yup.string(),
  expenseTypeId: yup.string().required("campo requerido"),
  providerId: yup.string().required("campo requerido"),
  // ledgerAccountId: yup.string().required("campo requerido")
})
//Component
const AddEditExpense = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
    Departments: [],
  })
  const [providers, setProviders] = useState([])
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [selectedProviderLabel, setSelectedProviderLabel] = useState("")
  const [expenseTypeList, setExpenseTypeList] = useState([])
  const [paid, setPaid] = useState(false)
  const [finantialAccounts, setFinantialAccounts] = useState([])
  const [ledgerAccounts, setLedgerAccounts] = useState([])
  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    let request = await API.postAction(
      "expense/AddEdit?ProviderName=" + selectedProvider.name,
      obj
    )

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const searchProvider = async term => {
    let query = "search=" + term + "&organizationid=" + user.OrganizationId
    let request = await API.getAction("provider/list?" + query)
    if (request.data.status === "ok") {
      setProviders(request.data.response)
    }
  }
  const searchExpenseTypes = async term => {
    let query = `&organizationid=${user.OrganizationId}` //" + user.OrganizationId;
    let request = await API.getAction("expense/types?" + query)
    if (request.data.status === "ok") {
      setExpenseTypeList(request.data.response)
    }
  }

  const loadAccounts = async term => {
    let query = `&organizationid=${user.OrganizationId}` //" + user.OrganizationId;
    let request = await API.getAction("FinancialAccount/List?" + query)
    if (request.data.status === "ok") {
      setFinantialAccounts(request.data.response)
    }
  }

  const loadLedgerAccounts = async term => {
    let query = `&organizationid=${user.OrganizationId}&nature=debit` //" + user.OrganizationId;
    let request = await API.getAction("ledgerAccounts/List?" + query)
    if (request.data.status === "ok") {
      setLedgerAccounts(request.data.response)
    }
  }

  const resultRendererProvider = props => {
    return <Label content={props.name} />
  }

  const [showAddEditProvider, setShowAddEditProvider] = useState(false)

  const toggleAddEditProvider = (obj, reload) => {
    setShowAddEditProvider(!showAddEditProvider)
    if (reload) {
      setSelectedProvider(obj)
      setSelectedProviderLabel(obj.name)
    }
  }
  useEffect(() => {
    if (props.model && props.model.provider !== null) {
      setSelectedProvider(props.model.provider)
      setSelectedProviderLabel(props.model.provider.name)
      setPaid(props.model.paidDate !== null)
    }
    searchExpenseTypes()
    loadAccounts()
    loadLedgerAccounts()
    setTimeout(() => {
      var obj = document.getElementsByName("date")[0]
      if (obj) {
        obj.focus()
      }
    }, 500)
  }, [props.model])

  return (
    <>
      <AddEdit
        toggle={toggleAddEditProvider}
        show={showAddEditProvider}
        model={selectedProvider}
      />
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("expense")}</Modal.Title>
        </Modal.Header>

        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            {/* LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                        {Object.values(formik.errors).map((item, index) => {
                            return (item);
                        })} */}
            <Row className="mx-0">
              <Col xs={12} md={4}>
                <Form.Label>{t("date")}</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={
                    formik.values.date
                      ? moment(formik.values.date).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD")
                  }
                  onChange={formik.handleChange}
                />
                {formik.errors.date ? (
                  <div className="invalid text-sm">{formik.errors.date}</div>
                ) : null}
              </Col>

              <Col xs={12} md={8}>
                <Form.Label>{t("provider")}</Form.Label>
                <Row>
                  <Col xs={10}>
                    <Search
                      placeholder={t("provider")}
                      results={providers}
                      minCharacters={3}
                      value={selectedProviderLabel}
                      resultRenderer={resultRendererProvider}
                      onSearchChange={(event, data) => {
                        console.log("event", event, "data", data)
                        searchProvider(data.value)
                        setSelectedProviderLabel(data.value)
                      }}
                      onResultSelect={(event, data) => {
                        console.log("selected event", event, "data", data)
                        setSelectedProvider(data.result)

                        setSelectedProviderLabel(data.result.name)
                        formik.setFieldValue("providerId", data.result.id)
                      }}
                    ></Search>
                  </Col>
                  <Col xs={2}>
                    <button
                      className="btn btn-secondary btn-sm mt-1"
                      onClick={() => {
                        toggleAddEditProvider()
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="mt-2">
                {formik.errors.providerId ? (
                  <div className="invalid text-sm">
                    {formik.errors.providerId}
                  </div>
                ) : null}
                {selectedProvider !== null ? (
                  <>
                    <b>
                      {selectedProvider.name +
                        " (" +
                        selectedProvider.identifier +
                        ")"}
                    </b>
                  </>
                ) : null}
              </Col>

              <Col xs={12} md={12} className="mt-2">
                <Form.Label>{t("description")}</Form.Label>
                <textarea
                  className="form-control"
                  value={
                    formik.values.description ? formik.values.description : ""
                  }
                  onChange={e => {
                    formik.setFieldValue("description", e.target.value)
                  }}
                ></textarea>

                {formik.errors.description ? (
                  <div className="invalid text-sm">
                    {formik.errors.description}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label># {t("invoice")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="expenseIdentifier"
                  value={formik.values.expenseIdentifier ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.expenseIdentifier ? (
                  <div className="invalid text-sm">
                    {formik.errors.expenseIdentifier}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label>NCF/ Tax ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="taxesIdentifier"
                  value={formik.values.taxesIdentifier ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.taxesIdentifier ? (
                  <div className="invalid text-sm">
                    {formik.errors.taxesIdentifier}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label>{t("expenses_type")}</Form.Label>
                <select
                  className="form-control"
                  value={
                    formik.values.expenseTypeId
                      ? formik.values.expenseTypeId
                      : ""
                  }
                  onChange={formik.handleChange}
                  name="expenseTypeId"
                >
                  <option value="">-</option>
                  {expenseTypeList.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                {formik.errors.expenseTypeId ? (
                  <div className="invalid text-sm">
                    {formik.errors.expenseTypeId}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label>{t("totalAmount")}</Form.Label>
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  value={formik.values.amount ?? ""}
                  onChange={formik.handleChange}
                  step={"any"}
                />
                {formik.errors.amount ? (
                  <div className="invalid text-sm">{formik.errors.amount}</div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label>{t("taxe")}</Form.Label>
                <input
                  type="number"
                  className="form-control"
                  name="taxes"
                  value={formik.values.taxes ?? ""}
                  onChange={formik.handleChange}
                  step={"any"}
                />
                {formik.errors.taxes ? (
                  <div className="invalid text-sm">{formik.errors.taxes}</div>
                ) : null}
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <Form.Label>{t("retention")}</Form.Label>
                <input
                  type="number"
                  className="form-control"
                  name="retention"
                  value={formik.values.retention ?? ""}
                  onChange={formik.handleChange}
                  step={"any"}
                />
                {formik.errors.retention ? (
                  <div className="invalid text-sm">
                    {formik.errors.retention}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <Checkbox
                  toggle
                  label={t("paid")}
                  checked={paid}
                  onClick={(event, data) => {
                    console.log("toggle", data)
                    if (formik.values.paidDate === null) {
                      formik.setFieldValue("paidDate", formik.values.date)
                    }
                    setPaid(data.checked)
                  }}
                />
              </Col>
            </Row>
            {paid ? (
              <Row>
                <Col xs={12} md={6} className="mt-2">
                  <Form.Label>{t("payment_date")}</Form.Label>
                  <input
                    type="date"
                    className="form-control"
                    name="paidDate"
                    value={
                      formik.values.paidDate
                        ? moment(formik.values.paidDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={formik.handleChange}
                  />
                  {formik.errors.paidDate ? (
                    <div className="invalid text-sm">
                      {formik.errors.paidDate}
                    </div>
                  ) : null}
                </Col>
                <Col xs={12} md={6} className="mt-2 d-none">
                  <Form.Label>{t("financial_account")}</Form.Label>
                  <select
                    className="form-control"
                    value={
                      formik.values.financialAccountId
                        ? formik.values.financialAccountId
                        : ""
                    }
                    onChange={formik.handleChange}
                    name="financialAccountId"
                  >
                    <option value="">-</option>
                    {finantialAccounts.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.errors.financialAccountId ? (
                    <div className="invalid text-sm">
                      {formik.errors.financialAccountId}
                    </div>
                  ) : null}
                </Col>
                <Col xs={12} md={6} className="mt-2">
                  <Form.Label>{t("ledger_account")}</Form.Label>
                  <select
                    className="form-control"
                    value={
                      formik.values.ledgerAccountId
                        ? formik.values.ledgerAccountId
                        : ""
                    }
                    onChange={formik.handleChange}
                    name="ledgerAccountId"
                  >
                    <option value="">-</option>
                    {ledgerAccounts.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.reference + "-" + item.account}
                        </option>
                      )
                    })}
                  </select>
                  {formik.errors.ledgerAccountId ? (
                    <div className="invalid text-sm">
                      {formik.errors.ledgerAccountId}
                    </div>
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="p-1">
            <small>{FormatMoney(formik.values.amount)}</small>
            <Button
              variant="primary"
              type="submit"
              className="btn-sm"
              disabled={state.UI.isLoading}
            >
              {t("save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default AddEditExpense
