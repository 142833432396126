import moment from "moment"
import React, { useEffect } from "react"
import { Routes } from "../../routes"
import emptyImg from "../../assets/img/illustrations/no_content.svg"
import { FormatMoney } from "../../common/helpers"
import Pagination from "../core/Pagination"
import ExportToExcel from "./ExportToExcel"

//Translation
import { useTranslation } from "react-i18next"
import ReceivedPaymentsList from "./ReceivedPaymentsList"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("received_payments")
  })

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <i className="fa fa-home"></i>
              </a>
            </li>

            <li className="breadcrumb-item">
              <a href={Routes.AccountReceivables.path}>
                {t("received_payments")}
              </a>
            </li>
          </ol>
        </nav>
        <div className="col-12">
          <ReceivedPaymentsList />
        </div>
      </div>
    </>
  )
}
export default Index
