import React, { useEffect } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import { parsedUser } from "../../common/GetCurrentUser"
import Settings from "../../common/Settings"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  from: yup.string().required(),
  until: yup.string().required(),
})
//Component
const Generate608 = props => {
  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    obj.organizationId = user.OrganizationId

    let query =
      "OrganizationId=" +
      user.OrganizationId +
      "&PeriodStart=" +
      moment(obj.from).format("YYYY-MM-DD") +
      "&PeriodEnd=" +
      moment(obj.until).format("YYYY-MM-DD")
    let path = Settings.BasePathForAPI + "invoice/Generate608Excel?" + query
    window.open(path, "_blank")

    props.toggle(null, true)
  }
  const formik = useFormik({
    initialValues: {
      from: moment().format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    setTimeout(() => {
      var focus = document.getElementsByName("from")[0]
      if (focus) {
        focus.focus()
      }
    }, 500)
  }, [])

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("dgii_608")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>{t("since")}</Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={moment(formik.values.from).format("YYYY-MM-DD")}
                onChange={formik.handleChange}
              />
              {formik.errors.from ? (
                <div className="invalid text-sm">{formik.errors.from}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("until")}</Form.Label>
              <Form.Control
                type="date"
                name="until"
                value={moment(formik.values.until).format("YYYY-MM-DD")}
                onChange={formik.handleChange}
              />
              {formik.errors.until ? (
                <div className="invalid text-sm">{formik.errors.until}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button variant="primary" type="submit" className="btn-sm">
            {t("generate_file")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default Generate608
