import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Routes } from "../../routes"
import API from "../../common/API"

import PostList from "../Posts/PostList"
import AddEditCustomer from "./AddEditCustomer"

import ContactList from "../Contacts/ContactList"
import Files from "../files"
import QuoteList from "../Quotes/QuoteList"
import InvoiceList from "../Invoices/InvoiceList"

//Translation
import { useTranslation } from "react-i18next"
import { Card, Col, Row } from "react-bootstrap"
import CustomerInvoiceSummary from "./CustomerInvoiceSummary"

const CustomerDetails = props => {
  const { t } = useTranslation("global")

  const [state, setState] = useState({
    CurrentSection: "general",
    UI: {
      showAddEdit: false,
    },
  })

  const [customer, setCustomer] = useState({})

  useEffect(() => {
    request()
  }, [])
  const AssignSection = opt => {
    setState({
      ...state,
      CurrentSection: opt,
    })
  }
  const request = async () => {
    let requestAPI = await API.getAction(
      "customer/Details?Id=" + props.match.params.id
    )

    if (requestAPI.data.status === "ok") {
      setCustomer(requestAPI.data.response)
    }
  }
  const toggle = async (obj, reload) => {
    //state.UI.SelectedItem.set(obj);
    setState({
      ...state,
      UI: {
        ...state.UI,
        showAddEdit: !state.UI.showAddEdit,
      },
    })

    if (reload !== undefined) {
      request()
    }
  }

  const toggleBalance = () => {
    window.open(Routes.CustomerBalance.clean + props.match.params.id, "_blank")
  }

  const ProfileUI = () => {
    return (
      <>
        <div className="col-12 col-lg-4 col-md-12">
          <Card className="shadow-sm mb-4">
            <Card.Header>
              <h5 className="display-5 d-inline">
                {" "}
                {customer.firstName} {customer.lastName}
              </h5>
            </Card.Header>
            <Card.Body className="p-0">
              <Row className="mx-0">
                <Col xs={6}>{t("id")}</Col>
                <Col xs={6} className="text-truncate">
                  {customer.identifier}
                </Col>
                <Col xs={6}>{t("city")}</Col>
                <Col xs={6}>
                  {customer.city}, {customer.country}
                </Col>
                <Col xs={6}>{t("email")}</Col>
                <Col xs={6}>{customer.email}</Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col className="text-left">
                  <button
                    className="btn btn-secondary btn-sm mx-2"
                    onClick={() => toggleBalance()}
                  >
                    {t("Account Balance")}
                  </button>
                </Col>
                <Col className="text-right">
                  <button
                    className="btn btn-primary d-inline ml-auto btn-sm"
                    onClick={() =>
                      toggle({
                        id: "",
                        created: "",
                        title: "",
                        content: "",
                        employeeId: props.EmployeeId,
                        customerId: props.CustomerId,
                        employeePositionId: props.EmployeePositionId,
                      })
                    }
                  >
                    <i className="fa fa-edit" />
                  </button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>

          <ul className="nav nav-pills nav-fill  flex-column my-4 mx-4">
            <li className="nav-item">
              <button
                className={
                  " btn-block btn text-left " +
                  (state.CurrentSection === "general" ? "btn-primary" : "btn")
                }
                onClick={() => AssignSection("general")}
              >
                {t("general")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  " btn-block btn text-left " +
                  (state.CurrentSection === "storyline" ? "btn-primary" : "btn")
                }
                onClick={() => AssignSection("storyline")}
              >
                {t("post")}
              </button>
            </li>

            <li className="nav-item d-none">
              <button
                className={
                  " btn-block btn  text-left " +
                  (state.CurrentSection === "projects" ? "btn-primary" : "btn")
                }
                onClick={() => AssignSection("projects")}
              >
                {t("contacts")}
              </button>
            </li>
            <li className="nav-item d-none">
              <button
                className={
                  " btn-block btn  text-left " +
                  (state.CurrentSection === "files" ? "btn-primary" : "btn")
                }
                onClick={() => AssignSection("files")}
              >
                {t("files")}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  " btn-block btn  text-left " +
                  (state.CurrentSection === "contacts" ? "btn-primary" : "btn")
                }
                onClick={() => AssignSection("contacts")}
              >
                {t("contacts")}
              </button>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          {state.CurrentSection === "storyline" ? TimelineUI() : null}
          {state.CurrentSection === "general" ? GeneralUI() : null}
          {state.CurrentSection === "projects" ? "projects" : null}

          {state.CurrentSection === "contacts" ? ContactUI() : null}
        </div>
      </>
    )
  }

  const TimelineUI = () => {
    return <PostList CustomerId={props.match.params.id} />
  }

  const ContactUI = () => {
    return <ContactList CustomerId={props.match.params.id} />
  }

  const GeneralUI = () => {
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <CustomerInvoiceSummary
            id={props.match.params.id}
          ></CustomerInvoiceSummary>
        </div>
        <div className="col-12 mb-4">
          <InvoiceList CustomerId={props.match.params.id} />
        </div>
        <div className="col-12 mb-4">
          <QuoteList CustomerId={props.match.params.id} />
        </div>
        {/* <div className="col-12 mb-4">
          <Files CustomerId={props.match.params.id} />
        </div> */}
      </div>
    )
  }

  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditCustomer
          toggle={toggle}
          show={state.UI.showAddEdit}
          model={customer}
        />
      ) : null}

      <div className="row">
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="fa fa-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={Routes.Customers.path}>{t("customers")}</Link>
            </li>
            <li className="breadcrumb-item">{t("details")}</li>
          </ol>
        </nav>

        {ProfileUI()}
      </div>
    </>
  )
}
export default CustomerDetails
