import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"

import API from "../../common/API"
import { Link } from "react-router-dom"
import { Routes } from "../../routes"

//Translation
import { useTranslation } from "react-i18next"

const Summary = () => {
  const [records, setRecords] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()

  const { t } = useTranslation("global")

  const request = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    setIsLoading(true)
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("dashboard/BillingSummary", Query)
    setIsLoading(false)
    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)
    }
  }

  useEffect(() => {
    request()
  }, [])

  if (!records) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-body">
          <div className="row">
            {isLoading ? (
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t("loading")}...</span>
                </div>
              </div>
            ) : null}
            <div className="col text-center">
              <span className="display-6">{t("leads")}</span>
              <br />
              <span className="display-4">
                <Link to={Routes.Customers.path}>{records.leads}</Link>
              </span>
            </div>
            <div className="col text-center">
              <span className="display-6">{t("customers")}</span>
              <br />
              <span className="display-4">
                <Link to={Routes.Customers.path}>{records.customers}</Link>
              </span>
            </div>
            <div className="col text-center">
              <span className="display-6">{t("invoiced")}</span>
              <br />
              <span className="display-4">
                <Link to={Routes.InvoiceDashboard.path}>
                  {records.invoices}
                </Link>
              </span>
            </div>
            <div className="col text-center">
              <span className="display-6">{t("quotes")}</span>
              <br />
              <span className="display-4">
                <Link to={Routes.QuoteDashboard.path}>{records.quotes}</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Summary
