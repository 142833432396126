import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"

import API from "../../common/API"
//import { Link } from "react-router-dom"
//import { Routes } from "../../routes"
import { FormatMoney } from "../../common/helpers"

//Translation
import { useTranslation } from "react-i18next"
import { Col, Row } from "react-bootstrap"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

import moment from "moment"

const BilledByType = () => {
  const [from, setFrom] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  )
  const [state, setState] = useState()
  const [until, setUntil] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  )

  const [totalAmount, setTotalAmount] = useState(0)
  const [viewMode, setViewMode] = useState("graphic")
  const [records, setRecords] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()

  const { t } = useTranslation("global")

  const request = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    Query += `&from=${from}&until=${until}`
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("invoice/SummaryByType", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)

      let labels = []
      let total = 0
      result.forEach(item => {
        labels.push(item.type)
        total += item.total
      })

      setTotalAmount(total)
      setState({
        labels,
        datasets: [
          {
            label: t("total"),
            data: result.map(x => x.total),
            backgroundColor: "#4081d0",
          },
        ],
      })
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: t("expenses_by_category.total_category"),
      },
    },
  }

  useEffect(() => {
    request()
  }, [])

  if (!records) {
    return <></>
  }

  if (!state) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col className="mt-2">
              <span className="card-title display-6">
                {t("billed_by_type.title")}
              </span>
            </Col>
            <Col>
              <div className="input-group input-group-sm ">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="">
                    Fecha
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control "
                  value={from}
                  onChange={e => {
                    setFrom(e.target.value)
                  }}
                />
                <input
                  type="date"
                  className="form-control "
                  value={until}
                  onChange={e => {
                    setUntil(e.target.value)
                    request()
                  }}
                />
              </div>
            </Col>
            <Col className="ms-auto col-auto mt-1 ">
              <div className="btn-group ">
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "graphic" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("graphic")}
                >
                  <i className="fa fa-chart-line"></i>
                </button>
                <button
                  className={
                    "btn btn-sm " + (viewMode === "list" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("list")}
                >
                  <i className="fa fa-list"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body">
          <div className="row">
            {isLoading ? (
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t("loading")}...</span>
                </div>
              </div>
            ) : null}
            <div className="col-12">
              {viewMode === "graphic" ? (
                <Bar options={options} data={state} />
              ) : (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>{t("type")}</th>
                      <th>{t("quantity")}</th>
                      <th>{t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.type}</td>
                          <td>{item.amount}</td>
                          <td>{FormatMoney(item.total)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <b>{FormatMoney(totalAmount)}</b>
        </div>
      </div>
    </>
  )
}

export default BilledByType
