import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const PayrollCategoriesComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      modalSize="sm"
      toggleCRUD={props.toggle}
      title={t("payrollCategory") ?? ""}
      getRecordsPath={`Employees/PayrollCategories?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath="Employees/PayrollCategoriesAddEdit"
      deleteRecordPath="Employees/DeletePayrollCategories"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },

      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },

        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default PayrollCategoriesComponent
