import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Routes } from "../../routes"
import { useHistory } from "react-router"
import PaymentHistory from "./PaymentHistory"

const CurrentSubscription = () => {
  const user = parsedUser()
  const history = useHistory()
  const { t } = useTranslation("global")
  const [subscription, setSubscription] = useState<any>(null)
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    const request = await API.getAction("Subscription/ForOrganization", Query)
    if (request.data.status === "error") {
      setSubscription(null)
    } else {
      setSubscription(request.data.response)
    }
  }
  const [showHistory, setShowHistory] = useState(false)
  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  useEffect(() => {
    bindDataRemote()
  }, [])

  if (subscription === null) {
    return null
  }
  return (
    <>
      {showHistory && (
        <PaymentHistory toggle={toggleHistory} id={user.OrganizationId} />
      )}
      <div>
        <h4><small className="me-2 text-muted">{t('subscription')}: </small>{subscription.name}</h4>
        <small className="text-muted">{t("Next Billing Date")}</small>
        <span className="ms-2 text-primary badge bg-secondary">
          {" "}
          {moment(subscription.nextBillingDate).format("MMM DD, YYYY")}
        </span>
        <button
          className="btn btn-sm btn-primary ms-2"
          onClick={() => {
            history.push(Routes.SelectSubscription.path)
          }}
        >
          {t("Upgrade")}
        </button>
        <button
          className="btn btn-sm btn-info ms-2"
          onClick={() => {
            toggleHistory()
          }}
        >
          {t("Payment History")}
        </button>
      </div>
    </>
  )
}

export default CurrentSubscription
