import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const ExtraHourComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("extraHours") ?? ""}
      getRecordsPath={`employees/extraHours?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`employees/AddEditExtraHour?currentUserId=${user.ID}`}
      deleteRecordPath="employees/DeleteExtrahour"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },
        {
          name: "percentage",
          title: t("percentage"),
          htmlType: "number",
          cssClass: 'text-center'
        },
      
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "percentage",
          htmlType: "number",
          dbType: "decimal",
          title: t("percentage") ?? "",
          containerClass: "col-6",
          required: true,
        },

       
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default ExtraHourComponent
