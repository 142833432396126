import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"

import emptyImg from "../../assets/img/illustrations/no_content.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import Pagination from "../core/Pagination"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"

import { v4 as uuidv4 } from "uuid"
import { Col, Dropdown } from "react-bootstrap"
import SmallMailComposer from "../Mailer/SmallMailComposer"

//Translation
import { useTranslation } from "react-i18next"
import Filter from "../core/Filter/Filter"
import FilterFields from "./FilterFields"
import ActionBar from "../core/ActionBar"
import SubscriptionStatusComponent from "../Subscriptions/SubscriptionStatusComponent"

const QuoteList = props => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [CustomerId, setCustomerId] = useState(props.CustomerId)
  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Recordselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  const user = parsedUser()
  if (user === null) {
    window.location = Routes.SignIn.path
  }
  const { t } = useTranslation("global")

  useEffect(() => {
    request()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const request = async () => {
    try {
      let Query =
        "OrganizationId=" +
        user.OrganizationId +
        "&Search=" +
        (filterFields.search ?? "") +
        "&Quantity=" +
        state.Quantity +
        "&Page=" +
        state.currentPage

      if (CustomerId) {
        Query += "&CustomerId=" + CustomerId
      }
      if (filterFields.from) {
        Query += "&From=" + moment(filterFields.from).format("YYYY-MM-DD")
      }
      if (filterFields.until) {
        Query += "&Until=" + moment(filterFields.until).format("YYYY-MM-DD")
      }
      if (filterFields.currencyId) {
        Query += "&CurrencyId=" + filterFields.currencyId
      }
      if (filterFields.invoiceTypeId) {
        Query += "&invoiceTypeId=" + filterFields.invoiceTypeId
      }
      setIsLoading(!isLoading)
      //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
      let requestAPI = await API.getAction("quote/List", Query)

      if (requestAPI.data.status === "ok") {
        setState({
          ...state,
          Records: requestAPI.data.response,
          //   Quantity: requestAPI.data.quantityOfRecords,
          //     Page: requestAPI.data.page,
          TotalPages: requestAPI.data.quantityOfPages,
        })
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  //const [selectedItem, setSelectedItem] = useState(null)

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.sequence + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "quote/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const [showExport, setShowExport] = useState(false)
  const [QuotePath, setQuotePath] = useState("")
  const toggleExport = () => {
    setShowExport(!showExport)
  }

  const [showMail, setShowMail] = useState(false)
  const [mailModel, setMailModel] = useState(null)
  const toggleMail = async obj => {
    setShowMail(!showMail)
    if (!obj) {
      return
    }
    let msg = `<p>Hola <b>${obj.customerName}</b>,</p>
        <br/>
<p>Adjunto está la cotización <b>${obj.sequence}</b></p>
<br/>
<p><a href="${window.location.protocol}//${window.location.hostname}:${window.location.port}${Routes.QuotePrint.clean}${obj.id}">Ver Cotización</a>
</p>`
    setMailModel({
      toEmailAddress: obj.customerEmail ?? "",
      sender: "",
      title: `Cotización ${obj.sequence}`,
      content: msg,
      replyToEmail: user.Email,
      replyToName: "",
      userId: user.ID,
    })
  }

  // FILTER RELATED
  const [filterFields, setFilterFields] = useState({})
  useEffect(() => {
    console.log(filterFields)
    if (filterFields) {
      request()
    }
  }, [filterFields])
  const [showFilter, setShowFilter] = useState(false)

  // END FILTER RELATED

  return (
    <>
    <SubscriptionStatusComponent finance/>
      {/* {showAddEdit ?
            <AddEditCustomer toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} /> : null} */}
      {showMail ? (
        <SmallMailComposer
          toggle={toggleMail}
          show={showMail}
          model={mailModel}
        />
      ) : null}
      {showExport ? (
        <ExportModal path={QuotePath} show={showExport} toggle={toggleExport} />
      ) : null}
      <Col xs={12} className="mb-4">
        <ActionBar
          title={t("quotes")}
          icon={"fas fa-file-invoice"}
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                type="button"
                onClick={() => {
                  // TODO eventually update this modal
                  history.push(Routes.AddEditQuote.clean + uuidv4())
                }}
              >
                <i className="fa fa-plus " />
              </button>
              <button
                className={
                  "btn  btn-sm me-2  " + (showFilter ? "btn-secondary" : "")
                }
                onClick={() => {
                  setShowFilter(!showFilter)
                }}
              >
                <i className="fa fa-filter" />
              </button>
            </>
          }
        ></ActionBar>
      </Col>
      {showFilter ? (
        <Filter fields={FilterFields} setFilterFields={setFilterFields} />
      ) : null}
      {state.Records.length === 0 ? (
        <div className="row">
          <div className="col-6 mx-auto text-center">
            <img
              src={emptyImg}
              className="img-fluid"
              style={{ maxWidth: "50%" }}
              alt=""
            />
          </div>
        </div>
      ) : null}
      {state.Records.length > 0 ? (
        <div className="card shadow-sm">
          {!props.Full ? (
            <div className="card-header py-1">
              <div className="card-title">
                <i className="fas fa-file-invoice" />
                &nbsp; {t("quotes")}
              </div>
            </div>
          ) : null}
          <div className="card-body p-0 ">
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{t("sequence")}</th>
                    <th>{t("customer")}</th>
                    <th>{t("created")}</th>
                    <th>{t("total")}</th>
                    <th>{t("status")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
            {state.Records.length > 0
              ? state.Records.map((item, index) => {
                  let path = Routes.CustomerDetails.clean + item.id
                  let total = 0
                  let details = item.quoteDetails.filter(
                    x => x.deleted === null
                  )
                  if (details) {
                    details.forEach(detail => {
                      let price = detail.price
                      let discount =
                        detail.discount && detail.discount > 0
                          ? price *
                            (detail.dicount <= 1
                              ? detail.discount
                              : detail.discount / 100)
                          : 0
                      let tax = (detail.tax / 100) * (price - discount)
                      let subTotal = price - discount + tax

                      total += subTotal * detail.quantity ?? 0
                      //debugger;
                    })
                  }
                  return (
                    <tr key={index}>
                      <td>{item.sequence}</td>
                      <td>{item.customerName}</td>
                      <td>{moment(item.date).format("MMM DD, YYYY")}</td>
                      <td>{FormatMoney(total)}</td>
                      <td>{item.status}</td>
                      <td className="text-end">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            setQuotePath(`/quotes/print/${item.id}`)
                            toggleExport()
                            //history.push(path)
                          }}
                        >
                          <i className="fa fa-print" />
                        </button>
                        <div className="btn-group ms-2">
                            <Dropdown>
                              <Dropdown.Toggle variant="primary" size="sm">
                                <i className="fa fa-ellipsis-v"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  type="button"
                                  onClick={() => {
                                    history.push(
                                      Routes.AddEditQuote.clean + item.id
                                    )
                                    //toggleAddEdit(item, false)
                                  }}
                                >
                                  {t("edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  type="button"
                                  onClick={() => {
                                    history.push(
                                      Routes.AddEditInvoice.clean +
                                        uuidv4() +
                                        "/" +
                                        item.id
                                    )
                                    //toggleAddEdit(item, false)
                                  }}
                                >
                                  {t("quotes.convert_facture")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  type="button"
                                  onClick={() => deleteConfirm(item)}
                                >
                                  {t("delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                      </td>
                    </tr>
                  
                  )
                })
              : null}
              
              </tbody>
                  </table>
              </div>
          </div>
          <div className="card-footer overflow-hidden p-1">
            <Pagination
              totalPages={state.TotalPages}
              currentPage={state.currentPage}
              onPageChanged={onPageChanged}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default QuoteList
