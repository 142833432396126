import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  name: yup.string().required(),
  //    organizationId: yup.string(),

  price: yup.number().required(),
})
//Component
const AddEditService = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })
  const [currencies, setCurrencies] = useState([])
  const [dataTaxes, setDataTaxes] = useState([])
  const user = parsedUser()

  const { t } = useTranslation("global")

  const requestTaxesData = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Taxes/List", Query)

    if (requestAPI.data.status === "ok") {
      setDataTaxes(requestAPI.data.response)
    }
  }
  const requestCurrencies = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Currencies/List", Query)

    if (requestAPI.data.status === "ok") {
      setCurrencies(requestAPI.data.response)
    }
  }
  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    obj.organizationId = user.OrganizationId
    if (obj.identifier === null) {
      obj.identifier = uuidv4()
    }

    let request = await API.postAction("Service/AddEdit", obj)

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const AddEditTaxes = item => {
    let serviceTasexOnModel = formik.values.serviceTaxes
    var exist = serviceTasexOnModel.filter(
      x => x.taxesId === item.id && x.deleted === null
    )[0]
    if (exist) {
      //means we are deleting
      serviceTasexOnModel.filter(
        x => x.taxesId === item.id && x.deleted === null
      )[0].deleted = moment().format()
    } else {
      serviceTasexOnModel.push({
        id: uuidv4(),
        created: moment().format(),
        serviceId: formik.values.id,
        taxesId: item.id,
      })
    }

    formik.setFieldValue("serviceTaxes", serviceTasexOnModel)
  }
  useEffect(() => {
    requestCurrencies()
    requestTaxesData()
    setTimeout(() => {
      var focus = document.getElementsByName("name")[0]
      if (focus) {
        focus.focus()
      }
    }, 500)
  }, [])
  if (!props.model) {
    return <></>
  }
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("services")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
          <Row>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("description")}</Form.Label>
              <textarea
                value={formik.values.description ?? ""}
                rows={4}
                className="form-control"
                onChange={e => {
                  formik.setFieldValue("description", e.target.value)
                }}
              ></textarea>
              {formik.errors.description ? (
                <div className="invalid text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6} className="mt-2">
              <Form.Label>{t("currency")}</Form.Label>
              <select
                className="form-control"
                name="currencyId"
                value={formik.values.currencyId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {currencies &&
                  currencies.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
              {formik.errors.currencyId ? (
                <div className="invalid text-sm">
                  {formik.errors.currencyId}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6} className="mt-2">
              <Form.Label>{t("price")}</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="price"
                value={formik.values.price ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.price ? (
                <div className="invalid text-sm">{formik.errors.price}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Label>{t("taxes")}</Form.Label>
              <Row>
                {dataTaxes &&
                  dataTaxes.map((item, index) => {
                    let current = item
                    let exist =
                      props.model.serviceTaxes &&
                      props.model.serviceTaxes.filter(
                        x => x.taxesId === item.id && x.deleted === null
                      )[0]
                    return (
                      <Col key={index} className="text-wrap">
                        <input
                          type="checkbox"
                          defaultChecked={exist}
                          value={item.id}
                          onClick={() => AddEditTaxes(current)}
                          id={"tax_" + item.id}
                        />
                        <label htmlFor={"tax_" + item.id} className="ms-2">
                          {item.name} {item.percentage} %
                        </label>
                      </Col>
                    )
                  })}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditService
