import moment from "moment"

import React, { useEffect } from "react"
import { useState } from "react"
import { Search } from "semantic-ui-react"
import { Routes } from "../../routes"
import { v4 as uuidv4 } from "uuid"
import { Link, useParams } from "react-router-dom"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"
import ExchangeRate from "../Common/ExchangeRate"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

//Translation
import { useTranslation } from "react-i18next"

const AddEditQuotes = () => {
  const { id } = useParams()
  const [state, setState] = useState({
    id: id,
    date: moment().format(),
    currencyId: null,
    organizationId: null,
    invoiceTypeId: null,
    customerId: null,
    customerName: null,
    customerPhone: null,
    customerEmail: null,
    invoiceNumber: null,
    paymentMethod: null,
    paymentMethodReference: null,
    note: "",
    currencyExchangeRate: 1,
    sequence: 0,
    created: moment().format(),
    createdUserId: null,
    deleted: null,
    deletedUserId: null,
    quoteDetails: [],
  })

  //const [records, setRecords] = useState([])
  const [queryCustomers, setQueryCustomers] = useState("")
  const [customers, setCustomers] = useState([])
  const [summary, setSummary] = useState({
    subTotal: 0,
    taxes: 0,
    total: 0,
  })
  const requestCustomers = async obj => {
    let Query =
      "OrganizationId=" + user.OrganizationId + "&Search=" + obj + "&Quantity=5"

    let requestAPI = await API.getAction("customer/List", Query)

    if (requestAPI.data.status === "ok") {
      setCustomers(requestAPI.data.response)
    }
  }
  const [items, setItems] = useState([])

  const request = async () => {
    let requestAPI = await API.getAction("quote/Details?Id=" + id)

    if (requestAPI.data.status === "ok") {
      if (requestAPI.data.response) {
        setState(requestAPI.data.response)
        // setRecords(requestAPI.data.response.quoteDetails)
      }
    }
  }

  const searchItems = async term => {
    let query = "search=" + term + "&organizationid=" + user.OrganizationId
    let request = await API.getAction("serviceProduct/list?" + query)
    if (request.data.status === "ok") {
      let grouped = [
        ...request.data.response.services,
        ...request.data.response.products,
      ]
      let parsed = []
      grouped.forEach(item => {
        parsed.push(item)
      })
      setItems(parsed)
    }
  }

  const resultRenderCustomers = obj => {
    return <div>{obj.firstName + " " + (obj.lastName ?? "")}</div>
  }
  const resultRendererItem = obj => {
    return <div>{obj.title}</div>
  }

  const user = parsedUser()

  const { t } = useTranslation("global")

  const [currencies, setCurrencies] = useState([])
  const requestCurrencies = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Currencies/List", Query)

    if (requestAPI.data.status === "ok") {
      setCurrencies(requestAPI.data.response)
    }
  }

  const addItem = () => {
    let items = state.quoteDetails
    items.push({
      id: uuidv4(),
      quoteId: state.id,
      itemType: 0,
      itemId: "",
      itemConcept: "",
      quantity: 1,
      price: 0,
      discount: 0,
      tax: 0,
      created: moment().format(),
      createdUserId: user.ID,
      deleted: null,
      obj: null,
    })
    setState({
      ...state,
      quoteDetails: items,
    })

    setTimeout(() => {
      let obj = document.querySelector("table tbody tr .concept input")[
        state.quoteDetails.length - 1
      ]
      if (obj) {
        obj.focus()
      }
    }, 1000)
  }

  const deleteConfirm = async obj => {
    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.itemConcept + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            let s = state.quoteDetails
            s.filter(x => x.id == obj.id)[0].deleted = moment().format()
            debugger
            setState({
              ...state,
              quoteDetails: s,
            })
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const calcSummary = () => {
    let subTotal = 0
    let tax = 0
    state &&
      state.quoteDetails &&
      state.quoteDetails
        .filter(x => x.deleted == null)
        .forEach(item => {
          let calculateDiscount =
            item.discount && item.discount > 0
              ? item.price * (item.discount / 100)
              : 0
          let calculatedTax =
            (item.tax / 100) * (item.price - calculateDiscount) * item.quantity
          let recordSubTotal = (item.price - calculateDiscount) * item.quantity
          //        let calculatedTax = tax * item.quantity
          //
          // var recordsubTotal =
          //   (item.price - item.price * (item.discount / 100)) * item.quantity

          // let calculatedTaxes = 0

          // calculatedTaxes = item.price * (item.tax / 100) * item.quantity

          subTotal += recordSubTotal
          tax += calculatedTax
        })

    setSummary({
      subTotal: subTotal,
      taxes: tax,
      total: subTotal + tax,
    })
  }

  const handleSubmit = async () => {
    console.log("values to submit", state)

    if (state.id.length === 0) {
      state.id = uuidv4()
      state.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }

    state.organizationId = user.OrganizationId

    let request = await API.postAction("quote/AddEdit", state)

    console.log("response from adding", request)
    if (request.status === 200) {
      setQuotePath(`/quotes/print/${state.id}?printExecute`)
      toggleExport()
    }
  }
  const [showExport, setShowExport] = useState(false)
  const [QuotePath, setQuotePath] = useState("")
  const toggleExport = () => {
    setShowExport(!showExport)
  }

  //EXCHANGE RATE
  const [showExchangeRate, setShowExchangeRate] = useState(false)
  const [exchangeRate, setExchangeRate] = useState(1)
  const toggleExchangeRate = amount => {
    setShowExchangeRate(!showExchangeRate)

    if (amount) {
      setExchangeRate(amount)
      let obj = state

      obj.quoteDetails.forEach((item, index) => {
        obj.quoteDetails[index].price = item.price * amount
        obj.quoteDetails[index].discount = item.discount * amount
        //obj.quoteDetails[index].tax=item.tax*amount;
      })
      setState(obj)
    }

    calcSummary()
  }

  useEffect(() => {
    request()
    requestCurrencies()
  }, [])
  useEffect(() => {
    console.log(state)
    calcSummary()
  }, [state])

  return (
    <>
      {showExport ? (
        <ExportModal path={QuotePath} show={showExport} toggle={toggleExport} />
      ) : null}
      {showExchangeRate ? (
        <ExchangeRate show={showExchangeRate} toggle={toggleExchangeRate} />
      ) : null}
      <nav aria-label="breadcrumb" className="mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <i className="fa fa-home"></i>
            </a>
          </li>

          <li className="breadcrumb-item">
            <a href={Routes.QuoteDashboard.path}>{t("quotes")}</a>
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col ">
          <h5 className="display-4">
            <Link className=" me-4 " to={Routes.QuoteDashboard.path}>
              <i className="fas fa-chevron-left"></i>
            </Link>
            <i className="fas fa-file-invoice"></i>&nbsp;{t("quote")}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-4 my-2">
          <div className="card shadow-sm">
            <div className="card-body p-2">
              <div className="row">
                <div className="col-12">
                  <label>{t("customer")}</label>
                </div>
                <div className="col-10">
                  {/* CUSTOMER */}
                  <Search
                    placeholder={t("customer")}
                    results={customers}
                    minCharacters={3}
                    value={state ? state.customerName ?? "" : ""}
                    resultRenderer={resultRenderCustomers}
                    onSearchChange={(event, data) => {
                      console.log("event", event, "data", data)
                      if (data.value && data.value.length > 2) {
                        requestCustomers(data.value)
                      }

                      setState({
                        ...state,
                        customerName: data.value,
                      })
                    }}
                    onResultSelect={(event, data) => {
                      console.log("selected event", event, "data", data)
                      //setSelectedProvider(data.result);
                      setQueryCustomers(data.result.firstName)

                      setState({
                        ...state,
                        customerId: data.result.id,
                        customerName: data.result.firstName,
                        customerEmail: data.result.email,
                        customerPhone: data.result.phone,
                        //customerId:data.
                      })
                      //setSelectedProviderLabel(data.result.name);
                      //   formik.setFieldValue("providerId", data.result.id);
                    }}
                  ></Search>
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-secondary btn-sm mt-1"
                    onClick={() => {
                      //  toggleAddEditProvider()
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>

                <div className="col-12">
                  <label>{t("date")}</label>
                  <input
                    type="date"
                    className="form-control"
                    value={
                      state && state.date
                        ? moment(state.date).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={e => {
                      setState({
                        ...state,
                        date: moment(e.target.value).format("YYYY-MM-DD"),
                      })
                    }}
                  />
                </div>
                <div className="col-12">
                  <label>{t("currency")}</label>
                  <select
                    className="form-control"
                    value={state && state.currencyId ? state.currencyId : ""}
                    onChange={e => {
                      setState({
                        ...state,
                        currencyId: e.target.value,
                      })
                    }}
                  >
                    <option value="">-</option>
                    {currencies &&
                      currencies.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="card-fotter p-2">
              <div className="row">
                <div className="col-12">
                  <label>
                    {t("sub_total")}: {FormatMoney(summary.subTotal)}
                  </label>
                  <br />
                  <label>
                    {t("taxes")}: {FormatMoney(summary.taxes)}
                  </label>
                  <br />
                  <label>
                    <b>
                      {t("total")}: {FormatMoney(summary.total)}
                    </b>
                  </label>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-block my-4"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-8 my-2">
          <div className="card shadow-sm">
            <div className="card-header p-2">
              <div className="row">
                <div className="col text-right">
                  <button
                    className="btn btn-secondary btn-sm"
                    type="button"
                    onClick={() => addItem()}
                  >
                    {t("add_product_service")}
                  </button>
                  <button
                    className="btn btn-secondary btn-sm ms-2"
                    type="button"
                    onClick={() => {
                      toggleExchangeRate()
                    }}
                  >
                    {t("exchange_rate")}
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body p-2">
              <div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <td>{t("concept")}</td>
                      <td>{t("quantity")}</td>
                      <td>{t("cost")}</td>
                      <td>{t("discount")} %</td>
                      <td>{t("taxes")}</td>
                      <td>{t("sub_total")}</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {state.quoteDetails &&
                      state.quoteDetails
                        .filter(x => x.deleted == null)
                        .map((item, index) => {
                          let record = item
                          let calculateDiscount =
                            record.discount && record.discount > 0
                              ? record.price * (record.discount / 100)
                              : 0
                          let tax =
                            (record.tax / 100) *
                            (record.price - calculateDiscount)
                          let subTotal =
                            (record.price - calculateDiscount) * record.quantity
                          tax = tax * record.quantity

                          return (
                            <tr key={index}>
                              <td className="px-0">
                                {/* PRODUCT SERVICE */}
                                <Search
                                  placeholder={t("product_service")??""}
                                  className="concept"
                                  results={items}
                                  minCharacters={1}
                                  value={record.itemConcept}
                                  resultRenderer={resultRendererItem}
                                  onSearchChange={(event, data) => {
                                    console.log( "data", data, index )
                                    searchItems(data.value)

                                    let details = state.quoteDetails.filter(x=>x.deleted === null)
                                    details[index].itemConcept = data.value
                                    setState({
                                      ...state,
                                      quoteDetails: details,
                                    })
                                  }}
                                  onResultSelect={(event, data) => {
                                    console.log(
                                      "selected event",
                                      event,
                                      "data",
                                      data
                                    )
                                    // setSelectedProvider(data.result);

                                    record.itemType = data.result.type
                                    record.itemId = data.result.id
                                    record.itemConcept = data.result.title
                                    record.price = data.result.price
                                    record.quantity = data.result.quantity
                                    record.obj = data.result

                                    let details = state.quoteDetails
                                    let taxes = 0

                                    record.obj &&
                                      record.obj.taxes.forEach(subTax => {
                                        taxes += subTax.taxes.percentage
                                      })
                                    record.tax = taxes

                                    setState({
                                      ...state,
                                      quoteDetails: details,
                                    })
                                  }}
                                ></Search>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={record.quantity}
                                  min={0}
                                  step={"any"}
                                  onChange={e => {
                                    let newCollection: any =
                                      state.quoteDetails.map(subItem => {
                                        if (subItem.id === record.id) {
                                          subItem.quantity = e.target.value
                                        }
                                        return subItem
                                      })

                                    setState({
                                      ...state,
                                      quoteDetails: newCollection,
                                    })
                                    //setRecords(newCollection)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={record.price}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step={"any"}
                                  min={0}
                                  max={100}
                                  onChange={e => {
                                    let newCollection = state.quoteDetails.map(
                                      subItem => {
                                        if (subItem.id === record.id) {
                                          subItem.discount = e.target.value
                                        }
                                        return subItem
                                      }
                                    )
                                    setState({
                                      ...state,
                                      quoteDetails: newCollection,
                                    })
                                    //setRecords(newCollection)
                                  }}
                                  name={"discount" + index}
                                  className="form-control"
                                  value={record.discount}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={tax}
                                  readOnly
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={subTotal + tax}
                                  readOnly
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  type="button"
                                  onClick={() => {
                                    deleteConfirm(record)
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card shadow-sm mt-4">
            <div className="card-body p-2">
              <div className="row">
                <div className="col-12">
                  <label>{t("note")}</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    value={
                      state && state.note
                        ? state.note.includes("<br/>")
                          ? state.note.replace(/<br\s*[/]?>/gi, "\n")
                          : state.note
                        : ""
                    }
                    onChange={e => {
                      setState({
                        ...state,
                        note: e.target.value,
                      })
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddEditQuotes
