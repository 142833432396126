import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row, Card, Container } from "react-bootstrap"
import PayrollDiscountsComponent from "../HR/PayrollDiscountsComponent"
import PayrollBenefitsComponent from "../HR/PayrollBenefitsComponent"
import DepartmentsComponents from "../HR/DepartmentsComponents"
import PositionsComponent from "../HR/PositionsComponent"
import OrganizationBox from "../Organizations/OrganizationBox"
import { useParams } from "react-router"
import LedgerAccountList from "./LedgerAccounts/LedgerAccountList"
import BankList from "./Banks/BankList"
import CurrencyList from "./Currencies/CurrencyList"
import TaxList from "./Taxes/TaxList"

import ExpensesType from "./ExpensesType/ExpensesTypes"
import ExpensesTypePercentages from "./ExpensesTypePercentages/ExpensesTypePercentages"
import WorkshopStatusComponent from "./WorkshopStatusComponent"
import PayrollCategoriesComponent from "../HR/PayrollCategoriesComponent"
import InvoiceTypeList from "../Invoices/InvoiceTypeList"
import RoleComponent from "./RoleComponent"
import ExtraHourComponent from "./ExtraHourComponent"
import WarehouseComponent from "./WarehouseComponent"
import SettingSetions from "./SettingSections"
import WarehouseSectionComponent from "./WarehouseSectionComponent"
import UserComponent from "./UserComponent"
import ExpenseTypeComponent from "./ExpenseTypeComponent"
import ProductContainerComponent from "../Products/ProductContainerComponent"
import ProductCategoriesComponent from "../Products/ProductCategoriesComponent"
import CurrentSubscription from "../Subscriptions/CurrentSubscription"


interface SettingsDTO {
  id: string
}
const Settings = () => {
  const { id } = useParams<SettingsDTO>()
  const [section, setSection] = useState("")
  const loadedSettings = SettingSetions

  const [t] = useTranslation("global")
  const toggle = (obj: string) => {
    setSection(obj)
  }
  return (
    <>
      {section === "users" && <UserComponent show={true} toggle={toggle} />}
      {section === "roles" && <RoleComponent show={true} toggle={toggle} />}
      {section === "expenseTypes" && (
        <ExpensesType show={true} toggle={toggle} />
      )}
      {section === "expenseTypesPercentages" && (
        <ExpensesTypePercentages show={true} toggle={toggle} />
      )}

      {section === "departments" && (
        <DepartmentsComponents show={true} toggle={toggle} />
      )}
      {section === "discounts" && (
        <PayrollDiscountsComponent show={true} toggle={toggle} />
      )}
      {section === "benefits" && (
        <PayrollBenefitsComponent show={true} toggle={toggle} />
      )}
      {section === "positions" && (
        <PositionsComponent show={true} toggle={toggle} />
      )}
      {section === "payrollCategories" && (
        <PayrollCategoriesComponent show={true} toggle={toggle} />
      )}
      {section === "extraHour" && (
        <ExtraHourComponent show={true} toggle={toggle} />
      )}

      {section === "ledgerAccounts" && (
        <LedgerAccountList show={true} toggle={toggle} />
      )}
      {section === "banks" && <BankList show={true} toggle={toggle} />}
      {section === "currencies" && <CurrencyList show={true} toggle={toggle} />}
      {section === "taxes" && <TaxList show={true} toggle={toggle} />}
      {section === "workshopStatus" && (
        <WorkshopStatusComponent show={true} toggle={toggle} />
      )}
      {section === "invoiceTypes" && (
        <InvoiceTypeList show={true} toggle={toggle} />
      )}
      {section === "warehouse" && (
        <WarehouseComponent show={true} toggle={toggle} />
      )}

      {section === "warehouseSection" && (
        <WarehouseSectionComponent show={true} toggle={toggle} />
      )}
        {section === "expenseType" && (
        <ExpenseTypeComponent show={true} toggle={toggle} />
      )}

{section === "containers" && (
        <ProductContainerComponent show={true} toggle={toggle} />
      )}
      {section === "productCategories" && (
        <ProductCategoriesComponent show={true} toggle={toggle} />
      )}

      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <Row>
              <Col xs={12} md={6}>
                <OrganizationBox />
              </Col>
              {/* Subscription Status  */}
              <Col xs={12} md={6} className="text-end">
              <CurrentSubscription/>
              </Col>
              <Col xs={12}>
                {Array.isArray(loadedSettings) &&
                  loadedSettings.map((mainSection, index) => {
                    if (
                      mainSection.requiredParent &&
                      mainSection.parentId !== id
                    )
                      return null
                    return (
                      <Card className="shadow-sm mb-2" key={index}>
                        <Card.Header>
                          <Card.Title>{t(mainSection.name)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ul className="nav nav-pills nav-fill  flex-column ">
                            {mainSection.sections.map(
                              (subSection, subSectionIndex) => {
                                return (
                                  <li
                                    className="nav-item "
                                    key={subSectionIndex}
                                  >
                                    <button
                                      className={
                                        " btn-block btn  text-left " +
                                        (section === subSection.reference
                                          ? "btn-primary"
                                          : "btn")
                                      }
                                      onClick={() => {
                                        toggle(subSection.reference)
                                      }}
                                    >
                                      {t(subSection.label)}
                                    </button>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </Card.Body>
                      </Card>
                    )
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Settings
