
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../routes";
import API from "../../../common/API";
import moment from "moment";
import {
  FormatMoney,
  payrollStatusParsed,
  PayrollTypes,
} from "../../../common/helpers";
import AssignEmployees from "./AssignEmployees";
import { parsedUser } from "../../../common/GetCurrentUser";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { summaryPerTransactions } from "./payrollHelper";
import PayrollEmployeeItem from "./PayrollEmployeeItem";
import AddEditPayroll from "./AddEditPayroll";
import { useTranslation } from "react-i18next";

const PayrollDetails = (props) => {
  const { id } = useParams();
  const { t } = useTranslation("global");
  const [showAssignEmployees, setShowAssignEmployees] = useState(false);
  const [state, setState] = useState({
    Payroll: null,
    CurrentSection: "general",
    UI: {

      showAssignEmployees: false,
      showAssignBenefits: false,
      showAddEditTransaction: false,
    },
  });


  const request = async () => {
    let requestAPI = await API.getAction(
      "Employees/PayrollDetails?Id=" + id
    );

    if (requestAPI.data.status === "ok") {

      setState({
        ...state,
        Payroll: requestAPI.data.response
      });

    }
  };



  const toggleAssignEmployees = async (reload) => {

    setShowAssignEmployees(!showAssignEmployees);
    if (reload !== undefined) {
      request();
    }
  };


  const toggleAddEdit = async (obj, reload) => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        showAddEdit: !state.UI.showAddEdit
      }
    })

    if (reload) {
      await request();
    }
  };

  const deleteConfirm = async (obj) => {
    let currentUser = parsedUser();

    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction(
              "Employees/DeletePayroll?id=" +
              obj.id +
              "&UserId=" +
              currentUser.ID
            );
            window.location = "/payrolls";
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    });
  };
  const ProfileUI = () => {
    let obj = state.Payroll;
    if (obj === null) {

      return <></>;
    }
    return (
      <>

        
        <div className="col-12 col-md-12 col-lg-4">
          <div className="card shadow-sm">
            <div className="card-header p-2">
              <span className="card-title me-2">{obj.name}</span>
              <div className="btn-group float-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => toggleAddEdit(obj, false)}
                >
                  <i className="fa fa-edit" />
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => deleteConfirm(obj)}
                  type="button"
                >
                  <i className="fa fa-trash text-danger" />
                </button>
              </div>
            </div>
            <div className="card-body p-2">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>
                    {obj.payrollTypeId === PayrollTypes.biWeekly
                      ? "Bi-Weekly"
                      : obj.payrollTypeId === PayrollTypes.monthly
                        ? "Monthly"
                        : "N/A"}
                  </b>
                </li>
                <li className="list-group-item">
                  {obj.status === 1 ? (
                    <small className="badge bg-warning badge-sm ">
                      {payrollStatusParsed(obj.status)}
                    </small>
                  ) : null}
                  {obj.status === 2 ? (
                    <small className="badge bg-success badge-sm ">
                      {payrollStatusParsed(obj.status)}
                    </small>
                  ) : null}
                  {obj.status === 3 ? (
                    <small className="badge bg-danger badge-sm ">
                      {payrollStatusParsed(obj.status)}
                    </small>
                  ) : null}
                </li>

                {obj.from !== null ? (
                  <li className="list-group-item">
                    <b>Desde: </b>
                    <span>
                      {moment(obj.from).format("MMMM DD,YYYY")}
                      <br />
                    </span>
                  </li>
                ) : null}
                {obj.until !== null ? (
                  <li className="list-group-item">
                    <b>Hasta: </b>
                    <span>
                      {moment(obj.until).format("MMMM DD,YYYY")}
                      <br />
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          {/* SUMMARY OF THE PAYROLL */}
          <div className="row mt-4">
            <div className="col">
              <b>Total</b>
            </div>
            <div className="ml-auto col-auto">
              <b>{FormatMoney(summaryOfThePayRoll())}</b>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <small className="text-muted">Costo total para la compañia</small>
            </div>
            <div className="ml-auto col-auto">
              {FormatMoney(summaryOfThePayRollForTheCompany())}
            </div>
          </div>

          <div className="card shadow-sm my-4">
            <div className="card-body text-center">
              <a
                className="btn btn-primary btn-block"
                type="button"
                rel="noreferrer"
                target="_blank"
                href={"/payrollReport/" + obj.id}
              >
                Reporte de nómina
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-12">
              <div className="card shadow-sm mb-4">
                <div className="card-header px-2">
                  <div className="row">
                    <div className="col-auto">
                      <h5 className="display-5 d-inline">{t('employees')}</h5>
                    </div>
                    <div className="col ml-auto text-right">
                      <button
                        className="btn btn-primary d-inline ml-auto btn-sm"
                        onClick={() => toggleAssignEmployees()}
                      >
                        <i className="fa fa-plus ms-2" /> {t('assign')}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body px-2">
                  {state.Payroll.payrollEmployee.length > 0
                    ? state.Payroll.payrollEmployee
                      .filter((x) => x.deleted === null)
                      .map((item, index) => {
                        let parsedItem = item
                        let employee = parsedItem.employee;
                        let basePayrollTypeForUser = employee.payrollTypeId;
                        let basePayrollType = state.Payroll.payrollTypeId;

                        return (
                          <PayrollEmployeeItem
                            basePayrollType={basePayrollType}
                            basePayrollTypeForUser={basePayrollTypeForUser}
                            employee={employee}
                            parsedItem={parsedItem}
                            key={index}
                            deleteFromPayRoll={deleteFromPayRoll}
                            addEditTransaction={addEditTransaction}
                            reload={request}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const summaryOfThePayRoll = () => {
    let total = 0;
    try {
      if (
        state.Payroll.payrollEmployee &&
        state.Payroll.payrollEmployee.filter((x) => x.deleted === null)
          .length > 0
      ) {
        state.Payroll.payrollEmployee
          .filter((x) => x.deleted === null)
          .forEach((item, index) => {
            let parsedItem = item
            let employee = parsedItem;

            total += summaryPerTransactions(
              employee,
              0,
              state.Payroll.payrollTypeId
            );
          });
      }
    } catch (ex) {
      console.error(ex);
    }

    return total;
  };

  const summaryOfThePayRollForTheCompany = () => {
    let total = 0;
    try {
      if (
        state.Payroll.payrollEmployee &&
        state.Payroll.payrollEmployee.filter((x) => x.deleted === null)
          .length > 0
      ) {
        state.Payroll.payrollEmployee
          .filter((x) => x.deleted === null)
          .forEach((item, index) => {
            let parsedItem = item
            let employee = parsedItem;

            total += summaryPerTransactions(
              employee,
              4,
              state.Payroll.payrollTypeId
            );
          });
      }
    } catch (ex) {
      console.error(ex);
    }

    return total;
  };

  const deleteFromPayRoll = async (obj) => {
    let currentUser = parsedUser();

    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.employee.firstName + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction(
              "Employees/DeleteEmployeeFromPayroll?id=" +
              obj.id +
              "&UserId=" +
              currentUser.ID
            );
            await request();
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    });
  };
  const addEditTransaction = async (item) => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        showAddEditTransaction: !state.UI.showAddEditTransaction
      }
    })

    if (state.UI.showAddEditTransaction === false) {
      await request();
    }
  };

  useEffect(() => {
    console.log(id)
    request();
  }, [id]);

  console.log("state value ", state);
  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditPayroll
          toggle={toggleAddEdit}
          show={state.UI.showAddEdit}
          model={state.Payroll}
        />
      ) : null}
      {showAssignEmployees ? (
        <AssignEmployees
          toggle={toggleAssignEmployees}
          show={showAssignEmployees}
          model={state.Payroll}
        />
      ) : null}

      <div className="row">
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="fa fa-home"></i>
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to={Routes.Payrolls.path}>Payrolls</Link>
            </li>
            <li className="breadcrumb-item">Details</li>
          </ol>
        </nav>
        {ProfileUI()}
      </div>
    </>
  );
};
export default PayrollDetails;
