

import AdminOrganizationsComponent from "../components/Admin/AdminOrganizationsComponent";



const AdminOrganizationPage = () => {
    return (<AdminOrganizationsComponent />)
}

export default AdminOrganizationPage;