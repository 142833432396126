import React, { useEffect } from "react"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"

const ChatMainScreen = props => {
  useEffect(() => {
    let connection = new HubConnectionBuilder()
      .withUrl("ws://signal.pleelo.com/hub/communicationHub")
      .configureLogging(LogLevel.Debug)
      .build()
  })
  return <></>
}

export default ChatMainScreen
