import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"
import { Label, Search } from "semantic-ui-react"
import AddEditProvider from '../Providers/AddEdit'
//Translation
import { useTranslation } from "react-i18next"
import { productTypes } from "../../common/helpers"

//Component
const AddEditProduct = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })

  //Validation
  const validationSchema = yup.object({
    //   id: yup.string().required(),
    name: yup.string().required(),
    barcode: yup.string().required(),
    providerId: yup.string().required(),
    currencyId: yup.string().required(),
    brand : yup.string().required(),
    //    organizationId: yup.string(),

    cost: yup.number().required(),
  })

  const [model, setModel] = useState(
    props.model && props.model.product
      ? {
          id: props.model.product.id,
          organizationId: props.model.product.organizationId,
          inventoryId: props.model.id,
          brand: props.model.brand,
          barcode: props.model.product.barcode,
          name: props.model.product.name,
          description: props.model.product.description,
          providerId: props.model.providerId,
          providerName: props.model.provider.name,
          warehouseSectionId: props.model.warehouseSectionId,
          currencyId: props.model.currencyId,
          quantity: props.model.quantity,
          minimumQuantity: props.model.minimumQuantity,
          cost: props.model.cost,
          listPrice: props.model.listPrice,
          productProductCategory: props.model.product.productProductCategory,
          productTaxes: props.model.product.productTaxes,
        }
      : {
          id: uuidv4(),
          brand: null,
          organizationId: "",
          inventoryId: "",
          barcode: "",
          name: "",
          description: "",
          providerId: "",
          providerName: "",
          warehouseSectionId: null,
          currencyId: "",
          quantity: 0,
          minimumQuantity: 0,
          cost: 0,
          listPrice: 0,
          productProductCategory: [],
          productTaxes: [],
        }
  )

  const [providers, setProviders] = useState([])

  const [selectedProvider, setSelectedProvider] = useState(null)

  const [selectedProviderLabel, setSelectedProviderLabel] = useState(
    props.model && props.model.provider ? props.model.provider.name : ""
  )

  const [currencies, setCurrencies] = useState([])
  const [dataTaxes, setDataTaxes] = useState([])
  const [dataCategories, setDataCategories] = useState([])
  const [dataWarehouses, setDataWarehouses] = useState([])
  const user = parsedUser()

  const { t } = useTranslation("global")

  const requestWarehouses = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("products/warehouses", Query)

    if (requestAPI.data.status === "ok") {
      setDataWarehouses(requestAPI.data.response)
    }
  }
  const requestTaxesData = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Taxes/List", Query)

    if (requestAPI.data.status === "ok") {
      setDataTaxes(requestAPI.data.response)
    }
  }

  const requestCategoriesData = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Products/productCategories", Query)

    if (requestAPI.data.status === "ok") {
      setDataCategories(requestAPI.data.response)
    }
  }
  const requestCurrencies = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Currencies/List", Query)

    if (requestAPI.data.status === "ok") {
      setCurrencies(requestAPI.data.response)
    }
  }
  const [containers, setContainers] = useState([])

  const requestContainers = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("products/containers", Query)

    if (requestAPI.data.status === "ok") {
      setContainers(requestAPI.data.response)
    }
  }
  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    if (obj.inventoryId.length === 0) {
      obj.inventoryId = uuidv4()
    }

    obj.organizationId = user.OrganizationId

    if (props.productType === productTypes.asset) {
      obj.isAnActive = true
    }
    console.log("VIEW OBJ", obj)

    let request = await API.postAction("products/AddEdit", obj)

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }

  const formik = useFormik({
    initialValues: model,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const searchProvider = async term => {
    let query = "search=" + term + "&organizationid=" + user.OrganizationId
    let request = await API.getAction("provider/list?" + query)
    if (request.data.status === "ok") {
      setProviders(request.data.response)
    }
  }
  const resultRendererProvider = props => {
    return <Label content={props.name} />
  }

  const [showAddEditProvider, setShowAddEditProvider] = useState(false)

  const toggleAddEditProvider = (obj, reload) => {
    setShowAddEditProvider(!showAddEditProvider)
    if (reload) {
      setSelectedProvider(obj)
      setSelectedProviderLabel(obj.name)
    }
  }

  const AddEditTaxes = async item => {
    let prodTaxes = formik.values.productTaxes
    let exist = prodTaxes.filter(
      x => x.taxesId === item.id && x.deleted === null
    )[0]

    if (exist) {
      //means we are deleting

      prodTaxes.filter(
        x => x.taxesId === item.id && x.deleted === null
      )[0].deleted = moment().format()
      exist.deleted = moment().format()
    } else {
      exist = {
        id: uuidv4(),
        created: moment().format(),
        productId: formik.values.id,
        taxesId: item.id,
      }
      prodTaxes.push(exist)
    }

    await API.postAction("products/AddEditTax", exist)

    formik.setFieldValue("productTaxes", prodTaxes)
  }

  const AddEditCategory = async item => {
    let prodModel = formik.values.productProductCategory
    var exist = prodModel.filter(
      x => x.taxesId === item.id && x.deleted === null
    )[0]

    if (exist) {
      //means we are deleting
      prodModel.filter(
        x => x.taxesId === item.id && x.deleted === null
      )[0].deleted = moment().format()
      item.deleted = moment().format()
    } else {
      exist = {
        id: uuidv4(),
        created: moment().format(),
        productId: formik.values.id,
        productCategoryId: item.id,
      }
      prodModel.push(exist)
    }

    await API.postAction("products/AddEditProductProductCategory", exist)

    formik.setFieldValue("productProductCategory", prodModel)
  }

  useEffect(() => {
    requestCurrencies()
    requestTaxesData()
    requestCategoriesData()
    requestWarehouses()
    requestContainers()
    setTimeout(() => {
      var focus = document.getElementsByName("name")[0]
      if (focus) {
        focus.focus()
      }
    }, 500)
  }, [])

  return (
    <>
    {showAddEditProvider && (
      <AddEditProvider show={true} toggle={toggleAddEditProvider} />  
    )}
    <Modal show={true} onHide={props.toggle} className="modalRight">
      <Modal.Header closeButton>
        <Modal.Title>
          {props.productType === productTypes.asset
            ? t("asset")
            : t("products")}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <label>{t("barcode")}</label>
              <i className="text-danger">*</i>
              <Form.Control
                type="text"
                name="barcode"
                value={formik.values.barcode ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.barcode ? (
                <div className="invalid text-sm">{formik.errors.barcode}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <label>{t("rfids")}</label>
              <Form.Control
                type="text"
                name="rfids"
                value={formik.values.rfids ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.rfids ? (
                <div className="invalid text-sm">{formik.errors.rfids}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <label>{t("brand")}</label>
              <i className="text-danger">*</i>
              <Form.Control
                type="text"
                name="brand"
                value={formik.values.brand ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.brand ? (
                <div className="invalid text-sm">{formik.errors.brand}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <label>{t("nameModel")}</label>
              <i className="text-danger">*</i>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <label>{t("version")}</label>
              <Form.Control
                type="text"
                name="version"
                value={formik.values.version ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.version ? (
                <div className="invalid text-sm">{formik.errors.version}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <label>{t("container")}</label>
              <select
                className="form-control"
                name="containerId"
                onChange={formik.handleChange}
                value={formik.values.containerId ?? ""}
              >
                <option value="">-</option>
                {containers &&
                  containers.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>

              {formik.errors.version ? (
                <div className="invalid text-sm">{formik.errors.version}</div>
              ) : null}
            </Col>

            <Col xs={12}>
              <label>{t("provider")}</label>
              
              <i className="text-danger">*</i>
              <Row>
                <Col xs={10}>
                  <Search
                    results={providers}
                    title=""
                    minCharacters={3}
                    value={selectedProviderLabel}
                    resultRenderer={resultRendererProvider}
                    onSearchChange={(event, data) => {
                      console.log("event", event, "data", data)
                      searchProvider(data.value)
                      setSelectedProviderLabel(data.value)
                    }}
                    onResultSelect={(event, data) => {
                      console.log("selected event", event, "data", data)
                      setSelectedProvider(data.result)
                      setSelectedProviderLabel(data.result.name)
                      formik.setFieldValue("providerId", data.result.id)
                    }}
                  ></Search>
                </Col>
                <Col xs={2}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mt-1"
                    onClick={() => {
                      toggleAddEditProvider()
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </Col>
              </Row>
            </Col>

            <Col xs={12}>
              {formik.errors.providerId ? (
                <div className="invalid text-sm">
                  {formik.errors.providerId}
                </div>
              ) : null}
              {selectedProvider !== null ? (
                <>
                  <b>
                    {selectedProvider.name +
                      " (" +
                      selectedProvider.identifier +
                      ")"}
                  </b>
                </>
              ) : null}
            </Col>
            <Col xs={12}>
              <label>{t("note")}</label>
              <textarea
                value={formik.values.description}
                rows={2}
                className="form-control"
                onChange={e => {
                  formik.setFieldValue("description", e.target.value)
                }}
              ></textarea>
              {formik.errors.description ? (
                <div className="invalid text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6} className="mt-2">
              <label>{t("warehouse")}</label>
              <select
                className="form-control"
                id="warehouseSectionId"
                value={formik.values.warehouseSectionId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {dataWarehouses &&
                  dataWarehouses.map((item, index) => {
                    return (
                      <optgroup label={item.name} key={index}>
                        {item &&
                          item.warehouseSection &&
                          item.warehouseSection.map((section, indexSection) => {
                            return (
                              <option value={section.id} key={indexSection}>
                                {section.section}-{section.area}
                              </option>
                            )
                          })}
                      </optgroup>
                    )
                  })}
              </select>
              {formik.errors.warehouseSectionId ? (
                <div className="invalid text-sm">
                  {formik.errors.warehouseSectionId}
                </div>
              ) : null}
            </Col>
            {props.productType === productTypes.product ? (
              <>
                <Col xs={12} md={6} className="mt-2">
                  <label>{t("quantity")}</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    min={0}
                    name="quantity"
                    value={formik.values.quantity ?? ""}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.quantity ? (
                    <div className="invalid text-sm">
                      {formik.errors.quantity}
                    </div>
                  ) : null}
                </Col>

                <Col xs={12} md={6} className="mt-2">
                  <label>{t("minimum_quantity")}</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    min={0}
                    name="minimumQuantity"
                    value={formik.values.minimumQuantity ?? ""}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.minimumQuantity ? (
                    <div className="invalid text-sm">
                      {formik.errors.minimumQuantity}
                    </div>
                  ) : null}
                </Col>
              </>
            ) : null}

            
              <Col xs={12} md={6} className="mt-2">
                <label>{t("currency")}</label>
                <i className="text-danger">*</i>
                <select
                  className="form-control"
                  id="currencyId"
                  value={formik.values.currencyId}
                  onChange={formik.handleChange}
                >
                  <option value="">-</option>
                  {currencies &&
                    currencies.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      )
                    })}
                </select>
                {formik.errors.currencyId ? (
                  <div className="invalid text-sm">
                    {formik.errors.currencyId}
                  </div>
                ) : null}
              </Col>
            
            
              <Col xs={12} md={6} className="mt-2">
                <label>{t("cost")}</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="cost"
                  value={formik.values.cost ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.cost ? (
                  <div className="invalid text-sm">{formik.errors.cost}</div>
                ) : null}
              </Col>
            
            {props.productType === productTypes.product && (
              <Col xs={12} md={6} className="mt-2">
                <label>{t("list_price")}</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="listPrice"
                  value={formik.values.listPrice ?? ""}
                  onChange={formik.handleChange}
                />
                {formik.errors.listPrice ? (
                  <div className="invalid text-sm">
                    {formik.errors.listPrice}
                  </div>
                ) : null}
              </Col>
            )}

            {props.productType === productTypes.product && (
              <Col xs={12} className="mt-3">
                <label>{t("taxes")}</label>
                {dataTaxes &&
                  dataTaxes.map((item: any, index: number) => {
                    let current = item
                    let exist =
                      props.model.product &&
                      props.model.product.productTaxes &&
                      props.model.product.productTaxes.filter(
                        x => x.taxesId === item.id && x.deleted === null
                      )[0]

                    return (
                      <p key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={exist}
                          value={item.id}
                          onClick={() => AddEditTaxes(current)}
                          id={"tax_" + item.id}
                        />
                        <label htmlFor={"tax_" + item.id} className="mx-2">
                          {item.name} {item.percentage} %
                        </label>
                      </p>
                    )
                  })}
              </Col>
            )}
            <Col xs={12} className="mt-4">
              <label className="text-uppercase">{t("categories")}</label>
              <hr />
              {dataCategories &&
                dataCategories.map((item : any, index:number) => {
                  let current = item
                  let exist =
                    props.model.product &&
                    props.model.product.productProductCategory &&
                    props.model.product.productProductCategory.filter(
                      x => x.productCategoryId === item.id && x.deleted === null
                    )[0]

                  return (
                    <p key={index}>
                      <input
                        type="checkbox"
                        defaultChecked={exist}
                        value={item.id}
                        onClick={() => AddEditCategory(current)}
                        id={"cat_" + item.id}
                      />
                      <label htmlFor={"cat_" + item.id} className="mx-2">
                        {item.name}
                      </label>
                    </p>
                  )
                })}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
    </>
  )
}

export default AddEditProduct
