import React from "react"
import ReportsContainer from "../../core/ReportsContainer"
import { useTranslation } from "react-i18next"

import { parsedUser } from "../../../common/GetCurrentUser"


const BirthdayReport = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields = [
   
  ]
  const headers = [
    {
      name: "department",
      title: t("department"),
      type: "text",
    },
    {
      name: "firstName",
      title: t("firstName"),
      type: "text",
    },
    {
      name: "lastName",
      title: t("lastName"),
      type: "text",
      
    },
    {
      name: "birthdate",
      title: t("birthdate"),
      type: "date",
      
    },
    {
      name: "yearsOld",
      title: t("age"),
      type: "int",
      
    },
  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("birthday_report") ?? ""}
      filterFields={filterFields}
      headers={headers}
      endpoint={`employees/BirthdayReport?organizationId=${user.OrganizationId}`}
    />
  )
}

export default BirthdayReport
