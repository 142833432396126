import { Routes } from "../../../routes"

export const SidebarFinance = () => {
  try {
    let results = {
      title: "finance",
      items: [
        {
          title: "calendar",
          icon: "fa fa-calendar",
          link: Routes.Home.path,
        },
        {
          title: "dashboard",
          icon: "fa fa-home",
          link: Routes.BillingDashboard.path,
        },
        {
          title: "customers",
          icon: "fa fa-users",
          link: Routes.Customers.path,
        },

        {
          title: "quotes",
          icon: "fa fa-file-invoice",
          link: Routes.QuoteDashboard.path,
        },
        {
          title: "invoices",
          icon: "fa fa-hand-holding-usd",
          link: Routes.InvoiceDashboard.path,
        },
        {
          title: "expenses",
          icon: "fa fa-file-invoice-dollar",
          link: Routes.ExpensesList.path,
        },

        {
          title: "services",
          icon: "fa fa-bell",
          link: Routes.Services.path,
        },

        {
          title: "products",
          icon: "fa fa-boxes",
          link: Routes.Products.path,
        },

        {
          title: "providers",
          icon: "fa fa-truck",
          link: Routes.Providers.path,
        },
        {
          title: "reports",
          icon: "fa fa-chart-line",
          link: Routes.FinanceReports.path,
        },
        {
          title: "settings",
          icon: "fa fa-cog",
          link: Routes.Settings.clean + "finance",
        },
      ],
    }
    return results
  } catch (e) {
    console.error(e)
  }
}

export const SidebarPayroll = () => {
  let results = {
    title: "hr",
    items: [
      {
        title: "calendar",
        icon: "fa fa-calendar",
        link: Routes.Home.path,
      },
      {
        title: "dashboard",
        icon: "fa fa-home",
        link: Routes.DashboardPayroll.path,
      },
      {
        title: "employees",
        icon: "fa fa-id-card",
        link: Routes.Employees.path,
      },
      {
        title: "payrolls",
        icon: "fa fa-money-bill",
        link: Routes.Payrolls.path,
      },

      {
        title: "reports",
        icon: "fa fa-chart-line",
        link: Routes.HRReports.path,
      },
      {
        title: "settings",
        icon: "fa fa-cog",
        link: Routes.Settings.clean + "hr",
      },
    ],
  }
  return results
}

export const SidebarFiles = () => {
  let results = {
    title: "files",
    items: [
      // {
      //   title: "home",
      //   icon: "fa fa-home",
      //   link: Routes.DashboardPayroll.path,
      // },
      // {
      //   title: "employees",
      //   icon: "fa fa-id-card",
      //   link: Routes.Employees.path,
      // },
      // {
      //   title: "payrolls",
      //   icon: "fa fa-money-bill",
      //   link: Routes.Payrolls.path,
      // },
      // {
      //   title: "positions",
      //   icon: "fa fa-bullhorn",
      //   link: Routes.Positions.path,
      // },
      // {
      //   title: "departments",
      //   icon: "fab fa-hornbill",
      //   link: Routes.Departments.path,
      // },
    ],
  }
  return results
}

export const SidebarWorkshop = () => {
  let results = {
    title: "workshop",
    items: [
      {
        title: "calendar",
        icon: "fa fa-calendar",
        link: Routes.Home.path,
      },
      {
        title: "maintenance",
        icon: "fa fa-toolbox",
        link: Routes.WorkshopMaintenance.path,
      },
      {
        title: "equipments",
        icon: "fa fa-boxes",
        link: Routes.Assets.path,
      },

      // {
      //   title: "services",
      //   icon: "fa fa-circle",
      //   link: Routes.Services.path,
      // },
      {
        title: "reports",
        icon: "fa fa-circle",
        link: Routes.WorkshopReports.path,
      },
      {
        title: "settings",
        icon: "fa fa-cog",
        link: Routes.Settings.clean + "workshop",
      },
      // {
      //   title: "departments",
      //   icon: "fab fa-hornbill",
      //   link: Routes.Departments.path,
      // },
    ],
  }
  return results
}

export const SidebarAdmin = () => {
  let results = {
    title: "admin",
    items: [
      {
        title: "users",
        icon: "fa fa-users",
        link: Routes.AdminUsers.path,
      },
      {
        title: "organizations",
        icon: "fa fa-sitemap",
        link: Routes.AdminOrganizations.path,
      },
      
      {
        title: "reports",
        icon: "fa fa-chart-line",
        link: Routes.AdminReports.path,
      },
   
    ],
  }
  return results
}
