import React from "react"
import ReportsContainer from "../../core/ReportsContainer"
import { useTranslation } from "react-i18next"
import Settings from "../../../common/Settings"
import { parsedUser } from "../../../common/GetCurrentUser"
import moment from "moment"

const VigencyReport = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields = [
   
  ]
  const headers = [
    {
      name: "department",
      title: t("department"),
      type: "text",
    },
    {
      name: "firstName",
      title: t("firstName"),
      type: "text",
    },
    {
      name: "lastName",
      title: t("lastName"),
      type: "text",
      
    },
    {
      name: "beginWorkingDate",
      title: t("beginWorkingDate"),
      type: "date",
      
    },
  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("vigencyReport") ?? ""}
      filterFields={filterFields}
      headers={headers}
      endpoint={`employees/VigencyReport?organizationId=${user.OrganizationId}`}
    />
  )
}

export default VigencyReport
