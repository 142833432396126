import { parsedUser } from "../../common/GetCurrentUser";
import CRUDField from "../core/genericCRUD/CRUDField";

const user = parsedUser()

const FilterFields: CRUDField[] = [
	{
		name: "currencyId",
		title: "currency",
		htmlType: "select",
		dbType: "text",
		containerClass: "col-4",
		dataSourceValueField: "id",
		dataSourceTextField: "name",
		dataSource: "Currencies/List?OrganizationId=" + user?.OrganizationId
	},

	{
		name: "from",
		title: "from",
		htmlType: "date",
		dbType: "date",
		containerClass: "col-4",
	},
	{
		name: "until",
		title: "until",
		dbType: "date",
		htmlType: "date",
		containerClass: "col-4",
	},
	{
		name: "search",
		title: "search",
		htmlType: "text",
		dbType: "text",
		containerClass: "col-4",
	},
]

export default FilterFields;