import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"

import "../../css/app_print.css"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import logo from "../../assets/img/logoPleeloTexto.png"
import { Col, Row } from "react-bootstrap"
const PaymentHistoryDetails = props => {
  const { t } = useTranslation("global")
  const { id } = useParams<any>()
  const [state, setState] = useState<any>(null)

  const bindDataRemote = async () => {
    let requestAPI = await API.getAction(
      "subscription/PaymentHistoryDetails?Id=" + id
    )

    if (requestAPI.data.status === "ok") {
      setState(requestAPI.data.response)
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [])

  if (state === null) {
    return <></>
  }

  console.log(state)
  return (
    <>
      <Row>
        <Col xs={12} md={8} className="mx-auto">
          <section className="invoice bg-white  ">
            <input
              type="hidden"
              id="CurrencyExchangeRateSelected"
              name="CurrencyExchangeRateSelected"
              value="1"
            />

            <div className="row m-2">
              <div className="col-8 mb-4">
                <img
                  src={logo}
                  alt="pleelo"
                  className="navbar-brand-light "
                  style={{ maxWidth: "200px" }}
                />
                <br/>
                <span>https://pleelo.com</span>
              </div>
              <div className="col-4 text-right">
                <b>No. {state.id}</b>
                <br />
                <b>{t("date")}: </b>
                {moment(state.date).format("MMM DD, YYYY")}
              </div>
            </div>

            <div className="row invoice-info m-2 my-4">
              <div className="col-6">
                <div className="col-12 ">
                  <h5>{state.organizationName}</h5>
                  <address>{state.organizationId}</address>
                </div>
              </div>

              <div className="col-6 text-right"></div>
            </div>

            <div className="row m-2">
              <div className="col-12 table-responsive">
                <table id="details" className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center" colSpan={2}>
                        <h3>{t("payment")}</h3>
                      </th>
                    </tr>
                    <tr>
                      <th className="col-md-6">{t("concept")}</th>
                      <th className="col-md-1 text-end">{t("sub-total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-wrap">{state.notes}</td>

                      <td className="text-end">
                        {FormatMoney(state.amountPaid)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-6 col-md-4 ms-auto">
              <p className="lead">{t("balance")}</p>

              <div className="table-responsive">
                <table className="table summary">
                  <tbody>
                    <tr className="subtotal">
                      <th>{t("sub-total")}:</th>
                      <td className="text-right">
                        {FormatMoney(state.amountPaid)}
                      </td>
                    </tr>

                    <tr className="total">
                      <th>{t("total")}:</th>
                      <td className="text-right">
                        {FormatMoney(state.amountPaid)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="d-print-none ">
                <button
                  className="btn btn-primary btn-block "
                  type="button"
                  onClick={() => {
                    window.print()
                  }}
                >
                  {t("print")}
                </button>
              </p>
            </div>
          </section>
        </Col>
      </Row>
    </>
  )
}

export default PaymentHistoryDetails
