import { useFormik } from "formik"
import React, { useState } from "react"
import * as yup from "yup"
import { Modal, Form, InputGroup, Alert } from "react-bootstrap"

import { parsedUser } from "../../common/GetCurrentUser"
import Sha256 from "../../common/sha256"

import API from "../../common/API"

//Translation
import { useTranslation } from "react-i18next"

const ChangePassword = props => {
  const { t } = useTranslation("global")

  const [isLoading, setIsLoading] = useState(false)
  const [visibleAlert, setAlertVisible] = useState(false)

  const theUser = parsedUser()

  const validationSchema = yup.object({
    password: yup.string().required("Password is a required field").min(9),
    repeatPassword: yup
      .string()
      .required("Repeat Password is a required field")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(9),
  })

  const handleSubmit = async obj => {
    try {
      setIsLoading(true)
      let query =
        "AccountID=" +
        theUser.ID +
        "&NewPassword=" +
        Sha256.hash(obj.password) +
        "&AdminId=" +
        theUser.ID
      let request = await API.getAction("Users/UpdatePassword", query)

      console.log(request)

      if (request.status === 200) {
        setAlertVisible(true)

        setTimeout(() => {
          setAlertVisible(false)
          props.toggle(true)
        }, 2000)
      }
    } catch (error) {
      console.log("Error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Modal show={props.show} onHide={props.toggle}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-lock " /> &nbsp; {t("changePassword")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group id="password" className="mb-4">
              <InputGroup>
                <Form.Control
                  autoFocus
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name="password"
                  type="password"
                  placeholder={t("newPassword")}
                />
              </InputGroup>
              {formik.errors.password ? (
                <div className="invalid text-sm">{formik.errors.password}</div>
              ) : null}
            </Form.Group>
            <Form.Group id="repeatPassword" className="mb-4">
              <InputGroup>
                <Form.Control
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  name="repeatPassword"
                  type="password"
                  placeholder={t("confirmPassword")}
                />
              </InputGroup>
              {formik.errors.repeatPassword ? (
                <div className="invalid text-sm">
                  {formik.errors.repeatPassword} {formik.isValid.repeatPassword}
                </div>
              ) : null}
            </Form.Group>
            <Alert show={visibleAlert} role="alert" variant="success">
              Password changed successfully!
            </Alert>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm mx-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              {t("save")}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangePassword
