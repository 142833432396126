import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"

import emptyImg from "../../assets/img/illustrations/no_content.svg"
import { v4 as uuidv4 } from "uuid"
import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { DropdownButton, Dropdown, Col } from "react-bootstrap"
import Pagination from "../core/Pagination"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"
import InvoiceTypeList from "./InvoiceTypeList"
import Generate607 from "./Generate607"
import Generate608 from "./Generate608"
import Payments from "./Payments"
import LoadingModal from "../Common/LoadingModal"
import SmallMailComposer from "../Mailer/SmallMailComposer"

import AddEditPayment from "./AddEditPayment"

//Translation
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Filter from "../core/Filter/Filter"
import InvoiceFilterFields from "./InvoiceFilterFields"
import ActionBar from "../core/ActionBar"
import SubscriptionStatusComponent from "../Subscriptions/SubscriptionStatusComponent"

const InvoiceList = props => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [CustomerId, setCustomerId] = useState(props.CustomerId)
  const [state, setState] = useState({
    Records: [],

    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  const theUser = parsedUser()

  const { t } = useTranslation("global")

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields = InvoiceFilterFields
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const bindDataRemote = async () => {
    try {
      let idOrganization = theUser.OrganizationId

      let Query =
        "OrganizationId=" +
        idOrganization +
        "&Search=" +
        (filterObj.search ?? "") +
        "&Quantity=" +
        state.Quantity +
        "&Page=" +
        state.currentPage

      if (CustomerId) {
        Query += "&CustomerId=" + CustomerId
      }
      if (filterObj.from) {
        Query += "&From=" + moment(filterObj.from).format("YYYY-MM-DD")
      }
      if (filterObj.until) {
        Query += "&Until=" + moment(filterObj.until).format("YYYY-MM-DD")
      }
      if (filterObj.currencyId) {
        Query += "&CurrencyId=" + filterObj.currencyId
      }
      if (filterObj.invoiceStatus) {
        Query += "&paidStatus=" + filterFields.invoiceStatus
      }
      if (filterObj.invoiceTypeId) {
        Query += "&invoiceTypeId=" + filterObj.invoiceTypeId
      }
      //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
      setIsLoading(!isLoading)
      let requestAPI = await API.getAction("Invoice/List", Query)

      if (requestAPI.data.status === "ok") {
        setState({
          ...state,
          Records: requestAPI.data.response,
          //   Quantity: requestAPI.data.quantityOfRecords,
          //     Page: requestAPI.data.page,
          TotalPages: requestAPI.data.quantityOfPages,
        })
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    history.push(Routes.AddEditInvoice.clean + obj.id)
  }

  const [showInvoiceType, setShowInvoiceType] = useState(false)
  const toggleShowInvoiceType = () => {
    setShowInvoiceType(!showInvoiceType)
  }

  const toggleAddEditCreditNote = async obj => {
    let invoiceId = "/" + obj.id
    let creditNoteId = uuidv4()
    history.push(Routes.AddEditCredit.clean + creditNoteId + invoiceId)
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + obj.sequence + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "Invoice/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const nullInvoice = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_cancel") + obj.sequence + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "Invoice/null?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: t("no") ?? "",
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const [show607, setShow607] = useState(false)
  const [show608, setShow608] = useState(false)

  const toggle607 = () => {
    setShow607(!show607)
  }
  const toggle608 = () => {
    setShow608(!show608)
  }
  const [showPayments, setShowPayments] = useState(false)

  const togglePayments = async obj => {
    setSelectedItem(obj)
    setShowPayments(!showPayments)
    if (showPayments) {
      await bindDataRemote()
    }
  }
  const [showExport, setShowExport] = useState(false)
  const [InvoicePath, setInvoicePath] = useState("")
  const toggleExport = () => {
    setShowExport(!showExport)
  }
  const [showStatusFix, setShowStatusFix] = useState(false)
  const toggleStatusFix = async reload => {
    setShowStatusFix(!showStatusFix)
    if (reload) {
      await bindDataRemote()
    }
  }

  const [showStatusFixCents, setShowStatusFixCents] = useState(false)
  const toggleStatusFixCents = async reload => {
    setShowStatusFixCents(!showStatusFixCents)
    if (reload) {
      await bindDataRemote()
    }
  }
  const [showMail, setShowMail] = useState(false)
  const [mailModel, setMailModel] = useState(null)
  const toggleMail = async obj => {
    setShowMail(!showMail)
    if (!obj) {
      return
    }
    let msg = `<p>Hola <b>${obj.customerName}</b>,</p>
        <br/>
<p>Adjunto está la factura <b>${obj.invoiceNumber}</b></p>
<br/>
<p><a href="${window.location.protocol}//${window.location.hostname}:${window.location.port}${Routes.InvoicePrint.clean}${obj.id}">Ver factura</a>
</p>`
    setMailModel({
      toEmailAddress: obj.customerEmail ?? "",
      sender: "",
      title: `Factura ${obj.invoiceNumber}`,
      content: msg,
      replyToEmail: theUser.Email,
      replyToName: "",
      userId: theUser.ID,
    })
  }
  const [showAddEditPayment, setShowAddEditPayment] = useState(false)
  const toggleAddEditPayment = async (obj, reload) => {
    if (obj) {
      setSelectedItem(obj)

      setPayments(obj.invoicePayment)
    }
    if (reload) {
      await bindDataRemote()
    }
    setShowAddEditPayment(!showAddEditPayment)
  }
  const [payments, setPayments] = useState([])

  useEffect(() => {
    if (theUser === null) {
      history.push(Routes.SignIn.path)
    }
    bindDataRemote()
  }, [state.currentPage])

  return (
    <>
    <SubscriptionStatusComponent finance/>
      {showMail ? (
        <SmallMailComposer
          toggle={toggleMail}
          show={showMail}
          model={mailModel}
        />
      ) : null}

      {showStatusFix ? (
        <LoadingModal
          toggle={toggleStatusFix}
          show={showStatusFix}
          URL={
            "invoice/FixPaymentStatus?OrganizationId=" + theUser.OrganizationId
          }
        />
      ) : null}
      {showStatusFixCents ? (
        <LoadingModal
          toggle={toggleStatusFixCents}
          show={showStatusFixCents}
          URL={
            "invoice/FixPaymentStatusWithCents?OrganizationId=" +
            theUser.OrganizationId
          }
        />
      ) : null}
      {showInvoiceType ? (
        <InvoiceTypeList
          toggle={toggleShowInvoiceType}
          show={showInvoiceType}
        />
      ) : null}
      {showPayments ? (
        <Payments
          toggle={togglePayments}
          show={showPayments}
          id={selectedItem.id}
          model={selectedItem}
        />
      ) : null}
      {show607 ? <Generate607 toggle={toggle607} show={show607} /> : null}
      {show608 ? <Generate608 toggle={toggle608} show={show608} /> : null}
      {/* {showAddEdit ?
            <AddEditCustomer toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} /> : null} */}
      {showExport ? (
        <ExportModal
          path={InvoicePath}
          show={showExport}
          toggle={toggleExport}
        />
      ) : null}
      {showAddEditPayment ? (
        <AddEditPayment
          payments={payments}
          toggle={toggleAddEditPayment}
          model={{
            id: "",
            invoiceId: selectedItem.id,
            payment: null,
            date: moment().format(),
            created: moment().format(),
            deleted: null,
            updated: null,
            createdId: "",
            deletedId: null,
            updatedId: null,
            note: "",
            financialAccountId: null,
            currencyId: "",
            exchangeRate: 1,
            retention: 0,
          }}
          show={showAddEditPayment}
          parentModel={selectedItem}
        />
      ) : null}

      <Col xs={12} className="mb-2">
        <ActionBar
          title={t("invoices")}
          icon={"fas fa-file-invoice"}
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                onClick={() => {
                  // TODO eventually update this modal

                  history.push(Routes.AddEditInvoice.clean + uuidv4())
                  //toggleAddEdit()
                }}
              >
                <i className="fa fa-plus " />
              </button>
              <button
                className={
                  "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                }
                onClick={() => toggleFilter()}
              >
                <i className="fa fa-filter" />
              </button>
              <DropdownButton
                align="start"
                size="sm"
                id="mainMore"
                className="ms-2 d-inline"
                title={<i className="fa fa-ellipsis-v"></i>}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    toggle607()
                  }}
                >
                  {t("generate_607")}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    toggle608()
                  }}
                >
                  {t("generate_608")}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  title="Valida que el estatus de todas las facturas esté correcto, puede tomar un tiempo "
                  onClick={() => {
                    toggleStatusFix()
                  }}
                >
                  {t("status_validation")}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  title="Correge pagos pendientes por diferencia de centavos, agregando una transaccion extra por esa diferencia"
                  onClick={() => {
                    toggleStatusFixCents()
                  }}
                >
                  {t("cents_correction")}
                </Dropdown.Item>
              </DropdownButton>
            </>
          }
        ></ActionBar>
      </Col>
      {showFilter ? (
        <Filter
          filterAction={toggleMail}
          fields={InvoiceFilterFields}
          setFilterFields={getFilterValues}
        />
      ) : null}

      {state.Records.length > 0 ? (
        <div className="card shadow-sm">
          {!props.Full ? (
            <div className="card-header py-1">
              <div className="card-title">
                <i className="fas fa-file-invoice" />
                &nbsp; {t("invoices")}
              </div>
            </div>
          ) : null}
          <div className="card-body p-0 ">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("customer")}</th>
                    <th>{t("total")}</th>

                    <th>{t("type")}</th>
                    <th>{t("payment")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {state.Records.length > 0
                    ? state.Records.map((item, index) => {
                        let status =
                          item.paidStatus === null || item.paidStatus === 0
                            ? "pendingPayment"
                            : item.paidStatus === 1
                            ? "partialPayment"
                            : "completePayment"

                        let total = 0
                        let details = item.invoiceDetails.filter(
                          x => x.deleted === null
                        )
                        if (details) {
                          details.forEach(detail => {
                            let price = detail.price
                            let discount = detail.discount ?? 0
                            let tax =
                              detail.tax && detail.tax > 0 ? detail.tax : 0
                            let subTotal = price - discount + tax

                            total += subTotal * detail.quantity ?? 0
                          })
                        }

                        let paid = 0
                        item.invoicePayment
                          .filter(x => x.deleted === null)
                          .forEach(payment => {
                            paid += payment.payment + payment.retention
                          })
                        console.log("Paid", paid)
                        if (!item) {
                          return null
                        }
                        return (
                          <tr key={index}>
                            <td className={status}>
                              {item.created ? (
                                <small className="text-muted">
                                  {moment(item.created).format("MMM DD, YYYY")}
                                </small>
                              ) : null}
                            </td>
                            <td>
                              <Link
                                title={item.customerName}
                                className="clickable text-truncate d-inline-block"
                                style={{ maxWidth: "300px" }}
                                to={
                                  Routes.CustomerDetails.clean + item.customerId
                                }
                              >
                                {item.customerName}

                                <br />
                              </Link>
                            </td>
                            <td>
                              {item.currency.name} {FormatMoney(total)}
                            </td>
                            <td
                              className="clickable"
                              onClick={() => {
                                setInvoicePath(
                                  `${Routes.InvoicePrint.clean}${item.id}`
                                )
                                toggleExport()
                                //history.push(path)
                              }}
                            >
                              <span className="badge bg-primary">
                                {item.invoiceType.name}
                              </span>
                              <br />
                              <small>
                                <b>{item.invoiceNumber}</b>
                              </small>
                            </td>
                            <td>
                              {paid !== total && total - paid > 1 ? (
                                <>
                                  {paid !== 0 && paid !== total ? (
                                    <>
                                      {FormatMoney(paid)}
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    onClick={() => toggleAddEditPayment(item)}
                                  >
                                    {t("add_payment")}
                                  </button>
                                </>
                              ) : (
                                FormatMoney(paid)
                              )}
                            </td>
                            <td className="text-end">
                              <div className="btn-group">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => {
                                    setInvoicePath(
                                      `${Routes.InvoicePrint.clean}${item.id}?printExecute`
                                    )
                                    toggleExport()
                                    //history.push(path)
                                  }}
                                >
                                  <i className="fa fa-print" />
                                </button>

                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => {
                                    toggleMail(item)
                                  }}
                                >
                                  <i className="fas fa-paper-plane"></i>
                                </button>
                              </div>
                              <div className="btn-group ms-2">
                                <DropdownButton
                                  size="sm"
                                  drop={"start"}
                                  title={<i className="fa fa-ellipsis-v"></i>}
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      togglePayments(item)
                                    }}
                                  >
                                    {t("payments")}
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => toggleAddEdit(item, false)}
                                  >
                                    {t("edit")}
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item as="button">Duplicar factura</Dropdown.Item> */}
                                  {!item.creditNoteId ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        toggleAddEditCreditNote(item)
                                      }
                                    >
                                      {t("add_credit_note")}
                                    </Dropdown.Item>
                                  ) : null}
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => {
                                      nullInvoice(item)
                                    }}
                                  >
                                    {t("cancel_invoice")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deleteConfirm(item)}
                                  >
                                    {t("delete_invoice")}
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer overflow-hidden p-1">
            <Pagination
              totalPages={state.TotalPages}
              currentPage={state.currentPage}
              onPageChanged={onPageChanged}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-6 mx-auto text-center">
            <img
              src={emptyImg}
              className="img-fluid"
              style={{ maxWidth: "50%" }}
              alt=""
            />
          </div>
        </div>
      )}
    </>
  )
}

export default InvoiceList
