import { createContext } from "react"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { date } from "yup"
import Settings from "../common/Settings"

export const socketComponent = ExecuteOnStart => {
  const socket = new HubConnectionBuilder()
    .withUrl(Settings.SocketPath)
    .configureLogging(LogLevel.Debug)
    .withAutomaticReconnect()
    .build()

  const cleanUp = () => {
    socket.off("ReceiveUnreadMessagesInGeneral")
    socket.off("onOnline")
    socket.off("onNotifyAboutNewMessage")
  }

  socket.onreconnected(() => {
    //  cleanUp();
    console.log("socket reconnected", socket.state)
    ExecuteOnStart()
  })

  const startSocket = () => {
    console.log("start socket", new date())
    cleanUp()
    socket.start().then(state => {
      console.log("socket connected", state)
      ExecuteOnStart()
    })
  }
  // TODO: Re-enable connection
  // startSocket()

  return socket
}

export const SocketContext = createContext()
