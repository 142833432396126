import moment from "moment"
import React, { useEffect } from "react"
import { useState } from "react"
import { Label, Search } from "semantic-ui-react"
import { Routes } from "../../routes"
import { v4 as uuidv4 } from "uuid"
import { Route, useParams, useHistory } from "react-router-dom"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"
import { Formik, useFormik } from "formik"
import * as yup from "yup"
import { Dropdown, DropdownButton, Form } from "react-bootstrap"
import ExchangeRate from "../Common/ExchangeRate"

//Translation
import { useTranslation } from "react-i18next"

const AddEditCreditNote = () => {
  const history = useHistory()

  const { t } = useTranslation("global")

  const { creditNoteId, invoiceId } = useParams()

  const [state, setState] = useState({
    id: invoiceId,
    date: moment().format(),
    currencyId: null,
    organizationId: null,
    invoiceTypeId: null,
    customerId: null,
    customerName: null,
    customerPhone: null,
    customerEmail: null,
    invoiceNumber: null,
    paymentMethod: null,
    paymentMethodReference: null,
    note: "",
    currencyExchangeRate: 1,

    sequence: 0,
    credit: false,
    created: moment().format(),
    createdUserId: null,
    deleted: null,
    deletedUserId: null,
    invoiceDetails: [],
  })
  const [summary, setSummary] = useState({
    subTotal: 0,
    taxes: 0,
    total: 0,
  })

  const cleanUp = () => {
    let clean = {
      id: uuidv4(),
      date: moment().format(),
      currencyId: null,
      organizationId: null,
      invoiceTypeId: null,
      customerId: null,
      customerName: null,
      customerPhone: null,
      customerEmail: null,
      invoiceNumber: null,
      paymentMethod: null,
      paymentMethodReference: null,
      note: "",
      currencyExchangeRate: 1,

      sequence: 0,
      credit: false,
      created: moment().format(),
      createdUserId: null,
      deleted: null,
      deletedUserId: null,
      invoiceDetails: [],
    }

    setState(clean)
  }

  const request = async () => {
    let requestAPI = await API.getAction("Invoice/Details?Id=" + invoiceId)

    if (requestAPI.data.status === "ok") {
      if (requestAPI.data.response) {
        setState(requestAPI.data.response)
        formik.setValues(requestAPI.data.response)
      }
    }
  }

  const user = parsedUser()

  // const { t } = useTranslation("global")

  const [currencies, setCurrencies] = useState([])

  const calcSummary = () => {
    let subTotal = 0
    let tax = 0
    formik.values &&
      formik.values.invoiceDetails &&
      state.invoiceDetails.forEach(item => {
        var recordsubTotal = (item.price - (item.discount ?? 0)) * item.quantity

        let calculatedTaxes = 0

        calculatedTaxes = item.tax * item.quantity

        subTotal += recordsubTotal * exchangeRate
        tax += calculatedTaxes * exchangeRate
      })

    setSummary({
      subTotal: subTotal,
      taxes: tax,
      total: subTotal + tax,
    })
  }

  const handleSubmit = async obj => {
    history.push(Routes.CustomerDetails.clean + state.customerId)
  }

  const formik = useFormik({
    initialValues: state,
    onSubmit: handleSubmit,
  })

  //EXCHANGE RATE
  const [showExchangeRate, setShowExchangeRate] = useState(false)
  const [exchangeRate, setExchangeRate] = useState(1)
  /*const toggleExchangeRate = amount => {
    setShowExchangeRate(!showExchangeRate)

    if (amount) {
      setExchangeRate(amount)
      let obj = formik.values

      obj.invoiceDetails.forEach((item, index) => {
        obj.invoiceDetails[index].price = item.price * amount
        obj.invoiceDetails[index].discount = (item.discount ?? 0) * amount
        obj.invoiceDetails[index].tax = item.tax * amount
      })
      formik.setValues(obj)
    }

    calcSummary()
  }*/

  //Initial request

  useEffect(() => {
    request()
  }, [])

  useEffect(() => {
    calcSummary()
  }, [state])

  return (
    <>
      <nav aria-label="breadcrumb" className="mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href={Routes.Home.path}>
              <i className="fa fa-home"></i>
            </a>
          </li>
          <li className="breadcrumb-item">
            <a href={Routes.InvoiceDashboard.path}>{t("invoices")}</a>
          </li>
          <li className="breadcrumb-item">
            <a href={Routes.InvoiceDashboard.path}>{t("credit_note")}</a>
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col ">
          <h5 className="display-4">
            <i className="fas fa-file-invoice"></i>&nbsp;
            {t("credit_note_invoice")}&nbsp;
            {invoiceId} {t("customer")} {state.customerName}
          </h5>
        </div>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="row mx-0">
          <div className="col-xl-3 col-md-4 col-12 my-2">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <div className="row">
                  <div className="col-12">
                    <label>
                      {t("sub_total")}: {FormatMoney(summary.subTotal)}
                    </label>
                    <br />
                    <label>
                      {t("taxes")}: {FormatMoney(summary.taxes)}
                    </label>
                    <br />
                    <label>
                      <b>
                        {t("total")}: {FormatMoney(summary.total)}
                      </b>
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-block my-4"
                      type="submit"
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/**TABLE */}
          <div className="col-xl-9 col-md-8 col-12 my-2">
            <div className="card shadow-sm">
              <div className="card-body p-2">
                <div className="w-100">
                  <table className="table">
                    <thead>
                      <tr>
                        <td>{t("concept")}</td>
                        <td>{t("quantity")}</td>
                        <td>{t("cost")}</td>
                        <td>{t("discount")}</td>
                        <td>{t("taxes")}</td>
                        <td>{t("sub_total")}</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {state &&
                        state.invoiceDetails.length > 0 &&
                        state.invoiceDetails.map((item, index) => {
                          var subTotal =
                            (item.price -
                              (item.discount === undefined
                                ? 0
                                : item.discount)) *
                            item.quantity
                          var taxes = item.tax * item.quantity

                          return (
                            <tr key={index}>
                              <td className="px-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={item.itemConcept}
                                  readOnly
                                />
                              </td>
                              <td className="px-2">
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={item.quantity}
                                  readOnly
                                />
                              </td>
                              <td className="px-2">
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={item.price}
                                  readOnly
                                />
                              </td>
                              <td className="px-2">
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step={"any"}
                                    className="form-control"
                                    value={item.discount?.toFixed(2)}
                                    readOnly
                                  />
                                  <div className="input-group-text">%</div>
                                </div>
                              </td>
                              <td className="px-2">
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={taxes?.toFixed(2)}
                                  readOnly
                                />
                              </td>
                              <td className="px-2">
                                <input
                                  type="number"
                                  step={"any"}
                                  className="form-control"
                                  value={(subTotal + taxes)?.toFixed(2)}
                                  readOnly
                                />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default AddEditCreditNote
