import moment from "moment"
import React from "react"
import { Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getAbbreviation } from "../../common/helpers"

const MaintenanceListView = props => {
  console.log(props)
  const { t } = useTranslation("global")
  return (
    <Card>
      <Card.Body className="p-0">
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th></th>
                <th>{t("name")}</th>
                <th>{t("status")}</th>
                <th>{t("created")}</th>
                <th>{t("asssigned")}</th>
                <th>{t("customer")}</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {props.records &&
                props.records.length > 0 &&
                props.records.map((item, index) => {
                  let equipment = item && item.product
                  let equipmentName = equipment
                    ? equipment.barcode +
                      " > " +
                      equipment.brand +
                      " " +
                      equipment.name
                    : ""
                  console.log(item)
                  return (
                    <tr key={index}>
                      <td>
                        <button
                          type="button"
                          onClick={() => props.markCompleted(item)}
                          className={
                            "btn btn-sm d-inline ms-2 " +
                            (item.completed
                              ? "btn-success"
                              : "btn-outline-success")
                          }
                        >
                          <i className="fa fa-check "></i>
                        </button>
                      </td>
                      <td className="clickable" onClick={() => props.toggleDetails(item)}>
                        
                        {equipmentName}</td>
                      <td>
                        <i
                          className="fa fa-circle me-2"
                          style={{ color: item.status.color }}
                        ></i>
                        {item.status.name}
                      </td>
                      <td>{moment(item.created).fromNow()}</td>
                      <td>
                        {item.assigned?.firstName} {item.assigned?.lastName}
                      </td>
                      <td>{item.customer?.firstName}</td>
                      <td className="text-end">
                        <div className="btn-group">
                          <button
                            onClick={() => props.toggleDetails(item)}
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-edit" />
                          </button>

                          <button
                            type="button"
                            onClick={() => props.delete(item)}
                            className="btn btn-primary btn-sm"
                          >
                            <i className="fa fa-trash text-danger" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  )
}

export default MaintenanceListView
