import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Routes } from "../../routes"
import { Link } from "react-router-dom"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import logo from "../../assets/img/logoPleeloTexto.png"
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js"
import { useHistory } from "react-router"
import { min } from "lodash"
import { FormatMoney } from "../../common/helpers"
import SelectOrganization from "../Organizations/SelectOrganization"
import OrganizationUI from "../Common/Sidebar/OrganizationUI"
import toast from "react-hot-toast"

interface moduleDTO {
  id: string
  name: string
  fieldName: string
  icon: string
}
const SubscriptionSelection = (props: any) => {
  const [presentPaymentMethod, setPresentPaymentMethod] =
    useState<boolean>(false)
  const history = useHistory()
  const [months, setMonths] = useState(1)
  const paypalProductionClientId = "AXx5aAf9XPqyZQlM1juQE4Jv4SvIqBPRS4B0CIj3RIzS0yRNyeEaiBEpBHwB1mVPbN2KAvzKJ0KV7UYL";
  const paypalSandboxClientId= "AXPVn8fyFlsLNwKWQXu0J_HSZOx9xKldLT3C82I6RoQ_DkK23XwJLmD-xv693twM0i4GrjjI5JpPDa9t";
  const paypalInitialOptions = {
    currency: "USD",

    intent: "capture",
    clientId:
     paypalProductionClientId// ,
  }

  const availableModules: moduleDTO[] = [
    {
      id: "hr",
      name: "HR",
      fieldName: "hrcost",
      icon: "fa fa-users",
    },
    {
      fieldName: "billingCost",
      id: "finance",
      name: "finance",
      icon: "fas fa-hand-holding-usd",
    },
    {
      fieldName: "workshopCost",
      id: "workshop",
      name: "workshop",
      icon: "fas fa-tools",
    },
  ]

  const [selectedSubscription, setSelectedSubscription] = useState<any>(null)
  const [selectedModules, setSelectedModules] = useState<moduleDTO[]>([])
  const { t } = useTranslation("global")
  const [subscriptions, setSubscriptions] = useState([])
  const user = parsedUser()

  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    const request = await API.getAction("Subscription/list", Query)

    console.log(request.data.status, request.data.response)
    setSubscriptions(request.data.response)
  }

  const toggleModule = (module: moduleDTO) => {
    const exist = selectedModules.find((item: any) => item.id === module.id)
    console.log(exist, module)
    if (exist) {
      setSelectedModules(
        selectedModules.filter((item: any) => item.id !== module.id)
      )
    } else {
      setSelectedModules([...selectedModules, module])
    }
  }
  let totalCost = 0
  const refVal = React.useRef(null)
  const onCreateOrder = (data, actions) => {
    let ele: any = refVal.current
    let val = ele.value * 1 || 0
    console.log(data, "Total cost", val, data)
    let theOrder = {
      purchase_units: [
        {
          amount: {
            value: data + "",
          },
        },
      ],
    }
    return actions.order.create(theOrder)
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then(async details => {
      console.log(details)
      const name = details.payer.name.given_name
      //Assembly the notes
    let modules ="";
      let notes = `Plan: ${selectedSubscription.name} | Renewal Period: ${months} | Amount: ${details.purchase_units[0].amount.value}`
      selectedModules.forEach(module => {
        notes += `| ${module.name}`
        modules+= (modules.length?",": "")+module.id;
      })
    
      //Save the results
      const queryData: any = {
        planId: selectedSubscription?.id,
        amount: details.purchase_units[0].amount.value,
        serviceTransferId: details.id,
        organizationId: user.OrganizationId,
        notes: notes,
        userId: user.ID,
        renewalPeriod: months,
        modules: modules
      }
      let request = await API.postAction(
        "subscription/registerRenewal",
        queryData
      )
      console.log(request)
      //Notify the front end
toast.success(t(`subscriptionRenewed`))
      //Redirect to home
      setPresentPaymentMethod(false);
      history.push(Routes.Home.path);
      //alert(`Transaction completed by ${name}`)
    })
  }
  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <>
      <input
        type="hidden"
        id="totalCostHidden"
        value={totalCost}
        ref={refVal}
      />
      <Container>
        <Row className="my-5">
          <Col xs={12}>
            <div
              className="text-center clickable"
              onClick={() => {
                history.push(Routes.Home.path)
              }}
            >
              <img
                src={logo}
                alt="pleelo"
                className="navbar-brand-light mb-4"
                style={{ maxWidth: "200px" }}
              />
            </div>
            <hr />
          </Col>
          <Col className="mb-4" xs={12} md={6}>
            <Card className="mb-4">
              <Card.Header>
                <Card.Title>1. {t("selectSubscription")}</Card.Title>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  {subscriptions.map((subscription: any, index: number) => {
                    return (
                      <Col
                        key={index}
                        className={
                          "py-2 px-4  m-2 clickable " +
                          (selectedSubscription &&
                          subscription.id === (selectedSubscription as any).id
                            ? "bg-primary text-white"
                            : "bg-white text-dark")
                        }
                        onClick={() => {
                          setSelectedSubscription(subscription)
                          setSelectedModules([])
                        }}
                      >
                        {subscription.name}
                      </Col>
                    )
                  })}
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-4 ">
              <Card.Header>
                <Card.Title className="d-inline ">
                  2. {t("selectModules")}
                </Card.Title>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  {availableModules.map((module: any, index: number) => {
                    const exist = selectedModules.find(
                      (item: any) => item.id === module.id
                    )
                    return (
                      <Col
                        key={index}
                        className={
                          "py-2 px-4  mx-2 text-center clickable " +
                          (exist
                            ? "bg-primary text-white"
                            : "bg-white text-dark")
                        }
                        onClick={() => toggleModule(module)}
                      >
                        <i className={module.icon + " fa-3x"}></i>
                        <br />
                        <div className="display-4 py-2">{t(module.name)}</div>
                        <br />
                        <b className="display-5">
                          {selectedSubscription &&
                            selectedSubscription[module.fieldName]}
                        </b>
                      </Col>
                    )
                  })}
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-4 ">
              <Card.Header>
                <Card.Title className="d-inline ">
                  3. {t("Renovation Period")}
                </Card.Title>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  <Col xs={12} md={3}>
                    <label>{t("months")}</label>
                    <input
                      className="form-control"
                      type="number"
                      onChange={e => {
                        setMonths(parseInt(e.target.value))
                      }}
                      value={months}
                      min="1"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/* SUMMARY SECTION */}
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>4. {t("summary")}</Card.Title>
              </Card.Header>
              <Card.Body>
                {selectedSubscription && (
                  <p className="text-uppercase">
                    <b>{t("subscription")}:</b>
                    <span className="ms-2">
                      {(selectedSubscription as any).name}
                    </span>
                  </p>
                )}

                <ul>
                  {selectedSubscription &&
                    selectedModules &&
                    selectedModules.map((module: any, index: number) => {
                      totalCost +=
                        (selectedSubscription as any)[module.fieldName] * months
                      return (
                        <li key={index}>
                          <b>{t(module.name)}:</b>
                          <span className="ms-2">
                            {(selectedSubscription as any)[module.fieldName]}
                          </span>
                        </li>
                      )
                    })}
                </ul>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col>
                    <h4>
                      {t("total")}: <span>${FormatMoney(totalCost)}</span>
                    </h4>
                  </Col>
                  <Col className="text-end mt-4" xs={12}>
                    {presentPaymentMethod && (
                      <PayPalScriptProvider options={paypalInitialOptions}>
                        <PayPalButtons
                          createOrder={(data, actions) =>
                            onCreateOrder(totalCost.toFixed(2), actions)
                          }
                          onApprove={onApproveOrder}
                        />
                      </PayPalScriptProvider>
                    )}
                    {!presentPaymentMethod && totalCost>0 && (
                      <button
                        className="btn btn-block btn-primary"
                        onClick={() => {
                          setPresentPaymentMethod(true)
                        }}
                        type="button"
                      >{t('pay')}</button>
                    )}
                    {/* 
                    <button className="btn btn-primary " type="submit">
                      {t("process")}
                    </button> */}
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
            <p className="text-center my-4">
              <Card.Link
                className="small text-end text-danger"
                as={Link}
                to={Routes.SignIn.path}
              >
                {t("logout")}
              </Card.Link>
              <Card.Link
                className="small text-end text-primary ms-2"
                as={Link}
                to={Routes.SelectOrganization.path}
              >
                {t("change_organization")}
              </Card.Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SubscriptionSelection
