import ReportSectionDTO from "./ReportSectionDTO";

const ReportSetions : ReportSectionDTO[]= [
	{
		name: "fiscal reports",
		parentId: "finance",
		requiredParent: true,
		sections: [
			{
				reference: "606",
				label: "DGII 606",
			},
			{
				reference: "607",
				label: "DGII 607",
			},
			
			{
				reference: "608",
				label: "DGII 608",
			},
		]
	},

	{
		name: "finance reports",
		parentId: "finance",
		requiredParent: true,
		sections: [
			
			
			{ reference: "AccountReceivables", label: "AccountReceivables"},
			{ reference: "AccountPayables", label: "AccountPayables"},
			// { reference: "BalanceSheet", label: "BalanceSheet"},
			{ reference: "profitAndLoss", label: "profitAndLoss"},
			// { reference: "CashFlow", label: "CashFlow"},
			// { reference: "CashFlowByType", label: "CashFlowByType"},
			{ reference: "ReceivedPayments", label: "ReceivedPayments"},
		
		]
	},

	{
		name: "hr reports",
		parentId: "hr",
		requiredParent: true,
		sections: [
			
			{ reference: "birthdays", label: "birthdays"},
			{ reference: "salaryReport", label: "salaryReport"},
			{ reference: "vigencyReport", label: "vigencyReport"},
		
		]
	},

	{
		name: "workshop reports",
		parentId: "workshop",
		requiredParent: true,
		sections: [
			
			{ reference: "workshopTaskByStatus", label: "workshopTaskByStatus"},
		
		]
	},
	
]

export default ReportSetions;