import React, { useEffect } from "react"
import { Routes } from "../../routes"
import ProviderList from "./ProviderList"

//Translation
import { useTranslation } from "react-i18next"
import MostFrequentedProvider from "../dashboard/MostFrequentedProvider"
import { Col, Container } from "react-bootstrap"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("providers")
  })

  return (
    <div className="row">
      <nav aria-label="breadcrumb" className="mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href={Routes.Home.path}>
              <i className="fa fa-home"></i>
            </a>
          </li>

          <li className="breadcrumb-item">
            <a href={Routes.Providers.path}>{t("providers")}</a>
          </li>
        </ol>
      </nav>
      <Col>
        <Container>
          <ProviderList Full />
        </Container>
      </Col>
    </div>
  )
}
export default Index
