import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row, Card, Container } from "react-bootstrap"
import Generate607 from "../Invoices/Generate607"
import Generate608 from "../Invoices/Generate608"
import Generate606 from "../Expenses/ExportToExcel"

import MostSoldProduct from "./MostSoldProduct"
import MostSoldService from "./MostSoldService"
import ReceivedPayments from "./ReceivedPayments"
import AccountReceivables from "./AccountReceivables"
import { useParams } from "react-router"
import SalaryReport from "../HR/Reports/SalaryReport"
import BirthdayReport from "../HR/Reports/BirthdayReport"
import VigencyReport from "../HR/Reports/VigencyReport"
import WorkshopTaskByStatusReport from "../workshopContainer/Reports/WorkshopTaskByStatusReport"
import ReportSetions from "./ReportSections"
import ProfitAndLoss from "./ProfitAndLost"

const Reports = () => {
  let { id } = useParams<{ id?: string }>();
  const loadedReports = ReportSetions;
  const [section, setSection] = useState("")
  const toggle = (obj: string) => {
    setSection(obj)
  }



  const [t] = useTranslation("global")

  return (
    <>

      {section === '606' ? <Generate606 toggle={toggle} show={true} /> : null}
      {section === '607' ? <Generate607 toggle={toggle} show={true} /> : null}
      {section === '608' ? <Generate608 toggle={toggle} show={true} /> : null}
      {section === 'salaryReport' ? <SalaryReport toggle={toggle} show={true} /> : null}
      {section === 'birthdays' ? <BirthdayReport toggle={toggle} show={true} /> : null}
      {section === 'vigencyReport' ? <VigencyReport toggle={toggle} show={true} /> : null}

      {section === "MostSoldProduct" && (
        <MostSoldProduct toggle={toggle} show={true} />
      )}
      {section === "MostSoldService" && (
        <MostSoldService toggle={toggle} show={true} />
      )}

      {section === "ReceivedPayments" && (
        <ReceivedPayments toggle={toggle} show={true} />
      )}
      {section === "AccountReceivables" && (
        <AccountReceivables toggle={toggle} show={true} />
      )}

      {section === "workshopTaskByStatus" && (
        <WorkshopTaskByStatusReport toggle={toggle} show={true} />
      )}
       {section === "profitAndLoss" && (
        <ProfitAndLoss toggle={toggle} show={true} />
      )}

      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <h5 className="display-4">
              <i className="fas fa-file-invoice"></i>&nbsp;
              {t("reports")}
            </h5>
            <Row>

            <Col xs={12}>
                {Array.isArray(loadedReports) &&
                  loadedReports.map((mainSection, index) => {
                    if (
                      mainSection.requiredParent &&
                      mainSection.parentId !== id
                    )
                      return null
                    return (
                      <Card className="shadow-sm mb-2" key={index}>
                        <Card.Header>
                          <Card.Title>{t(mainSection.name)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ul className="nav nav-pills nav-fill  flex-column ">
                            {mainSection.sections.map(
                              (subSection, subSectionIndex) => {
                                return (
                                  <li
                                    className="nav-item "
                                    key={subSectionIndex}
                                  >
                                    <button
                                      className={
                                        " btn-block btn  text-left " +
                                        (section === subSection.reference
                                          ? "btn-primary"
                                          : "btn")
                                      }
                                      onClick={() => {
                                        toggle(subSection.reference)
                                      }}
                                    >
                                      {t(subSection.label)}
                                    </button>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </Card.Body>
                      </Card>
                    )
                  })}
              </Col>


              
              {id === 'finance' &&
                <Col xs={12}>

                  <Card className="shadow-sm mb-2">
                    <Card.Header>
                      <Card.Title>Lo más vendido</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <ul className="nav nav-pills nav-fill  flex-column ">
                        <li className="nav-item ">
                          <button
                            className={
                              " btn-block btn  text-left " +
                              (section === "mostSoldProducts"
                                ? "btn-primary"
                                : "btn")
                            }
                            onClick={() => {
                              toggle('mostSoldProducts')
                            }}
                          /*  onClick={() => toggle606()} */
                          >
                            {t("mostSoldProducts")}
                          </button>
                        </li>

                        <li className="nav-item">
                          <button
                            className={
                              " btn-block btn text-left " +
                              (section === "typeOfExpenses"
                                ? "btn-primary"
                                : "btn")
                            }
                            onClick={() => {
                              /*handleShow("typeOfExpenses")*/
                              toggle('mostSoldService')
                            }}
                          >
                            {t("mostSoldService")}
                          </button>
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>

                </Col>
              }


           
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Reports
