import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { Routes } from "../../routes"
import emptyImg from "../../assets/img/illustrations/no_content.svg"
import { FormatMoney } from "../../common/helpers"
import Pagination from "../core/Pagination"
import ExportToExcel from "./ExportToExcel"

//Translation
import { useTranslation } from "react-i18next"

const ReceivedPaymentsList = props => {
  const { t } = useTranslation("global")
  const history = useHistory()
  const [Search, setSearch] = useState("")

  const theUser = parsedUser()
  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Customerselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  useEffect(() => {
    request()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const request = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      "&Search=" +
      Search +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage

    // if (CustomerId) {
    //     Query += "&CustomerId=" + CustomerId;
    // }
    if (state.From) {
      Query += "&From=" + moment(state.From).format("YYYY-MM-DD")
    }
    if (state.Until) {
      Query += "&Until=" + moment(state.Until).format("YYYY-MM-DD")
    }
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Invoice/ReceivedPayments", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  const [showExport, setShowExport] = useState(false)
  const toggleExport = () => {
    setShowExport(!showExport)
  }

  return (
    <>
      {showExport ? (
        <ExportToExcel toggle={toggleExport} show={showExport} />
      ) : null}
      <div className="col  mb-4">
        <h5 className="display-4">
          <i className="fas fa-file-invoice"></i>&nbsp;
          {t("received_payments")}
        </h5>
      </div>
      <div className="ml-auto col-auto text-right mb-4">
        <button
          className="btn btn-secondary ms-2"
          onClick={() => {
            toggleExport()
          }}
        >
          {t("export_excel")}
        </button>
      </div>
      <div className="col-12">
        {state.Records.length === 0 ? (
          <div className="row">
            <div className="col-6 mx-auto text-center">
              <img
                src={emptyImg}
                className="img-fluid"
                style={{ maxWidth: "50%" }}
                alt=""
              />
            </div>
          </div>
        ) : null}
        {state.Records.length > 0 ? (
          <div className="card shadow-sm">
            <div className="card-body  ">
              {state.Records.length > 0
                ? state.Records.map((item, index) => {
                    let status =
                      item.invoice.paidStatus === null ||
                      item.invoice.paidStatus === 0
                        ? "pendingPayment"
                        : item.invoice.paidStatus === 1
                        ? "partialPayment"
                        : "completePayment"
                    let path = Routes.CustomerDetails.clean + item.id
                    let total = 0
                    let details = item.invoice.invoiceDetails.filter(
                      x => x.deleted === null
                    )
                    if (details) {
                      details.forEach(detail => {
                        let price = detail.price
                        let discount = detail.discount ?? 0
                        let tax = detail.tax && detail.tax > 0 ? detail.tax : 0
                        let subTotal = price - discount + tax

                        total += subTotal * detail.quantity ?? 0
                      })
                    }
                    if (!item) {
                      return null
                    }
                    return (
                      <div className={"row px-0 "} key={index}>
                        <div
                          className={"col clickable " + status}
                          onClick={() => {
                            //setInvoicePath(`${Routes.InvoicePrint.clean}${item.id}`);
                            //  toggleExport();
                            //history.push(path)
                          }}
                        >
                          <span className="d-inline-block">
                            {item.created ? (
                              <>
                                <small className="text-muted">
                                  {moment(item.date).format("MMM DD, YYYY")}
                                </small>
                                <br />
                              </>
                            ) : null}
                            {!props.CustomerId ? (
                              <>
                                <label>{item.invoice.customerName}</label>

                                <br />
                              </>
                            ) : null}
                            {item.currency.name} {FormatMoney(item.payment)}{" "}
                            <small>
                              {" "}
                              {t("of")} {FormatMoney(total)}
                            </small>
                          </span>
                        </div>
                        <div className="col-auto ml-auto text-right">
                          <span className="badge bg-primary">
                            {item.invoice.invoiceType.name}
                          </span>
                          <br />
                          <small>
                            <b>{item.invoice.invoiceNumber}</b>
                          </small>
                        </div>

                        <div className="col-auto text-right">
                          <div className="btn-group">
                            {/* <button className="btn btn-primary btn-sm"
                                            onClick={() => {
                                              //  setInvoicePath(`${Routes.InvoicePrint.clean}${item.id}?printExecute`);
                                                //toggleExport();
                                                //history.push(path)
                                            }}
                                        >
                                            <i className="fa fa-print" />
                                        </button> */}

                            {/* <button className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                toggleMail(item);
                                            }}
                                        >
                                            <i className="fas fa-paper-plane"></i>
                                        </button> */}
                          </div>
                          <div className="btn-group ms-2">
                            {/* <DropdownButton
                                            size="sm"
                                            drop={"start"}
                                            title={<i className='fa fa-ellipsis-v'></i>}>

                                          
                                        </DropdownButton> */}
                          </div>
                        </div>
                        <div className="col-12">
                          <hr className="my-1" />
                        </div>
                      </div>
                    )
                  })
                : null}
            </div>
            <div className="card-footer overflow-hidden p-1">
              <Pagination
                totalPages={state.TotalPages}
                currentPage={state.currentPage}
                onPageChanged={onPageChanged}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ReceivedPaymentsList
