import moment from "moment"
import React, { useEffect, useState } from "react"

import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"

import emptyImg from "../../assets/img/illustrations/no_content.svg"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { DropdownButton, Dropdown } from "react-bootstrap"
import Pagination from "../core/Pagination"
import { FormatMoney } from "../../common/helpers"
import ExportModal from "../Common/ExportModal"

import Payments from "../Invoices/Payments"
import ExportToExcel from "./ExportToExcel"
import AddEditPayment from "../Invoices/AddEditPayment"

//Translation
import { useTranslation } from "react-i18next"

const AccountReceivableList = props => {
  const history = useHistory()
  const [Search, setSearch] = useState("")
  const [CustomerId, setCustomerId] = useState(props.CustomerId)
  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Customerselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  const theUser = parsedUser()

  const { t } = useTranslation("global")

  useEffect(() => {
    if (theUser === null) {
      window.location = Routes.SignIn.path
    }
    request()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const request = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      "&Search=" +
      Search +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage

    if (CustomerId) {
      Query += "&CustomerId=" + CustomerId
    }
    if (state.From) {
      Query += "&From=" + moment(state.From).format("YYYY-MM-DD")
    }
    if (state.Until) {
      Query += "&Until=" + moment(state.Until).format("YYYY-MM-DD")
    }
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("AccountReceivable/List", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  const [showExportExcel, setShowExportExcel] = useState(false)
  const toggleExportExcel = () => {
    setShowExportExcel(!showExportExcel)
  }

  const markAsPaid = async item => {
    let Query = "id=" + item.id

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("AccountReceivable/MarkAsPaid", Query)

    if (requestAPI.data.status === "ok") {
      await request()
    }
  }

  //const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    history.push(Routes.AddEditInvoice.clean + obj.id)
  }

  const [showInvoiceType, setShowInvoiceType] = useState(false)
  const toggleShowInvoiceType = () => {
    setShowInvoiceType(!showInvoiceType)
  }

  const [showPayments, setShowPayments] = useState(false)

  const togglePayments = async obj => {
    setSelectedItem(obj)
    setShowPayments(!showPayments)
    if (showPayments) {
      await request()
    }
  }
  const [showExport, setShowExport] = useState(false)
  const [InvoicePath, setInvoicePath] = useState("")
  const toggleExport = () => {
    setShowExport(!showExport)
  }

  const [showAddEditPayment, setShowAddEditPayment] = useState(false)
  const toggleAddEditPayment = async (obj, reload) => {
    if (obj) {
      setSelectedItem(obj)

      setPayments(obj.invoicePayment)
    }
    if (reload) {
      await request()
    }
    setShowAddEditPayment(!showAddEditPayment)
  }
  const [payments, setPayments] = useState([])

  return (
    <>
      {showPayments ? (
        <Payments
          toggle={togglePayments}
          show={showPayments}
          id={selectedItem.id}
        />
      ) : null}
      {showExportExcel ? (
        <ExportToExcel toggle={toggleExportExcel} show={showExportExcel} />
      ) : null}
      {/* {showAddEdit ?
            <AddEditCustomer toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} /> : null} */}
      {showExport ? (
        <ExportModal
          path={InvoicePath}
          show={showExport}
          toggle={toggleExport}
        />
      ) : null}
      {showAddEditPayment ? (
        <AddEditPayment
          payments={payments}
          toggle={toggleAddEditPayment}
          model={{
            id: "",
            invoiceId: selectedItem.id,
            payment: null,
            date: moment().format(),
            created: moment().format(),
            deleted: null,
            updated: null,
            createdId: "",
            deletedId: null,
            updatedId: null,
            note: "",
            financialAccountId: null,
            currencyId: "",
            exchangeRate: 1,
            retention: 0,
          }}
          show={showAddEditPayment}
          parentModel={selectedItem}
        />
      ) : null}
      {props.Full ? (
        <div className="row mb-2 mx-0">
          <div className="col ">
            <h5 className="display-4">
              <i className="fas fa-file-invoice"></i>&nbsp;
              {t("accounts_receivables")}
            </h5>
          </div>
          <div className="ml-auto col-auto text-right">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-search"></i>
              </div>
              <input
                type="text"
                className="form-control "
                placeholder={t("filter")}
                value={Search}
                onChange={async e => {
                  setSearch(e.target.value)

                  await request()
                }}
              />
              <div className="input-group-append"></div>
              <DropdownButton
                className="ms-2"
                title={<i className="fa fa-ellipsis-v"></i>}
              >
                <Dropdown.Item
                  type="button"
                  onClick={() => {
                    toggleExportExcel()
                  }}
                >
                  {t("export_excel")}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      ) : null}

      {state.Records.length === 0 ? (
        <div className="row">
          <div className="col-6 mx-auto text-center">
            <img
              src={emptyImg}
              className="img-fluid"
              style={{ maxWidth: "50%" }}
              alt=""
            />
          </div>
        </div>
      ) : null}
      {state.Records.length > 0 ? (
        <div className="card shadow-sm">
          {!props.Full ? (
            <div className="card-header py-1">
              <div className="card-title">
                <i className="fas fa-file-invoice" />
                &nbsp; {t("invoices")}
              </div>
            </div>
          ) : null}
          <div className="card-body  ">
            {state.Records.length > 0
              ? state.Records.map((item, index) => {
                  let status =
                    item.paidStatus === null || item.paidStatus === 0
                      ? "pendingPayment"
                      : item.paidStatus === 1
                      ? "partialPayment"
                      : "completePayment"
                  let path = Routes.CustomerDetails.clean + item.id
                  let total = 0
                  let totalPaid = 0
                  let details = item.invoiceDetails.filter(
                    x => x.deleted === null
                  )
                  if (details) {
                    details.forEach(detail => {
                      let price = detail.price
                      let discount = detail.discount ?? 0
                      let tax = detail.tax && detail.tax > 0 ? detail.tax : 0
                      let subTotal = price - discount + tax

                      total += subTotal * detail.quantity ?? 0
                    })
                  }
                  let paid = 0
                  item.invoicePayment.forEach(payment => {
                    paid += payment.payment
                  })

                  item.invoicePayment &&
                    item.invoicePayment.forEach(payment => {
                      totalPaid += payment.payment
                    })
                  return (
                    <div className={"row px-0 "} key={index}>
                      <div
                        className={"col-6 clickable " + status}
                        onClick={() => {
                          setInvoicePath(
                            `${Routes.InvoicePrint.clean}${item.id}?printExecute`
                          )
                          toggleExport()
                          //history.push(path)
                        }}
                      >
                        <span className="d-inline-block">
                          {item.created ? (
                            <>
                              <small className="text-muted">
                                {moment(item.created).format("MMM DD, YYYY")}
                              </small>
                              <br />
                            </>
                          ) : null}
                          {!props.CustomerId ? (
                            <>
                              <label>{item.customerName}</label>

                              <br />
                            </>
                          ) : null}
                          {item.currency.name}&nbsp;
                          {totalPaid === 0
                            ? FormatMoney(total)
                            : `Total: ${FormatMoney(
                                total
                              )} | Pagado: ${FormatMoney(
                                totalPaid
                              )} | Pendiente: ${FormatMoney(
                                total - totalPaid
                              )}`}
                        </span>
                      </div>
                      <div className="col-2 text-center">
                        <small>
                          <b>
                            {paid !== total && total - paid > 1 ? (
                              <>
                                {paid !== 0 && paid !== total ? (
                                  <>
                                    {FormatMoney(paid)}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                <button
                                  className="btn btn-sm btn-secondary"
                                  onClick={() => toggleAddEditPayment(item)}
                                >
                                  {t("add_payment")}
                                </button>
                              </>
                            ) : (
                              FormatMoney(paid)
                            )}
                          </b>
                        </small>
                      </div>
                      <div className="col ml-auto text-right">
                        <span className="badge bg-primary">
                          {item.invoiceType.name}
                        </span>
                        <br />
                        <small>
                          <b>{item.invoiceNumber}</b>
                        </small>
                      </div>

                      <div className="col-auto text-right">
                        <div className="btn-group">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setInvoicePath(
                                `${Routes.InvoicePrint.clean}${item.id}?printExecute`
                              )
                              toggleExport()
                              //history.push(path)
                            }}
                          >
                            <i className="fa fa-print" />
                          </button>
                        </div>
                        <div className="btn-group ms-2">
                          <DropdownButton
                            id="dropdown-item-button"
                            size="sm"
                            title={<i className="fa fa-ellipsis-v"></i>}
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                togglePayments(item)
                              }}
                            >
                              {t("payments")}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                markAsPaid(item)
                              }}
                            >
                              {t("account_receivable.mark_paid")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr className="my-1" />
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <div className="card-footer overflow-hidden p-1">
            <Pagination
              totalPages={state.TotalPages}
              currentPage={state.currentPage}
              onPageChanged={onPageChanged}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AccountReceivableList
