import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"

const ProviderSummary = props => {
  const [model, setModel] = useState(null)

  const bindRemote = async () => {
    let Query = "ProviderId=" + props.id

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("provider/Summary", Query)

    if (requestAPI.data.status === "ok") {
      setModel(requestAPI.data.response)
    }
  }
  useEffect(()=>{
    bindRemote();
  },[])


  if (!model) {
    return <></>
  }


  return (
    <Card>
      <Card.Body>
        <Row>
          <Col className="text-center">
            <small>Total</small>
            <br />
            <h2>{model.total}</h2>
          </Col>
          <Col className="text-center">
            <small>Pagado</small>
            <br />
            <h2>{FormatMoney( model.paid)}</h2>
          </Col>
          <Col className="text-center">
            <small>Pendiente</small>
            <br />
            <h2>{FormatMoney( model.pending)}</h2>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ProviderSummary
