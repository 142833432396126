import React from "react";
import { Routes } from "../../../routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"


const OrganizationUI = (user: any, organizations: any) => {
  const history = useHistory();
  const { t } = useTranslation("global");
  if (
    user &&
    organizations &&
    organizations.filter((x: any) => x.id === user.OrganizationId)[0]
  ) {
    return (
      <div className="currentOrganization mt-auto text-light align-items-end">
        <hr />
        <div className="row mx-0">
          <div
            className="col-auto clickable p-1 text-truncate"
            onClick={() => {
              history.push(Routes.SelectOrganization.path)
            }}
          >
            <span className="">
              {organizations.filter((x: any) => x.id === user.OrganizationId)[0].name}
            </span>
            <br />
            <small className="text-muted">
              {t("change_organization")}
            </small>
          </div>

        </div>
      </div>
    )
  }
}


export default OrganizationUI