import React, { useEffect } from "react"

import ServiceList from "./ServiceList"
const Index = () => {
  return (
    <div className="row">
      <div className="col-12">
        <ServiceList Full />
      </div>
    </div>
  )
}
export default Index
