//import moment from "moment"
import React, { useEffect, useState } from "react"

//import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { Routes } from "../../routes"
import AddEditProduct from "./AddEditProduct"
import { v4 as uuidv4 } from "uuid"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import { productTypes } from "../../common/helpers"
import Settings from "../../common/Settings"



import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap"

//Translation
import { useTranslation } from "react-i18next"
import Empty from "../Empty"
import Filter from "../core/Filter/Filter"

import ProductListView from "./ProductListView"

import ActionBar from "../core/ActionBar"
import FilterFields from "./FilterFields"
import { useHistory } from "react-router"

const ProductList = props => {
  const history = useHistory()

  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      Customerselected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    TotalRecords:0,
    OnlyPendingToPaid: false,
  })

  const theUser = parsedUser()

  const { t } = useTranslation("global")

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields = FilterFields
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END

  useEffect(() => {
    if (theUser === null) {
      history.push(Routes.SignIn.path)
    }
    bindDataRemote()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const bindDataRemote = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      // "&Search=" +
      // Search +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage

    if (props.productType === productTypes.asset) {
      Query += "&IsAnActive=true"
    }

    if (filterObj.containerId) {
      Query += "&ContainerId=" + filterObj.containerId
    }

    if (filterObj.warehouseId) {
      Query += "&warehouseId=" + filterObj.warehouseId
    }

    if (filterObj.currencyId) {
      Query += "&currencyId=" + filterObj.currencyId
    }
    if (filterObj.search) {
      Query += "&search=" + filterObj.search
    }
    let requestAPI = await API.getAction("Products/List?" + Query)

    console.log("API REQUEST", requestAPI.data)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalRecords: requestAPI.data.quantityOfRecords,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }
  
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    setSelectedItem(obj)
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      await bindDataRemote()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()
    //debugger
    confirmAlert({
      title: t("confirm")??"",
      message: t("questions.sure_delete") + obj.product.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "products/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: t("no"),
               onClick: () => console.log('Click No')
        },
      ],
    })
  }
  
  return (
    <>
      {showAddEdit && (
        <AddEditProduct
          toggle={toggleAddEdit}
          show={true}
          model={selectedItem}
          productType={props.productType}
        />
      )}
     
    

      <Row>
        <Col xs={12} className="mb-4">
          <ActionBar
            title={
              props.productType === productTypes.asset
                ? t("assets")
                : t("products")
            }
            icon={"fas fa-boxes"}
            actions={
              <>
                <button
                  className="btn btn-primary btn-sm me-2"
                  type="button"
                  onClick={() => {
                    // TODO eventually update this modal

                    toggleAddEdit({
                      id: uuidv4(),
                      organizationId: "",
                      description: "",
                      price: 0,
                      maxDiscountPercentage: 0,
                      created: null,
                      deleted: null,
                      serviceTaxes: [],
                    })
                    //toggleAddEdit()
                  }}
                >
                  <i className="fa fa-plus " />
                </button>
                <button
                  className={
                    "btn  btn-sm me-2  " + (showFilter ? "btn-secondary" : "")
                  }
                  onClick={() => toggleFilter()}
                >
                  <i className="fa fa-filter" />
                </button>

            
                <DropdownButton
                  align="start"
                  size="sm"
                  id="mainMore"
                  className="ms-2 d-inline"
                  title={<i className="fa fa-ellipsis-v"></i>}
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => {
                      window.open(
                        Settings.BasePathForAPI +
                          "service/exportToExcel?OrganizationId=" +
                          theUser.OrganizationId,
                        "_target"
                      )
                    }}
                  >
                    {t("export_excel")}
                  </Dropdown.Item>
                </DropdownButton>
              </>
            }
          ></ActionBar>
        </Col>
        {showFilter ? (
          <Col xs={12}>
            <Filter
              filterAction={toggleFilter}
              fields={filterFields}
              setFilterFields={getFilterValues}
            />
          </Col>
        ) : null}

        {state.Records.length === 0 ? <Empty /> : null}
        {state.Records.length > 0 ? (
          <Col xs={12}>
            <ProductListView
              records={state.Records}
              TotalPages={state.TotalPages}
              TotalRecords={state.TotalRecords}
              currentPage={state.currentPage}
              onPageChanged={onPageChanged}
              toggleAddEdit={toggleAddEdit}
              deleteConfirm={deleteConfirm}
            />
          </Col>
        ) : null}
      </Row>
    </>
  )
}

export default ProductList
