

import AdminUsersComponent from "../components/Admin/AdminUsersComponent";
import Assets from "../components/Products/assets";



const AdminUsersPage = () => {
    return (<AdminUsersComponent />)
}

export default AdminUsersPage;