import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form, Card, Container } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"
import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6
import {
  DropdownMenu,
  DropdownItem,
  DropdownHeader,
  DropdownDivider,
  Dropdown,
  Input,
} from "semantic-ui-react"
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead" // ES2015

import "react-bootstrap-typeahead/css/Typeahead.css"

import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import API from "../../common/API"
import { makeId, onlyHTMLTags } from "../../common/helpers"
import { parsedUser } from "../../common/GetCurrentUser"
import { confirmAlert } from "react-confirm-alert"

import { db } from "../../common/db"

//Translation
import { useTranslation } from "react-i18next"
import Calendar from "./Calendar"

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
  date: yup.string().required(),
  status: yup.number().required(),
  type: yup.number().required(),
})
//Component
const AddEditActivity = props => {
  const { t } = useTranslation("global")

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  })
  const [invitees, setInvitees] = useState([])
  const [inviteesOpts, setInviteesOpts] = useState([])
  const [projects, setProjects] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [showMinute, setShowMinute] = useState(
    props.model
      ? props.model.minute && props.model.minute.length > 0
        ? true
        : false
      : false
  )

  const [model, setModel] = useState(
    props.model
      ? props.model
      : {
          name: "",
          notes: "",

          status: 0,
          createdId: null,
          updatedId: "",
          invites: "",
          location: "",
          date: moment().format("YYYY-MM-DDTHH:mm"),
          endDate: moment(moment().format("YYYY-MM-DD"))
            .add(8, "hours")
            .add(30, "minutes")
            .format(),
          created: moment().format(),
          id: "",
          organizationId: null,
          appointmentTypeId: null,
          assignedToId: null,
          projectId: props.projectId,
          type: 1,
          //  duration: duration
        }
  )

  const [singleSelections, setSingleSelections] = useState([])
  const [options, setOptions] = useState([])
  const user = parsedUser()
  useEffect(() => {
    console.log("props received on activity", props)

    setModel({
      ...model,
      organizationId: user.OrganizationId,
      assignedToId: user.ID,
    })

    if (model && model.assignedTo) {
      //find the user
      setSingleSelections([
        {
          id: model.assignedTo.assignedToId,
          label: model.assignedTo.email,
        },
      ])
    } else {
      //Select current user by default
      setSingleSelections([
        {
          id: user.ID,
          label: user.Email,
        },
      ])
    }

    //Invitees
    if (model.invitees) {
      let listInvitees = model.invitees.split(",")
      let editableInvitees = []
      listInvitees.forEach(item => {
        editableInvitees.push({
          id: item,
          label: item,
        })
      })

      setInvitees(editableInvitees)
    }

    //    projectRequest();
    //  requestOrganizations();

    //Focus
    setTimeout(() => {
      let focus = document.getElementsByClassName("name")[0]
      if (focus) {
        focus.focus()
      }
    }, 1000)
  }, [])

  const onDateSelect = obj => {
    console.log(obj)
  }
  //Load all the projects

  // const projectRequest = async () => {
  //   let user = parsedUser()
  //   let projectsLocal = await db.projects
  //     .where({
  //       organizationId: props.model.organizationId
  //         ? props.model.organizationId
  //         : user.OrganizationId,
  //     })
  //     .toArray()
  //   projectsLocal = projectsLocal.sort((a, b) => {
  //     return a.name.localeCompare(b.name)
  //   })

  //   setProjects(projectsLocal)
  // }

  // const requestOrganizations = async () => {
  //   let theUser = parsedUser()

  //   if (theUser === null) {
  //     return
  //   }
  //   let requestAPI = await API.getAction(
  //     "Organization/OrganizationsWhereTheUserBelong?AccountId=" + theUser.ID
  //   )

  //   if (requestAPI.data.status === "ok") {
  //     setOrganizations(requestAPI.data.response)
  //   }
  // }

  const handleSubmit = async obj => {
    try {
      console.log("values to submit", obj)

      if (obj.id.length === 0) {
        obj.id = uuidv4()
        obj.created = moment().format()
      }

      let parsed = parsedUser()

      //Load the invitees
      let theInvitees = ""
      invitees.forEach(item => {
        theInvitees += (theInvitees.length > 0 ? "," : "") + item.label
      })
      obj.organizationId = parsed.OrganizationId
      obj.invitees = theInvitees
      obj.projectId = obj.projectId === null ? props.projectId : obj.projectId
      var parsedDate = moment(obj.date).format()
      obj.date = parsedDate.replace("+00:00", "-04:00")

      if (obj.endDate) {
        var parsedEndDate = moment(obj.endDate).format()
        obj.endDate = parsedEndDate.replace("+00:00", "-04:00")
      } else {
        obj.allDay = true
      }

      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: !state.UI.isLoading,
        },
      })

      let request = await API.postAction(
        "Appointments/AddEdit?CurrentUserId=" + parsed.ID,
        obj
      )
      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: !state.UI.isLoading,
        },
      })

      if (request.status === 200) {
        props.toggle(request.data, "", true)
      }
    } catch (ex) {
      console.error(ex)
      setState({
        ...state,
        UI: {
          ...state.UI,
          isLoading: false,
        },
      })
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: model,
    validationSchema,
    onSubmit: handleSubmit,
  })
  const handleSearchUsers = async query => {
    var theUser = parsedUser()
    var request = await API.getAction(
      "users/AutoCompleteSearch?Search=" +
        query +
        "&OrganizationId=" +
        theUser.OrganizationId
    )
    if (request.data.status === "ok") {
      let opts = request.data.response.map(e => ({
        id: e.id,
        label: e.firstName + " " + (e.lastName ?? "") + " (" + e.email + ")",
      }))

      setOptions(opts)
    }
  }

  const toggleRecurrency = () => {
    if (formik.values.recurrence > 0) {
      formik.setFieldValue("recurrence", null)
    } else {
      formik.setFieldValue("recurrence", 2)
    }
  }
  const handleSearchInvitees = async query => {
    var theUser = parsedUser()
    var request = await API.getAction(
      "appointments/InviteesAutoCompleteSearch?Search=" +
        query +
        "&OrganizationId=" +
        theUser.OrganizationId
    )
    if (request.data.status === "ok") {
      let opts = request.data.response.map(e => ({
        id: e,
        label: e,
      }))
      setInviteesOpts(opts)
    }
  }

  const deleteConfirm = async () => {
    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + props.model.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction("appointments/Delete?id=" + props.model.id)
            props.toggle(props.model, "delete", true)
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }
  // const onDateSelect = obj => {
  //   console.log(obj);
  // }
  const formUI = () => {
    return (
      <Card className="bg-white">
        <Card.Body className="p-1 py-2">
          <Container>
            <Row>
              {props.unified === true ? (
                <Col xs={6}>
                  <label>{t("organization")}</label>
                  <select
                    value={formik.values.organizationId}
                    onChange={formik.handleChange}
                    className="form-control"
                    id="organizationId"
                  >
                    <option value="">-</option>
                    {organizations &&
                      organizations.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                  </select>

                  {formik.errors.organizationId ? (
                    <div className="invalid text-sm">
                      {formik.errors.organizationId}
                    </div>
                  ) : null}
                </Col>
              ) : null}
              <Col xs={6} className="d-none">
                <label>{t("project")}</label>
                <select
                  value={formik.values.projectId}
                  onChange={formik.handleChange}
                  className="form-control"
                  id="projectId"
                >
                  <option value="">-</option>
                  {projects &&
                    projects.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      )
                    })}
                </select>

                {formik.errors.projectId ? (
                  <div className="invalid text-sm">
                    {formik.errors.projectId}
                  </div>
                ) : null}
              </Col>
              <Col xs={12}>
                <label>{t("name")}</label>
                <Form.Control
                  type="text"
                  className="name"
                  id="name"
                  maxLength={50}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name ? (
                  <div className="invalid text-sm">{formik.errors.name}</div>
                ) : null}
              </Col>
              <Col xs={8} md={6} className="mt-2">
                <label>{t("date")}</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="date"
                  value={moment(formik.values.date).format("YYYY-MM-DDTHH:mm")}
                  onChange={formik.handleChange}
                ></input>

                {formik.errors.date ? (
                  <div className="invalid text-sm">{formik.errors.date}</div>
                ) : null}
              </Col>

              <Col xs={4} md={4} className="mt-2">
                <label>{t("duration")}</label>
                <input
                  type="time"
                  className="form-control"
                  name="endDate"
                  value={
                    formik.values.endDate
                      ? moment(formik.values.endDate).format("HH:mm")
                      : moment(formik.values.date).format("HH:mm")
                  }
                  onChange={formik.handleChange}
                ></input>

                {formik.errors.endDate ? (
                  <div className="invalid text-sm">{formik.errors.endDate}</div>
                ) : null}

                {formik.errors.endDate ? (
                  <div className="invalid text-sm">{formik.errors.endDate}</div>
                ) : null}
              </Col>

              <Col xs={12} className="mt-2">
                <label>{t("assigned_to")}</label>

                <AsyncTypeahead
                  id="basic-typeahead-single"
                  labelKey="label"
                  isLoading={state.UI.isLoading}
                  onChange={e => {
                    setSingleSelections(e)
                    if (e[0] !== undefined) {
                      formik.setFieldValue("assignedToId", e[0].id)
                    }
                  }}
                  options={options}
                  minLength={2}
                  onSearch={handleSearchUsers}
                  placeholder={t("select_user")}
                  selected={singleSelections}
                />

                {formik.errors.assignedToId ? (
                  <div className="invalid text-sm">
                    {formik.errors.assignedToId}
                  </div>
                ) : null}
              </Col>
              <Col xs={4} className="mt-2 d-none">
                <label>{t("status")}</label>
                <select
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  className="form-control"
                  name="status"
                >
                  <option value="">-</option>
                  <option value="0">{t("to_be_done")}</option>
                  <option value="1">{t("in_progress")}</option>
                  <option value="2">{t("Completed")}</option>
                </select>

                {formik.errors.status ? (
                  <div className="invalid text-sm">{formik.errors.status}</div>
                ) : null}
              </Col>
              <Col xs={12} className="mt-2">
                <label>{t("guests")}</label>
                <AsyncTypeahead
                  id="basic-typeahead-singleInvitees"
                  labelKey="label"
                  allowNew
                  multiple
                  isLoading={state.UI.isLoading}
                  onChange={e => {
                    setInvitees(e)
                    if (e[0] !== undefined) {
                      formik.setFieldValue("invitees", e[0].id)
                    }
                  }}
                  options={inviteesOpts}
                  minLength={2}
                  onSearch={handleSearchInvitees}
                  placeholder={t("email")}
                  selected={invitees}
                />

                {formik.errors.invitees ? (
                  <div className="invalid text-sm">
                    {formik.errors.invitees}
                  </div>
                ) : null}
              </Col>
              <Col xs={12} className="mt-2">
                <label>{t("ubication")}</label>
                <button
                  className="btn  btn-sm mx-1"
                  type="button"
                  title={t("generate_link")}
                  onClick={() => {
                    let id = makeId(5)
                    formik.setFieldValue(
                      "location",
                      `${window.location.protocol}//${window.location.hostname}:${window.location.port}/m/${id}`
                    )
                  }}
                >
                  <i className="fas fa-retweet me-2"></i>
                  {t("generate_link")}
                </button>
                <button
                  title="Open link"
                  className="btn btn-sm"
                  type="button"
                  onClick={() => {
                    window.open(formik.values.location)
                  }}
                >
                  <i className="fas fa-external-link-alt"></i> {t("open")}
                </button>
                <div className="input-group">
                  <Form.Control
                    type="text"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.errors.location ? (
                  <div className="invalid text-sm">
                    {formik.errors.location}
                  </div>
                ) : null}
              </Col>
              {/* RECURRENT TASK */}
              <Col xs={12} className="mt-4">
                <label htmlFor="isRecurrent">{t("recurring_activity")}</label>
                <input
                  type="checkbox"
                  checked={formik.values.recurrence}
                  id="isRecurrent"
                  className="mx-2"
                  onClick={() => toggleRecurrency()}
                />
              </Col>
              {formik.values.recurrence > 0 ? (
                <>
                  <Col xs={6} className="mt-4">
                    <label>{t("occurs")}</label>
                    <select
                      className="form-control"
                      value={formik.values.recurrence}
                      onChange={formik.handleChange}
                    >
                      <option value="">-</option>
                      <option value="1">{t("diary")}</option>
                      <option value="2">{t("weekly")}</option>
                      <option value="3">{t("monthly")}</option>
                      <option value="4">{t("annual")}</option>
                    </select>
                  </Col>

                  <Col xs={6} className="mt-4">
                    <label>{t("until")}</label>
                    <input
                      type="date"
                      className="form-control"
                      value={formik.values.untilDate}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </>
              ) : null}
              <Col xs={12} className="mt-2">
                <label>{t("notes")}</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={formik.values.notes}
                  onChange={e => {
                    let onlyTags = onlyHTMLTags(e.target.value)
                    formik.setFieldValue(
                      "notes",
                      onlyTags ? "" : e.target.value
                    )
                  }}
                ></textarea>

                {formik.errors.notes ? (
                  <div className="invalid text-sm">{formik.errors.notes}</div>
                ) : null}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    )
  }

  const tagOptions = [
    {
      key: "Important",
      text: "Important",
      value: "Important",
      label: { color: "red", empty: true, circular: true },
    },
    {
      key: "Announcement",
      text: "Announcement",
      value: "Announcement",
      label: { color: "blue", empty: true, circular: true },
    },
    {
      key: "Cannot Fix",
      text: "Cannot Fix",
      value: "Cannot Fix",
      label: { color: "black", empty: true, circular: true },
    },
    {
      key: "News",
      text: "News",
      value: "News",
      label: { color: "purple", empty: true, circular: true },
    },
    {
      key: "Enhancement",
      text: "Enhancement",
      value: "Enhancement",
      label: { color: "orange", empty: true, circular: true },
    },
    {
      key: "Change Declined",
      text: "Change Declined",
      value: "Change Declined",
      label: { empty: true, circular: true },
    },
    {
      key: "Off Topic",
      text: "Off Topic",
      value: "Off Topic",
      label: { color: "yellow", empty: true, circular: true },
    },
    {
      key: "Interesting",
      text: "Interesting",
      value: "Interesting",
      label: { color: "pink", empty: true, circular: true },
    },
    {
      key: "Discussion",
      text: "Discussion",
      value: "Discussion",
      label: { color: "green", empty: true, circular: true },
    },
  ]

  return (
    <Modal
      className="addActivityModal"
      show={props.show}
      onHide={props.toggle}
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("activity")}</Modal.Title>
        <button
          className={
            (showMinute ? "btn-primary" : "btn-secondary") + " btn btn-sm ms-2"
          }
          onClick={() => {
            setShowMinute(!showMinute)
          }}
        >
          {t("minuta")}
        </button>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={8}>
              {formUI()}
            </Col>
            {/* SECOND CAlendar */}
            <Col xs={12} md={4} className="mt-2">
              <Calendar
                mode="timeGridDay"
                currentDate={formik.values.date}
                onClick={onDateSelect}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="row"
            style={{
              width: "100%",
            }}
          >
            <div className="col-6">
              {props.mode !== "add" ? (
                <Button
                  variant="danger"
                  type="button"
                  className="btn-sm"
                  disabled={state.UI.isLoading}
                  onClick={() => deleteConfirm()}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              ) : null}

              <Dropdown text="Cliente" className="ms-2" inline labeled button>
                <DropdownMenu>
                  <Input
                    icon="search"
                    iconPosition="left"
                    className="search"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  />
                  <DropdownDivider />
                  <DropdownHeader icon="tags" content="Tag Label" />
                  <DropdownMenu scrolling>
                    {tagOptions.map(option => (
                      <DropdownItem key={option.value} {...option} />
                    ))}
                  </DropdownMenu>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="col-6 text-right">
              <Button
                variant="primary"
                type="submit"
                className="btn-sm"
                disabled={state.UI.isLoading}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditActivity
