import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"

import { Routes } from "../../routes"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

interface PropsDTO {
  hr?: boolean
  workshop?: boolean
  finance?: boolean
}

const SubscriptionStatusComponent = (props: PropsDTO) => {
  const history = useHistory()
  const [subscription, setSubscription] = useState<any>(null)
  const [organization, setOrganization] = useState<any>(null)
  const [nextBillingDate, setNextBillingDate] = useState(null)
  const [diffDays, setDiffDays] = useState<number| null>(null)
  const { t } = useTranslation("global")
  const user = parsedUser()

  const bindDataRemote = async () => {
    if (!user) {
      history.push(Routes.SignIn.path)
      return
    }
    let Query = "OrganizationId=" + user.OrganizationId
    const request = await API.getAction("Subscription/ForOrganization", Query)

    console.log(request.data.status, request.data.response)

    setSubscription(
      request.data.status === "error" ? null : request.data.response
    )
    if (
      request.data.status === "error" &&
      request.data.response === "no_subscription"
    ) {
      history.push(Routes.SelectSubscription.path)
    }

    // Now let's check what the organization can see
    const QueryOrganization = "id=" + user.OrganizationId
    const requestOrganization = await API.getAction(
      "Organization/OrganizationSubscription",
      QueryOrganization
    )

    console.log(
      requestOrganization.data.status,
      requestOrganization.data.response
    )
    let theOrganization = requestOrganization.data.response
    setOrganization(theOrganization)
    // CHECKS
    const msg = "Your current subscription does not have this module enabled"
    if (props.finance && props.hr && props.workshop) {
      //Dont do anything
    } else if (props.finance) {
      if (!theOrganization.modules?.includes("finance")) {
        confirmAlert({
          closeOnClickOutside: false,
          message: t(msg) ?? "",
          buttons: [
            {
              label: t("ok"),
              onClick: async () => {
                history.push(Routes.SelectSubscription.path)
              },
            },
          ],
        })
      }
    } else if (props.hr) {
      if (!theOrganization.modules?.includes("hr")) {
        confirmAlert({
          closeOnClickOutside: false,
          message: t(msg) ?? "",
          buttons: [
            {
              label: t("ok"),
              onClick: async () => {
                history.push(Routes.SelectSubscription.path)
              },
            },
          ],
        })
      }
    } else if (props.workshop) {
      if (!theOrganization.modules?.includes("workshop")) {
        confirmAlert({
          closeOnClickOutside: false,
          message: t(msg) ?? "",
          buttons: [
            {
              label: t("ok"),
              onClick: async () => {
                history.push(Routes.SelectSubscription.path)
              },
            },
          ],
        })
      }
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])

  useEffect(() => {
    console.log(subscription)
    setNextBillingDate(subscription?.nextBillingDate)
  }, [subscription])

  useEffect(() => {
    if (nextBillingDate) {
      let theDate = moment(nextBillingDate)
      let now = moment()
      let diff = theDate.diff(now, "days")
      setDiffDays(diff)
      console.log(nextBillingDate, diff, diff < -7)
      if (diff < -7) {
        history.push(Routes.SelectSubscription.path)
      }
    }
  }, [nextBillingDate])
console.log(nextBillingDate,diffDays  )
  // check if the next billing date is prior to today
  // if so, then show the renew button

  
    if (moment(nextBillingDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") && diffDays != null && diffDays >=0) {
      return (
        <div className="alert alert-danger" role="alert">
          {t("Your subscription has expired")}{" "}
          {moment(nextBillingDate).toNow()}
          <button
            className="btn btn-primary btn-sm ms-2"
            type="button"
            onClick={() => history.push(Routes.SelectSubscription.path)}
          >
            {t("renew")}
          </button>
        </div>
      )
  
  }
  return <></>
}

export default SubscriptionStatusComponent
