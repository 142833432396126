import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
})
//Component
const AddEditTax = props => {
  const { t } = useTranslation("global")
  const [ledgerAccounts, setLedgerAccounts] = useState([])
  const state = useState({
    UI: {
      Validated: false,
    },
    Departments: [],
  })

  const user = parsedUser()

  const loadLedgerAccounts = async term => {
    let query = `&organizationid=${user.OrganizationId}&nature=debit` //" + user.OrganizationId;
    let request = await API.getAction("ledgerAccounts/List?" + query)
    if (request.data.status === "ok") {
      setLedgerAccounts(request.data.response)
    }
  }

  useEffect(() => {
    loadLedgerAccounts()
  }, [])

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    let request = await API.postAction(
      "Taxes/AddEdit?currentUserId=" + user.ID,
      obj
    )

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model
      ? props.model
      : {
          name: "",
          percentage: 0,
          id: "",
          organizationId: "",
          ledgerAccountId: null,
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("taxe")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <div className="col-12">
              <Form.Label>{t("percentage")}</Form.Label>
              <input
                type="number"
                min={0}
                name="percentage"
                max={100}
                value={formik.values.percentage}
                onChange={formik.handleChange}
                className="form-control"
              />
              {formik.errors.percentage ? (
                <div className="invalid text-sm">
                  {formik.errors.percentage}
                </div>
              ) : null}
            </div>
            <div className="col-12">
              <label>{t("accounting_account")}</label>
              <select
                className="form-control"
                name="ledgerAccountId"
                value={formik.values.ledgerAccountId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {ledgerAccounts.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.reference + "-" + item.account}
                    </option>
                  )
                })}
              </select>

              {formik.errors.ledgerAccountId ? (
                <div className="invalid text-sm">
                  {formik.errors.ledgerAccountId}
                </div>
              ) : null}
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" className="btn-sm">
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditTax
