import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6
import { onlyHTMLTags } from "../../common/helpers"
import API from "../../common/API"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"

//Validation
const validationSchema = yup.object({
  title: yup.string().required("required"),
  content: yup.string().required(),
})
//Component
const AddEditPost = props => {
  //   const state = useState({
  //     UI: {
  //       isLoading: false,
  //     },
  //   })

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  })

  useEffect(() => {
    console.log("props received on post", props)
  }, [])

  const handleSubmit = async obj => {
    console.log("values to submit", obj)
    let user = parsedUser()
    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
      obj.creatorID = user.ID
    } else {
      obj.UpdateID = user.ID
    }
    let request = await API.postAction("Post/AddEdit", obj)
    if (request.status === 200) {
      props.toggle(false)
    }
  }

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          title: "",
          content: "",
          created: new Date(),
          id: "",
          OrganizationId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Post</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title ? (
                <div className="invalid text-sm">{formik.errors.title}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>Content</Form.Label>

              <ReactQuill
                value={formik.values.content}
                name="content"
                onChange={e => {
                  let onlyTags = onlyHTMLTags(e)
                  formik.setFieldValue("content", onlyTags ? "" : e)
                }}
              />
              {formik.errors.content ? (
                <div className="invalid text-sm">{formik.errors.content}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.toggle} className="btn-sm">
            Close
          </Button>
          <Button variant="primary" type="submit" className="btn-sm">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditPost
