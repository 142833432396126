import React, { useState /*useEffect*/ } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"
//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
})
//Component
const AddEditCurrency = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
    Departments: [],
  })

  const user = parsedUser()

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    // state.UI.isLoading.set(!state.UI.isLoading.value)
    setState({ ...state, UI: { isLoading: true } })

    let request = await API.postAction(
      "Currencies/AddEdit?currentUserId=" + user.ID,
      obj
    )
    setState({ ...state, UI: { isLoading: false } })

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          created: new Date(),
          id: "",
          organizationId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Monedas</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditCurrency
