import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const WorkshopStatusComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("workshopStatus") ?? ""}
      getRecordsPath={`workshop/ListStatus?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`workshop/AddEditStatus?currentUserId=${user.ID}`}
      deleteRecordPath="workshop/DeleteStatus"
      filterFields={[  {
        name: "name",
        htmlType: "text",
        dbType: "string",
        title: t("Name") ?? "",
        containerClass: "col-4",
        required: true,
      }]}
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },
        {
          name: "color",
          title: t("color"),
          htmlType: "color",
          cssClass: 'text-center'
        },
        {
          name: "order",
          title: t("order"),
          cssClass: 'text-center'
        },
        {
          name: "notifyClient",
          title: t("notifyClient"),
          htmlType: "bool",
          cssClass: 'text-center'
        },
        {
          name: "notifyWhenIn",
          title: t("notifyWhenIn"),
          htmlType: "string",
          cssClass: 'text-center'
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "color",
          htmlType: "color",
          dbType: "string",
          title: t("Color") ?? "",
          containerClass: "col-6",
          required: true,
        },

        {
          name: "order",
          htmlType: "number",
          dbType: "int",
          title: t("Order") ?? "",
          containerClass: "col-6",
          required: false,
        },
        {
          name: "notifyClient",
          htmlType: "bool",
          dbType: "bit",
          title: t("notifyClient") ?? "",
          containerClass: "col-6",
          required: false,
        },

        {
          name: "NotifyWhenIn",
          htmlType: "text",
          dbType: "string",
          title: t("notifyWhenIn") ?? "",
          containerClass: "col-6",
          required: false,
        },
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default WorkshopStatusComponent
