
import moment from 'moment';
import React, { useEffect , useState} from 'react';

import { useHistory } from 'react-router';
import API from '../../../common/API';
import { parsedUser } from '../../../common/GetCurrentUser';
import { Routes } from '../../../routes';


const Birthdates = props => {
    const history = useHistory();
    const [state, setState] = useState({
        Records: {
            today:[],
            upcoming:[]
        },
        UI: {
            IsLoading: false,
            showAddEdit: false,
            Bankselected: null
        }
    })
    const theUser = parsedUser();
    useEffect(() => {
        if(theUser=== null){
            window.location = (Routes.Login.path)
        }
        request();
    }, []);


    const request = async () => {
        let requestAPI = await API.getAction("employees/Birthdays?OrganizationId=" + theUser.OrganizationId);

        if (requestAPI.data.status === "ok") {
            
            
            setState({
                ...state, 
                Records: requestAPI.data.response
            });
        }
    };
  

    return (<>
       
   
            <div className="card shadow-sm">

                {!props.Full ?
                    <div className="card-header py-1">
                        <div className="card-title">
                        <i className="fas fa-birthday-cake"></i>&nbsp;
                            Cumpleaños</div>
                    </div>
                    : null}
                <div className="card-body  ">
                    {state.Records &&  state.Records.today !== undefined && state.Records.today.length>0?
                    <>
                    <b>Hoy</b>
                    <hr className="my-1"/>
                        {state.Records.today.map((item, index)=>{
                            
                            return(<small key={index}>{item.firstName + " " + item.lastName+" ("+moment(item.birthdate).format("DD MMMM")+")"}</small>)
                        })}
                    </>:null}
                    {state.Records &&  state.Records.upcoming !== undefined && state.Records.upcoming.length>0?
                    <>
                        {state.Records.upcoming.map((item, index)=>{
                            
                            return(<small key={index}>{index>0? <br/>:null}{item.firstName + " " + item.lastName+" ("+moment(item.birthdate).format("DD MMMM")+")"}</small>)
                            
                        })}
                    </>:null}
                    
                   
                </div>
            </div>
    </>);
}

export default Birthdates;