import React, { useContext, useEffect, useState } from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { userAvatar } from "../../../common/helpers"
import { parsedUser } from "../../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"
import { Routes } from "../../../routes"
import ClearUser from "../../../common/ClearUser"
import { db } from "../../../common/db"
import { useHistory } from "react-router-dom"
import { SubscriptionContext } from "../../../Context/Subscription"

const OmniBar = props => {
  const user = parsedUser()
  const { t } = useTranslation("global")
  const history = useHistory()

  const hasSubscriptionContext = useContext(SubscriptionContext)

  if (!props.settings || hasSubscriptionContext.currentRole === null) {
    return <></>
  }

  return (
    <>
      <div className="omniBar d-sm-none d-md-block d-lg-block text-dark">
        <DropdownButton
          align="end"
          className="p-0"
          title={
            <div className="bg-secondary  d-flex align-items-center justify-content-center rounded"
            style={{
              width:"40px",
              height:"40px"
            }}
            >
              {user && user.Email.substring(0, 1)}
              </div>
           
          }
        >
          <Dropdown.Item
            className="fw-bold"
            onClick={() => history.push(Routes.Profile.path)}
          >
            <i className="fa fa-user-circle me-2"></i> {t("profile")}
          </Dropdown.Item>

          <Dropdown.Item
            className="fw-bold"
            as="button"
            onClick={() => {
              window.open("mailto:info@valegos.com", "_blank")
            }}
          >
            <i className="far fa-life-ring me-2"></i> {t("help")}
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            className="fw-bold"
            onClick={() => {
              ClearUser()
              history.push(Routes.SignIn.path)
            }}
          >
            <i className="fas fa-sign-out-alt me-2"></i>
            {t("logout")}
          </Dropdown.Item>
        </DropdownButton>
        {hasSubscriptionContext &&
          hasSubscriptionContext.currentRole?.finance > 0 && (
            <button
              className={
                (props.settings.application === "finance" ? "active " : "") +
                "btn btn-sm btn-block"
              }
              style={{ color: "palegoldenrod" }}
              onClick={async () => {
                let settings = props.settings
                settings.application = "finance"
                await db.settings.put(settings)
                history.push(Routes.Home.path)
              }}
            >
              <i className="fas fa-cash-register"></i>
              <br />
              <small>{t("finance")}</small>
            </button>
          )}

        {hasSubscriptionContext &&
          hasSubscriptionContext.currentRole?.hr > 0 && (
            <button
              className={
                (props.settings.application === "hr" ? "active " : "") +
                "btn btn-sm btn-block"
              }
              style={{ color: "deepskyblue" }}
              onClick={async () => {
                let settings = props.settings
                settings.application = "hr"
                await db.settings.put(settings)
                history.push(Routes.DashboardPayroll.path)
              }}
            >
              <i className="fas fa-user-friends"></i>
              <br />
              <small>{t("hr")}</small>
            </button>
          )}
        {hasSubscriptionContext &&
          hasSubscriptionContext.currentRole?.workshop > 0 && (
            <button
              className={
                (props.settings.application === "workshop" ? "active " : "") +
                "btn btn-sm btn-block"
              }
              style={{ color: "orangered" }}
              onClick={async () => {
                let settings = props.settings
                settings.application = "workshop"
                await db.settings.put(settings)
                history.push(Routes.WorkshopMaintenance.path)
              }}
            >
              <i className="fas fa-toolbox"></i>
              <br />
              <small>{t("workshop")}</small>
            </button>
          )}

        {user.RoleId === "admin" && (
          <button
            className={
              (props.settings.application === "admin" ? "active " : "") +
              "btn btn-sm btn-block"
            }
            style={{ color: "yellow" }}
            onClick={async () => {
              let settings = props.settings
              settings.application = "admin"
              await db.settings.put(settings)
              history.push(Routes.WorkshopMaintenance.path)
            }}
          >
            <i className="fas  fa-bolt"></i>
            <br />
            <small>{t("Admin")}</small>
          </button>
        )}

        {/* <button
          className={
            (props.settings.application === "operations" ? "active " : "") +
            "btn btn-sm btn-block"
          }
          style={{ color: "orangeRed" }}
          onClick={async () => {
            let settings = props.settings
            settings.application = "operations"
            await db.settings.put(settings)
            history.push(Routes.DashboardPayroll.path)
          }}
        >
          <i className="fas fa-toolbox"></i>
          <br />
          <small>{t("operations_short")}</small>
        </button> */}
      </div>
    </>
  )
}

export default OmniBar
