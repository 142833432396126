import React, { useEffect } from "react"
import { Routes } from "../../routes"

import QuoteList from "./QuoteList"

//Translation
import { useTranslation } from "react-i18next"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("quotes")
  })
  return (
    <div className="row">

      <div className="col-12">
        <QuoteList Full />
      </div>
    </div>
  )
}
export default Index
