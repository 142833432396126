import React, { useState, useEffect, createRef } from "react"
import FullCalendar from "@fullcalendar/react"
// import listPlugin from "@fullcalendar/list"
import dayGridPlugin from "@fullcalendar/daygrid" // a plugin!

import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction" // a plugin!
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import moment from "moment"
import AddEditActivity from "../Agenda/AddEditActivity"

const Calendar = props => {
  var theUser = parsedUser()
  const fullCalendarRef = createRef()
  const [activityMode, setActivityMode] = useState("add")
  const [CurrentDate, setCurrentDate] = useState(new Date())
  const [showAddEdit, setShowAddEdit] = useState(false)

  const [state, setState] = useState({
    Events: [],
    UI: {
      OnlyForTheUser: true,
      ActivitySelected: null,
      selectedId: null,
    },
  })

  const request = async () => {
    let currentUser = parsedUser()
    let query = ""

    if (state.UI.OnlyForTheUser) {
      query = "AssignedToId=" + currentUser.ID
    } else {
      query = "OrganizationId=" + currentUser.OrganizationId + "&AssignedToId="
    }

    let requestAPI = await API.getAction("Appointments/List?" + query)

    if (requestAPI.data.status === "ok") {
      let parsedEvents = []
      requestAPI.data.response.forEach(item => {
        // debugger
        parsedEvents.push({
          id: item.id,
          date: item.date,
          title: item.name,
        })
      })
      setState({
        ...state,
        Events: parsedEvents,
      })
    }
  }

  const moveMonth = obj => {
    let d = moment(CurrentDate).add(obj, "months")
    setCurrentDate(d.format())
    if (fullCalendarRef.current !== null) {
      fullCalendarRef.current.getApi().gotoDate(d.format())
      fullCalendarRef.current.getApi().select(d.format())
    }
    //We must request current month events :)
  }

  useEffect(() => {
    request()
  }, [])
  useEffect(() => {
    console.log(props)
    if (props.mode) {
      fullCalendarRef.current.getApi().changeView(props.mode)
    }
  }, [props.mode])

  const toggleAddEdit = async (opt, mode, reload) => {
    if (mode === "edit") {
      let ExistingTask = await API.getAction(
        "appointments/details?id=" + opt.id
      )
      if (ExistingTask.data.status === "ok") {
        opt = ExistingTask.data.response
      }
    }

    setState({
      ...state,
      UI: {
        ...state.UI,
        ActivitySelected: opt,
      },
    })

    setActivityMode(mode)

    setShowAddEdit(!showAddEdit)

    if (reload === true) {
      await request()
    }
  }

  useEffect(() => {
    if (props.currentDate) {
      setCurrentDate(props.currentDate)
    }
  }, [props.currentDate])

  return (
    <>
      {showAddEdit ? (
        <AddEditActivity
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={state.UI.ActivitySelected}
        />
      ) : null}
      <div className="row calendarContainer">
        <div className="col-12 col-md-12">
          <div className="card shadow-sm   mb-2 full">
            <div className="card-header  px-1 py-2">
              <div className="row mx-0">
                <div className="col-auto pt-1">
                  <h4 className="text-uppercase">
                    {moment(CurrentDate).format("MMM YYYY")}
                  </h4>
                </div>
                <div className="col">
                  <div className="btn-group">
                    <button
                      className="btn btn-sm btn-secondary "
                      onClick={() => {
                        setCurrentDate(moment())
                      }}
                    >
                      Hoy
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        moveMonth(-1)
                      }}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        moveMonth(1)
                      }}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
                <div className="col text-right pt-1">
                  <div className="text-right btn-group px-2"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow-sm">
            <div className="card-body p-0">
              <FullCalendar
                ref={fullCalendarRef}
                className="calendarStyle"
                initialDate={CurrentDate}
                headerToolbar={false}
                events={state.Events}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                initialView={"dayGridMonth"}
                eventClick={i => {
                  console.log("click", i, i.event.id)
                  toggleAddEdit(i.event, "edit", false)
                  //     props.history.push(Routes.ActivityDetails.clean+i.event.id);
                }}
                dateClick={i => {
                  console.log("click ====", i)
                  if (props.onClick) {
                    props.onClick(i)
                    return
                  }
                  //Continue

                  toggleAddEdit(
                    {
                      name: "",
                      notes: "",
                      status: 0,
                      createdId: theUser.ID,
                      updatedId: "",
                      invites: "",
                      customerId: null,
                      location: "",
                      date: moment(i.date).add(8, "hours").format(),
                      endDate: moment(i.date)
                        .add(8, "hours")
                        .add(30, "minutes")
                        .format(),
                      created: moment(new Date()).format(
                        "YYYY-MM-DDTHH:mm:SSZ"
                      ),
                      id: "",
                      organizationId: theUser.OrganizationId,
                      appointmentTypeId: null,
                      assignedToId: theUser.ID,
                      type: 1,
                    },
                    "add",
                    false
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Calendar
