import { parsedUser } from "../../common/GetCurrentUser";
import CRUDField from "../core/genericCRUD/CRUDField";
const user = parsedUser()
 const FilterFields : CRUDField[] = [
    {
      name: "statusId",
      title: "status",
      htmlType: "select",
      dbType: "text",
      containerClass: "col-3",
      
		dataSourceValueField: "id",
		dataSourceTextField: "name",
		dataSource: "workshop/ListStatus?OrganizationId=" + user?.OrganizationId
    },
    {
      name: "completed",
      title: "completed",
      htmlType: "bool",
      dbType: "bit",
      containerClass: "col-3",
      
		
    },
    {
      name: "assignedToId",
      title: "assignedTo",
      htmlType: "select",
      dbType: "text",
      containerClass: "col-3",
      
		dataSourceValueField: "id",
		dataSourceTextField: "name",
		dataSource: "users/AutoCompleteSearch?OrganizationId=" + user?.OrganizationId
    },
    {
      name: "from",
      title: "from",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-3",
    },
    {
      name: "until",
      title: "until",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-3",
    },
    {
      name: "search",
      title: "search",
      htmlType: "text",
      dbType: "date",
      containerClass: "col-3",
    },
  ]

  export default FilterFields;