import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"
import { FormatMoney } from "../../common/helpers"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  exchangeRate: yup.string().required(),
  // financialAccountId: yup.string().required(),
  payment: yup.string().required(),
})
//Component
const AddEditPayment = props => {
  const [model, setModel] = useState(props.model)
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })
  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }

    let request = await API.postAction(
      "invoice/AddEditPayment?currentUserID=" + user.ID,
      obj
    )

    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: model,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const [currencies, setCurrencies] = useState([])
  const [finantialAccounts, setFinantialAccounts] = useState([])
  const [ledgerAccounts, setLedgerAccounts] = useState([])

  const requestCurrencies = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Currencies/List", Query)

    if (requestAPI.data.status === "ok") {
      setCurrencies(requestAPI.data.response)
    }
  }

  const loadAccounts = async term => {
    let query = `&organizationid=${user.OrganizationId}` //" + user.OrganizationId;
    let request = await API.getAction("FinancialAccount/List?" + query)
    if (request.data.status === "ok") {
      setFinantialAccounts(request.data.response)
    }
  }
  const loadLedgerAccounts = async term => {
    let query = `&organizationid=${user.OrganizationId}` //" + user.OrganizationId;
    let request = await API.getAction("ledgerAccounts/List?" + query)
    if (request.data.status === "ok") {
      setLedgerAccounts(request.data.response)
    }
  }
  const [totalTaxes, setTotalTaxes] = useState(0)
  const [totalInvoice, setTotalInvoice] = useState(0)
  const calcSummary = () => {
    let subTotal = 0
    let tax = 0

    props.parentModel.invoiceDetails.forEach(item => {
      var recordsubTotal = (item.price - item.discount) * item.quantity
      var taxes = 0
      let calculatedTaxes = 0

      calculatedTaxes = item.tax * item.quantity

      subTotal += recordsubTotal
      tax += calculatedTaxes
    })

    let payments = 0

    if (props.payments && props.payments.length > 0) {
      props.payments &&
        props.payments
          .filter(x => x.deleted === null || !x.deleted)
          .forEach(e => {
            payments += e.payment + e.retention ?? 0
          })
    }
    setTotalTaxes(tax)

    let total = subTotal + tax - payments
    setTotalInvoice(total)
    // if (model.payment === 0) {

    if (total < 0.001) {
      formik.setFieldValue("payment", 0)
    } else {
      formik.setFieldValue("payment", total)
    }

    // }
  }

  const addRetentionByType = obj => {
    formik.setFieldValue("retentionType", obj)
    switch (obj) {
      case "itbis30":
        formik.setFieldValue("retention", totalTaxes * 0.3)
        break
      case "itbis75":
        formik.setFieldValue("retention", totalTaxes * 0.75)
        break
      case "itbis100":
        formik.setFieldValue("retention", totalTaxes)
        break
      case "byService":
        formik.setFieldValue("retention", totalTaxes * 0.1)
        break
      case "stateProvider":
        formik.setFieldValue("retention", totalTaxes * 0.05)
        break
      default:
        formik.setFieldValue("retention", 0)
        break
    }
  }

  useEffect(() => {
    requestCurrencies()
    loadAccounts()
    loadLedgerAccounts()
    setModel(props.model)
    setTimeout(() => {
      var focus = document.getElementsByName("payment")[0]
      if (focus) {
        focus.focus()
      }
    }, 500)
  }, [])
  useEffect(() => {
    calcSummary()
  }, [model])

  if (!model) {
    return <></>
  }
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("payment")}
          <small>
            - {t("total_invoice")}: {FormatMoney(totalInvoice)}
          </small>
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>{t("date")}</Form.Label>
              <input
                type="date"
                className="form-control"
                name="date"
                value={
                  formik.values.date
                    ? moment(formik.values.date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={formik.handleChange}
              />
              {formik.errors.date ? (
                <div className="invalid text-sm">{formik.errors.date}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("payment")}</Form.Label>
              <input
                type="number"
                className="form-control"
                min={0}
                step={"any"}
                name="payment"
                value={formik.values.payment ?? 0}
                onChange={formik.handleChange}
              />
              {formik.errors.payment ? (
                <div className="invalid text-sm">{formik.errors.payment}</div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>{t("withholding_type")}</Form.Label>
              <select
                name="retentionType"
                className="form-control"
                value={formik.values.retentionType ?? ""}
                onChange={e => {
                  addRetentionByType(e.target.value)
                }}
              >
                <option value="">{t("others")}</option>
                <option value="itbis30">30% {t("of_itbis")}</option>
                <option value="itbis75">75% {t("of_itbis")}</option>
                <option value="itbis100">100% {t("of_itbis")}</option>
                <option value="byService">
                  {t("fees_services")} (10%)
                </option>
                <option value="stateProvider">
                  {t("withholding_state_providers")}
                </option>
              </select>

              {formik.errors.retentionType ? (
                <div className="invalid text-sm">
                  {formik.errors.retentionType}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("retention")}</Form.Label>
              <input
                type="number"
                className="form-control"
                min={0}
                step={"any"}
                name="retention"
                value={formik.values.retention ?? 0}
                onChange={formik.handleChange}
              />
              {formik.errors.retention ? (
                <div className="invalid text-sm">{formik.errors.retention}</div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>{t("note")}</Form.Label>
              <textarea
                className="form-control"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
                rows={2}
              ></textarea>
              {formik.errors.note ? (
                <div className="invalid text-sm">{formik.errors.note}</div>
              ) : null}
            </Col>

            <Col xs={6} className="d-none">
              <Form.Label>{t("financial_account")}</Form.Label>
              <select
                className="form-control"
                name="financialAccountId"
                value={formik.values.financialAccountId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {finantialAccounts.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  )
                })}
              </select>

              {formik.errors.financialAccountId ? (
                <div className="invalid text-sm">
                  {formik.errors.financialAccountId}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("accounting_account")}</Form.Label>
              <select
                className="form-control"
                value={
                  formik.values.ledgerAccountId
                    ? formik.values.ledgerAccountId
                    : ""
                }
                onChange={formik.handleChange}
                name="ledgerAccountId"
              >
                <option value="">-</option>
                {ledgerAccounts.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.reference + "-" + item.account}
                    </option>
                  )
                })}
              </select>
              {formik.errors.ledgerAccountId ? (
                <div className="invalid text-sm">
                  {formik.errors.ledgerAccountId}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>{t("currency")}</Form.Label>
              <select
                className="form-control"
                name="currencyId"
                value={formik.values.currencyId}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                {currencies &&
                  currencies.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>

              {formik.errors.currencyId ? (
                <div className="invalid text-sm">
                  {formik.errors.currencyId}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>{t("exchange_rate")}</Form.Label>
              <input
                type="number"
                min={1}
                step={"any"}
                className="form-control"
                name="exchangeRate"
                value={formik.values.exchangeRate}
                onChange={formik.handleChange}
              />

              {formik.errors.exchangeRate ? (
                <div className="invalid text-sm">
                  {formik.errors.exchangeRate}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <label>
            {t("total")}:{" "}
            {FormatMoney(formik.values.payment - formik.values.retention)}
          </label>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
          {formik.values.payment - formik.values.retention - totalInvoice &&
          totalInvoice > 0 ? (
            <p className="text-danger">
              {t("alerts.payment_hold_exceed")}{" "}
              {formik.values.payment - formik.values.retention - totalInvoice}
            </p>
          ) : null}
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditPayment
