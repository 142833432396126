
import React, { useEffect, useState } from 'react'
import API from '../../../common/API';
import moment from 'moment';
import { FormatMoney, PayrollTypes, sortArray, TransactionCalculationType, TransactionType } from '../../../common/helpers';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { summaryPerTransactions } from './payrollHelper';

const PayrollReport = props => {
    const { t } = useTranslation("global");
    const { id } = useParams();
    const [HeaderForEmployees, setHeaderForEmployees] = useState([]);
    const [state, setState] = useState({
        Payroll: null,
        CurrentSection: "general",
        UI: {
            showAddEdit: false,
            showAssignEmployees: false,
            showAssingBenefits: false,
            HeaderForEmployees: []
        }
    });

    useEffect(() => {
        request();
    }, [id]);

    useEffect(() => {
        if (state.Payroll) {
            GenerateFieldsForEmployee();
        }
    }, [state.Payroll])

    const request = async () => {
        let requestAPI = await API.getAction("Employees/PayrollDetails?Id=" + id);

        if (requestAPI.data.status === "ok") {

            setState({
                ...state,
                Payroll: requestAPI.data.response
            })

        }
    };

    const GenerateFieldsForEmployee = () => {
        let copy = state
        let header = []
        copy.Payroll.payrollEmployee.forEach(employee => {

            employee.payrollEmployeeTransactions.forEach(item => {
                let exist = header.length > 0 && header.filter(x => x.name === item.concept)[0];
                console.log("record exist", exist, item);
                if (!exist) {
                    header.push({
                        name: item.concept,
                        transactionType: item.transactionType
                    })
                }
            });
        });
        setHeaderForEmployees(header)
        setState(copy)

    }

    const ProfileUI = () => {
        let obj = state.Payroll;

        if (obj === null) {
            return (
                <></>
            );
        }
        return (<>
            <div className="col-6">

                <b>{obj.organization.name}</b><br />
                <small>{obj.organization.identifier}</small>


            </div>
            <div className="col-6 text-right">
                <b>{obj.name}</b>
                <p>

                    <small> {obj.from !== null ? <span>{moment(obj.from).format("MMMM DD,YYYY")}</span> : null}
                        {obj.until !== null ? <span> - {moment(obj.until).format("MMMM DD,YYYY")}</span> : null}</small>
                </p>
                <small>
                    {moment().format("MMM DD, YYYY")}<br />
                    {moment().format("hh:mm:ss a")}
                </small>

            </div>

            <div className="col-12 ">

                {state.CurrentSection === "general" ? GeneralUI() : null}

                {/* SUMMARY OF THE PAYROLL */}

                <p className="display-5 text-right mt-2">
                    <small className="text-muted">{t('Total Cost for the Company')} <b>{FormatMoney(summaryOfThePayRollForTheCompany())}</b></small>
                </p>
                <p className='text-right'>
                    <button className='btn btn-primary btn-sm d-print-none' onClick={() => window.print()}>
                        <i className='fa fa-print' />
                    </button>
                </p>
            </div>
        </>);
    }


    const summaryOfThePayRollForTheCompany = () => {
        let total = 0;
        try {
            if (state.Payroll.payrollEmployee && state.Payroll.payrollEmployee.filter(x => x.deleted === null).length > 0) {
                state.Payroll.payrollEmployee.filter(x => x.deleted === null).forEach((item, index) => {
                    let parsedItem = item
                    let employee = parsedItem;

                    total += summaryPerTransactions(employee, 4, state.Payroll.payrollTypeId);
                })
            }
        } catch (ex) {
            console.error(ex);
        }

        return total;
    }


    const loadFooterUI = () => {
        let assigned = 0;
        let debt = 0;
        let liquid = 0;

        //Summary
        state.Payroll.payrollEmployee.filter(x => x.deleted === null).forEach(employee => {

            let parsedItem = JSON.parse(JSON.stringify(employee));
            assigned += summaryPerTransactions(parsedItem, 5, state.Payroll.payrollTypeId);
            debt += summaryPerTransactions(parsedItem, TransactionType.discounts, state.Payroll.payrollTypeId);
            liquid += summaryPerTransactions(parsedItem, 6, state.Payroll.payrollTypeId);
            console.log("assigned", assigned, "debt", debt, "liquid", liquid);
        })

        return (<tfoot>
            <tr>
                <th>

                </th>
                {state.UI.HeaderForEmployees && sortArray(state.UI.HeaderForEmployees, "transactionType").map((item, index) => {
                    let value = 0;
                    state.Payroll.payrollEmployee.filter(x => x.deleted === null).forEach(employee => {

                        let parsedItem = employee
                        let transactionsExist = parsedItem.payrollEmployeeTransactions.filter(x => x.concept === item.name);
                        let base = summaryPerTransactions(parsedItem, 1);
                        if (transactionsExist.length > 0) {
                            transactionsExist.forEach(transaction => {
                                value += (transaction.transactionType === 1 ? base * transaction.amount / 100 : transaction.transactionType === 2 ? base / transaction.amount : transaction.type === 1 ? base : transaction.amount);
                                value = (parsedItem.type === 3 ? -1 : 1) * value;
                            })
                        }

                    })
                    return (<th key={index}>
                        {FormatMoney(value)}</th>)
                })}
                <th>{FormatMoney(assigned)}</th>
                <th>{FormatMoney(debt)}</th>
                <th>{FormatMoney(liquid)}</th>
            </tr>
        </tfoot>)
    }



    const GeneralUI = () => {

        if(!state.Payroll){
            return<></>
        }
        return (
            <div className="row ">
                <div className="col-12">

                    <div className="card shadow-sm mb-4">

                        <div className="card-body p-0">
                            <div className="col-12 overflow-auto">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                {t('employee')}
                                            </th>

                                            <th>{t('assigned')}</th>
                                            <th>{t('debt')}</th>
                                            <th>{t('liquido')}</th>
                                            {HeaderForEmployees && sortArray(HeaderForEmployees, "transactionType").map((item, index) => {

                                                return (<th key={index}>{item.name}</th>)
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.Payroll.payrollEmployee.length > 0 ?
                                            state.Payroll.payrollEmployee.filter(x => x.deleted === null).map((item, index) => {
                                                let parsedItem = item;
                                                let employee = parsedItem.employee;

                                                return <EmployeeItem  employee={employee}
                                                    parsedItem={parsedItem} key={index} headers={HeaderForEmployees} />
                                            })
                                            : null}
                                    </tbody>
                                    {loadFooterUI()}
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div >)
    }



    const EmployeeItem = obj => {
        console.log("props received", obj);
        
        const employee = obj.employee;
        const parsedItem = obj.parsedItem;


        //console.log("what we get", props);


        return (
            <tr>
                <td>
                    {employee.firstName + " " + employee.lastName}
                </td>
                <td>{FormatMoney(summaryPerTransactions(parsedItem, 5, state.Payroll.payrollTypeId))}</td>
                <td>{FormatMoney(summaryPerTransactions(parsedItem, TransactionType.discounts, state.Payroll.payrollTypeId))}</td>
                <td>{FormatMoney(summaryPerTransactions(parsedItem, 6, state.Payroll.payrollTypeId))}</td>
                {sortArray(obj.headers, "transactionType").map((item, index) => {
                    let findValue = parsedItem.payrollEmployeeTransactions.filter(x => x.concept === item.name)[0];
                    let base = summaryPerTransactions(parsedItem, TransactionType.baseAmount, state.Payroll.payrollTypeId);
                    return (<td key={index}>
                        {findValue && findValue.type === 3 ? "-" : ""}
                        {findValue ? findValue.transactionType === TransactionCalculationType.percentage ? FormatMoney(base * findValue.amount / 100) :
                            findValue.transactionType === TransactionCalculationType.factor ? FormatMoney(base / findValue.amount) :
                                findValue.type === 1 ? FormatMoney(base) : FormatMoney(findValue.amount) : "-"}
                    </td>)

                })}
            </tr>
        )
    }
    return (
        <div className="row mx-0">

            {state !== undefined ? ProfileUI() : null}
        </div>

    )
}
export default PayrollReport;


