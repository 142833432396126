import React, { useEffect, useState } from "react"

import * as yup from "yup"
import API from "../../common/API"

//Translation
import { useTranslation } from "react-i18next"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import { useFormik } from "formik"

const AddEditLink = props => {
  const user = parsedUser()

  const { t } = useTranslation("global")
  //Validation
  const validationSchema = yup.object({
    name: yup.string().required(),
  })
  useEffect(() => {
    setTimeout(() => {
      let focus = document.getElementsByName("name")[0]
      if (focus !== null) {
        focus.focus()
      }
    }, 500)
  }, [])

  const handleSubmit = async obj => {
    try {
      console.log(obj)
      obj.organizationId = user.OrganizationId
      //We have to sent to api
      const request = await API.postAction("File/AddEditFile", obj)
      console.log("request add edit", request)
      // state.IsLoading.set(!state.IsLoading.value)

      if (request.data.status === "ok") {
        props.toggle(request.data.response, true)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      // state.IsLoading.set(false)
      //props.toggle();
    }
  }

  const formik = useFormik({
    initialValues: props.model,

    validationSchema,
    onSubmit: handleSubmit,
  })
  if (!formik.values) {
    return <></>
  }
  return (
    <Modal show={true} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("link")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={12} md={12}>
              <Form.Label>{t("link")}</Form.Label>
              <Form.Control
                type="text"
                name="file"
                value={formik.values.file}
                onChange={formik.handleChange}
              />
              {formik.errors.file ? (
                <div className="invalid text-sm">{formik.errors.file}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            // disabled={state.IsLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditLink
