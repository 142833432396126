import React, { useEffect, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

// import Swal from "sweetalert2";

import { useLocation } from "react-router-dom"

const DragAndDrop = props => {
  const location = useLocation()
  const dropRef = useRef()
  let dragCounter = 0

  const [state, setState] = useState({
    drag: false,
  })

  const onChangeDrop = files => {
    const event = location.pathname.replace("/calendar/", "")
    try {
      if (files) {
        const arrFiles = Array.from(files)
        arrFiles.map((file, index) => {
          window.URL.createObjectURL(file)
          let attachment = {
            name: file.name,
            note: "",
            event: event,
            file: file,
          }
        })
      }
    } catch (error) {
      //Swal.fire("Oops...", "Something went wrong!", "error");
    }
  }

  const handleDrag = e => {
    e.preventDefault()
    e.stopPropagation()
    setState({ drag: true })
  }

  const handleDragIn = e => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setState({ drag: true })
    }
  }

  const handleDragOut = e => {
    e.preventDefault()
    e.stopPropagation()
    dragCounter--
    if (dragCounter === 0) {
      setState({ drag: false })
    }
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props?.onChangeDrop
        ? props.onChangeDrop(e.dataTransfer.files[0], "drop")
        : props.handleDropCalendarEvent
        ? props.handleDropCalendarEvent(e.dataTransfer.files)
        : onChangeDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      dragCounter = 0
      props.handleDragOut && props.handleDragOut(e)
    }
  }

  useEffect(() => {
    let div = dropRef.current
    div.addEventListener("dragenter", handleDragIn)
    div.addEventListener("dragleave", handleDragOut)
    div.addEventListener("dragover", handleDrag)
    div.addEventListener("drop", handleDrop)

    return () => {
      let div = dropRef.current
      if (div) {
        div.removeEventListener("dragenter", handleDragIn)
        div.removeEventListener("dragleave", handleDragOut)
        div.removeEventListener("dragover", handleDrag)
        div.removeEventListener("drop", handleDrop)
      }
    }
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <div ref={dropRef}>
        {state.drag && (
          <div
            style={{
              border: "dashed blue 4px",
              // backgroundColor: "rgba(255,255,255,.8)",
              position: "absolute",
              // top: props.top ?? 0,
              // bottom: 0,
              // left: 0,
              // right: 0,
              zIndex: 9999,
              height: "100%",
              // height: props.height ?? "100%",
              width: props.width ?? "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                display: "flex",
                top: 0,
                right: 0,
                left: 0,
                textAlign: "center",
                color: "grey",
                fontSize: props.fontSize || 36,
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <img src={IMAGES.EMPTY_ATTACHMENTS} alt="" /> */}
              <div>{props.dropText ?? "Drop your files here"}</div>
            </div>
          </div>
        )}
        {props.children}
      </div>
    </DndProvider>
  )
}

export default DragAndDrop
