import React, { useEffect, useState } from "react"

import { Modal, DropdownButton, Dropdown } from "react-bootstrap"

//import moment from "moment"
import API from "../../common/API"

import { parsedUser } from "../../common/GetCurrentUser"
import { confirmAlert } from "react-confirm-alert"
import AddEditInvoiceType from "./AddEditInvoiceType"

//Translation
import { useTranslation } from "react-i18next"

//Component
const InvoiceTypeList = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedObj, setSelectedObj] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    if (obj) {
      setSelectedObj(obj)
    }
    setShowAddEdit(!showAddEdit)

    if (reload) {
      await request()
    }
  }

  const user = parsedUser()
  const { t } = useTranslation("global")

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "Invoicetypes/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const request = async () => {
    let Query =
      "OrganizationId=" +
      user.OrganizationId +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("invoiceTypes/List", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  useEffect(() => {
    request()
  }, [])

  return (
    <>
      {showAddEdit ? (
        <AddEditInvoiceType
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={selectedObj}
        ></AddEditInvoiceType>
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("type_invoices")}</Modal.Title>
          <button
            className=" mx-1 btn btn-sm btn-primary"
            type="button"
            onClick={() =>
              toggleAddEdit({
                id: "",
                name: "",
                organizationId: "",
                prefix: "",
                sequence: 0,
                created: null,
                createdUserId: null,
                updated: null,
                updatedUserId: null,
                deleted: null,
                deletedUserId: null,
                quantityOfZerosBefore: 8,
                expirationDate: null,
                defaultNote: "",
                defaultCurrencyId: null,
                defaultPaymentMethod: null,
                validForDgii: true,
                template: null,
                organization: null,
                invoice: [],
                invoiceTypeTaxes: [],
                quote: [],
              })
            }
          >
            <i className="fa fa-plus"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <table className="table table-striped">
            <tbody>
              {state &&
                state.Records &&
                state.Records.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => toggleAddEdit(item)}
                      className="clickable"
                    >
                      <td>
                        {item.name}
                        <br />
                        <small>
                          <b>{t("prefix")}</b>
                          {`: ${item.prefix} `}
                          <b>{t("sequence")}</b>
                          {`: ${item.sequence}`}
                        </small>
                      </td>
                      <td className="text-right">
                        <DropdownButton
                          id="dropdown-item-button"
                          title={<i className="fa fa-ellipsis-v"></i>}
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => deleteConfirm(item)}
                          >
                            {t("edit")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => deleteConfirm(item)}
                          >
                            {t("delete")}
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InvoiceTypeList
