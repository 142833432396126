import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Routes } from "../../routes"
import API from "../../common/API"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
//Translation
import { useTranslation } from "react-i18next"
import ExpensesList from "../Expenses/ExpenseList"
import { Card } from "react-bootstrap"
import ProviderSummary from "./ProviderSummary"
import AddEdit from "./AddEdit"
import { parsedUser } from "../../common/GetCurrentUser"

const ProviderDetails = () => {
  const { t } = useTranslation("global")
  const { id } = useParams()

  const [state, setState] = useState({
    provider: {},
    CurrentSection: "general",
    UI: {
      showAddEdit: false,
    },
  })

  useEffect(() => {
    request()
  }, [])
  const AssignSection = opt => {
    setState({
      ...state,
      CurrentSection: opt,
    })
  }

  const request = async () => {
    let requestAPI = await API.getAction("Provider/Details?Id=" + id)

    console.log("DATA PROVIDER DETAILS: ", requestAPI)

    if (requestAPI.data.status === "ok") {
      setState({ ...state, provider: requestAPI.data.response })
    }
  }

  const ProfileUI = () => {
    return (
      <>
        <div className="col-12 col-lg-4 col-md-12">
          <Card className="hadow-sm mb-4">
            <Card.Header>
              <div className="row">
                <div className="col-auto">
                  <h5 className="display-5 d-inline">{state.provider.name}</h5>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="row mx-0">
                <div className="col-6">
                  <b>{t("id")}:</b>
                </div>
                <div className="col-6">{state.provider.identifier}</div>
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="row">
                <div className="col-auto">
                  <div className="col ml-auto text-left">
                    <button
                      className="btn btn-primary d-inline ml-auto btn-sm"
                      onClick={() => {
                        deleteConfirm(state.provider)
                        console.log("El button Delete aun no es funcional.")
                      }}
                    >
                      <i className="fa fa-trash text-danger" />
                    </button>
                  </div>
                </div>
                <div className="col ml-auto text-right">
                  <button
                    className="btn btn-primary d-inline ml-auto btn-sm"
                    onClick={() => {
                      toggle(state.provider)
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              </div>
            </Card.Footer>
          </Card>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-12 mb-2">
              <ProviderSummary id={id}></ProviderSummary>
            </div>

            <div className="col-12 mb-2">{GetExpensesUI()}</div>
          </div>
        </div>
      </>
    )
  }

  const GetExpensesUI = () => {
    return <ExpensesList Search={"provider"} ProviderId={id} />
  }
  const [showAddEdit, setShowADdEdit] = useState(false)
  const toggle = obj => {
    setShowADdEdit(!showAddEdit)
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "provider/Delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      {showAddEdit ? (
        <AddEdit toggle={toggle} show={showAddEdit} model={state.provider} />
      ) : null}

      <div className="row">
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Routes.Home.path}>
                <i className="fa fa-home"></i>
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to={Routes.Providers.path}>{t("providers")}</Link>
            </li>
            <li className="breadcrumb-item">{t("details")}</li>
          </ol>
        </nav>

        {ProfileUI()}
      </div>
    </>
  )
}
export default ProviderDetails
