import React, { useState } from "react"

import { divisionOfTheAmount, summaryPerTransactions } from "./payrollHelper"
import {
  FormatMoney,
  sortArray,
  TransactionType,
} from "../../../common/helpers"
import { Routes } from "../../../routes"
import { useTranslation } from "react-i18next"
import { confirmAlert } from "react-confirm-alert"
import API from "../../../common/API"
import { Dropdown, DropdownMenu } from "react-bootstrap"
import AssignBenefitTransaction from "../benefits/AssignBenefitTransaction"

const PayrollEmployeeItem = props => {
  console.log(props)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation("global")
  const employee = props.employee
  const parsedItem = props.parsedItem

  const basePayrollTypeForUser = props.basePayrollTypeForUser

  const basePayrollType = props.basePayrollType

  const confirmDelete = item => {
    confirmAlert({
      title: t("confirmDelete") ?? "",
      message: t("confirmDeleteMessage") ?? "",
      buttons: [
        {
          label: t("delete"),
          onClick: async () => {
            console.log("delete")
            await API.getAction(
              `employees/DeletePayrollTransaction?id=${item.id}`
            )
            await props.reload()
          },
        },
        {
          label: t("cancel"),
          onClick: () => {
            console.log("Cancel")
          },
        },
      ],
    })
  }

  const toggle = () => {
    setOpen(!open)
  }
  const [showBenefit, setShowBenefit] = useState(false)
  const toggleShowBenefit = () => {
    setShowBenefit(!showBenefit)
    if (showBenefit) {
      props.reload()
    }
  }

  return (
    <>
      {showBenefit && (
        <AssignBenefitTransaction
          show={true}
          toggle={toggleShowBenefit}
          employeeId={employee.id}
          payrollId={props.parsedItem.payrollId}
        />
      )}
      <div className="row mx-0">
        <div
          className={"col-lg-4 col-md-6 col-12 clickable"}
          onClick={() => toggle()}
        >
          {open ? (
            <b>
              <i className="fa fa-chevron-down me-2"></i>
              {employee.firstName} {employee.lastName}
            </b>
          ) : (
            <span>
              <i className="fa fa-chevron-right me-2"></i>
              {employee.firstName + " " + employee.lastName}
            </span>
          )}
          <br />
          <small className="text-muted">{employee.email}</small>
        </div>
        <div className="col">
          <small className="text-muted">{t("basePayroll")}</small>
          <br />

          {FormatMoney(summaryPerTransactions(parsedItem, TransactionType.baseAmount, basePayrollType))}
        </div>
        <div className="col">
          <small className="text-muted">{t("benefits")}</small>
          <br />
          {FormatMoney(summaryPerTransactions(parsedItem, TransactionType.benefits , basePayrollType))}
        </div>
        <div className="col">
          <small className="text-muted">{t("discounts")}</small>
          <br />
          {FormatMoney(summaryPerTransactions(parsedItem, TransactionType.discounts, basePayrollType))}
        </div>
        <div className="col-12 col-md-auto  text-right">
          <b>
            <small className="text-muted">{t("total")}</small>
            <br />
            {FormatMoney(
              summaryPerTransactions(parsedItem, 0, basePayrollType)
            )}
          </b>
        </div>
        <div className="col-auto text-right">
          <Dropdown className="d-inline me-2">
            <Dropdown.Toggle variant="success" size="sm">
              <i className="fa fa-plus"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>{t("Add Discount")}</Dropdown.Item>
              <Dropdown.Item onClick={() => toggleShowBenefit()}>
                {t("Add Benefit")}
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                {t("Add Extra Hour")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="btn-group">
            <a
              rel="noreferrer"
              className="btn btn-primary btn-sm"
              href={Routes.PayrollPaymentDetails.clean + parsedItem.id}
              title="Payment Flyer"
              target="_blank"
            >
              <i className="fas fa-external-link-alt"></i>
            </a>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => props.deleteFromPayRoll(parsedItem)}
              type="button"
            >
              <i className="fa fa-trash text-danger" />
            </button>
          </div>
        </div>
        {open ? (
          <div className="col-12 table-responsive ">
            <table className="table border table-striped ">
              <thead>
                <tr>
                  <th>{t("concept")}</th>

                  <th>{t("value")}</th>

                  <th className="text-right">{t("subTotal")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortArray(
                  parsedItem.payrollEmployeeTransactions.filter(
                    x => !x.visibleByTheCompanyOnly
                  ),
                  "type"
                ).map((item, index) => {
                  let base = summaryPerTransactions(
                    parsedItem,
                    1,
                    basePayrollType
                  )
                  let baseSalary =
                    parsedItem.payrollEmployeeTransactions.filter(
                      x => x.transactionType === 0
                    )[0]
                  let howMany = baseSalary.amount / base //how many times is the current salary into this payroll type

                  console.log(
                    basePayrollTypeForUser,
                    basePayrollType,
                    parsedItem,
                    base
                  )
                  let amount = divisionOfTheAmount(
                    basePayrollTypeForUser,
                    basePayrollType,
                    item,
                    base
                  ).NewValue
                  let transactionAmount =
                    item.transactionType === TransactionType.percentage
                      ? FormatMoney((amount / base) * 100) + "%"
                      : item.transactionType === TransactionType.factor
                      ? "Salary/" + FormatMoney(amount)
                      : item.type === 1
                      ? FormatMoney(base)
                      : FormatMoney(amount)

                  let transactionSubTotal =
                    item.transactionType === TransactionType.percentage
                      ? FormatMoney((base * item.amount) / 100)
                      : item.transactionType === TransactionType.factor
                      ? FormatMoney(base / amount)
                      : item.type === 1
                      ? FormatMoney(base)
                      : FormatMoney(amount)

                  return (
                    <tr
                      key={index}
                      className={
                        item.type === 2
                          ? "text-success"
                          : item.type === 3
                          ? "text-danger"
                          : ""
                      }
                    >
                      <td>{item.concept} </td>

                      <td>{transactionAmount}</td>
                      <td className="text-right">{transactionSubTotal}</td>
                      <td className="text-right">
                        <button
                          onClick={() => confirmDelete(item)}
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fa fa-trash text-danger" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="col-12">
          <hr />
        </div>
      </div>
    </>
  )
}

export default PayrollEmployeeItem
