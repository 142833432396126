import moment from "moment"
import React, { useEffect, useState } from "react"

//import { useHistory } from "react-router"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { FormatMoney } from "../../common/helpers"
import { Routes } from "../../routes"
import AddEditExpense from "./AddEditExpense"
import emptyImg from "../../assets/img/illustrations/no_content.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

import Pagination from "../core/Pagination"
import ExportToExcel from "./ExportToExcel"
import { Dropdown } from "react-bootstrap"

//Translation
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const ExpensesList = props => {
  const [Search, setSearch] = useState("")
  const [state, setState] = useState({
    Expenses: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      ExpenseSelected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    OnlyPendingToPaid: false,
  })
  const theUser = parsedUser()

  const { t } = useTranslation("global")

  useEffect(() => {
    if (theUser === null) {
      window.location = Routes.SignIn.path
    }
    request()
  }, [state.currentPage])

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const request = async () => {
    let Query =
      "OrganizationId=" +
      theUser.OrganizationId +
      "&Search=" +
      Search +
      "&Quantity=" +
      state.Quantity +
      "&Page=" +
      state.currentPage +
      "&OnlyPendingToPaid=" +
      state.OnlyPendingToPaid

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    if (props.ProviderId) {
      Query += "&ProviderId=" + props.ProviderId
    }
    let requestAPI = await API.getAction("Expense/List", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Expenses: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    setSelectedItem(obj)
    setShowAddEdit(!showAddEdit)
    if (reload) {
      await request()
    }
  }
  const [showExport, setShowExport] = useState(false)
  const toggleExport = async (obj, reload) => {
    setShowExport(!showExport)
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "expense/delete?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  return (
    <>
      {showAddEdit ? (
        <AddEditExpense
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={selectedItem}
        />
      ) : null}

      {showExport ? (
        <ExportToExcel toggle={toggleExport} show={showExport} />
      ) : null}

      {props.Full ? (
        <div className="row mb-2 mx-0">
          <div className="col ">
            <h5 className="display-4">
              <i className="fas fa-file-invoice-dollar"></i>&nbsp;
              {t("expenses")}
            </h5>
          </div>
          <div className="ml-auto col-auto text-right">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-search"></i>
              </div>
              <input
                type="text"
                className="form-control "
                placeholder={t("filter")}
                value={Search}
                onChange={async e => {
                  setSearch(e.target.value)

                  await request()
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary "
                  onClick={() =>
                    toggleAddEdit({
                      id: "",
                      description: null,
                      date: moment().format("YYYY-MM-DD"),
                      created: null,
                      createdUserId: null,
                      updated: null,
                      updatedUserId: null,
                      deleted: null,
                      deletedUserId: null,
                      amount: null,
                      organizationId: null,
                      expenseIdentifier: null,
                      providerId: null,
                      image: null,
                      expenseTypeId: null,
                      paidDate: null,
                      taxesIdentifier: null,
                      validTaxesIdentifier: null,
                      taxes: null,
                      tips: null,
                      projectId: null,
                      financialAccountId: null,
                      expenseType: null,
                      financialAccount: null,
                      organization: null,
                      provider: null,
                      ledgerAccountId: null,
                    })
                  }
                >
                  <i className="fa fa-plus" />
                </button>
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <i className="fa fa-ellipsis-v"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => toggleExport()}>
                      {t("export_excel")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {state.Expenses.length === 0 ? (
        <div className="row">
          <div className="col-6 mx-auto text-center">
            <img
              src={emptyImg}
              className="img-fluid"
              style={{ maxWidth: "50%" }}
              alt=""
            />
          </div>
        </div>
      ) : null}
      {state.Expenses.length > 0 ? (
        <div className="card shadow-sm">
          {!props.Full ? (
            <div className="card-header py-1">
              <div className="card-title">
                <i className="fa fa-id-card " />
                &nbsp; {t("expenses")}
              </div>
            </div>
          ) : null}
          <div className="card-body  ">
            {state.Expenses.length > 0
              ? state.Expenses.map((parsedItem, index) => {
                  return (
                    <div className="row px-0 " key={index}>
                      <div
                        className="col clickable"
                        onClick={() => toggleAddEdit(parsedItem)}
                      >
                        <small className="text-uppercase ">
                          {moment(parsedItem.date).format("MMM DD, YYYY")}
                        </small>
                        <span>&nbsp;|&nbsp;</span>
                        {parsedItem.expenseType ? (
                          <small className="badge bg-primary">
                            {parsedItem.expenseType.name}
                          </small>
                        ) : null}

                        <span>&nbsp;|&nbsp;</span>
                        <small className="text-muted">
                          ID: {parsedItem.provider.identifier}
                        </small>
                        <br />

                        {parsedItem.provider ? (
                          <Link
                            to={
                              Routes.ProviderDetails.clean +
                              parsedItem.providerId
                            }
                          >
                            {parsedItem.provider.name}
                          </Link>
                        ) : null}
                      </div>
                      <div className="text-right col-auto ml-auto">
                        {parsedItem.amount ? (
                          <span
                            className={
                              parsedItem.paidDate
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {FormatMoney(parsedItem.amount)}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-auto text-right">
                        <div className="btn-group">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => toggleAddEdit(parsedItem, false)}
                          >
                            <i className="fa fa-edit" />
                          </button>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deleteConfirm(parsedItem)}
                            type="button"
                          >
                            <i className="fa fa-trash text-danger" />
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr className="my-1" />
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <div className="card-footer overflow-hidden">
            <Pagination
              totalPages={state.TotalPages}
              currentPage={state.currentPage}
              onPageChanged={onPageChanged}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ExpensesList
