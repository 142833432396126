import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import AddEditPost from "./AddEditPost"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

const PostList = props => {
  //   const state = useState({
  //     Posts: [],
  //     UI: {
  //       showAddEdit: false,
  //       SelectedItem: null,
  //     },
  //   })
  const [state, setState] = useState({
    Posts: [],
    UI: {
      showAddEdit: false,
      SelectedItem: null,
    },
  })
  useEffect(() => {
    //Load remote records
    request()
  }, [])

  const request = async () => {
    let query = ""

    if (props.CustomerId) {
      query += "CustomerID=" + props.CustomerId
    }
    if (props.EmployeeId) {
      query += (query.length > 0 ? "&" : "") + "EmployeeId=" + props.EmployeeId
    }
    if (props.EmployeePositionId) {
      query +=
        (query.length > 0 ? "&" : "") +
        "EmployeePositionId=" +
        props.EmployeePositionId
    }
    if (query.length === 0) {
      return
    }
    let requestAPI = await API.getAction("Post/List?" + query)

    if (requestAPI.data.status === "ok") {
      console.log("post", requestAPI)
      let copy = JSON.parse(JSON.stringify(state))
      copy.Posts = requestAPI.data.response
      setState(copy)
    }
  }
  const toggle = async (obj, reload) => {
    // state.UI.SelectedItem.set(obj)
    setState({ ...state, UI: { ...state.UI, SelectedItem: obj } })
    // state.UI.showAddEdit.set(!state.UI.showAddEdit.value)
    setState({
      ...state,
      UI: { ...state.UI, showAddEdit: !state.UI.showAddEdit },
    })
    if (reload !== undefined) {
      request()
    }
  }

  const deleteConfirm = async obj => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.title + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction("Post/Delete?id=" + obj.id)
            await request()
          },
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    })
  }

  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditPost
          toggle={toggle}
          show={state.UI.showAddEdit}
          model={state.UI.SelectedItem}
        />
      ) : null}
      <div className="col-12">
        <div className="row">
          <div className="col-auto">
            <h5 className="display-4 d-inline">Timeline</h5>
          </div>
          <div className="col ml-auto text-right">
            <button
              className="btn btn-primary d-inline ml-auto btn-sm"
              onClick={() =>
                toggle({
                  id: "",
                  created: "",
                  title: "",
                  content: "",
                  employeeId: props.EmployeeId,
                  customerId: props.CustomerId,
                  employeePositionId: props.EmployeePositionId,
                })
              }
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        {state.Posts.length > 0 ? (
          <ul className="timeline">
            {state.Posts.map((item, index) => {
              let itemParsed = JSON.parse(JSON.stringify(item))
              console.log("the item", item, itemParsed)
              return (
                <li
                  className="timeline-item bg-white rounded ml-3 p-4 shadow"
                  key={index}
                >
                  <div className="timeline-arrow"></div>
                  <div className="row">
                    <div className="col-auto">
                      {item.title ? (
                        <h2 className="h5 mb-0">{item.title}</h2>
                      ) : null}
                    </div>
                    <div className="col ml-auto text-right">
                      <div className="btn-group">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => toggle(itemParsed)}
                        >
                          <i className="fa fa-edit" />
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => deleteConfirm(itemParsed)}
                        >
                          <i className="fa fa-trash text-danger" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <span className="small text-gray">
                    <i className="fa fa-clock-o mr-1"></i>
                    {moment(item.created).fromNow()}
                  </span>
                  <div
                    className="text-small mt-2 font-weight-light"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                  <div className="col-12 text-right">
                    {item.creator ? (
                      <small className="text-muted">
                        created by:{" "}
                        {item.creator.firstName + " " + item.creator.lastName}
                      </small>
                    ) : null}
                    {item.update && item.creatorId !== item.updateId ? (
                      <small className="text-muted">
                        Editado por:{" "}
                        {item.update.firstName + " " + item.update.lastName}
                      </small>
                    ) : null}
                  </div>
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
    </>
  )
}

export default PostList
