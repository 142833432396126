import React from "react"

import { Modal, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
//import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6
//import { onlyHTMLTags } from "../../common/helpers"
//import API from "../../common/API"
//import moment from "moment"
//import { v4 as uuidv4 } from "uuid"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  amount: yup.string().required("required"),
})
//Component
const ExchangeRate = props => {
  const { t } = useTranslation("global")

  const handleSubmit = async obj => {
    props.toggle(obj.amount * 1)
  }

  const formik = useFormik({
    initialValues: {
      amount: 1,
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{t("conversion")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>{t("exchange_rate")}</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
              />
              {formik.errors.amount ? (
                <div className="invalid text-sm">{formik.errors.amount}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-sm" type="submit">
            {t("convert")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ExchangeRate
