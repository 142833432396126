import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
import API from "../../common/API"
import moment from "moment"

import { parsedUser } from "../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"
import { ToDoListProps } from "./TodoListProps"

//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
})
//Component
const EditToDo =( props:any) => {
  //   const state = useState({
  //     UI: {
  //       isLoading: false,
  //     },
  //   })
  const user = parsedUser()
  const { t } = useTranslation("global")

  useEffect(() => {
    console.log("props received on post", props)
    formik.setValues(props.record as ToDoListProps)
  }, [])

  const handleSubmit = async( obj: any) => {
    console.log("values to submit", obj)
    let query = {
      id: obj.id,
      name: obj.name,
      note: obj.note,
      assignedToId: obj.assignedToId,
      completed: obj.completed,
      dueDate: obj.dueDate,
    }
    obj.assignedToId = user.ID
    const request = await API.postAction(
      "ToDo/AddEdit?currentUserId=" + user.ID,
      query
    )
    if (request.status === 200) {
      props.toggle()
    }
  }

  const formik = useFormik({
    initialValues:props.record as ToDoListProps,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} >
    <Modal.Header closeButton>
      <Modal.Title>{t("todo")}</Modal.Title>
    </Modal.Header>

    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
      <Row className="mx-0">
        <Col xs={12}>
          <label htmlFor="name">{t("name")}</label>
          <input
            type="text"
            className="form-control "
            id="name"
            placeholder=""
            value={formik.values.name}
            onChange={formik.handleChange}
          />

          {formik.errors.name ? (
            <div className="invalid text-sm">{formik.errors.name}</div>
          ) : null}
        </Col>

        <Col xs={12}>
          <label htmlFor="name">{t("note")}</label>
          <textarea
            
            className="form-control "
            id="note"
            
            value={formik.values.note}
            onChange={formik.handleChange}
          />

          {formik.errors.name ? (
            <div className="invalid text-sm">{formik.errors.note}</div>
          ) : null}
        </Col>

      
      </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
        <Col className="col-12 text-end mt-2">
         

          <Button variant="primary" type="submit" className="btn-sm">
            <i className="fa fa-save"></i>
          </Button>
        </Col>
        </Row>
      </Modal.Footer>
    </Form>
    </Modal>
  )
}

export default EditToDo
