import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import moment from "moment"
import DiagnosticAddEdit from "./DiagnosticAddEdit"
import { Col, Row } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { Routes } from "../../../routes"

interface propsDTO {
  parentId: string
  readOnly?: boolean
}

const DiagnosticList = (props: propsDTO) => {
  const [records, setRecords] = useState([])

  const { t } = useTranslation("global")
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedDiagnostic, setSelectedDiagnostic] = useState<any | null>(null)

  const bindRecordRemote = async () => {
    try {
      const query = `id=${props.parentId}`
      const request = await API.getAction("workshop/DiagnosticList", query)
      if (request.data.status === "error") {
        console.error(request.data.response)
        setRecords([])
      } else {
        setRecords(request.data.response)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const deleteConfirm = async model => {
    confirmAlert({
      title: t("confirm") ?? "",
      message: t("confirmDeleteMessage") ?? "",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction("workshop/DiagnosticDelete?id=" + model.id)
            bindRecordRemote()
          },
        },
        {
          label: t("no"),
          onClick: () => {
            console.log("no")
          },
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const toggle = async () => {
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      await bindRecordRemote()
    }
  }

  useEffect(() => {
    bindRecordRemote()
  }, [])

  return (
    <>
      {showAddEdit && (
        <DiagnosticAddEdit
          toggle={toggle}
          workshopRecordId={props.parentId}
          record={selectedDiagnostic?.id}
        />
      )}
      <Row className="w-100">
        <Col xs={12} className="text-end">
          {!props.readOnly && (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setSelectedDiagnostic(null)
                toggle()
              }}
            >
              {t("Add")}
            </button>
          )}
        </Col>
        <Col xs={12}>
          <div className="table-responsive bg-white">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("date")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records &&
                  records.length > 0 &&
                  records.map((item: any, index: number) => {
                    console.log(item)
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(item.created).format("MMM DD, YYYYY")}</td>

                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => {
                                window.open(
                                  `${Routes.WorkshopDiagnosticPrint.clean}${item.id}`,
                                  "_blank"
                                )
                              }}
                            >
                              <i className="fa fa-print"></i>
                            </button>
                            {!props.readOnly && (
                              <>
                                <button
                                  className="btn btn-sm btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setSelectedDiagnostic(item)
                                    toggle()
                                  }}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-primary"
                                  type="button"
                                  onClick={() => {
                                    deleteConfirm(item)
                                  }}
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                  {records && records.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default DiagnosticList
