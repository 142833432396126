/* eslint-disable indent */
import React, { useEffect, useState } from "react"

import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"
import { FormatMoney } from "../../../common/helpers"

import AddEditExpenseType from "./AddEditExpenseType"
import emptyImg from "../../../assets/img/illustrations/no_content.svg"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css

//Translation
import { useTranslation } from "react-i18next"
import { Col, Modal, Row } from "react-bootstrap"
import { textFilter } from "../../../helpers/filters"

const ExpensesType = props => {
  const [searchFilter, setSearchFilter] = useState("")
  const [state, setState] = useState({
    Records: [],
    UI: {
      IsLoading: false,
      showAddEdit: false,
      ItemSelected: null,
    },
    Page: 0,
    Quantity: 20,
    OnlyPendingToPaid: false,
  })
  const theUser = parsedUser()
  const [filteredData, setFilteredData] = useState([])

  const { t } = useTranslation("global")

  const request = async () => {
    let Query = "OrganizationId=" + theUser.OrganizationId
    // +    "&Search=" + Search

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("Expense/Types", Query)
    console.log(requestAPI.data.response)
    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
      })
    }
  }
  const toggleAddEdit = async (obj, reload) => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        ItemSelected: obj,
        showAddEdit: !state.UI.showAddEdit,
      },
    })

    if (reload) {
      await request()
    }
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "expense/DeleteExpenseType?id=" +
                obj.id +
                "&UserId=" +
                currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  useEffect(() => {
    request()
  }, [])

  useEffect(() => {
    if (state.Records) {
      console.log("filtering")
      setFilteredData(
        state.Records.filter(el => {
          return textFilter(el.name, searchFilter)
          // return el
        })
      )
    }
  }, [searchFilter, state.Records])

  useEffect(() => {
    console.log(filteredData)
  }, [filteredData])

  return (
    <>
      {state.UI.showAddEdit ? (
        <AddEditExpenseType
          toggle={toggleAddEdit}
          show={state.UI.showAddEdit}
          model={state.UI.ItemSelected}
        />
      ) : null}

      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("expensesTypes")}</Modal.Title>
          <button
            className="ms-2 btn btn-sm btn-primary"
            onClick={() => toggleAddEdit()}
          >
            <i className="fa fa-plus"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          {state.Records.length === 0 ? (
            <div className="row">
              <div className="col-6 mx-auto text-center">
                <img
                  src={emptyImg}
                  className="img-fluid"
                  style={{ maxWidth: "50%" }}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          {state.Records.length > 0 ? (
            <div className="shadow-sm" style={{ marginTop: "1rem" }}>
              {state.Records.length > 0
                ? filteredData.map((parsedItem, index) => {
                    return (
                      <div className="row px-0 " key={index}>
                        <div
                          className="col clickable"
                          onClick={() => toggleAddEdit(parsedItem, false)}
                        >
                          <span>{parsedItem.name}</span>
                        </div>
                        <div className="text-right col-auto ml-auto">
                          {parsedItem.amount ? (
                            <span
                              className={
                                parsedItem.paidDate
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {FormatMoney(parsedItem.amount)}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-auto text-right">
                          <div className="btn-group">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => toggleAddEdit(parsedItem, false)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => deleteConfirm(parsedItem)}
                              type="button"
                            >
                              <i className="fa fa-trash text-danger" />
                            </button>
                          </div>
                        </div>
                        <div className="col-12">
                          <hr className="my-1" />
                        </div>
                      </div>
                    )
                  })
                : null}
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ExpensesType
