import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import API from "../../common/API"
import moment from "moment"
import { FormatMoney } from "../../common/helpers"

//Translation
import { useTranslation } from "react-i18next"
import { Col, Row } from "react-bootstrap"

const ExpenseTotalMonthly = () => {
  const [state, setState] = useState()
  const [records, setRecords] = useState()
  const user = parsedUser()
  const [viewMode, setViewMode] = useState("graphic")
  const { t } = useTranslation("global")

  const request = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("expense/TotalMonthly", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)
      // {
      //     labels,
      //     datasets: [
      //       {
      //         label: 'Dataset 1',
      //         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //       },

      //     ],
      //   };
      let labels = []

      result.forEach(item => {
        labels.push(moment(item.month).format("MMM YYYY"))
      })
      setState({
        labels,
        datasets: [
          {
            label: t("total"),
            data: result.map(x => x.total),
            backgroundColor: "#4081d0",
          },
        ],
      })
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: t("expense_total_monthly.total_month"),
      },
    },
  }
  useEffect(() => {
    request()
  }, [])

  if (!state) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header p-2 px-4">
          <Row>
            <Col>
              <span className="card-title display-6">
                {t("expense_total_monthly.expenses_month")}
              </span>
            </Col>
            <Col className="ms-auto col-auto">
              <div className="btn-group ">
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "graphic" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("graphic")}
                >
                  <i className="fa fa-chart-line"></i>
                </button>
                <button
                  className={
                    "btn btn-sm " + (viewMode === "list" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("list")}
                >
                  <i className="fa fa-list"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body">
          {viewMode === "graphic" ? (
            <Line options={options} data={state} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>{t("month")}</th>
                  <th>{t("quantity")}</th>
                  <th className="text-right">{t("total")}</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{moment(item.month).format("MMM YYYY")}</td>
                      <td>{item.amount}</td>
                      <td className="text-right">{FormatMoney(item.total)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}

export default ExpenseTotalMonthly
