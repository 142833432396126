

import CalendarOverview from "../components/dashboard/CalendarOverview";
import SubscriptionStatusComponent from "../components/Subscriptions/SubscriptionStatusComponent";


const CalendarPage = () => {
    return (<>
    <SubscriptionStatusComponent/>
    <CalendarOverview />
    </>)
}

export default CalendarPage;