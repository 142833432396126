import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import API from '../../../common/API';

import { parsedUser } from '../../../common/GetCurrentUser';


//Component
const AssignEmployees = props => {
    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
        Data: {},
        selectedEmployees: []

    })

    const requestEmployees = async () => {
        try {
            let currentUser = parsedUser();
            let query = "PayrollId=" + props.model.id + "&OrganizationId=" + currentUser.OrganizationId;
            let request = await API.getAction("Employees/PayrollEmployeesAvailable", query);
            if (request.status === 200) {
                setState({
                    ...state,
                    Data: request.data.response
                })

            }
        }
        catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        console.log("props received on post", props);
        requestEmployees();
    }, [])

    const handleSubmit = async () => {

        let obj = {
            PayrollId: props.model.id,
            EmployeesId: ""
        };
        state.selectedEmployees.forEach(item => {
            obj.EmployeesId += (obj.EmployeesId.length > 0 ? "," : "") + item.id;
        })

        let request = await API.postAction("Employees/AssignEmployeesToPayroll", obj);

        if (request.status === 200) {
            props.toggle(true);
        }
    };


    const toggleSelect = async obj => {
        console.log("obj selected", obj)
        let copy = state.selectedEmployees

        let exist = copy.filter(x => x.id === obj.id)[0];

        if (!exist) {
            //Doesn't exist
            copy.push(obj);
        } else {
            //Exist
            copy = copy.filter(x => x.id !== obj.id);
        }
        console.log(copy)
        setState({
            ...state,
            selectedEmployees: copy
        })

    }

    return (
        <Modal show={props.show} onHide={() => props.toggle(null, true)} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Asignar empleados</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {state.Data.length > 0 && state.Data.map((item, index) => {
                    let itemParsed = item
                    let selectedEmployees = state.selectedEmployees;
                    let exist = selectedEmployees.filter(x => x.id === itemParsed.id)[0];
                    console.log(itemParsed, selectedEmployees, exist, state)
                    return (
                        <Row key={index} className="clickable" onClick={() => toggleSelect(itemParsed)}>
                            <Col className="col-auto">
                                {exist ?
                                    <i className="fa fa-check-square" /> :
                                    <i className="fa fa-square" />}
                            </Col>
                            <Col className="text-truncate">{itemParsed.firstName} {itemParsed.lastName}<br />
                                <small className="text-muted">{itemParsed.email}</small>
                            </Col>
                        </Row>
                    )
                })}

            </Modal.Body>
            <Modal.Footer className="text-right">
                <button className="btn btn-primary"
                    disabled={state.UI.isLoading}
                    onClick={() => handleSubmit()}>Asignar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignEmployees;