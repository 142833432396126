import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"
import { title } from "process"

const ExpenseTypeComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      modalSize="sm"
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Expense Types") ?? ""}
      getRecordsPath={`expense/types?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`expense/AddEditWarehouse?currentUserId=${user.ID}`}
      deleteRecordPath="expense/DeleteExpenseType"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },
        {
          name: "dgiiequivalentCode",
          title: t("dgii_code"),
          htmlType: "text",
          cssClass: 'text-center'
        },
        
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "dgiiequivalentCode",
          htmlType: "select",
          dbType: "string",
          title: t("dgii_code") ?? "",
          containerClass: "col-12",
          required: false,
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSourceLocal:[
            { id: 1, name:t("dgii_equivalent_code.personnel_expenses")},
            { id: 2, name:t("dgii_equivalent_code.Expenses_wss")},
            { id: 3, name:t("dgii_equivalent_code.leases")},
            { id: 4, name:t("dgii_equivalent_code.fixed_assets")},
            { id:5, name:t("dgii_equivalent_code.representation_expenses")},
            { id:6, name:t("dgii_equivalent_code.other_deductions")},
            { id:7, name:t("dgii_equivalent_code.financial_expenses")},
            { id:8, name:t("dgii_equivalent_code.extraordinary_expenses")},
            { id:9, name:t("dgii_equivalent_code.purchases_expenses")},
            { id:10, name:t("dgii_equivalent_code.asset_acquisitions")},
            { id:11, name:t("dgii_equivalent_code.insurance_expense")},
          ]
        },

       {name: "label",
        htmlType: "label",
        dbType:"string",
        
        containerClass: "col-12 text-muted",
        title: t("charges_select")??""
       },
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default ExpenseTypeComponent
