import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
import API from "../../common/API"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
  lastName: yup.string().required(),
  phone: yup.string().required(),
})
//Component
const ActivityDetails = props => {
  const { t } = useTranslation("global")

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  })

  const [model, setModel] = useState(props.model)

  const [showTemplate, setShowTemplate] = useState(false)
  useEffect(() => {
    console.log("props received on contact", props)
    request()
  }, [])

  const toggleTemplates = () => {
    setShowTemplate(!showTemplate)
  }

  const request = async () => {
    let query = "id=" + props.selectedId

    let requestAPI = await API.getAction("Appointments/Details?" + query)

    if (requestAPI.data.status === "ok") {
      setModel(requestAPI.data.response)
    }
  }
  const handleSubmit = async obj => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
    }

    let request = await API.postAction("Contact/AddEdit", obj)

    if (request.status === 200) {
      props.toggle(request.data, true)
    }
  }

  const formik = useFormik({
    initialValues: model ?? {},
    validationSchema,
    onSubmit: handleSubmit,
  })
  if (model === null) {
    return <></>
  }

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="lg"
      className="modalRight"
    >
      <div className="header px-2 py-2">
        <div
          className="row "
          style={{
            width: "100%",
          }}
        >
          <div className="col">
            <button className="btn btn-sm btn-outline-primary">
              <i className="fa fa-check "></i>&nbsp; {t("completed")}
            </button>
          </div>
          <div className="col-auto ml-auto text-right">
            <span>{t("priority")}: </span>
            <div className="btn-group">
              <button className="btn btn-sm btn-outline-warning">
                <i className="fas fa-exclamation"></i>
              </button>
              <button className="btn btn-sm btn-outline-danger">
                <i className="fas fa-exclamation"></i>

                <i className="fas fa-exclamation"></i>
              </button>
            </div>
            <button className="btn btn-sm" onClick={() => props.toggle()}>
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("type")}</Form.Label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input "
                  type="radio"
                  name="type"
                  id="inlineRadio1"
                  value="1"
                  checked={formik.values.type === 1}
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  {t("Activity")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type"
                  id="inlineRadio2"
                  value="2"
                  checked={formik.values.type === 2}
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  {t("meeting")}
                </label>
              </div>
            </Col>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("date")}</Form.Label>
              <Form.Control
                type="date"
                className="form-control-sm"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />
              {formik.errors.date ? (
                <div className="invalid text-sm">{formik.errors.date}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("status")}</Form.Label>
              <select
                value={formik.values.status}
                onChange={formik.handleChange}
                className="form-control form-control-sm"
                name="status"
              >
                <option value="">-</option>
                <option value="0">{t("to_be_made")}</option>
                <option value="1">{t("in_progress")}</option>
                <option value="2">{t("completed")}</option>
              </select>

              {formik.errors.status ? (
                <div className="invalid text-sm">{formik.errors.status}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("assigned_to")}</Form.Label>
              <Form.Control
                type="text"
                className="form-control-sm"
                name="assignedToId"
                value={formik.values.assignedToId}
                onChange={formik.handleChange}
              />
              {formik.errors.assignedToId ? (
                <div className="invalid text-sm">
                  {formik.errors.assignedToId}
                </div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("Guests")}</Form.Label>
              <Form.Control
                type="text"
                name="invitees"
                className="form-control-sm"
                value={formik.values.invitees}
                onChange={formik.handleChange}
              />
              {formik.errors.invitees ? (
                <div className="invalid text-sm">{formik.errors.invitees}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("ubication")}</Form.Label>
              <div className="input-group">
                <Form.Control
                  type="text"
                  className="form-control-sm"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.errors.location ? (
                <div className="invalid text-sm">{formik.errors.location}</div>
              ) : null}
            </Col>
            <Col xs={12} className="mt-2">
              <Form.Label>{t("notes")}</Form.Label>
              <textarea
                className="form-control form-control-sm"
                rows={5}
              ></textarea>

              {formik.errors.notes ? (
                <div className="invalid text-sm">{formik.errors.notes}</div>
              ) : null}
            </Col>
          </Row>
          {/* CHECKLIST */}
          <Row className="mt-4">
            <Col>{t("check_list")}</Col>
            <Col className="text-right">
              <div className="btn-group">
                <button className="btn btn-sm">
                  <i className="fa fa-plus"></i>
                </button>
                <button
                  className="btn btn-sm"
                  onClick={() => toggleTemplates()}
                >
                  <i className="fas fa-ellipsis-v"></i>
                </button>
              </div>
            </Col>
            <Col xs={12}>
              <hr className="my-0 " />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="input-group">
            <textarea rows={1} className="form-control"></textarea>

            <Button
              variant="primary"
              type="submit"
              className="btn-sm"
              disabled={state.UI.isLoading}
            >
              {t("comment")}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ActivityDetails
