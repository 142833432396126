import React from "react"
import emptyImg from "../assets/img/illustrations/no_content.svg"

const Empty = () => {
  return (
    <div className="row">
      <div className="col-6 mx-auto text-center">
        <img
          src={emptyImg}
          className="img-fluid"
          style={{ maxWidth: "50%" }}
          alt=""
        />
      </div>
    </div>
  )
}

export default Empty
