import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"

import API from "../../common/API"
import { useTranslation } from "react-i18next"
import { FormatMoney } from "../../common/helpers"
import ReportsContainerProps from "./ReportsContainerProps"

import moment from "moment"
import Filter from "./Filter/Filter"
import Settings from "../../common/Settings"
import { groupBy } from "lodash"

const ReportsContainer = (props: ReportsContainerProps) => {
  const [filterFields, setFilterFields] = useState(props.filterFields)
  const [filterObj, setFilterObj] = useState({})
  const [records, setRecords] = useState<any>([])
  

  
  const { t } = useTranslation("global")

  const bindDataRemote = async () => {
    try {
      let query = ""

      filterFields.forEach(item => {
        query += `&${item.name}=${filterObj[item.name]}`
      })
      const request = await API.getAction(props.endpoint + query)
      console.log(request)
      if (request.data.status === "ok") {
        if (props.groupBy) {
          let resp = groupBy(request.data.response, props.groupBy as string)
          setRecords(resp)
        } else {
          setRecords(request.data.response)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const format = (col: any, item: any) => {
    switch (col.type) {
      case "money":
        return FormatMoney(item[col.name])
      case "date":
        return moment(item[col.name]).format("MMMM DD, YYYY")

      default:
        return item[col.name]
    }
  }
  const updateFilter = (name: string, value: string) => {
    let filters = filterFields.map(item => {
      if (item.name === name) {
        item.value = value
      }
      return item
    })
    setFilterFields(filters)
  }

  const [generatingPDF, setGeneratingPDF] = useState(false)
  const GeneratePDF = async () => {
    let content = document.getElementById("content")
    let query = {
      html: content?.outerHTML,
    }
    setGeneratingPDF(true)
    let request = await API.postAction(
      "GeneratePDF/FromHTML?OnlyBody=true",
      query
    )
    console.log(request)
    //Now let's downlaod
    if (request.data.status === "ok") {
      const data = Settings.BasePath + "pdf/" + request.data.response.name

      const response = await fetch(data)

      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob)

        // Setting various property values
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = request.data.response.name
        alink.click()
        setGeneratingPDF(false)
      })
    }
  }

  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }

  useEffect(() => {
    bindDataRemote()
  }, [filterObj])
  useEffect(() => {
    bindDataRemote()
  }, [])

  const GroupedUI = () => {
    return (
      records &&
      Object.keys(records).map((item, index) => {
        return (
          <div key={index}>
            <h4 className="mt-4 ">{item}</h4>
            
            {RegularUI(records[item])}
          </div>
        )
      })
    )
  }

  const RegularUI = (items) => {
    let summaryObj: any = {}
    props.summaryFields &&
    props.summaryFields.forEach(item => (summaryObj[item.name] = 0))
    let ui = (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>#</th>
            {props.headers &&
              props.headers.map((item, index) => {
                return (
                  <th key={index} className={item.class}>
                    {item.title}
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {props.headers &&
                    props.headers.map((itemCol, indexCol) => {
                      if (Object.keys(summaryObj).includes(itemCol.name)) {
                        summaryObj[itemCol.name] += item[itemCol.name]
                      }
                      return (
                        <td key={indexCol} className={itemCol.class}>
                          {format(itemCol, item)}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            {props.summaryFields &&
              props.summaryFields.map((itemCol, indexCol) => {
                return (
                  <th
                    key={indexCol}
                    className={itemCol.class}
                    colSpan={itemCol.colspan}
                  >
                    <b>{format(itemCol, summaryObj)}</b>
                  </th>
                )
              })}
          </tr>
        </tfoot>
      </table>
    )
    return ui
  }

  return (
    <Modal size="xl" show={props.show} onHide={props.toggle}>
      <Modal.Header closeButton>
        <Modal.Title className="d-inline me-2">{t("report")}</Modal.Title>
        <button
          className="btn btn-primary btn-sm"
          disabled={generatingPDF}
          onClick={() => GeneratePDF()}
        >
          <i className="fa-solid fa-file-pdf"></i> {t("Download")}
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* FILTERS */}
        {filterFields && filterFields.length > 0 ? (
          <Filter
            filterAction={() => {
              bindDataRemote()
            }}
            fields={filterFields}
            setFilterFields={getFilterValues}
          />
        ) : null}
        {/* CONTENT */}
        
          <div className="table-responsive mt-4" id="content">
            <h4 className="text-center">{props.title}</h4>
            {props.groupBy ? GroupedUI() : RegularUI(records)}
          </div>
        
      </Modal.Body>
    </Modal>
  )
}

export default ReportsContainer
