import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  //   id: yup.string().required(),
  name: yup.string().required(),
  sequence: yup.string().required(),
})
//Component
const AddEditInvoiceType = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })
  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj)

    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    let request = await API.postAction("InvoiceTypes/AddEdit", obj)

    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model,
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    setTimeout(() => {
      var focus = document.getElementsByName("name")[0]
      if (focus) {
        focus.focus()
      }
    }, 500)
  }, [])
  if (!props.model) {
    return <></>
  }
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("type_invoices")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
          <Row>
            <Col xs={12} md={6}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("prefix")}</Form.Label>
              <Form.Control
                type="text"
                name="prefix"
                value={formik.values.prefix ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.prefix ? (
                <div className="invalid text-sm">{formik.errors.prefix}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("zeros_before")}</Form.Label>
              <Form.Control
                type="text"
                name="quantityOfZerosBefore"
                value={formik.values.quantityOfZerosBefore ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.quantityOfZerosBefore ? (
                <div className="invalid text-sm">
                  {formik.errors.quantityOfZerosBefore}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label>{t("sequence")}</Form.Label>
              <Form.Control
                type="text"
                name="sequence"
                value={formik.values.sequence ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.sequence ? (
                <div className="invalid text-sm">{formik.errors.sequence}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <Form.Label className="me-2">
                {t("valid_dgii")}
              </Form.Label>
              <input
                type="checkbox"
                name="validForDgii"
                checked={formik.values.validForDgii ? true : false}
                value={formik.values.validForDgii ?? ""}
                onChange={formik.handleChange}
              />
              {formik.errors.validForDgii ? (
                <div className="invalid text-sm">
                  {formik.errors.validForDgii}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label>{t("expiration_date")}</Form.Label>
              <Form.Control
                type="date"
                name="expirationDate"
                value={
                  formik.values.expirationDate
                    ? moment(formik.values.expirationDate).format("YYYY-MM-DD")
                    : ""
                }
                onChange={formik.handleChange}
              />
              {formik.errors.expirationDate ? (
                <div className="invalid text-sm">
                  {formik.errors.expirationDate}
                </div>
              ) : null}
            </Col>

            <Col xs={12} md={6}>
              <Form.Label className="me-2">{t("includeStamp")}</Form.Label>
              <input
                className="form-check-input "
                type="checkbox"
                value={formik.values.includeStamp ?? false}
                onChange={formik.handleChange}
                id="includeStamp"
                checked={formik.values.includeStamp ? true : false}
              />

              {formik.errors.includeStamp ? (
                <div className="invalid text-sm">
                  {formik.errors.includeStamp}
                </div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>{t("note")}</Form.Label>
              <textarea
                rows={5}
                className="form-control"
                name="defaultNote"
                value={formik.values.defaultNote ?? ""}
                onChange={formik.handleChange}
              ></textarea>
              {formik.errors.defaultNote ? (
                <div className="invalid text-sm">
                  {formik.errors.defaultNote}
                </div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>{t("disclaimer")}</Form.Label>
              <textarea
                rows={5}
                className="form-control"
                name="disclaimer"
                value={formik.values.disclaimer ?? ""}
                onChange={formik.handleChange}
              ></textarea>
              {formik.errors.disclaimer ? (
                <div className="invalid text-sm">
                  {formik.errors.disclaimer}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditInvoiceType
