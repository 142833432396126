import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"
import { Search } from "semantic-ui-react"
import { confirmAlert } from "react-confirm-alert"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

interface RecordsDTO {
  id: string
  workshopRecordDiagnosticId?: string | null
  itemType: string
  itemId: string
  itemConcept: string
  quantity: number
  deleted?: string | null
}
interface propsDTO {
  workshopRecordId: string
  record: any | null
  toggle: () => void
}

const DiagnosticAddEdit = (props: propsDTO) => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const [diagnosticRecords, setDiagnosticRecords] = useState<RecordsDTO[]>([])
  const [diagnosticRecord, setDiagnosticRecord] = useState<RecordsDTO | null>(
    null
  )
  const [record, setRecord] = useState<any | null>(null)
  const [searchValue, setSearchValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [itemsComplete, setItemsComplete] = useState([])

  const searchItems = async term => {
    try {
      let query = "search=" + term + "&organizationid=" + user.OrganizationId
      let request = await API.getAction("serviceProduct/list?" + query)
      if (request.data.status === "ok") {
        let grouped = [
          ...request.data.response.services,
          ...request.data.response.products,
        ]
        let parsed: any = []
        grouped.forEach((item: any) => {
          parsed.push(item)
        })
        setItemsComplete(parsed)
      }
    } catch (ex) {
      console.error(ex)
    }
  }
  const resultRendererItem = obj => {
    return <div>{obj.title}</div>
  }
  const deleteConfirm = async obj => {
    confirmAlert({
      title: t("confirm") ?? "",
      message: t("questions.sure_delete") + obj.itemConcept + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            let s = diagnosticRecords
            s.filter(x => x.id == obj.id)[0].deleted = moment().format()
            setDiagnosticRecords(s)
          },
        },
        {
          label: t("no"),
          onClick: () => console.log("Click No"),
        },
      ],
    })
  }

  const bindDataDetailsRemote = async () => {
    try {
      let query = "id=" + props.record
      setIsLoading(true)
      let request = await API.getAction("workshop/DiagnosticDetails?" + query)
      console.log(request.data.response)
      setIsLoading(false)
      let response = request.data.response
      if (response) {
        setRecord({
          id: response.id,
          date: response.date,
          workshopRecordId: response.workshopRecordId,
          note: response.note,
        })

        setDiagnosticRecords(response.workshopRecordDiagnosticDetails)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      console.log(record)
      //Prepare object
      let queryData = {
        workshopRecordId: props.workshopRecordId,
        note: record?.note,
        id: props.record,
        date: record?.date,
        subRecords: diagnosticRecords,
      }

      setIsLoading(true)
      const request = await API.postAction(
        "workshop/DiagnosticAddEdit",
        queryData
      )
      setIsLoading(false)
      console.log(request)
      if (request.data.status === "ok") {
        props.toggle()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(props)
    if (props.record !== null) {
      setRecord(props.record)

      bindDataDetailsRemote()
    } else {
      setRecord({
        id: null,
        workshopRecordId: props.workshopRecordId,
        Note: null,
        date: null,
      })
    }
  }, [props.record])

  return (
    <Modal show={true} onHide={props.toggle} size="lg" className=" modalRight">
      <Modal.Header closeButton>
        <Modal.Title>{t("Diagnostic")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={4} className="mb-4">
            <Search
              placeholder={t("product_service") ?? ""}
              className="concept"
              results={itemsComplete}
              minCharacters={1}
              value={searchValue}
              resultRenderer={resultRendererItem}
              onSearchChange={(event, data) => {
                console.log("event", event, "data", data)
                searchItems(data.value)
                setSearchValue(data.value + "")
              }}
              onResultSelect={(event, data) => {
                let theData = data.result
                let newRecord: RecordsDTO = {
                  id: uuidv4(),
                  workshopRecordDiagnosticId: null,
                  itemType: theData.type,
                  itemId: theData.id,
                  itemConcept: theData.title,
                  quantity: 1,
                  deleted: null,
                }
                setDiagnosticRecords([...diagnosticRecords, newRecord])

                setSearchValue("")
              }}
            ></Search>
          </Col>

          <Col xs={12}>
            <div>
              <table className="table table-striped bg-white border">
                <thead>
                  <tr>
                    <td>{t("concept")}</td>
                    <td>{t("quantity")}</td>

                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {diagnosticRecords
                    .filter(x => x.deleted == null)
                    .map((item, index) => {
                      let recordItem = item
                      console.log(item)
                      return (
                        <tr key={index}>
                          <td className="px-0 text-truncate">
                            {item.itemConcept}
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={recordItem.quantity}
                              min={1}
                              step={"any"}
                              onChange={e => {
                                let quantity = parseFloat(e.target.value) || 0

                                let newCollection = diagnosticRecords.map(
                                  subItem => {
                                    if (subItem.id === recordItem.id) {
                                      subItem.quantity = quantity
                                    }
                                    return subItem
                                  }
                                )

                                setDiagnosticRecords(newCollection)
                              }}
                            />
                          </td>

                          <td className="text-end">
                            <button
                              className="btn btn-sm btn-danger"
                              type="button"
                              onClick={() => {
                                deleteConfirm(recordItem)
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  {diagnosticRecords.length == 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        {t("addProductOrService")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          <Col>
            <label>{t("note")}</label>
            <textarea
              className="form-control"
              value={record && record.note}
              onChange={e => setRecord({ ...record, note: e.target.value })}
            ></textarea>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col className="text-end">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading && <i className="fa fa-spinner fa-pulse me-2"></i>}
              <i className="fa fa-save me-2"></i>
              {t("save")}
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default DiagnosticAddEdit
