import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../common/API"
import moment from "moment"
import { FormatMoney } from "../../common/helpers"
import { Routes } from "../../routes"
import { useParams } from "react-router"

interface PropsDTO {
  id: string
  toggle: () => void
}
const PaymentHistory = (props: PropsDTO) => {
	
  const { t } = useTranslation("global")
  const [records, setRecords] = useState<any>([])

  const bindDataRemote = async () => {
    try {
      let request = await API.getAction(
        "Subscription/PaymentHistory?id=" + props.id
      )
      setRecords(request.data.response)
    } catch (ex) {
      console.error(ex)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <Modal show={true} onHide={props.toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Payment History")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="table-responsive">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>{t("Date")}</th>
              <th>{t("Concept")}</th>
              <th>{t("Amount")}</th>
            </tr>
          </thead>
          <tbody>
            {records.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td
					className="clickable">
					<a href={Routes.PaymentHistoryDetails.clean+ item.id} 
					rel="noreferrer"
					target="_blank">
					{moment(item.date).format("MMM DD, YYYY")}
					</a>
					</td>
                  <td>{item.notes}</td>
                  <td>
                    {item.currency} {FormatMoney(item.amountPaid)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}

export default PaymentHistory
