import React, { useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  account: yup.string().required(),
  reference: yup.string().required(),
})
//Component
const AddEdit = props => {
  //   const state = useState({
  //     UI: {
  //       Validated: false,
  //     },
  //     Departments: [],
  //   })

  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
    Departments: [],
  })

  const user = parsedUser()

  const { t } = useTranslation("global")

  const handleSubmit = async (obj, event) => {
    try {
      console.log("values to submit", obj)

      if (obj.id.length === 0) {
        obj.id = uuidv4()
        obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

        obj.organizationId = user.OrganizationId
      }

      setState({
        ...state,
        UI: { ...state.UI, isLoading: !state.UI.isLoading },
      })

      let request = await API.postAction(
        "LedgerAccounts/AddEdit?currentUserId=" + user.ID,
        obj
      )

      setState({
        ...state,
        UI: { ...state.UI, isLoading: !state.UI.isLoading },
      })

      console.log("response from adding", request)
      if (request.status === 200) {
        props.toggle(null, true)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          account: "",
          reference: "",
          description: "",
          nature: "",
          parentAccountId: "",
          created: new Date(),
          id: "",
          organizationId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("accounting_account")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="account"
                value={formik.values.account}
                onChange={formik.handleChange}
              />
              {formik.errors.account ? (
                <div className="invalid text-sm">{formik.errors.account}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("accounting_code")}</Form.Label>
              <Form.Control
                type="text"
                name="reference"
                placeholder="1, 1.1, 1.1.2, 2, 2.1"
                value={formik.values.reference}
                onChange={formik.handleChange}
              />
              {formik.errors.reference ? (
                <div className="invalid text-sm">{formik.errors.reference}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("nature")}</Form.Label>
              <select
                className="form-control"
                id="nature"
                value={formik.values.nature}
                onChange={formik.handleChange}
              >
                <option value="">-</option>
                <option value="debit">{t("debtor")}</option>
                <option value="credit">{t("creditor")}</option>
              </select>

              {formik.errors.nature ? (
                <div className="invalid text-sm">{formik.errors.nature}</div>
              ) : null}
            </Col>

            <Col xs={12}>
              <Form.Label>{t("description")}</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description ? (
                <div className="invalid text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEdit
