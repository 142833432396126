import React, { useEffect, useState } from "react"
import API from "../../../common/API"
import { Routes } from "../../../routes"
import moment from "moment"
import { useHistory } from "react-router"
import AddEditPayroll from "./AddEditPayroll"
import emptyImg from "../../../assets/img/illustrations/payroll.svg"
import { parsedUser } from "../../../common/GetCurrentUser"
import { payrollStatusParsed, PayrollTypes } from "../../../common/helpers"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import Pagination from "../../core/Pagination"
import ActionBar from "../../core/ActionBar"
import { useTranslation } from "react-i18next"
import Filter from "../../core/Filter/Filter"
import SubscriptionStatusComponent from "../../Subscriptions/SubscriptionStatusComponent"
import { Card } from "react-bootstrap"

const PayrollList = props => {
  const { t } = useTranslation("global")
  const [showAddEdit, setShowAddEdit] = useState(false)
  const history = useHistory()
  const user = parsedUser()
  const [state, setState] = useState({
    UI: {
      isLoading: false,
      showAddEdit: false,
      PayrollSelected: null,
    },
    currentPage: 0,
    Quantity: 20,
    TotalPages: 0,
    Payrolls: [],
  })

  // FILTER RELATED
  const [showFilter, setShowFilter] = useState(false)
  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }
  const filterFields = [
    {
      name: "payrollCategoryId",
      title: "payrollCategory",
      dbType: "text",
      htmlType: "select",
      containerClass: "col-4",
      dataSourceValueField: "id",
      dataSourceTextField: "name",
      dataSource:
        "employees/payrollCategories?OrganizationId=" + user?.OrganizationId,
    },
    {
      name: "from",
      title: "from",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-4",
    },
    {
      name: "until",
      title: "until",
      htmlType: "date",
      dbType: "date",
      containerClass: "col-4",
    },
    {
      name: "search",
      title: "search",
      htmlType: "text",
      dbType: "date",
      containerClass: "col-4",
    },
  ]
  const [filterObj, setFilterObj] = useState({})
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  useEffect(() => {
    if (filterObj) {
      bindDataRemote()
    }
  }, [filterObj])
  // FILTER RELATED END
  const toggleAddEdit = async (obj, reload) => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        PayrollSelected: obj,
        showAddEdit: !state.UI.showAddEdit,
      },
    })

    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      await bindDataRemote()
    }
  }
  const bindDataRemote = async () => {
    let query = "OrganizationId=" + user.OrganizationId
    query += "&Quantity=" + state.Quantity + "&Page=" + state.currentPage

    //Add filter here
    Object.keys(filterObj).forEach(element => {
      query += `&${element}=${filterObj[element]}`
    })

    let requestAPI = await API.getAction("Employees/ListPayroll?" + query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Payrolls: requestAPI.data.response,

        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  const onPageChanged = async obj => {
    setState({
      ...state,
      currentPage: obj,
    })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: "Confirm",
      message: "Are you sure to delete " + obj.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await API.getAction(
              "Employees/DeletePayroll?id=" +
              obj.id +
              "&UserId=" +
              currentUser.ID
            )
            await bindDataRemote()
          },
        },
        {
          label: "No",
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }
  useEffect(() => {
    if (user === null) {
      history.push(Routes.SignIn.path)
    }
    bindDataRemote()
  }, [state.currentPage])

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <>
      <SubscriptionStatusComponent hr />
      {showAddEdit ? (
        <AddEditPayroll
          toggle={toggleAddEdit}
          show={true}
          model={state.UI.PayrollSelected}
        />
      ) : null}
      {props.Full ? (
        <ActionBar
          title={t("payroll")}
          icon={"fa fa-id-card"}
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                onClick={() => toggleAddEdit()}
              >
                <i className="fa fa-plus " />
              </button>
              <button
                className={
                  "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                }
                onClick={() => toggleFilter()}
              >
                <i className="fa fa-filter" />
              </button>
            </>
          }
        ></ActionBar>
      ) : null}

      {/* FILTERS */}
      {showFilter && filterFields && filterFields.length > 0 && (
        <Filter
          filterAction={() => {
            bindDataRemote()
          }}
          fields={filterFields}
          setFilterFields={getFilterValues}
        />
      )}

      <div className="card shadow-sm mt-2">
        <div className="card-body p-0 ">
          <div className="table-responsive">
            {state.Payrolls.length > 0 ? (
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("name")}</th>
                    <th>{t("status")}</th>
                    <th>{t("frequency")}</th>
                    <th>{t("category")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {state.Payrolls.map((item, index) => {
                    let parsedITem = JSON.parse(JSON.stringify(item))
                    return (
                      <tr key={index}>
                        <td>
                          <small className="text-muted">
                            {item.from
                              ? moment(item.from).format("MMM DD, YYYY")
                              : null}
                          </small>
                          {item.until ? (
                            <small className="text-muted mr-2">
                              &nbsp;-{" "}
                              {moment(item.until).format("MMM DD, YYYY")}{" "}
                            </small>
                          ) : null}
                        </td>
                        <td
                          className="clickable"
                          onClick={() =>
                            history.push(Routes.PayrollDetails.clean + item.id)
                          }
                        >
                          {item.name}
                        </td>
                        <td>
                          {item.status === 1 ? (
                            <small className="badge bg-warning badge-sm ">
                              {payrollStatusParsed(item.status)}
                            </small>
                          ) : null}
                          {item.status === 2 ? (
                            <small className="badge bg-success badge-sm ">
                              {payrollStatusParsed(item.status)}
                            </small>
                          ) : null}
                          {item.status === 3 ? (
                            <small className="badge bg-danger badge-sm ">
                              {payrollStatusParsed(item.status)}
                            </small>
                          ) : null}
                        </td>
                        <td>
                          {item.payrollTypeId === PayrollTypes.biWeekly
                            ? t("Bi-Weekly")
                            : item.payrollTypeId === PayrollTypes.monthly
                              ? t("Monthly")
                              : "N/A"}
                        </td>
                        <td>
                          {item.payrollCategory
                            ? item.payrollCategory.name
                            : "N/A"}
                        </td>
                        <td className="text-end">
                          {props.noOptions ? null : (
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => toggleAddEdit(parsedITem, false)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(parsedITem)}
                                type="button"
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <img
                src={emptyImg}
                className="img-fluid"
                style={{ maxWidth: "50%" }}
                alt=""
              />
            )}
          </div>
        </div>

      </div>
      <Card className=" my-4">
        <Card.Body className="p-1 px-2">
          <Pagination
            totalPages={state.TotalPages}
            currentPage={state.currentPage}
            onPageChanged={onPageChanged}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default PayrollList
