import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"
import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"

//Translation
import { useTranslation } from "react-i18next"

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
})
//Component
const AddEditExpenseType = props => {
  // const state = useState({
  //     UI: {
  //       Validated: false,
  //     },
  //   })
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })

  const user = parsedUser()

  const { t } = useTranslation("global")

  const AddEditPercentage = async item => {
    let prodTaxes = formik.values.expenseTypePercentageAssigned
    let exist = prodTaxes.filter(
      x => x.taxesId === item.id && x.deleted === null
    )[0]

    if (exist) {
      //means we are deleting

      prodTaxes.filter(
        x => x.taxesId === item.id && x.deleted === null
      )[0].deleted = moment().format()
      exist.deleted = moment().format()
    } else {
      exist = {
        id: null,
        expenseTypeId: formik.values.id,
        expenseTypePercentageId: item.id,
      }
      prodTaxes.push(exist)
    }

    await API.postAction("expense/AddEditExpenseTypePercentage", exist)

    // formik.setFieldValue("productTaxes", prodTaxes)
  }

  const handleSubmit = async (obj, event) => {
    if (obj.id.length === 0) {
      obj.id = uuidv4()
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

      obj.organizationId = user.OrganizationId
    }

    // state.UI.isLoading.set(!state.UI.isLoading.value)
    setState({ ...state, UI: { isLoading: true } })
    let request = await API.postAction("expense/AddEditExpenseType", obj)
    // state.UI.isLoading.set(!state.UI.isLoading.value)
    setState({ ...state, UI: { isLoading: false } })
    console.log("response from adding", request)
    if (request.status === 200) {
      props.toggle(null, true)
    }
  }
  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          created: new Date(),
          id: "",
          organizationId: "",
          dgiiequivalentCode: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  })

  const [percentages, setPercentages] = useState([])
  const requestPercentageData = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("expense/percentages", Query)

    if (requestAPI.data.status === "ok") {
      setPercentages(requestAPI.data.response)
    }
  }

  useEffect(() => {
    requestPercentageData()
    //   loadDepartments();
  }, [])

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("type_expenses")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>

            <Col xs={12} className="mt-2">
              <Form.Label>{t("dgii_equivalent_code")}</Form.Label>
              <select
                value={formik.values.dgiiequivalentCode}
                name="dgiiequivalentCode"
                onChange={e => {
                  formik.handleChange(e)
                  console.log(e.target.value)
                  console.log(formik.values.dgiiequivalentCode)
                }}
                className="form-control"
              >
                <option value="">-</option>
                <option value="1">
                  {t("dgii_equivalent_code.personnel_expenses")}
                </option>
                <option value="2">
                  {t("dgii_equivalent_code.Expenses_wss")}
                </option>
                <option value="3">{t("dgii_equivalent_code.leases")}</option>
                <option value="4">
                  {t("dgii_equivalent_code.fixed_assets")}
                </option>
                <option value="5">
                  {t("dgii_equivalent_code.representation_expenses")}
                </option>
                <option value="6">
                  {t("dgii_equivalent_code.other_deductions")}
                </option>
                <option value="7">
                  {t("dgii_equivalent_code.financial_expenses")}
                </option>
                <option value="8">
                  {t("dgii_equivalent_code.extraordinary_expenses")}
                </option>
                <option value="9">
                  {t("dgii_equivalent_code.purchases_expenses")}
                </option>
                <option value="10">
                  {t("dgii_equivalent_code.asset_acquisitions")}
                </option>
                <option value="11">
                  {t("dgii_equivalent_code.insurance_expense")}
                </option>
              </select>

              <a
                className="btn btn-link btn-sm"
                href="https://dgii.gov.do/servicios/formularios/formatoEnvioDatos/Documents/Formato%20de%20compras,%20bienes%20y%20servicios/Formato-de-Envio-606-(NG-07-2018-y-05-2019).zip"
                rel="noreferrer"
                target="_blank"
              >
                {t("download_606")}
              </a>
              {formik.errors.status ? (
                <div className="invalid text-sm">{formik.errors.status}</div>
              ) : null}
            </Col>

            <Col xs={12} className="mt-3">
              <Form.Label>{t("taxes")}</Form.Label>
              {percentages &&
                percentages.map((item, index) => {
                  let current = item

                  let exist =
                    props.model.expenseTypePercentageAssigned &&
                    props.model.expenseTypePercentageAssigned.filter(
                      x =>
                        x.expenseTypePercentageId === item.id &&
                        x.deleted === null
                    )[0]
                  console.log(
                    "percentages",
                    props.model,
                    exist,
                    "percentage id",
                    item
                  )
                  return (
                    <p key={index}>
                      <input
                        type="checkbox"
                        defaultChecked={exist}
                        value={item.id}
                        onClick={() => AddEditPercentage(current)}
                        id={"percentage_" + item.id}
                      />
                      <label htmlFor={"percentage_" + item.id} className="mx-2">
                        {item.name} {item.value} %
                      </label>
                    </p>
                  )
                })}
            </Col>

            <Col xs={12} className="mt-4">
              <p>{t("expenses_type.charges_select")}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditExpenseType
