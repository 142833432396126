import React, { useEffect, useState } from "react"

import { Col, Row, Container } from "react-bootstrap"

import API from "../../common/API"
import { Routes } from "../../routes"
import BgImage from "../../assets/img/illustrations/signin.svg"

import { userAvatar } from "../../common/helpers"
import { parsedUser } from "../../common/GetCurrentUser"
import AddEditOrganization from "./AddEditOrganization"
import { useHistory } from "react-router-dom"

//Translation
import { useTranslation } from "react-i18next"
import Logo from "../Common/Logo"

const SelectOrganization = props => {
  const history = useHistory()

  const [organizations, setOrganizations] = useState([])
  const [state, setState] = useState({
    showEdit: false,
  })
  const [user, setUser] = useState(null)

  const { t } = useTranslation("global")

  useEffect(() => {
    setUser(parsedUser())
    requestOrganizations()
  }, [])

  const requestOrganizations = async () => {
    let theUser = parsedUser()

    console.log("render side bar", new Date(), user, theUser)
    if (theUser === null) {
      return
    }
    let requestAPI = await API.getAction(
      "Organization/OrganizationsWhereTheUserBelong?AccountId=" + theUser.ID
    )

    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response)
    }
  }

  const changeTheOrganization = async obj => {
    let theUser = parsedUser()
    let requestAPI = await API.getAction(
      "Organization/SetOrganization?AccountId=" +
        theUser.ID +
        "&OrganizationId=" +
        obj
    )

    if (requestAPI.data.status === "ok") {
      localStorage.setItem("LogedUser", requestAPI.data.response)
      history.push(Routes.Home.path)
    }
  }

  const toggleAddEdit = async reload => {
    setState({
      ...state,
      showEdit: !state.showEdit,
    })
    if (reload) {
      await requestOrganizations()
    }
  }

  return (
    <main>
      {state.showEdit ? (
        <AddEditOrganization show={state.showEdit} toggle={toggleAddEdit} />
      ) : null}
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
         
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light px-4 px-lg-5 py-3 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">{t("select_organization")}</h5>
                  <hr />
                </div>
                {organizations
                  ? organizations.map((item, index) => (
                      <Row key={index}>
                        <div className="col-auto">
                          <img
                            src={userAvatar(item.name, 40)}
                            className="img-fluid rounded"
                          />
                        </div>
                        <Col
                          className="clickable "
                          onClick={() => {
                            changeTheOrganization(item.id)
                          }}
                        >
                          <span>{item.name}</span>
                          <br />
                          <small className="text-muted">
                            {item.creatorName} ({item.creatorEmail})
                          </small>
                        </Col>
                        <Col xs={12}>
                          <hr />
                        </Col>
                      </Row>
                    ))
                  : null}
                <Row>
                  <Col className="text-center mt-4" xs={12}>
                    
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => toggleAddEdit()}
                    >
                      {t("create_organization")}
                    </button>
                    <br />
                    <button
                      className="btn btn-link"
                      onClick={() => {
                        props.history.push(Routes.SignIn.path)
                      }}
                    >
                      {t("logout")}
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default SelectOrganization
