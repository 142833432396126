import moment from "moment"
import React, { useEffect, useState } from "react"
//import { useParams } from "react-router-dom";
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import Settings from "../../common/Settings"
import "../../css/app_print.css"

//Translation
import { useTranslation } from "react-i18next"

const Print = props => {
  const [state, setState] = useState(null)
  const [subTotalGeneral, setSubtotalGeneral] = useState(0)
  const [TotalTaxes, setTotalTaxes] = useState(0)

  const { t } = useTranslation("global")

  const request = async () => {
    let requestAPI = await API.getAction(
      "quote/Details?Id=" + props.match.params.id
    )

    if (requestAPI.data.status === "ok") {
      setState(requestAPI.data.response)
    }
  }

  const summaryCalc = () => {
    let totalTaxes = 0,
      subTotalGeneral = 0
    state &&
      state.quoteDetails
        .filter(x => x.deleted === null)
        .forEach((item, index) => {
          let calculateDisocunt =
            item.discount && item.discount > 0
              ? item.price * (item.discount / 100)
              : 0
          let tax = (item.tax / 100) * (item.price - calculateDisocunt)
          tax = tax * item.quantity
          let subTotal = (item.price - calculateDisocunt) * item.quantity
          totalTaxes += tax
          subTotalGeneral += subTotal

          setTotalTaxes(totalTaxes)
          setSubtotalGeneral(subTotalGeneral)
        })
  }
  useEffect(() => {
    summaryCalc()
  }, [state])
  useEffect(() => {
    request()
    // setTimeout(() => {
    //   if (window.location.href.includes("printExecute")) {
    //     window.print()
    //   }
    // }, [1000])
  }, [])

  if (state === null) {
    return <></>
  }

  return (
    <>
      <section className="invoice bg-white">
        <div className="row m-2">
          <div className="col-8">
            <h3 className="page-header text-left">
              {state.organization.logo ? (
                <img
                  src={`${Settings.BasePath}Files/${state.organization.logo}?h=100`}
                  title={state.organization.name}
                  alt={state.organization.name}
                  style={{ maxHeight: "100px" }}
                />
              ) : (
                state.organization.name
              )}
            </h3>
          </div>
          <div className="col-4 text-right">
            <b>
              {t("quote")} # {state.sequence}
            </b>
          </div>
        </div>

        <div className="row invoice-info m-2">
          <div className="col-6">
            <div className="col-12 ">
              <address>
                <strong>{state.organization?.name}</strong>
                <br />
                {state.organization.identifier}
                <br />
                {`${state.organization.address ?? ""}, ${
                  state.organization.city === null
                    ? ""
                    : state.organization.city
                }, 
                ${
                  state.organization.zipCode === null
                    ? ""
                    : state.organization.zipCode
                }, ${state.organization.country}`.replace(", ,", ", ")}
                <br />

                <br />
                {state.organization.phone ? (
                  <>
                    {t("phone")}
                    {`: ${state.organization.phone}`}
                    <br />
                  </>
                ) : null}

                {state.organization.email ? (
                  <>
                    {t("email")}
                    {`: ${state.organization.email}`}
                  </>
                ) : null}
              </address>
            </div>
            <div className="col-sm-12 col-md-12 CustomerInfo">
              <address>
                <strong>{`${state.customer.firstName} ${
                  state.customer.lastName ?? ""
                }`}</strong>
                <br />
                {state.customer.identifier}
                <br />
                {`${state.customer.address ?? ""}, ${
                  state.customer.city ?? ""
                }, ${state.customer.zipCode ?? ""}, ${
                  state.customer.country
                }`.replace(", ,", ", ")}
                <br />
                {state.customer.phone ? (
                  <>
                    {t("phone")}
                    {`: ${state.customer.phone}`}
                    <br />
                  </>
                ) : null}

                {state.customer.email ? (
                  <>
                    {t("email")}
                    {`: ${state.customer.email}`}
                  </>
                ) : null}
              </address>
            </div>
          </div>

          <div className="col-6 text-right">
            <b>{t("date")}: </b>
            {moment(state.date).format("MMM DD, YYYY")}
            <br />

            <b>{t("time")}: </b>

            {moment(state.date).format("HH:mm:ss ")}
            {state.currency?.name ? (
              <>
                <br />
                <b>{t("currency")}: </b> {state.currency?.name}
              </>
            ) : null}
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12 table-responsive">
            <table id="details" className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-md-6 py-1">{t("concept")}</th>
                  <th className="col-md-1 py-1">{t("quantity")}</th>
                  <th className="col-md-1 py-1">{t("unit_price")}</th>
                  <th className="col-md-1  py-1">{t("discount")}</th>
                  <th className="col-md-1  py-1">{t("taxes")}</th>
                  <th className="col-md-1 py-1">{t("sub_total")}</th>
                </tr>
              </thead>
              <tbody>
                {state.quoteDetails
                  .filter(x => x.deleted === null)
                  .map((item, index) => {
                    let calculateDiscount =
                      item.discount && item.discount > 0
                        ? item.price * (item.discount / 100)
                        : 0
                    let tax =
                      (item.tax / 100) * (item.price - calculateDiscount)
                    let subTotal =
                      (item.price - calculateDiscount) * item.quantity
                    tax = tax * item.quantity
                    return (
                      <tr key={index}>
                        <td className="py-1 text-wrap">{item.itemConcept}</td>
                        <td className="py-1">{item.quantity}</td>
                        <td className="py-1">{FormatMoney(item.price)}</td>
                        <td className="py-1">
                          {FormatMoney(item.discount ?? 0)} %
                        </td>
                        <td className="py-1">{FormatMoney(tax)}</td>
                        <td className="py-1">{FormatMoney(subTotal)}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-6">
            {state.note ? (
              <div
                className="text-muted well well-sm no-shadow"
                dangerouslySetInnerHTML={{
                  __html: state.note.replace(/\n/g, "<br />"),
                }}
              ></div>
            ) : null}
          </div>

          <div className="col-6">
            <p className="lead">{t("balance")}</p>

            <div className="table-responsive">
              <table className="table summary p-0 m-0">
                <tbody>
                  <tr className="subtotal">
                    <th className=" py-1">{t("sub_total")}:</th>
                    <td className="text-right py-1">
                      {FormatMoney(subTotalGeneral)}
                    </td>
                  </tr>
                  <tr className="taxes">
                    <th className=" py-1">{t("taxes")}:</th>
                    <td className="text-right py-1">
                      {FormatMoney(TotalTaxes)}
                    </td>
                  </tr>

                  <tr className="total">
                    <th className=" py-1">{t("total")}:</th>
                    <td className="text-right py-1">
                      {FormatMoney(subTotalGeneral + TotalTaxes)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="d-print-none ">
              <button
                className="btn btn-primary btn-block "
                type="button"
                onClick={() => {
                  window.print()
                }}
              >
                {t("print")}
              </button>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Print
