import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const PositionsComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("positions") ?? ""}
      getRecordsPath={`Employees/PositionList?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath="Employees/PositionAddEdit"
      deleteRecordPath="Employees/DeletePosition"
      fieldsList={[
        {
          name: "departmentName",
          title: t("department"),
        },
        {
          name: "name",
          title: t("Name"),
        },
       
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "departmentId",
          htmlType: "select",
          dbType: "string",
          title: t("department") ?? "",
          dataSource: 'employees/departments?OrganizationId=' + user.OrganizationId,
          dataSourceValueField: "id",
          dataSourceTextField: "name",
          containerClass: "col-12",
          required: true,
        },
       
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
       
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  )
}

export default PositionsComponent
