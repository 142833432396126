import * as React from "react"
import { useEffect, useState } from "react"

import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban"

import "./overview.css"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { getAbbreviation } from "../../common/helpers"
import { Card, Col, Row } from "react-bootstrap"
/**
 * Kanban Overview sample
 */
const MaintenanceKanbanView = props => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const [selectedItem, setSelectedItem] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  const [statusColumns, setStatusColumns] = useState([])

  const bindStatus = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("workshop/ListStatus", Query)

    if (requestAPI.data.status === "ok") {
      setStatusColumns(requestAPI.data.response)
    }
  }

  const columnTemplate = props => {
    let record = statusColumns.filter(x => x.id === props.keyField)[0]
    return (
      <div
        className="header-template-wrap"
        style={{ borderBottom: "solid 1px " + props.color }}
      >
        <i
          className="fa fa-square"
          style={{ color: record.color ?? "#000" }}
        ></i>
        <div className="header-text d-inline ms-2">
          {props.headerText.toUpperCase()}
        </div>
        {/* <button className="btn btn-sm btn-primary">
          <i className="fa fa-plus"></i>
        </button> */}
      </div>
    )
  }
  const cardTemplate = item => {
    console.log(item)
    let equipment = item && item.product
    let assignee =
      item && item.assigned
        ? item.assigned.firstName + " " + item.assigned.lastName
        : "-"
    //  console.log("template", props)
    let equipmentName = equipment
      ? equipment.barcode + " > " + equipment.brand + " " + equipment.name
      : ""
    return (
      <Card>
        <Card.Header className="p-1 bg-white">
          <small className="text-truncated" title={equipmentName}>
             {`${equipmentName}`}
          </small>
        </Card.Header>
        {props.extendedView && (
          <Card.Body className="p-1 bg-white">
            <small className="text-muted">
              {moment(item.created).fromNow()}
            </small>
            <br />
            <small>
              <b>{item.customer.firstName}</b>
            </small>
          </Card.Body>
        )}
        <Card.Footer className="p-1">
          <Row>
            <Col>
              <button
                type="button"
                onClick={() => props.markCompleted(item)}
                className={
                  "btn btn-sm d-inline ms-2 " +
                  (item.completed ? "btn-success" : "btn-outline-success")
                }
              >
                <i className="fa fa-check "></i>
              </button>
            </Col>
            <Col>
              <small className="badge bg-dark">
                {getAbbreviation(assignee)}
              </small>
            </Col>
            <Col className="text-end">
              <div className="btn-group">
                <button
                  onClick={() => props.toggleDetails(item)}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  <i className="fa fa-edit" />
                </button>

                <button
                  type="button"
                  onClick={() => props.delete(item)}
                  className="btn btn-primary btn-sm"
                >
                  <i className="fa fa-trash text-danger" />
                </button>
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    )
  }

  const toggleDetails = obj => {
    setSelectedItem(obj)
    setShowDetails(!showDetails)
  }

  useEffect(() => {
    bindStatus()
  }, [])

  if (!props.records) {
    return <></>
  }

  return (
    <KanbanComponent
      id="kanban"
      //cssClass="kanban-overview"
      keyField="workshopStatusId"
      dataSource={props.records ?? []}
      enableTooltip={false}
      // swimlaneSettings={{ keyField: "Assignee" }}
      cardSettings={{
        headerField: "id",
        template: cardTemplate.bind(this),
        selectionType: "single",
      }}
      cardDoubleClick={obj => {
        console.log(obj)
        props.toggleDetails(obj.data)
        obj.cancel = true
        return
        //  obj.preventDefault()
      }}
      dragStop={obj => {
        console.log(obj.target, obj)
      }}
      width="100%"
      height="70vh"

      // dialogSettings={{ fields: fields }}
      // cardRendered={cardRendered.bind(this)}
    >
      <ColumnsDirective>
        {statusColumns &&
          statusColumns.map((item, index) => {
            console.log(item)
            return (
              <ColumnDirective
                key={index}
                headerText={item.name}
                keyField={item.id}
                // allowToggle={true}
                allowDrag={false}
                template={columnTemplate.bind(this)}
              />
            )
          })}
      </ColumnsDirective>
    </KanbanComponent>
  )
}
export default MaintenanceKanbanView
