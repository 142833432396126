import React, { useEffect } from "react"
import { Routes } from "../../routes"
import InvoiceList from "./InvoiceList"

//Translation
import { useTranslation } from "react-i18next"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("invoices")
  })
  return (
    <div className="row">
      <div className="col-12">
        <InvoiceList Full />
      </div>
    </div>
  )
}
export default Index
