import { TransactionType, PayrollTypes, TransactionCalculationType } from "../../../common/helpers";



export const divisionOfTheAmount = (
  basePayrollTypeForUser,
  basePayrollType,
  item,
  baseSalary
) => {



  let total = 0;

  let amount = 0

  switch (item.transactionType) {
    case TransactionCalculationType.percentage:
      amount = item.amount / 100 * baseSalary;
      console.log(basePayrollTypeForUser, basePayrollType, item, baseSalary, amount)
      
      break;
    case TransactionCalculationType.factor:
      amount = baseSalary / item.amount;
      break;

    case TransactionCalculationType.amount:
      console.log(basePayrollTypeForUser, item.amount);
      amount = (basePayrollTypeForUser === PayrollTypes.biWeekly ? item.amount / 2 : 
                basePayrollTypeForUser === PayrollTypes.monthly ? 
                item.amount : item.amount);
      break;
  }

  console.log(amount, baseSalary, item);
  switch (basePayrollType) {
    case PayrollTypes.biWeekly:
      if (basePayrollTypeForUser === PayrollTypes.biWeekly) {
        total += amount;
      }
      if (basePayrollTypeForUser === PayrollTypes.monthly) {
        total += amount / 2;
      }
      if (basePayrollTypeForUser === PayrollTypes.weekly) {
        total += amount / 4;
      }
      break;
    case PayrollTypes.monthly:
      if (basePayrollTypeForUser === PayrollTypes.biWeekly) {
        total += amount * 2;
      }
      if (basePayrollTypeForUser === PayrollTypes.monthly) {
        total += amount;
      }
      if (basePayrollTypeForUser === PayrollTypes.weekly) {
        total += amount * 4;
      }
      break;
  }
  return { ActualValue: amount, NewValue: total };
};

export const summaryPerTransactions = (employee, type, payrollType) => {
  
  let total = 0;
  let base = 0;
  let baseWithoutDivision = 0;
  let basePayrollTypeForUser = employee.employee.payrollTypeId;
  let basePayrollType = payrollType;
  let benefits, discounts, baseSalary, discountToPay,
    benefitsToPay
  switch (type) {
    case 0: //Means calculate the summary of all
      benefits = summaryPerTransactions(employee, 2, basePayrollType);
      discounts = summaryPerTransactions(employee, 3, basePayrollType);
      total =
        summaryPerTransactions(employee, 1, basePayrollType) +
        benefits -
        discounts;

      break;
      case TransactionType.globalBaseAmount: ///base amount
      
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.baseAmount)
        .forEach((item) => {
          
          total +=
            item.amount;
          
        });
      break;
    case TransactionType.baseAmount: ///base amount
      
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.baseAmount)
        .forEach((item) => {
          
          total += divisionOfTheAmount(
            basePayrollTypeForUser,
            basePayrollType,
            item,
            item.amount
          ).NewValue;
        });
      break;
    case TransactionType.benefits: ///Benefits
      base = summaryPerTransactions(employee, TransactionType.baseAmount);
      baseWithoutDivision = summaryPerTransactions(
        employee,
        5,
        basePayrollType
      );
      
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.benefits && !x.visibleByTheCompanyOnly)
        .forEach((item) => {
          console.log(item)
          total += divisionOfTheAmount(
            basePayrollTypeForUser,
            basePayrollType,
            item,
            baseWithoutDivision
          ).NewValue;
          //total += item.transactionType === 1 ? item.amount / 100 * base : item.transactionType === 2 ? base / item.amount : item.amount;
        });
      break;
    case TransactionType.discounts: ///discounts
      base = summaryPerTransactions(employee, TransactionType.baseAmount, basePayrollType);
      baseWithoutDivision = summaryPerTransactions(
        employee,
        5,
        basePayrollType
      );

      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.discounts)
        .forEach((item) => {
          total += divisionOfTheAmount(
            basePayrollTypeForUser,
            basePayrollType,
            item,
            baseWithoutDivision
          ).NewValue;
          //total += item.transactionType === 1 ? item.amount / 100 * base : item.transactionType === 2 ? base / item.amount : item.amount;
        });
      break;
    case 4: //Calculate the cost for the company
      baseSalary = summaryPerTransactions(employee, TransactionType.baseAmount, basePayrollType);

      //Benefits
      benefitsToPay = 0;
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.benefits)
        .forEach((item) => {
          benefitsToPay += divisionOfTheAmount(
            basePayrollTypeForUser,
            basePayrollType,
            item,
            baseSalary
          ).NewValue;
          //benefitsToPay += item.transactionType === 1 ? item.amount / 100 * baseSalary : item.transactionType === 2 ? baseSalary / item.amount : item.amount;
        });
      //Discount
      discountToPay = 0;
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.discounts)
        .forEach((item) => {
          discountToPay += divisionOfTheAmount(
            basePayrollTypeForUser,
            basePayrollType,
            item,
            baseSalary
          ).NewValue;
          //discountToPay += item.transactionType === 1 ? item.amount / 100 * baseSalary : item.transactionType === 2 ? baseSalary / item.amount : item.amount;
        });
      //debugger;
      total = baseSalary + benefitsToPay; //- discountToPay;
      break;
    case 5: ///base amount without division
      
      employee.payrollEmployeeTransactions
        .filter((x) => x.type === TransactionType.baseAmount)
        .forEach((item) => {
          total += item.amount;
        });
      break;
  }

  return total;
};
