

import Assets from "../components/Products/assets";



const AssetsPage = () => {
    return (<Assets />)
}

export default AssetsPage;