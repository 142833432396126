import React, { useEffect } from "react"
import { Routes } from "../../routes"

import AccountReceivableList from "./AccountReceivableList"

//Translation
import { useTranslation } from "react-i18next"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("account_receivables")
  })
  return (
    <div className="row">
      <nav aria-label="breadcrumb" className="mb-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">
              <i className="fa fa-home"></i>
            </a>
          </li>

          <li className="breadcrumb-item">
            <a href={Routes.AccountReceivables.path}>
              {t("account_receivables")}
            </a>
          </li>
        </ol>
      </nav>
      <div className="col-12">
        <AccountReceivableList Full />
      </div>
    </div>
  )
}
export default Index
