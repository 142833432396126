import React, { createContext, useState, useEffect } from "react"

export const PreferencesContext = createContext()

const PreferencesContextProvider = props => {
  const [registration, setRegistration] = useState()

  return (
    <PreferencesContext.Provider value={{ registration, setRegistration }}>
      {props.children}
    </PreferencesContext.Provider>
  )
}

export default PreferencesContextProvider
