import { parsedUser } from "../../common/GetCurrentUser";
import CRUDField from "../core/genericCRUD/CRUDField";
const user = parsedUser()

const InvoiceFilterFields : CRUDField[]= [
	{
		name: "currencyId",
		title: "currency",
		dbType: "text",
		htmlType: "select",
		containerClass: "col-4",
		dataSourceValueField: "id",
		dataSourceTextField: "name",
		dataSource: "Currencies/List?OrganizationId=" + user?.OrganizationId
	},
	{
		name: "invoiceTypeId",
		title: "invoiceTypeId",
		dbType: "text",
		htmlType: "select",
		containerClass: "col-4",
		dataSourceValueField: "id",
		dataSourceTextField: "name",
		dataSource: "invoiceTypes/List?OrganizationId=" + user?.OrganizationId
	},
	{
		name: "invoiceStatus",
		title: "invoiceStatus",
		htmlType: "select",
		containerClass: "col-4",
		dbType: "text",
		dataSourceValueField: "id",
		dataSourceTextField: "name",
	//	dataSourceAddress: "",
		dataSourceLocal: [{ id: 0, name: 'Pendiente' }, { id: 1, name: 'Parcial' }, { id: 2, name: 'Completo' }]
	},
	{
		name: "from",
		title: "from",
		htmlType: "date",
		dbType: "date",
		containerClass: "col-4",
	},
	{
		name: "until",
		title: "until",
		htmlType: "date",
		dbType: "date",
		containerClass: "col-4",
	},
	{
		name: "search",
		title: "search",
		htmlType: "text",
		dbType: "date",
		containerClass: "col-4",
	},
]

export default InvoiceFilterFields;