import React from "react"
import { Modal, Form, InputGroup, Row, Col } from "react-bootstrap"
import { useFormik } from "formik"
import * as yup from "yup"
import API from "../../common/API"
import { useTranslation } from "react-i18next"

const EditProfile = props => {
  const { t } = useTranslation("global")
  console.log(props)
  const handleSubmit = async obj => {
    try {
      let body = {
        id: props.model.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
      }

      let requestAPI = await API.postAction("Accounts/AddEditGeneralInfo", body)

      console.log(requestAPI)
      if (requestAPI.data.status === "ok") {
        props.toggle(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const validationSchema = yup.object({
    firstName: yup.string().required("FirstName is a required field"),
    lastName: yup.string().required("LastName is a required field"),
    email: yup.string().required("E-mail is a required field"),
  })
  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          firstName: props.model?.firstName,
        },

    validationSchema,
    onSubmit: handleSubmit,
  })
  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("profile")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <label>{t("firstName")}</label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />

              {formik.errors.firstName ? (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <label>{t("lastName")}</label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />

              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>
            <Col xs={12} md={12}>
              <label>E-Mail</label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />

              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-block btn-primary" type="submit">
            
              <span
                className="spinner-border spinner-border-sm mx-4"
                role="status"
                aria-hidden="true"
              ></span>
            
            {t("save")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditProfile
