import React, { useEffect } from "react"
import { Row } from "react-bootstrap"
import InvoicePendingPaymentMonthly from "./InvoicePendingPaymentMonthly"
import TotalBilledMonthly from "./TotalBilledMonthly"
import CustomersByRank from "./CustomersByRank"
import Summary from "./Summary"
import ExpensesByCategory from "./ExpensesByCategory"

import ExpenseTotalMonthly from "./ExpenseTotalMonthly"
import BilledByType from "./BilledByType"

import { useHistory } from "react-router-dom"

//Translation
import { useTranslation } from "react-i18next"
import Calendar from "../Agenda/Calendar"

const DashboardOverview = () => {
  const history = useHistory()

  

  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("webapp.name") + " - " + t("dashboard_overdview")
  })

  /*const [state, setState] = useState({
    UI: {
      showAddEditGeofence: false,
      GeofenceSelected: null,
    },
  })*/

  //const handleApiLoaded = (map, mapsReference) => {}

  return (
    <Row>
      <div className="col-12 col-lg-6 ">
        <Summary />
        <div className="mt-4 row"></div>
        <CustomersByRank />
        <div className="mt-4 row"></div>
        <BilledByType />
        <div className="mt-4 row"></div>
        <ExpenseTotalMonthly />
        <ExpensesByCategory />
      </div>
      <div className="col-12 col-lg-6 ">
        <Calendar></Calendar>
        <div className="mt-4 row"></div>
        <TotalBilledMonthly />
        <div className="mt-4 row"></div>
        <InvoicePendingPaymentMonthly />
        <div className="mt-4 row"></div>
      </div>
      <div className="col-12 col-md-4"></div>
      <div className="col-12 col-md-4"></div>
    </Row>
  )
}

export default DashboardOverview
