import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getAbbreviation } from "../../common/helpers"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import Files from "../files"
import { confirmAlert } from "react-confirm-alert"
import DiagnosticList from "./diagnostic/DiagnosticList"
import { useParams } from "react-router"

const MaintenanceDetailsExternal = props => {
  const [note, setNote] = useState("")
  const [statuses, setStatuses] = useState([])
  const user = parsedUser()
  const [notes, setNotes] = useState([])
  const [model, setModel] = useState(null) // props.record
  console.log(model)
  const { t } = useTranslation("global")
  const { id } = useParams()

  // NOTES MANAGEMENT
  const bindRemoteNotes = async () => {
    if (model === null) return
    const request = await API.getAction(
      "workshop/ListComments?workshopId=" + id
    )
    console.log(request)
    setNotes(request.data.response)
  }

  const bindDataStatus = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    let requestAPI = await API.getAction("workshop/ListStatus", Query)

    let s = requestAPI.data.response
    let final = s.map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.name,
      }
    })
    setStatuses(final)
  }

  const bindDataDetails = async () => {
    try {
      const request = await API.getAction("workshop/Details?id=" + id)
      console.log(request)
      setModel(request.data.response)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    bindRemoteNotes()
    bindDataStatus()
  }, [model])
  // END NOTES MANAGEMENT
  useEffect(() => {
    bindDataDetails()
  }, [])

  if (
    (model == null && !model) ||
    !model.product ||
    model.product === undefined
  ) {
    return <></>
  }
  return (
    <Container>
      <Row className="mx-0 w-100">
        <Col>
          <h5 className="d-inline">
          {`${model.product.brand ?? ""}  ${model.product.name} (${
                  model.product.barcode
                })`}
          </h5>
        </Col>
        <Col xs={"auto"} className="ml-auto">
          <small className="text-muted me-2">
            {moment(model.created).fromNow()}
          </small>
        </Col>
      </Row>
      <Row>
        <Col>
        <Card>
          <Card.Body>
          <table className="table">
            <tbody>
             
              <tr>
                <th>{t("status")}</th>
                <td>
                  <i
                    className="fa fa-circle me-2"
                    style={{ color: model.status.color }}
                  ></i>
                  {`${model.status.name}`}{" "}
                </td>
              </tr>
              <tr>
                <th>{t("assignee")}</th>
                <td>
                  <span className=" avatar me-2">
                    <i>
                      {getAbbreviation(
                        model.assigned.firstName +
                          " " +
                          model.assigned.lastName ?? ""
                      )}
                    </i>
                  </span>
                  {`${model.assigned.firstName} ${
                    model.assigned.lastName ?? ""
                  }`}
                </td>
              </tr>
              <tr>
                <th>{t("customer")}</th>
                <td>
                
                  <span>{`${model.customer.firstName}`}</span>
                </td>
              </tr>
            </tbody>
          </table>
          </Card.Body>
        </Card>
          <h4 className="my-4">{t("diagnostics")}</h4>
          <DiagnosticList parentId={model.id} readOnly />

          <h4 className="my-4">{t("notes")}</h4>
          <Card className="my-4">
            {/* <Card.Header className="p-2">{t("notes")}</Card.Header> */}
            <Card.Body className="p-2">
              <Row>
                {notes.map((item, index) => {
                  return (
                    <Col key={index} xs={12}>
                      <small className="text-muted">
                        {moment(item.created).fromNow()}
                      </small>
                      <br />
                      <b>
                        {item.assignedTo.firstName} {item.assignedTo.lastName}
                      </b>
                      <br />
                      {item.description}
                      {index + 1 !== notes.length && <hr />}
                    </Col>
                  )
                })}
                {notes.length === 0 && (
                  <Col xs={12} className="text-center">
                    {t("no_notes")}
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
          {/* <Tabs defaultActiveKey={"notes"} className="mx-0 px-0">
            <Tab eventKey={"notes"} title={t("notes")}>
            
            </Tab>

            <Tab eventKey={"diagnostic"} title={t("diagnostic")}>
              
            </Tab>
            <Tab eventKey={"files"} title={t("files")}>
              <Files WorkshopRecordId={model.id} />
            </Tab>
          </Tabs> */}
        </Col>
      </Row>
    </Container>
  )
}

export default MaintenanceDetailsExternal
