import React, { useState } from 'react'


import PayrollList from './PayrollList';
const Index = props => {
    return (
        <PayrollList Full />

    )
}
export default Index;