import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';

import 'react-quill/dist/quill.snow.css'; // ES6
import API from '../../../common/API';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import GetCurrentUser, { parsedUser } from '../../../common/GetCurrentUser';

//Validation
const validationSchema = yup.object({
    name: yup.string().required("required"),
    organizationId: yup.string().required(),

});
//Component
const AddDiscountsForEmployee = props => {
    const [state, setState] = useState({

        UI: {
            isLoading: false
        },
        Data: {}

    })

    const requestDiscountsAvailable = async () => {
        try {
            let currentUser = parsedUser();
            let query = "EmployeeID=" + props.employeeId + "&OrganizationId=" + currentUser.OrganizationId;
            let request = await API.getAction("Employees/DiscountsPerEmployee", query);
            if (request.status === 200) {
                setState({
                    ...state,
                    Data: request.data.response
                })

            }
        }
        catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        console.log("props received on post", props);
        requestDiscountsAvailable();
    }, [])

    const handleSubmit = async obj => {
        console.log("values to submit", obj);

        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
        }
        let request = await API.postAction("Post/AddEdit", obj);
        if (request.status === 200) {
            props.toggle(request.data, true);
        }
    };


    const toggleSelect = async obj => {
        console.log("obj selected", obj)
        let copy = state.Data
        copy.forEach(async item => {
            let currentObj = item;
            if (currentObj.discountID === obj.discountID) {
                console.log("obj selected inside ", obj, currentObj)
                item.marked = !item.marked;
                await API.postAction("employees/AddEditDiscountForEmployee", item);
            }
        });

        setState({
            ...state,
            Data: copy
        })
    }

    return (
        <Modal show={props.show} onHide={() => props.toggle(null, true)} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Descuentos</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {state.Data.length > 0 && state.Data.map((item, index) => {
                    let itemParsed = item
                    return (
                        <Row key={index} className="clickable" onClick={() => toggleSelect(itemParsed)}>
                            <Col className="col-auto">
                                {itemParsed.marked ?
                                    <i className="fa fa-check-square" /> :
                                    <i className="fa fa-square" />}
                            </Col>
                            <Col>{itemParsed.discountName} {itemParsed.discountPercentage ? itemParsed.discountValue + "% " : itemParsed.discountValue}</Col>
                        </Row>
                    )
                })}

            </Modal.Body>
        </Modal>
    )
}

export default AddDiscountsForEmployee;