/**
 * TRADUCIR
 */

import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import "../../css/app_print.css"
import { Routes } from "../../routes"

const CustomerBalance = props => {
  const [state, setState] = useState(null)
  const [status, setStatus] = useState(0)

  const request = async () => {
    let requestAPI = await API.getAction(
      `Customer/CustomerBalance?Id=${props.match.params.id}&status=${status}`
    )

    if (requestAPI.data.status === "ok") {
      setState(requestAPI.data.response)
    }
  }

  useEffect(() => {
    request()
  }, [status])
  useEffect(() => {
    request()

    setTimeout(() => {
      if (window.location.href.includes("printExecute")) {
        window.print()
      }
    }, [1000])
  }, [])

  if (state === null) {
    return <></>
  }
  let from0To30Total = 0,
    from30To60Total = 0,
    from60To90Total = 0,
    moreThan90Total = 0,
    paidTotal = 0,
    total = 0
  return (
    <div className="row mx-0">
      <div className="col-10 mx-auto">
        <section className="invoice bg-white">
          <div className="row m-2">
            <div className="col-12">
              <h3 className="page-header text-left">
                {!state.organization.logo ? (
                  <img
                    src={`/Files/${state.organization.logo}?h=100`}
                    title={state.organization.name}
                    alt={state.organization.name}
                    style={{ maxHeight: "100px" }}
                  />
                ) : (
                  state.organization.name
                )}
              </h3>
            </div>
            {/* ORGANIZATION */}
            <div className="col-6">
              <address>
                <strong>{state.organization?.name}</strong>
                <br />
                {state.organization.identifier}
                <br />
                {`${state.organization.address ?? ""}, ${
                  state.organization.city ?? ""
                }, ${state.organization.zipCode ?? ""}, ${
                  state.organization.country ?? ""
                }`.replace(", ,", ", ")}
                <br />

                {state.organization.phone ? (
                  <>
                    {`Teléfono: ${state.organization.phone}`}
                    <br />
                  </>
                ) : null}

                {state.organization.email
                  ? `Email: ${state.organization.email}`
                  : null}
              </address>
            </div>
            {/* CUSTOMER */}
            <div className="col-6 text-right">
              <address>
                <strong>{`${state.customer.name}`}</strong>
                <br />
                {state.customer.identifier}
                <br />
                {`${state.customer.address ?? ""}, ${
                  state.customer.city ?? ""
                }, ${state.customer.zipCode ?? ""}, ${
                  state.customer.country ?? ""
                }`.replace(", ,", ", ")}
                <br />
                {state.phone ? (
                  <>
                    {`Teléfono: ${state.customer.phone}`}
                    <br />
                  </>
                ) : null}

                {state.email ? `Email: ${state.customer.email}` : null}
              </address>
            </div>

            <div className="col-12 mt-4">
              <h2 className="text-center text-uppercase">
                Estado de Cuenta {moment().format("MMM DD, YYYY")}
              </h2>
              <hr />
            </div>
            <div className="col-auto d-print-none d-none">
              <label>Tipos de factura</label>
              <select id="typeid" className="form-control" name="typeid">
                <option value="">Todos</option>
              </select>
            </div>
            <div className="col-auto d-print-none ">
              <label>Estatus</label>
              <select
                id="status"
                className="form-control"
                value={status}
                onChange={e => {
                  setStatus(e.target.value)
                }}
                name="status"
              >
                <option value="5">Todas</option>
                <option value="0">Pendiente</option>
                <option value="1">Parcial</option>
                <option value="2">Completado</option>
              </select>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-12 ">
              {state.data &&
                Array.isArray(state.data) &&
                state.data.map((itemMain, indexMain) => {
                  return (
                    <div className="table-responsive" key={indexMain}>
                      <p>
                        <h2>Moneda: {itemMain.currency.name}</h2>
                      </p>
                      <table
                        id="details"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Fecha</th>
                            <th>Factura</th>

                            <th>Pago</th>
                            <th>Pendiente de 0 a 30 días</th>
                            <th>Pendiente de 30 a 60 días</th>
                            <th>Pendiente de 60 a 90 días</th>
                            <th>Pendiente más de 90 días</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemMain.records.map((item, index) => {
                            let from0To30 = 0
                            let from30To60 = 0
                            let from60To90 = 0
                            let moreThan90 = 0
                            let paid = 0
                            item.invoiceDetails.forEach(x => {
                              total +=
                                (x.price + x.tax - x.discount) * x.quantity
                            })
                            item.invoicePayment
                              .filter(x => x.deleted === null)
                              .forEach(x => {
                                paid = x.payment
                              })
                            total += -paid
                            paidTotal += paid
                            if (
                              item.paidStatus != null &&
                              item.paidStatus == 2
                            ) {
                              console.log("item paid status", item)
                            } else {
                              var now = moment()
                              var duration = moment.duration(
                                now.diff(item.created)
                              )
                              var days = duration.asDays()
                              console.log("days", days)
                              if (days <= 30) {
                                item.invoiceDetails.forEach(x => {
                                  from0To30 +=
                                    (x.price + x.tax - x.discount) * x.quantity
                                })
                                from0To30 = from0To30 - paid
                                from0To30Total += from0To30
                              }
                              if (days > 30 && days <= 60) {
                                item.invoiceDetails.forEach(x => {
                                  from30To60 +=
                                    (x.price + x.tax - x.discount) * x.quantity
                                })
                                from30To60 = from30To60 - paid
                                from30To60Total += from30To60
                              }
                              if (days > 60 && days <= 90) {
                                item.invoiceDetails.forEach(x => {
                                  from60To90 +=
                                    (x.price + x.tax - x.discount) * x.quantity
                                })
                                from60To90 = from60To90 - paid
                                from60To90Total += from60To90
                              }
                              if (days > 90) {
                                item.invoiceDetails.forEach(x => {
                                  moreThan90 +=
                                    (x.price + x.tax - x.discount) * x.quantity
                                })
                                moreThan90 = moreThan90 - paid
                                moreThan90Total += moreThan90
                              }
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  {moment(item.created).format("MMM DD, YYYY")}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-link"
                                    onClick={() => {
                                      window.open(
                                        Routes.InvoicePrint.clean + item.id,
                                        "_blank"
                                      )
                                    }}
                                  >
                                    {item.invoiceNumber}
                                  </button>
                                </td>

                                <td>{FormatMoney(paid)}</td>
                                <td>{FormatMoney(from0To30)}</td>
                                <td>{FormatMoney(from30To60)}</td>
                                <td>{FormatMoney(from60To90)}</td>
                                <td>{FormatMoney(moreThan90)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <th>
                              <h3>{FormatMoney(paidTotal)}</h3>
                            </th>
                            <th>
                              <h3>{FormatMoney(from0To30Total)}</h3>
                            </th>
                            <th>
                              <h3>{FormatMoney(from30To60Total)}</h3>
                            </th>
                            <th>
                              <h3>{FormatMoney(from60To90Total)}</h3>
                            </th>
                            <th>
                              <h3>{FormatMoney(moreThan90Total)}</h3>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                      <p>
                        <h2>Total pendiente: {FormatMoney(total)}</h2>
                      </p>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CustomerBalance
