import React, { useEffect } from "react"
import { Routes } from "../../routes"
import ProductList from "./ProductList"

//Translation
import { useTranslation } from "react-i18next"
import { productTypes } from "../../common/helpers"

const Index = () => {
  const { t } = useTranslation("global")

  useEffect(() => {
    document.title = t("products")
  })
  return <ProductList productType={productTypes.product} />
}
export default Index
