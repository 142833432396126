import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"
import * as yup from "yup"
import { Formik, useFormik } from "formik"
import API from "../../common/API"
import moment from "moment"

import { parsedUser } from "../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"
import { ToDoListProps } from "./TodoListProps"

interface AddToDoProps
{
  toggle: (val: boolean | undefined) => void
}
//Component
const AddToDo = (props: AddToDoProps) => {

//Validation
const validationSchema = yup.object({
  name: yup.string().required("required"),
})
  const user = parsedUser()
  const { t } = useTranslation("global")

  useEffect(() => {
    console.log("props received on post", props)
  }, [])

  const handleSubmit = async( obj  : ToDoListProps) => {
    console.log("values to submit", obj)
    
    obj.assignedToId = user.ID
    const request = await API.postAction(
      "ToDo/AddEdit?currentUserId=" + user.ID,
      obj
    )
    if (request.status === 200) {
      props.toggle(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
    } as ToDoListProps,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="mx-0">
        <Col xs={12}>
          <label htmlFor="name">{t("name")}</label>
          <input
            type="text"
            className="form-control "
            id="name"
            placeholder=""
            value={formik.values.name}
            onChange={formik.handleChange}
          />

          {formik.errors.name ? (
            <div className="invalid text-sm">{formik.errors.name}</div>
          ) : null}
        </Col>

        <Col className="col-12 text-end mt-2">
          <button
            className="btn me-2"
            onClick={() => props.toggle(false)}
            type="button"
          >
            <i className="fa fa-times"></i>
          </button>

          <Button variant="primary" type="submit" className="btn-sm">
            <i className="fa fa-save"></i>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AddToDo
