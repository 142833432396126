import React from "react"

import { Modal } from "react-bootstrap"
//import * as yup from "yup"
//import { Formik, useFormik } from "formik"
//import ReactQuill from "react-quill" // ES6
import "react-quill/dist/quill.snow.css" // ES6
//import { onlyHTMLTags } from "../../common/helpers"
//import API from "../../common/API"
//import moment from "moment"
//import { v4 as uuidv4 } from "uuid"

//Translation
import { useTranslation } from "react-i18next"

//Component
const ExportModal = props => {
  const { t } = useTranslation("global")

  const print = () => {
    window.open(props.path + "&printExecute", "_blank")
  }
  return (
    <Modal show={props.show} onHide={props.toggle} size="xl" className="full">
      <Modal.Header closeButton>
        <Modal.Title>{t("export")}</Modal.Title>
        <button
          className="btn btn-sm btn-primary mx-1"
          onClick={() => {
            print()
          }}
        >
          <i className="fas fa-print"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
        <iframe id="ModalIframe" src={props.path}></iframe>
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
