import moment from "moment"
import React, { useEffect, useState } from "react"

import API from "../../../common/API"

import Settings from "../../../common/Settings"
import "../../../css/app_print.css"

//Translation
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Container } from "react-bootstrap"
type paramsDTO = {
  id: string
}
const DiagnosticPrint = props => {
  const [state, setState] = useState<any | null>(null)
  const { id } = useParams<paramsDTO>()
  const { t } = useTranslation("global")

  const request = async () => {
    let requestAPI = await API.getAction("workshop/DiagnosticPrint?Id=" + id)
    console.log(requestAPI.data.response)
    if (requestAPI.data.status === "ok") {
      setState(requestAPI.data.response)
    }
  }

const composedAddress = ()=>{
  let address = "";
  if(state.organizationAddress){
    address = state.organizationAddress
  }
  if(state.organizationCity){
    address = address + ", " + state.organizationCity
  }
  if(state.organizationZipCode){
    address = address + ", " + state.organizationZipCode
  }
  if(state.organizationCountry){
    address = address + ", " + state.organizationCountry
  }
  return address
}

const composedCustomerAddress = ()=>{
  let address = "";
  if(state.customerAddress){
    address = state.customerAddress
  }
  if(state.customerCity){
    address = address + ", " + state.customerCity
  }
  if(state.customerZipCode){
    address = address + ", " + state.customerZipCode
  }
  if(state.customerCountry){
    address = address + ", " + state.customerCountry
  }
  return address
}

  useEffect(() => {
    request()
  }, [])

  if (state === null) {
    return <></>
  }

  return (
    <Container className="my-4">
      <section className="invoice bg-white">
        <div className="row m-2">
          <div className="col-8">
            <h3 className="page-header text-left">
              {state.organizationLogo ? (
                <img
                  src={`${Settings.BasePath}Files/${state.organizationLogo}?h=100`}
                  title={state.organizationName}
                  alt={state.organizationName}
                  style={{ maxHeight: "100px" }}
                />
              ) : (
                state.organizationName
              )}
            </h3>
          </div>
          
        </div>

        <div className="row invoice-info m-2">
          <div className="col-6">
            <div className="col-12 ">
              <address>
                <strong>{state.organizationName}</strong>
                <br />
                {state.organizationIdentifier}
                <br />
                {composedAddress()}
                <br />

                <br />
                {state.organizationPhone ? (
                  <>
                    {t("phone")}
                    {`: ${state.organizationPhone}`}
                    <br />
                  </>
                ) : null}

                {state.organizationEmail ? (
                  <>
                    {t("email")}
                    {`: ${state.organizationEmail}`}
                  </>
                ) : null}
              </address>
            </div>
            <div className="col-sm-12 col-md-12 CustomerInfo">
              <address>
                <strong>{`${state.customerName}`}</strong>
                <br />
                {state.customerIdentifier??""}
                <br />
                {composedCustomerAddress()}
                <br />
                {state.customerPhone ? (
                  <>
                    {t("phone")}
                    {`: ${state.customerPhone}`}
                    <br />
                  </>
                ) : null}

                {state.customerEmail ? (
                  <>
                    {t("email")}
                    {`: ${state.customerEmail}`}
                  </>
                ) : null}
              </address>
            </div>
          </div>

          <div className="col-6 text-right">
            <b>{t("date")}: </b>
            {moment(state.date).format("MMM DD, YYYY")}
            <br />

            <b>{t("time")}: </b>

            {moment(state.date).format("HH:mm:ss ")}
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12 table-responsive">
            <table id="details" className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan={2} className="text-center ">
                  {t("Diagnostic")} 
                    </th>
                </tr>
                <tr>
                  <th className="col-md-6 py-1">{t("concept")}</th>
                  <th className="col-md-1 py-1 text-end">{t("quantity")}</th>
                </tr>
              </thead>
              <tbody>
                {state.details
                  .map((item, index) => {
                    console.log(item)
                    return (
                      <tr key={index}>
                        <td className="py-1 text-wrap">{item.itemConcept}</td>
                        <td className="py-1 text-end">{item.quantity}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-6">
            {state.note ? (
              <div
                className="text-muted well well-sm no-shadow"
                dangerouslySetInnerHTML={{
                  __html: state.note.replace(/\n/g, "<br />"),
                }}
              ></div>
            ) : null}
          </div>

          <div className="col-6">
            <p className="d-print-none my-4 ">
              <button
                className="btn btn-primary btn-block "
                type="button"
                onClick={() => {
                  window.print()
                }}
              >
                {t("print")}
              </button>
            </p>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default DiagnosticPrint
