import React from "react"
import ReportsContainer from "../core/ReportsContainer"
import { useTranslation } from "react-i18next"

import { parsedUser } from "../../common/GetCurrentUser"
import moment from "moment"
import HeaderField from "../core/HeaderField"
import SummaryField from "../core/SummaryField"
import CRUDField from "../core/genericCRUD/CRUDField"

const ReceivedPayments = (props: any) => {
  const { t } = useTranslation("global")
  const user = parsedUser()
  const filterFields: CRUDField[] = [
    {
      name: "from",
      title: t("from")??"",
      dbType: "date",
      htmlType: "date",
      containerClass: "col-4",
      value: moment().add(-1, "months").format("YYYY-MM-DD"),
    },
    {
      name: "until",
      title: t("until")??"",
      dbType: "date",
      htmlType: "date",
      containerClass: "col-4",
      value: moment().format("YYYY-MM-DD"),
    },
  ];

  const headers: HeaderField[] = [
    {
      name: "date",
      title: t("date"),
      type: "date",
    },

    {
      name: "customer",
      title: t("customer"),
      type: "string",
      class: "",
    },
    {
      name: "invoiceDate",
      title: t("invoiceDate"),
      type: "date",
      class: "",
    },
    {
      name: "invoiceNumber",
      title: t("invoiceNumber"),
      type: "string",
      class: "",
    },
    {
      name: "currency",
      title: t("currency"),
      type: "string",
    },
    {
      name: "payment",
      title: t("payment"),
      type: "money",
      class: "text-end",
    },
  ]
  const summaryFields: SummaryField[] = [
    {
      name: "payment",
      title: t("payment"),
      type: "money",
      class: "text-end",
      colspan: 6,
    },
  ]
  return (
    <ReportsContainer
      show={props.show}
      toggle={props.toggle}
      title={t("receivedPayments")}
      filterFields={filterFields}
      headers={headers}
      summaryFields={summaryFields}
      endpoint={`invoice/ReportReceivedPayments?organizationId=${user.OrganizationId}`}
    />
  )
}

export default ReceivedPayments
